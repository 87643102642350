const airRiderMenu = {
    items: [
      
      {
        id: "_dashboard",
        title: "Dashboard",
        type: "group",
        children: [
          {
            id: "dashboard",
            title: "Dashboard",
            type: "item",
            url: "/dashboard",
            //   icon: icons.IconDashboard,
            breadcrumbs: true,
          },
        ],
      },
      {
        id: "profile_",
        title: "My Profile",
        type: "group",
        children: [
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/my-profile",
            breadcrumbs: true,
          },
        ],
      },
      {
        id: "settings_",
        title: "Settings",
        type: "group",
        children: [
          {
            id: "settings",
            title: "Settings",
            type: "item",
            url: "/settings",
            breadcrumbs: true,
          },
        ],
      },
      {
        id: "help",
        title: "Help",
        type: "group",
        children: [
          {
            id: "help_",
            title: "Help",
            type: "collapse",
            //   icon: icons.IconDashboard,
            children: [
              {
                id: "faqs",
                title: "FAQ",
                type: "item",
                url: "/help/faqs",
                //   icon: icons.IconDashboard,
              },
              {
                id: "prohobited-item",
                title: "Prohobited Items",
                type: "item",
                url: "/help/prohobited-item",
                //   icon: icons.IconDashboard,
              },
              {
                id: "terms-conditions",
                title: "Terms & Conditions",
                type: "item",
                url: "/help/terms-conditions",
                //   icon: icons.IconDashboard,
              },
              {
                id: "privacy-policy",
                title: "Privacy Policy",
                type: "item",
                url: "/help/privacy-policy",
                //   icon: icons.IconDashboard,
              },
            ],
          },
        ],
      },
      {
        id: "support_",
        title: "Support",
        type: "group",
        children: [
          {
            id: "support",
            title: "Support",
            type: "item",
            url: "/support",
            breadcrumbs: true,
          },
        ],
      },
    ],
  };
  
export default airRiderMenu;