import { Grid } from "@mui/material";
import useStyles from "routes/style-jss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTime } from "store/breadcrumbReducer";
import { Circle } from "@mui/icons-material";

import { getDataFromLocalStorage } from "utils/LocalStore.util";

const Breadcrumbs = () => {
  const classes = useStyles() as any;
let getSettingsData = getDataFromLocalStorage("settings")

  let BreadcrumbTitle = useSelector(
    (state: RootState) => state.breadcrumb
  );
  const dispatch = useDispatch<any>();
  let BreadcrumbTime = useSelector((state: RootState) => state.breadcrumb.time);

  useEffect(() => {
    let interval = setInterval(() => {
      let date = new Date();
      dispatch(setTime(JSON.stringify(date) as any));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes.breadcrumb_container}>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          md={7}
          item
          className={classNames(classes.align_center, classes.breadcrumb_head)}
        >
          {BreadcrumbTitle?.title || ""}
          {BreadcrumbTitle?.sub_title && (
            <Circle style={{ margin: "0px 15px", fontSize: "1.2rem" }} />
          )}
          {BreadcrumbTitle?.sub_title || ""}
        </Grid>
        <Grid
          xs={12}
          md={5}
          item
          className={classNames(classes.align_end, classes.direction_column)}
        >
          <div className={classNames(classes.breadcrumb_time)}>
            {moment(JSON.parse(BreadcrumbTime)).format(getSettingsData?.time_format ?? "hh:mm A")}
          </div>
          <div className={classNames(classes.breadcrumb_description)}>
            {moment().format("dddd DD MMMM YYYY")}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Breadcrumbs;
