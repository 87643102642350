import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { firebaseConfig } from "./constant";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = async () => {
  try {
    const notif = await Notification.requestPermission();
    if (notif === "granted") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getDeviceToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BDnHYaA-4X40aSKMrCQi8glsCZTYoO9WsNv1Xpyf5Avha3QjRDkO5E-Olc9uzH2iOq11pdF4naWEvtoaOO25cq8",
  })
    .then((currentToken) => {
      if (currentToken) {
      
        return currentToken;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

onMessage(messaging, (payload: any) => {
  navigator.serviceWorker
    .getRegistration("/firebase-cloud-messaging-push-scope")
    .then((registration: any) => {
      registration.showNotification(payload.notification.title, {
        icon: payload.notification.image,
      });
    });
});
