import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { my_driver } from "dummy-data";
import apiServices from "services/RequestHandler";

export interface driverState {
  all_drivers: any;
  all_drivers_data: any;
  selected_driver: object;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: driverState = {
all_drivers: [],
all_drivers_data: [],
selected_driver: {},
activity: true,
};

export const driverRequests = createAsyncThunk(
  "countries/web",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "drivers");
      response = my_driver;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setSelectedDriver: (state: driverState, action: any) => {
       state.selected_driver = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(driverRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(driverRequests.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          orders.push([
            item?.driver_id || "- - -",
            item?.driver_name || "- - -",
            item?.status || "- - -",
            item?.total_jobs || "- - -",
            item?.assigned ? "Yes" : "No",
            item?.pending || "- - -",
            item?.totalPaid || "0.00"
          ]);
        }
        state.all_drivers_data = payload?.data;
        state.all_drivers = orders;
        state.activity = false;
      });
  },
});

export const { setSelectedDriver } = driverSlice.actions;

export default driverSlice.reducer;