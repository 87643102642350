export const gridSpacing = 3;
export const drawerWidth = 240;
export const appDrawerWidth = 320;

export const all_fonts = {
    Roboto: {
      font_a: "Roboto-Black",
      font_b: "Roboto-Bold",
      font_c: "Roboto-Medium",
      font_d: "Roboto-Regular",
      font_e: "Roboto-Italic",
      font_f: "RobotoCondensed-Bold",
      font_g: "RobotoCondensed-Medium",
      font_h: "RobotoCondensed-Regular",
    },
  };