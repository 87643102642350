import Strings from "store/Localization";

export const firebaseConfig = {
  apiKey: "AIzaSyDCv2EROhs5ttKupy_-BAbbf1EJWy0A1qE",
  authDomain: "frankme-f966f.firebaseapp.com",
  databaseURL: "https://frankme-f966f-default-rtdb.firebaseio.com",
  projectId: "frankme-f966f",
  storageBucket: "frankme-f966f.appspot.com",
  messagingSenderId: "642076474590",
  appId: "1:642076474590:web:2c5e8fae327933975c564f",
  measurementId: "G-2KMH34E8LX",
};

export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#EEEEEE",
  color_h: "#F8F8F8",
  main_orange: "#EE6931",
  light_orange: "#F9C9B3",
  sand: "#F9D9B6",
  main_blue: "#3026F1",
  dark_blue: "#191847",
  orange_yellow: "#D3BE00",
  green: "green",
  red: "#F10000",
  labelColor: "#364152",
};

export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];

export const snackColors = {
  error: "#b53a40",
  success: "#32a852",
  warning: "#e3ce46",
};

export const vehicleTypes = [
  { label: "Car", value: "Car" },
  { label: "Truck", value: "Truck" },
  { label: "Van", value: "Van" },
  { label: "Motor Bike", value: "Motor Bike" },
];

export const weekdays = [
  { label: "Mon", value: "Mon" },
  { label: "Tue", value: "Tue" },
  { label: "Wed", value: "Wed" },
  { label: "Thu", value: "Thu" },
  { label: "Fri", value: "Fri" },
  { label: "Sat", value: "Sat" },
  { label: "Sun", value: "Sun" },
];

export const frequency = [
  {
    label: "Repeat Every Week",
    value: "Repeat Every Week",
  },
  { label: "Once", value: "Once" },
  { label: "Date Range", value: "Date Range" },
];

export const circleOptions = {
  fillColor: "gray",
  fillOpacity: 0.5,
  strokeWeight: 1,
  strokeColor: "DrakGray",
  clickable: false,
  editable: true,
  zIndex: 1,
};

export const TRANSPORTER_TYPES = [
  { label: "Fleet Manager", icon: "fleet", type: "Fleet Manager" },
  { label: "Rider", icon: "rider", type: "Rider" },
  { label: "Sub Rider", icon: "sub_rider", type: "Sub Rider" },
  // { label: "Air Rider", icon: "air", type: "Air Rider" },
];

export const INFO_BOX = [
  { index: 1, data: "Tap search button to search for a specific area." },
  {
    index: 2,
    data: "Press anywhere on the mao to create a point. atleast add 3 points.",
  },
  { index: 3, data: "Press the Draw button when you have added the points." },
  { index: 3, data: "Press Save button to save your service area." },
];

export const DUMMY_NOTIFICATION = [
  { message: "New Order Arrived", data: "1 days ago" },
  {
    message: "New Sub-Rider Added",
    data: "3 days ago",
  },
  { message: "Parcel Arrived at Space", data: "5 days ago" },
  { message: "#ODR-432-5553 Order Cancelled ", data: "a month ago" },
];

export const label_to_value = {
  vehicle_reg_no: Strings.VEHICLE_REG_NUMBER,
  vehicle_make: Strings.VEHICLE_MAKE,
  vehicle_type: Strings.VEHICLE_TYPE,
  vehicle_reg_country_id: Strings.VEHICLE_COUTRY,
  vehicle_reg_city_id: Strings.VEHICLE_CITY,
  vehicle_reg_owner: Strings.VEHICLE_OWNER,
  vehicle_model: Strings.VEHICLE_MODEL,
  vehicle_color: Strings.VEHICLE_COLOR,
  payload_capacity: Strings.VEHICLE_LOAD_CAPACITY,
  vehicle_width: Strings.VEHICLE_WIDTH,
  vehicle_height: Strings.VEHICLE_HEIGHT,
  vehicle_weight: Strings.VEHICLE_WEIGHT,
  vehicle_image1: Strings.VEHICLE_IMGAE_1,
  vehicle_image2: Strings.VEHICLE_IMGAE_2,
  vehicle_document1: Strings.VEHICLE_DOCUMENT_1,
  is_green_type:Strings?.IS_GREEN_TYPE,
  vehicle_document2: Strings.VEHICLE_DOCUMENT_2,
  verfication_status: Strings.VERIFICATION_STATUS,
  Activation_status: Strings.ACTIVATION_STATUS,
  Profile_Name: Strings.PROFILE_NAME,
  Email: Strings.EMAIL,
  Phone_Number: Strings.PHONE_NUMBER,
  Ranking: Strings.RANKING,
  Ratings: Strings.RATINGS,
  Attach: Strings.ATTACH,
  Select_sub_rider: Strings.SELECT_SUB_RIDER,
  First_name: Strings.First_Name,
  Last_name: Strings.Last_Name,
} as any;

export const Size_Units = [
  {
    label: "m",
    value: 1,
  },
  {
    label: "cm",
    value: 2,
  },
  {
    label: "mm",
    value: 3,
  },
  {
    label: "in",
    value: 4,
  },
  {
    label: "ft",
    value: 5,
  },
];

export const Weight_Units = [
  { label: "kg", value: 1, unit: "kg" },
  { label: "metric ton", value: 2, unit: "mt" },
  { label: "ounce", value: 3, unit: "oz" },
  { label: "pound", value: 4, unit: "lb" },
  { label: "ton", value: 5, unit: "t" },
  { label: "g", value: 6, unit: "g" },
];

export const Volume = [
  { label: "l (litre)", value: 1 },
  { label: "gal (gallon)", value: 2 },
  { label: "bbl (barrel)", value: 3 },
];

export const Language = [
  { label: "English", value: "English" },
  { label: "French", value: "French" },
  { label: "Arabic", value: "Arabic" },
];

export const filterTypes = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Requests", value: "new" },
  { label: "History", value: "history" },
];

export const Date_Format = [
  { label: "D MMM YYYY", value: "D MMM YYYY" },
  { label: "DD-MMM-YYYY", value: "DD-MMM-YYYY" },
  { label: "DD-MMM-YY", value: "DD-MMM-YY" },
  { label: "DD.MMM.YY", value: "DD.MMM.YY" },
  { label: "DD-MM-YYYY", value: "DD-MMM-YY" },
  { label: "YYYY-MMM-DD", value: "YYYY-MMM-DD" },
];

export const Time_Format = [
  {
    label: "h:mm am/pm",
    value: "h:mm a",
  },
  {
    label: "hh:mm 24-hours",
    value: "HH:mm",
  },
  {
    label: "hh:mm am/pm",
    value: "hh:mm a",
  },
  {
    label: "hh:mm:ss 24-hours",
    value: "HH:mm:ss",
  },
  {
    label: "hh:mm:ss am/pm",
    value: "hh:mm:ss a",
  },
];

export const Distance_Unit = [
  { label: "km", value: "KM" },
  { label: "mile", value: "Mile" },
];

export const Banks_list = [
  { label: "Al Baraka Bank", value: "Al Baraka Bank" },
  { label: "Allied Bank", value: "Allied Bank" },
  { label: "Askari Bank ", value: "Askari Bank " },
  { label: "Bank Al Falah", value: "Bank Al Falah" },
  { label: "Bank Al Habib", value: "Bank Al Habib" },
  { label: "BankIslami ", value: "BankIslami " },
  { label: "Citibank ", value: "Citibank " },
  { label: "Dubai Islamic Bank", value: "Dubai Islamic Bank" },
  { label: "Faysal Bank", value: "Faysal Bank" },
  { label: "Habib Bank", value: "Habib Bank" },
  { label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
  { label: "Habib Metropolitan Bank ", value: "Habib Metropolitan Bank " },
  { label: "JS Bank ", value: "JS Bank " },
  { label: "MCB Bank", value: "MCB Bank" },
  { label: "Meezan Bank", value: "Meezan Bank" },
  { label: "National Bank", value: "National Bank" },
  { label: "Samba Bank", value: "Samba Bank" },
  { label: "Silkbank", value: "Silkbank" },
  { label: "Sindh Bank", value: "Sindh Bank" },
  { label: "Soneri Bank", value: "Soneri Bank" },
];
