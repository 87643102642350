import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { GetApiParams, PostApiParams } from "store";
import SnackBar from "ui-components/SnackBar/SnackBar";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

export interface subRiderState {
  subRiders: any;
  subRiders_data: any;
  selected_subRider: object;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

let getSettingsData = getDataFromLocalStorage("settings")

const initialState: subRiderState = {
  subRiders: [],
  subRiders_data: [],
  selected_subRider: {},
  activity: true,
};

export const subRiderRequests = createAsyncThunk(
  "transporters/get-all-subriders-by-fleetmnanger",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "subRiders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getSubRiderByIDRequests = createAsyncThunk(
  "transporters/sub-rider:/id",
  async (data: GetApiParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, `subRider`);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addSubRiderRequest = createAsyncThunk(
  "transporters/rider/add-sub-rider",
  async (data: PostApiParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.data,
        "AddSubRider"
      );
      if (response.status === 201) {
        SnackBar.showSuccess("Sub-Rider Added Succesfully");
      } else {
        SnackBar.Error(
          response.error.error ??
          response.error.message ??
          response.error.isEmpty ??
          response.error.isEnum ??
            `${JSON.stringify(response.error)}`.replaceAll('"', "")
        );
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const subRidersSlice = createSlice({
  name: "subRiders",
  initialState,
  reducers: {
    setSelectedSubRider: (state: subRiderState, action: any) => {
      state.selected_subRider = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subRiderRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(subRiderRequests.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          orders.push([
            item?.id || index + 1,
            `${item?.first_name || ""} ${item?.last_name || "- - -"}`,
            `${item?.phone_code || ""} ${item?.phone || "- - -"}`,
            item?.email || "- - -",
            item?.total_orders || "0",
            item?.verification_date
              ? moment(item?.verification_date).format(getSettingsData?.date_format ?? "DD-MM-YYYY")
              : "- - -",
            item?.is_active ? "Active" : "Inactive" || "Inactive",
            item?.is_available ? "Yes" : "No",
          ]);
        }
        state.subRiders_data = payload?.data;
        state.subRiders = orders;
        state.activity = false;
      })
      .addCase(getSubRiderByIDRequests.fulfilled, (state, { payload }) => {
        state.selected_subRider = payload.data as any;
        state.activity = false;
      });
  },
});

export const { setSelectedSubRider } = subRidersSlice.actions;

export default subRidersSlice.reducer;
