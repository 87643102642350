import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { earnings } from "dummy-data";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
 
let getSettingsData = getDataFromLocalStorage("settings");
export interface bankState {
  all_banks: any;
  all_banks_data: any;
  selected_bank: object;
  activity: boolean;
  paginate_data?: {
    current_count: any;
    // current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

interface APIParams {
  end_point: string;
}

const initialState: bankState = {
  all_banks: [],
  all_banks_data: [],
  selected_bank: {},
  activity: true,
};

export const bankRequests = createAsyncThunk(
  "banks",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "banks");
      console.log("response",response);
      return response;
      
    } catch (err) {
      console.log(err);
    }
  }
);

export const bankSlice = createSlice({
  name: "earning",
  initialState,
  reducers: {
    setSelectedBank: (state: bankState, action: any) => {
      state.selected_bank = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bankRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(bankRequests.fulfilled, (state, { payload }) => {
        let banks = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
           banks.push([
            item?.id || "- - -",
             item?.bank_name || "- - -",
            item?.account_title || "- - -",
            item?.iban_number|| "- - -",
            item?.account_number || "- - -",
            item?.is_primary ? "Yes" : "No",
            item?.is_active ? "Active" : "Inactive"
          ]);
        }
        state.all_banks_data = payload?.data;
        state.all_banks = banks;
        state.paginate_data = {
          current_count: payload?.current_count,
          // current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages ?? 1,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

export const { setSelectedBank } = bankSlice.actions;

export default bankSlice.reducer;
