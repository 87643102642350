import { createSlice } from "@reduxjs/toolkit";

export interface BreadcrumbState {
  title: string;
  sub_title: string;
  time: string;
  
}

const initialState: BreadcrumbState = {
  title: "Dashboard",
  sub_title: "",
  time: JSON.stringify(new Date()),

};

export const BreadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setTitle: (state: BreadcrumbState, action: any) => {
      state.title = action.payload;
    },
    setSubTitle: (state: BreadcrumbState, action: any) => {
      state.sub_title = action.payload;
    },
    setTime: (state: BreadcrumbState, action: any) => {
      state.time = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTitle, setTime,setSubTitle } = BreadcrumbSlice.actions;
export default BreadcrumbSlice.reducer;
