import { NewLogin, UploadDocument } from "./PageAsync";
import PropTypes from "prop-types";

export function AuthRoutes({ classes }: any) {
  const _AuthRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        element: <NewLogin classes={classes} />,
      },
      {
        path: "/login",
        element: <NewLogin classes={classes} />,
      },
      {
        path: "/*",
        element: <NewLogin classes={classes} />,
      },
    ],
  };

  return _AuthRoutes;
}

AuthRoutes.propTypes = {
  classes: PropTypes.object,
};
