import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";

// project imports
import { Logout, Settings } from "@mui/icons-material";
import {
  Person2Outlined,
  Fullscreen,
  FullscreenExit,
} from "@mui/icons-material";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "utils/LocalStore.util";
import { useNavigate } from "react-router-dom";
import SnackBar from "ui-components/SnackBar/SnackBar";
import DraggableDialog from "ui-components/DraggableDialog";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const elem = document.documentElement;
const Header = ({ handleLeftDrawerToggle, drawerOpen, props }: any) => {

   const theme = useTheme() as any;
  const [fullScreen, setFullScreen] = useState(false);
  const [appInfo, setAppInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const openFullScreen = () => {
    setFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAppInfo(false);
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      SnackBar.showSuccess("Logged out sucessfully");
      navigate("/");
    } catch (error) {}
  };
  console.log("classes",props);
  

  const getUserData = getDataFromLocalStorage("user");

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          marginLeft: drawerOpen ? "234px" : "0px",
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          justifyContent: drawerOpen ? "flex-end" : "flex-start",
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: "white",
              "&:hover": {
                color: theme.palette.frank.appLightPrimary,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon
              style={{
                fontSize: 38,
              }}
            />
          </Avatar>
        </ButtonBase>
        <div
          style={{
            fontSize: "22px",
            color: "#fff",
            justifyContent: drawerOpen ? "flex-end" : "flex-start",
            marginTop: "5px",
          }}
        >
          {getUserData?.transporter_type}
        </div>
      </Box>
      {/* header search */}
      <Box
        sx={{
          flexGrow: 1,
        }}
      ></Box>
      {/* notification & profile */}
      {fullScreen ? (
        <Tooltip title="Exit Full Screen" placement="bottom">
          <FullscreenExit
            style={{
              fontSize: 28,
              color: "white",
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={closeFullScreen}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Full Screen" placement="bottom">
          <Fullscreen
            style={{
              fontSize: 28,
              color: "white",
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={openFullScreen}
          />
        </Tooltip>
      )}

      <span
        style={{
          fontSize: 18,
          color: "white",
          marginRight: 20,
          cursor: "pointer",
        }}
        onClick={() => {
          setAppInfo(true);
        }}
      >
        About
      </span>

      <span
        style={{
          fontSize: 18,
          color: "white",
          marginRight: 20,
          cursor: "pointer",
        }}
        onClick={() => navigate("help/faqs")}
      >
        Help
      </span>

      <IconButton onClick={handleClick}>
        <Person2Outlined
          style={{
            fontSize: 28,
            color: "white",
            marginRight: 20,
            cursor: "pointer",
          }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/my-profile");
          }}
        >
          <Avatar style={{ color: "#3026F1" }} />{" "}
          {`${getUserData?.first_name} ${getUserData?.last_name}`}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            navigate("/settings");
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" style={{ color: "#3026F1" }} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" style={{ color: "#3026F1" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <DraggableDialog
        maxWidth="xs"
        open={appInfo}
        aria-labelledby="responsive-dialog-title"
        handleClose={handleClose}
        classes={props?.classes}
        dialog_title={`App Info`}
         dialogBody={
          <DialogContent>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <h3 style={{ color: "#d84315" }}>App Release</h3>
              </Grid>
              <Grid xs={6} item>
                <p>App Version</p>
              </Grid>
              <Grid xs={6} item>
                <p>β 0.0.1</p>
              </Grid>
              <Grid xs={6} item>
                <p>Latest Update</p>
              </Grid>
              <Grid xs={6} item>
                <p>17 June 2023</p>
              </Grid>
              <hr style={{ fontSize: "4px",background:'red',color:'red' }} />
              <Grid xs={12} item>
                <h3 style={{ color: "#d84315" }}>Tech Support</h3>
              </Grid>
              <Grid xs={12} item>
                <p>HiTech Prime PVT. LTD.</p>
                <p>info@hitech-prime.com</p>
                <p>(021) 38898067</p>
              </Grid>
            </Grid>
          </DialogContent>
        }
      />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

export default Header;
