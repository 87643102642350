import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "services/RequestHandler";
import { GetApiParams, PostApiParams } from "store";

export interface vehicleState {
  all_vehicle: any;
  all_vehicle_data: any;
  selected_vehicle: any;
  activity: boolean;
  panel: {
    vehicleDoc: boolean;
    vehicleInfo: boolean;
    assignVehicle: boolean;
  };
  paginate_data?: {
    current_count: any;
    // current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

const initialState: vehicleState = {
  all_vehicle: [],
  all_vehicle_data: [],
  selected_vehicle: {},
  activity: false,
  panel: {
    vehicleDoc: true,
    vehicleInfo: true,
    assignVehicle: true,
  },
};

export const vehicleRequests = createAsyncThunk(
  "vehicles/get",
  async (data: GetApiParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "vehicles");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getVehicleByIDRequests = createAsyncThunk(
  "vehicles:/id",
  async (data: GetApiParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, `vehicles`);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addVehicleRequest = createAsyncThunk(
  "vehicles",
  async (data: PostApiParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.data,
        "vehicles"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const vehicleSlice = createSlice({
  name: "vahicles",
  initialState,
  reducers: {
    setSelectedvehicle: (state: vehicleState, action: any) => {
      state.selected_vehicle = action.payload;
    },
    handleToggleAllPanels: (state: vehicleState, action: any) => {
      state.panel = {
        vehicleDoc: action.payload,
        vehicleInfo: action.payload,
        assignVehicle: action.payload,
      };
    },
    handleTogglePanel: (state: any, action: any) => {
      state.panel[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(vehicleRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(vehicleRequests.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          orders.push([
            item?.id || "- - -",
            item?.transporter_id || "- - -",
            item?.vehicle_type || "- - -",
            item?.vehicle_model || "- - -",
            item?.vehicle_reg_owner || "- - -",
            item?.vehicle_reg_no || "",
            item?.is_active === true ? "Active" : "Inactive" || "- - -",
          ]);
        }
        state.all_vehicle_data = payload?.data;
        state.all_vehicle = orders;
        state.paginate_data = {
          current_count: payload?.current_count,
          // current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages ?? 1,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(getVehicleByIDRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(getVehicleByIDRequests.fulfilled, (state, { payload }) => {
        state.selected_vehicle = payload.data as any;
        state.activity = false;
      });
  },
});

export const { setSelectedvehicle, handleToggleAllPanels, handleTogglePanel } =
  vehicleSlice.actions;
export default vehicleSlice.reducer;
