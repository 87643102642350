import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import { AuthRoutes } from "./AuthenticationRoutes";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

export function AppRoute() {
  const classes = useStyles() as any;
  let user = getDataFromLocalStorage("user");
  let pvt_routes = MainAppRoutes({ classes });
  let public_routes = AuthRoutes({ classes });

  // eslint-disable-next-line
  if (!user) {
    // eslint-disable-next-line
    return useRoutes([public_routes]);
  }
  // eslint-disable-next-line
  return useRoutes([pvt_routes]);
}
export default AppRoute;
