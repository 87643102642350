import { makeStyles } from "@mui/styles";
import { all_fonts } from "store/constants";
import { defaultLabelColors } from "utils/constant";

const useStyles = makeStyles((theme: any) => ({
  align_center: {
    display: "flex",
    alignItems: "center",
  },

  align_end: {
    display: "flex",
    alignItems: "flex-end",
  },

  justify_center: {
    display: "flex",
    justifyContent: "center",
  },

  direction_row: {
    flexDirection: "row",
  },

  direction_column: {
    flexDirection: "column",
  },

  display_flex_baseline: {
    display: "flex",
    alignItems: "baseline",
  },

  breadcrumb_container: {
    padding: "10px 40px",
    background: theme.palette.frank.sand,
    boxShadow:
      "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f",
    // position: "fixed",
    // width: "100%",
    marginBottom: 10,
  },

  breadcrumb_head: {
    color: "#000000" || theme.palette.frank.black,
    fontFamily: "Roboto-Regular",
    fontSize: 34,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  breadcrumb_time: {
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.black,
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  breadcrumb_description: {
    fontFamily: "Roboto-Regular",
    color: theme.palette.frank.black,
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  main_layout_padding: {
    padding: "30px 20px 50px 20px",
    backgroundColor: "#F6F6F6",
  },

  card_title: {
    fontSize: 20,
    fontFamily: "Roboto-Medium",
  },

  colored_input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.appSecondary,
    background: "#fff",
  },

  input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: theme.palette.frank.black,
  },

  info_table: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.color_f,
  },

  background_sand: {
    background: theme.palette.frank.sand,
  },

  background_light_gray: {
    background: "#e0e0e0",
  },

  table_head: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },

  normal_text: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },

  simple_normal_text: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },

  pagination_per_page: {
    padding: 5,
    borderRadius: 4,
    fontSize: 14,
    color: theme.palette.frank.appSecondary,
    background: theme.palette.frank.grey1,
    marginRight: 30,
  },

  color_secondary: {
    color: `${theme.palette.frank.appSecondary} !important`,
  },

  color_primary: {
    color: `${theme.palette.frank.appPrimary} !important`,
  },

  color_sand: {
    color: `${theme.palette.frank.appLightPrimary} !important`,
  },

  bg_gray: {
    backgroundColor: "#f6f6f6",
    background: "#f6f6f6",
  },

  primary_divider: {
    borderColor: theme.palette.frank.appPrimary,
    borderBottomWidth: "medium",
  },

  secondary_divider: {
    borderColor: theme.palette.frank.appSecondary,
    borderBottomWidth: "medium",
  },

  gray_divider: {
    borderColor: theme.palette.frank.grey1,
    borderBottomWidth: "medium",
    borderLeftWidth: "medium",
    borderRadius: 10,
  },

  pagination_container: {
    color: theme.palette.frank.appSecondary,
    "& ul": {
      "& li": {
        "& .Mui-selected": {
          background: theme.palette.frank.appSecondary,
          border: `1px solid ${theme.palette.frank.appSecondary}`,
          color: theme.palette.frank.sand,
          borderRadius: 3,
          "&:hover": {
            background: theme.palette.frank.appSecondary,
            color: theme.palette.frank.sand,
          },
        },
        "& button": {
          background: theme.palette.frank.grey1,
          color: theme.palette.frank.appSecondary,
        },
      },
    },
  },

  check_icon: {
    color: `${theme.palette.frank.appSecondary} !important`,
    height: 24,
    width: 24,
  },

  help_heading:{
fontSize:'22px',
color:theme.palette.frank.appSecondary,
fontFamily:'RobotoCondensed-Bold',
 
  },

  selected_row: {
    border: `2px solid ${theme.palette.frank.appSecondary}`,
  },

  unselected_row: {
    borderBottom: `none`,
    borderTop: `2px solid transparent`,
    borderLeft: `2px solid transparent`,
    borderRight: `2px solid transparent`,
  },

  input_label_style: {
    padding: "0px 0px 4px 10px",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#364152",
  },

  timePicker: {
    backgroundColor: `null !important`,
    width: "100%",
    fontFamily: `RobotoCondensed-Regular !important`,
  },

  accordion_panel: {
    border: "1px solid #f9d9b6",
  },

  accordion_head: {
    width: "100%",
    height: `38px !important`,
    background: `#f9d9b6 !important`,
    minHeight: `38px !important`,
    paddingLeft: "15px",
    borderRadius: "3px 3px 0px 0px",
    paddingRight: "15px",
    backgroundColor: `#f9d9b6 !important`,
  },

  accordion_headd: {
    width: "100%",
    height: `38px !important`,
    background: `#f9d9b6 !important`,
    minHeight: `38px !important`,
    paddingLeft: "15px",
    borderRadius: "3px 3px 0px 0px",
    paddingRight: "15px",
    backgroundColor: `#f9d9b6 !important`,
    fontSize:22
  },

  status_buttons_active: {
    border: "2px solid #ee6931",
    background: "#F7D9B6",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    fontFamily: "RobotoCondensed-Medium",
  },

  status_buttons_inactive: {
    background: "#CECECE",
    border: "2px solid gray",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    fontFamily: "RobotoCondensed-Medium",
  },

  color_red: {
    color: `red !important`,
  },

  text_1: {
    fontSize: 16,
    fontFamily: all_fonts.Roboto.font_c,
    color: "#181818",
  },

  accordion_title: {
    fontSize: "20px",
    fontFamily: "Roboto-Medium",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
  },

  accordion_titlee: {
    fontSize: "17px",
    fontFamily: "Roboto-Medium",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
  },

  button_width: {
    // maxWidth: 150,
    minWidth: 120,
    maxHeight: 43,
    minHeight: 43,
    textTransform: "none",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      minWidth: "0px",
      marginBottom: 5,
    },
  },

  contained_button: {
    background: theme.palette.frank.appSecondary,
    color: defaultLabelColors.color_h,
    transition: "1s",
    "&:hover": {
      background: theme.palette.frank.appSecondary,
      color: defaultLabelColors.color_h,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    
  },

  contained_orange_button: {
    background: theme.palette.frank.appPrimary,
    fontFamily:"Roboto-Bold",
    border:"1px solid #ee6931",
    color: "white",
    transition: "1s",
    "&:hover": {
      background: theme.palette.frank.appPrimary,
      color: "white",
      transition: "1s",
    },
  },

  outlined_button: {
    background: defaultLabelColors.color_h,
    color: theme.palette.frank.appSecondary,
    borderColor: theme.palette.frank.appSecondary,
    transition: "1s",
    "&:hover": {
      background: defaultLabelColors.color_h,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  disabled_button: {
    background: theme.palette.frank.grey1,
    color: theme.palette.frank.grey3,
    borderColor: theme.palette.frank.grey1,
    // transition: "1s",
  },

  modal_background: {
    backgroundColor: theme.palette.frank.appSecondary,
    background: theme.palette.frank.appSecondary,
    padding: "14px 20px",
  },
  
  modal_title: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    color: defaultLabelColors.color_h,
    letterSpacing: "0.015em",
  },

  card_label_style: {
    fontSize: 16,
    color: "gray",
    fontFamily: "Roboto-Medium",
  },

  card_value_style: {
    fontSize: 16,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Medium",
  },

  profile_name: {
    fontSize: 20,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Bold",
    letterSpacing: 1.5,
  },

  title_header: {
    background: theme.palette.frank.sand,
    padding: 2,
  },

  text_1_with_background: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: theme.palette.frank.appSecondary,
    padding: 10,
    paddingLeft: 20,
    color: theme.palette.frank.appSecondary,
    textTransform: "capitalize",
  },

  active_tab: {
    borderRadius: "5px 5px 0px 0px",
    color: theme.palette.frank.appSecondary,
    letterSpacing: ": 0.05em",
    fontSize: 18,
    minWidth: 250,
    fontFamily: "RobotoCondensed-Medium",
  },

  inactive_tab: {
    color: theme.palette.frank.grey3,
    letterSpacing: ": 0.05em",
    fontSize: 18,
    minWidth: 250,
    fontFamily: "RobotoCondensed-Medium",
  },

  accordion_head_container: {
    backgroundColor: `${theme.palette.frank.sand} !important`,
    background: `${theme.palette.frank.sand} !important`,
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "3px 3px 0px 0px ",
    minHeight: `38px !important`,
    height: `38px !important`,
  },

  accordion_heading: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Medium",
  },

  accordion_icons: {
    fontSize: 20,
    color: theme.palette.frank.grey3,
  },

  accordion_container: {
    border: `1px solid ${theme.palette.frank.sand}`,
  },

  accordion_detail_padding: {
    padding: "24px 32px 30px",
  },

  toggle_sections: {
    color: theme.palette.frank.appSecondary,
    fontSize: 16,
    paddingLeft: 15,
    fontFamily: "Roboto-Regular",
    cursor: "pointer",
  },

  view_image_text: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.frank.appSecondary,
  },

  setting_container: {
    background: "#fff",
    margin: 30,
    padding: 20,
    border: `2px solid ${theme.palette.frank.appLightPrimary}`,
    borderRadius: 2,
  },

  order_card: {
    background: "#fff",
    border: "1px solid #3026f1",
    position: "relative",
    boxShadow:
      "0px 4px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    cursor: "pointer",
  },

  inactive_tab_button: {
    color: "#A3A3A3",
    fontSize: 18,
    minWidth: 250,
    fontFamily: "RobotoCondensed-Medium",
    borderRadius: "5 5 0 0",
    letterSpacing: "0.05em",
  },

  subRider_name: {
    fontSize: 20,
    fontFamily: "Roboto-Bold",
    letterSpacing: "1.5px",
  },

  active_tab_button: {
    color: `#3026f1 !important`,
    fontSize: 18,
    minWidth: 250,
    fontFamily: "RobotoCondensed-Medium",
    borderRadius: "5 5 0 0",
    letterSpacing: "0.05em",
  },

  subRiderImg: {
    width: "100%",
    objectFit: "contain",
    height: 300,
    border: "1px solid rgb(174, 174, 174)",
    borderRadius: 5,
  },

  subRiderDocs: {
    width: "100%",
    objectFit: "contain",
    borderRadius: 5,
    height: "100%",
    
  },

  zoom: {
    padding: 10,
    transition: "transform .3s",
    margin: `0 auto`,
    "&:hover": {
      transform: `scale(1.02)`,
    },
  },

  text2: {
    fontFamily: "RobotoCondensed-Regular",
    fontSize: "15px",
    fontWeight: "none",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  card_content: {
    fontSize: "18px",
    fontFamily: "Roboto-Regular",
    borderTop: "1px solid #d1cfcf",
  },

  copy_pdf_icon: {
    color: "#626663",
    fontSize: 22,
    marginRight: 4,
    cursor: "pointer",
    "&:hover": {
      color: `#3026f1`,
      fontSize: 22,
    },
  },

  info_line: {
    fontSize: 18,
    color: "gray",
    fontFamily: "Roboto-Medium",
  },

  tracking_label: {
    fontSize: 20,
    fontFamily: "RobotoCondensed-Medium",
    fontWeight: 500,
  },

  order_details_heading: {
    letterspacing: 1.2,
    flex: 1,
    padding: "10px 20px",
    texttransform: "capitalize",
    fontFamily: "RobotoCondensed-Regular",
    color: `#3026f1 !important`,
    fontSize: 22,
  },

  error: {
    fontSize: "22px",
    color: "red",
  },

  divider: {
    borderColor: "#ee6931",
    borderBottomWidth: "medium",
    color: "#ee6931",
  },

  divider_gray: {
    marginTop: 15,
    borderColor: "#697586",
    borderBottomWidth: "small",
    color: "gray",
  },

  order_detail_light: {
    flex: 1,
    color: "#697586",
    fontFamily: "RobotoCondensed-Medium",
    fontSize: 14,
  },

  order_details_title: {
    color: " #f8f8f8",
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
    fontFamily: "Roboto-Medium",
  },

  order_detail_dark: {
    flex: 1,
    fontFamily: "Roboto-Medium",
    fontSize: 15,
  },

  marginLeft20: {
    marginLeft: "60px",
  },

  setting_icons: {
    fontSize: 60,
    color: "#3026f1",
  },

  settings_heading: {
    fontSize: 18,
    fontFamily: "Roboto-Medium",
  },

  setting_description: {
    color: "#666666",
    fontSize: 15,
    fontFamily: "Roboto-Regular",
  },

  sign_in_type_selected: {
    border: `2px solid ${theme.palette.frank.appPrimary}`,
  },

  sign_in_type_not_selected: {
    border: `2px solid ${theme.palette.frank.grey1}`,
  },

  upload_documents: {
    background: "#F6F6F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    width: "60px",
    height: "50px",
    marginLeft: "5px",
  },

  upload_document_parent: {
    boxShadow: "#9f9b9b 0px 3px 5px -1px",
    borderRadius: "8px",
    padding: "3px",
    height: "55px",
    marginTop: "13px",
    background: "#fff",
    display: "flex",
  },

  upload_document_content: {
    paddingLeft: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  setting_button: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: 10,
    transition: "0.5s",
    borderRadius: 10,
    "&:hover": {
      background: theme.palette.frank.appLightPrimary,
      cursor: "pointer",
      transition: "0.5s",
    },
  },

  dashboard_card_head: {
    fontSize: 30,
    color: theme.palette.frank.appSecondary,
    fontFamily: "RobotoCondensed-Medium",
  },

  dashboard_card_title: {
    fontSize: 16,
    color: theme.palette.frank.appSecondary,
    fontFamily: "Roboto-Medium",
  },

  dashboard_card_container: {
    padding: 10,
    border: `1px solid ${theme.palette.frank.appSecondary}`,
    borderRadius: 5,
    position: "relative",
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
  },
}));

export default useStyles;
