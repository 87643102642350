import MainLayout from "layout/MainLayout";
import {
  Support,
  Dashboard,
  DriverRequest,
  Faqs,
  FindOrder,
  MyDriver,
  MyEarning,
  MyRequests,
  MyVehicle,
  Notifications,
  PrivacyNPolicy,
  ProhobitedItem,
  SchedulePickup,
  Settings,
  TermNConditions,
  SubRider,
  ShippingOrderDetails,
  // ServiceArea,
  MyProfile,
  ServiceAreas,
  NewLogin,
  MyPayments,
} from "./PageAsync";
import PropTypes from "prop-types";
import AddNewVehicleComponent from "views/MyVehicle/AddNewVehicle/AddNewVehicleComponent";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import UnauthorizePage from "views/UnauthorizePage";
import Header from "layout/MainLayout/Header";
// ==============================|| MAIN ROUTING ||============================== //

const user_routes = [
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "dashboard",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "shipping-orders",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "my-profile",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "order-details",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": false,
    "Air Rider": false,
    Rider: false,
    id: "sub-rider",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "service-area",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": false,
    "Air Rider": false,
    Rider: true,
    id: "vehicles",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": false,
    "Air Rider": false,
    Rider: true,
    id: "add-vehicle",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": false,
    "Air Rider": false,
    Rider: true,
    id: "add-vehicle-id",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": false,
    "Air Rider": false,
    Rider: true,
    id: "my-earning",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": false,
    "Air Rider": false,
    Rider: false,
    id: "payments",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "notification",
  },

  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "settings",
  },

  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "help-faq",
  },

  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "help-terms-condition",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "help-prohobited-item",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "help-privacy-policy",
  },
  {
    "Fleet Manager": true,
    "Sub Rider": true,
    "Air Rider": false,
    Rider: true,
    id: "support",
  },
];

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/login",
        element: <NewLogin classes={classes} />,
      },
      {
        path: "/login",
        element: <NewLogin classes={classes} />,
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute
            WrappedComponent={Dashboard}
            classes={classes}
            id="dashboard"
          />
        ),
      },
      {
        path: "/requests/my-requests",
        element: <MyRequests classes={classes} />,
      },
      {
        path: "/requests/schedule-pickup",
        element: <SchedulePickup classes={classes} />,
      },
      {
        path: "/requests/drivers-requests",
        element: <DriverRequest classes={classes} />,
      },
      {
        path: "/shipping-orders",
        element: (
          <PrivateRoute
            WrappedComponent={FindOrder}
            classes={classes}
            id="shipping-orders"
          />
        ),
      },
      {
        path: "/shipping-orders/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ShippingOrderDetails}
            classes={classes}
            id="order-details"
          />
        ),
      },
      {
        path: "/my-profile",
        element: (
          <PrivateRoute
            WrappedComponent={MyProfile}
            classes={classes}
            id="my-profile"
          />
        ),
      },
      {
        path: "/sub-riders",
        element: (
          <PrivateRoute
            WrappedComponent={SubRider}
            classes={classes}
            id="sub-rider"
          />
        ),
      },
      {
        path: "/service-area",
        element: (
          <PrivateRoute
            WrappedComponent={ServiceAreas}
            classes={classes}
            id="service-area"
          />
        ),
      },
      {
        path: "/my-drivers",
        element: <MyDriver classes={classes} />,
      },
      {
        path: "/vehicle",
        element: (
          <PrivateRoute
            WrappedComponent={MyVehicle}
            classes={classes}
            id="vehicles"
          />
        ),
      },
      {
        path: "/add-vehicle",
        element: (
          <PrivateRoute
            WrappedComponent={AddNewVehicleComponent}
            classes={classes}
            id="add-vehicle"
          />
        ),
      },
      {
        path: "/add-vehicle/:id",
        element: (
          <PrivateRoute
            WrappedComponent={AddNewVehicleComponent}
            classes={classes}
            id="add-vehicle-id"
          />
        ),
      },
      {
        path: "/my-earning",
        element: (
          <PrivateRoute
            WrappedComponent={MyEarning}
            classes={classes}
            id="my-earning"
          />
        ),
      },
      {
        path: "/payments",
        element: (
          <PrivateRoute
            WrappedComponent={MyPayments}
            classes={classes}
            id="payments"
          />
        ),
      },
      {
        path: "/notification",
        element: (
          <PrivateRoute
            WrappedComponent={Notifications}
            classes={classes}
            id="notification"
          />
        ),
      },
      {
        path: "/settings",
        element: (
          <PrivateRoute
            WrappedComponent={Settings}
            classes={classes}
            id="notification"
          />
        ),
      },
      {
        path: "/help/faqs",
        element: (
          <PrivateRoute
            WrappedComponent={Faqs}
            classes={classes}
            id="help-faq"
          />
        ),
      },
      {
        path: "/help/terms-conditions",
        element: (
          <PrivateRoute
            WrappedComponent={TermNConditions}
            classes={classes}
            id="help-terms-condition"
          />
        ),
      },
      {
        path: "/help/prohobited-item",
        element: (
          <PrivateRoute
            WrappedComponent={ProhobitedItem}
            classes={classes}
            id="help-prohobited-item"
          />
        ),
      },
      {
        path: "/help/privacy-policy",
        element: (
          <PrivateRoute
            WrappedComponent={PrivacyNPolicy}
            classes={classes}
            id="help-privacy-policy"
          />
        ),
      },
      {
        path: "/support",
        element: (
          <PrivateRoute
            WrappedComponent={Support}
            classes={classes}
            id="support"
          />
        ),
      },

    ],
  };
  return MainRoutes;
}

const PrivateRoute = ({ WrappedComponent, id, classes }: any) => {
  const user = getDataFromLocalStorage("user");
  const item = user_routes?.filter((i: any) => i?.id === id)[0] as any;
   

  if (item[user?.transporter_type]) {
    return <WrappedComponent classes={classes} />;
  } else {
    return <UnauthorizePage />;
  }
};

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
