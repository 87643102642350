/*
  Created By Muhammad Hassaan
  Date 05 April 2023
*/
import PropTypes from "prop-types";
import MUIButton from "@mui/material/Button";
export default function Button(props: any) {
  const { classesNames, onClick, value, disable, style, startIcon, type } =
    props;
  return (
    <MUIButton
      startIcon={startIcon}
      className={classesNames}
      disabled={disable}
      variant="outlined"
      onClick={onClick}
      style={style}
      type={type}
    >
      {value}
    </MUIButton>
  );
}

Button.defaultProps = {
  value: "",
  classesNames: null,
  size: "small",
  variant: "outlined",
  onClick: () => {},
  disable: false,
  style: {},
  startIcon: "",
  type: "",
};

Button.propTypes = {
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  classesNames: PropTypes.any,
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  style: PropTypes.any,
  startIcon: PropTypes.any,
  type: PropTypes.any,
};
