import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { PostApiParams } from "store";
import SnackBar from "ui-components/SnackBar/SnackBar";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

export interface serviceAreaState {
  service_area: any;
  serviceArea_data: any;
  selected_serviceArea: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: serviceAreaState = {
  service_area: [],
  serviceArea_data: [],
  selected_serviceArea: {},
  activity: true,
};

let getSettingsData = getDataFromLocalStorage("settings");

export const addServiceAreaRequest = createAsyncThunk(
  "service-areas",
  async (data: PostApiParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.data,
        "service-areas  "
      );
      if (response.status === 201) {
        SnackBar.showSuccess("Service Area Added Successfully");
      } else {
        SnackBar.Error(
          response.error.error ??
            response.error.message ??
            response.error.isEmpty ??
            response.error.isEnum ??
            `${JSON.stringify(response.error)}`.replaceAll('"', "")
        );
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const serviceAreaRequests = createAsyncThunk(
  "service-areas/my",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "serviceArea"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateServiceArea = createAsyncThunk(
  "service-areas-update/:id",
  async (data: PostApiParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.data,
        ""
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const serviceAreaSlice = createSlice({
  name: "serviceArea",
  initialState,
  reducers: {
    setSelectedServiceArea: (state: serviceAreaState, action: any) => {
      state.selected_serviceArea = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(serviceAreaRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(serviceAreaRequests.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          orders.push([
            {
              name: item?.id,
              hidden: true,
            },
            item?.area_name || "- - -",
            item?.from_time
              ? `${moment(`2023-01-01T${item?.from_time}`).format(
                  getSettingsData?.time_format ?? "HH:mm"
                )} to ${moment(`2023-01-01T${item?.to_time}`).format(
                  getSettingsData?.time_format ?? "HH:mm"
                )}`
              : "- - -",
            `${item?.from_week_day ?? "- - -"} to ${
              item?.to_week_day ?? "- - -"
            }`,
            item?.frequency || "",
            item?.is_active ? "Active" : "Inactive",
          ]);
        }
        state.serviceArea_data = payload?.data;
        state.service_area = orders;
        state.activity = false;
      })

      .addCase(updateServiceArea.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(updateServiceArea.fulfilled, (state, { payload }) => {
        if (payload.status === 201) {
          SnackBar.showSuccess("Service Area Updated Successfully");
        } else {
          SnackBar.Error(
            payload.error.error ??
              payload.error.message ??
              payload.error.isEmpty ??
              payload.error.isEnum ??
              `${JSON.stringify(payload.error)}`.replaceAll('"', "")
          );
        }
        state.activity = false;
      });
  },
});

export const { setSelectedServiceArea } = serviceAreaSlice.actions;

export default serviceAreaSlice.reducer;
