import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect } from "react";
import Button from "ui-components/Button";
import classNames from "classnames";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InputField from "ui-components/TextFeild";
import SelectField from "ui-components/SelectField";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  handleToggleAllPanels,
  handleTogglePanel,
  setSelectedvehicle,
} from "store/vehicleReducers";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import {
  Size_Units,
  Weight_Units,
  label_to_value,
  vehicleTypes,
} from "utils/constant";
import { cars } from "utils/cars";
import _ from "lodash";
import apiServices from "services/RequestHandler";
import Strings from "store/Localization";
import SnackBar from "ui-components/SnackBar/SnackBar";
import { BASE_URL } from "services/HttpProvider";
import axios from "axios";
import DraggableDialog from "ui-components/DraggableDialog";
import LoadingComponent from "ui-components/LoadinComponent";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { copyToClipboard, toBase64 } from "utils/helper";
import UnitTextfeild from "ui-components/UnitTextfeild";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import VehiclePDF from "ui-components/PdfComponent/VehiclePDF";
import { PdfImage } from "uploads";
import imageCompression from "browser-image-compression";

function AddNewVehicleComponent(props: any) {
  const parentSlicer = useSelector((state: RootState) => state.parent);
  const params = useParams();
  const navigate = useNavigate();
  const { classes } = props;
  const dispatch = useDispatch<any>();
  const vehicleSlicer = useSelector((state: RootState) => state.vehicle);
  const subRidersSlicer = useSelector((state: RootState) => state.subRider);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openDialog: false,
      accordionExpandCollapse: true,
      vehicleMake: null,
      editable: false,
      show_textfeild: false,
      all_subRiders: [],
      is_assign_vehicle: false,
      confirmBox: false,
      loading: true,
      selected_vehicle: null,
      isBtnDisable: false,
    }
  );

  let getSettingsData = getDataFromLocalStorage("settings");
  let getUserData = getDataFromLocalStorage("user");

  let weightUnit = Weight_Units?.filter(
    (i: any) => i?.value === getSettingsData?.weight_unit
  );

  let sizeUnit = Size_Units?.filter(
    (i: any) => i?.value === getSettingsData?.size_unit
  );

  useEffect(() => {
    initPage();
    return () => {
      dispatch(setSelectedvehicle(null as any));
      dispatch(setTitle("My Vehicle" as any));
      dispatch(setSubTitle(params?.id as any));
    };
  }, []);

  const initPage = () => {
    getAllSubRiders();
    getCarMakeName();
    if (params?.id) {
      getVehicleByID();
    } else {
      setState({
        loading: false,
      });
    }
  };

  const handleExpandCollapse = (value: boolean) => {
    try {
      dispatch(handleToggleAllPanels(value as any));
    } catch (error) {
      console.log(error);
    }
  };

  const handleHandleStatus = async () => {
    try {
      let obj = {
        resource_id: params?.id,
        is_active: state.selected_is_active?.value,
      };
      let resp = await apiServices.postFromApi(
        "vehicles/transporter-activate",
        obj,
        ""
      );
      if (resp.status === 200) {
        SnackBar.showSuccess("Vehicle Activation Status Changed Successfully");
      } else {
        SnackBar.showSuccess(resp.error.error);
      }
      setState({
        confirmBox: false,
      });
    } catch (error) {}
  };

  const getAllSubRiders = async () => {
    try {
      const response = await apiServices.getFromApi(
        "transporters/get-all-subriders-name-by-fleetmnanger",
        ""
      );
      setState({
        all_subRiders: response?.data,
      });
    } catch (error) {
      console.log();
    }
  };

  const getVehicleByID = async () => {
    try {
      let response = await apiServices.getFromApi(
        `vehicles/${params?.id}`,
        `vehicles`
      );
      const selected_vehicle = response?.data;

      let selected_vehicle_type = vehicleTypes.filter(
        (i) => i?.value === selected_vehicle?.vehicle_type
      )[0];

      let selected_vehicle_make = cars.filter(
        (i) => i?.make === selected_vehicle.vehicle_make
      )[0];

      let selected_vehicle_model = cars.filter(
        (i) => i?.fullName === selected_vehicle.vehicle_model
      )[0];

      let selected_vehicle_reg_country_id = parentSlicer.countries?.filter(
        (i: any) => i?.value === selected_vehicle.vehicle_reg_country_id
      )[0];

      let selected_vehicle_reg_city_id = parentSlicer.cities?.filter(
        (i: any) => i?.value === selected_vehicle.vehicle_reg_city_id
      )[0];

      let user_cities = parentSlicer.cities?.filter(
        (i: any) => i?.country_id === selected_vehicle.vehicle_reg_country_id
      )[0];

      let final_state_obj = {
        ...selected_vehicle,
        selected_vehicle_type,
        selected_sub_rider: selected_vehicle?.is_vehicle_assigned
          ? {
              label: selected_vehicle?.assigned_driver_name,
              value: selected_vehicle?.assigned_transporter_id,
            }
          : null,

        selected_is_green_type: {
          label: selected_vehicle?.is_green_type ? "Yes" : "No",
          value: selected_vehicle?.is_green_type,
        },

        selected_is_active: {
          label: selected_vehicle?.is_active ? "Active" : "Inactive",
          value: selected_vehicle?.is_active,
        },

        selected_vehicle_make: selected_vehicle_make?.make
          ? {
              ...selected_vehicle_make,
              label: selected_vehicle_make?.make,
              value: selected_vehicle_make?.make,
            }
          : null,

        selected_vehicle_model: {
          ...selected_vehicle_model,
          label: selected_vehicle_model?.fullName,
          value: selected_vehicle_model?.fullName,
        },
        payload_capacity: convert(selected_vehicle?.payload_capacity)
          .from("m")
          .to(sizeUnit[0]?.label || "m")
          ?.toFixed(2),

        vehicle_height: convert(selected_vehicle?.vehicle_height)
          .from("m")
          .to(sizeUnit[0]?.label || "m")
          ?.toFixed(2),
        vehicle_weight: convert(selected_vehicle?.vehicle_weight)
          .from("kg")
          .to(weightUnit[0]?.unit || "kg")
          ?.toFixed(2),
        vehicle_width: convert(selected_vehicle?.vehicle_width)
          .from("m")
          .to(sizeUnit[0]?.label || "m")
          ?.toFixed(2),

        selected_vehicle_reg_country_id,
        selected_vehicle_reg_city_id,
        user_cities,
      };

      setState({
        ...final_state_obj,
        selected_vehicle: final_state_obj,
        is_vehicle_assigned: selected_vehicle?.is_vehicle_assigned,
        selected_sub_rider: final_state_obj?.selected_sub_rider,
        editable: false,
        loading: false,
      });
    } catch (error) {
      console.log("error", error);
      setState({
        loading: false,
      });
    }
  };

  const handleSwitchOnChange = () => {
    try {
      setState({
        editable: !state.editable,
      });
    } catch (error) {}
  };

  const assignVehicletoRider = async () => {
    try {
      let obj = {
        vehicle_id: params?.id,
        rider_id: getUserData?.id,
      };
      const response = await apiServices.postFromApi(
        "vehicles/assign-to-rider",
        obj,
        ""
      );
      if (response?.status === 200 || response?.status === 201) {
        SnackBar.showSuccess("Vehicle Assigned Succesfully");
      } else {
        SnackBar.Error(
          response.error.error ??
            response.error.message ??
            response.error.isEmpty ??
            response.error.isEnum ??
            `${JSON.stringify(response.error)}`.replaceAll('"', "")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const assignVehicle = async () => {
    try {
      const obj = {
        vehicle_id: params?.id,
        rider_id: state?.selected_sub_rider?.value,
      };
      if (!state?.is_vehicle_assigned) {
        const response = await apiServices.postFromApi(
          "vehicles/assign-to-rider",
          obj,
          ""
        );
        if (response?.status === 200 || response?.status === 201) {
          SnackBar.showSuccess("Vehicle Assigned Succesfully");
        } else {
          SnackBar.Error(
            response.error.error ??
              response.error.message ??
              response.error.isEmpty ??
              response.error.isEnum ??
              `${JSON.stringify(response.error)}`.replaceAll('"', "")
          );
        }
      } else {
        const response = await apiServices.postFromApi(
          "vehicles/vehicle-release",
          obj,
          ""
        );
        if (response?.status === 200 || response?.status === 201) {
          SnackBar.showSuccess("Vehicle Released Succesfully");
        } else {
          SnackBar.Error(
            response.error.error ??
              response.error.message ??
              response.error.isEmpty ??
              response.error.isEnum ??
              `${JSON.stringify(response.error)}`.replaceAll('"', "")
          );
        }
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleImage = async (name: any, file: File) => {
    try {
      if (params?.id) {
        return;
      } else {
        const modifiedFile = new File(
          [file],
          `${name}-` + new Date().getTime(),
          {
            type: file.type,
          }
        );

        setState({
          [name]: modifiedFile,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (name: any, event: any, value: any) => {
    try {
      if (!value) {
        if (name === "vehicle_reg_country_id") {
          setState({
            vehicle_reg_country_id: null,
            selected_vehicle_reg_country_id: null,
            vehicle_reg_city_id: null,
            selected_vehicle_reg_city_id: null,
            user_cities: [],
          });
          return;
        }
        if (name === "vehicle_reg_city_id") {
          setState({
            vehicle_reg_city_id: null,
            selected_vehicle_reg_city_id: null,
          });
          return;
        }
      }
      if (name === "vehicle_reg_country_id") {
        const _cities = parentSlicer?.cities?.filter(
          (i: any) => i.country_id === value.value
        );
        setState({
          vehicle_reg_country_id: value.value,
          selected_vehicle_reg_country_id: value,
          vehicle_reg_city_id: null,
          selected_vehicle_reg_city_id: null,
          user_cities: _cities,
        });
      }

      if (name === "vehicle_reg_city_id") {
        setState({
          vehicle_reg_city_id: value.value,
          selected_vehicle_reg_city_id: value,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelect = (name: any, event: any, value: any) => {
    try {
      if (!value) {
        if (name === "vehicle_make" || name === "vehicle_type") {
          setState({
            [name]: null,
            [`selected_${name}`]: null,
            vehicle_make: null,
            selected_vehicle_make: null,
            vehicle_model: null,
            selected_vehicle_model: null,
            vehicleModel: [],
            payload_capacity: "",
            vehicle_width: "",
            vehicle_height: "",
            vehicle_weight: "",
          });
          return;
        } else {
          setState({
            [name]: null,
            [`selected_${name}`]: null,
          });
          return;
        }
      }

      if (name === "vehicle_model") {
        let _selected_vehicle_model = state?.vehicleModel.filter(
          (i: any) => i?.fullName === value?.value
        )[0];
        let vehicle_width, vehicle_height, payload_capacity;
        if (sizeUnit[0]?.label === "m" || sizeUnit[0]?.label === "mm") {
          vehicle_width = convert(_selected_vehicle_model?.width["ft"])
            .from("ft")
            .to(sizeUnit[0]?.label || "in");

          vehicle_height = convert(_selected_vehicle_model?.height["ft"])
            .from("ft")
            .to(sizeUnit[0]?.label || "in");

          payload_capacity = convert(_selected_vehicle_model?.capacity["ft"])
            .from("ft")
            .to(sizeUnit[0]?.label || "in");
        } else {
          vehicle_width =
            _selected_vehicle_model?.width[sizeUnit[0]?.["label"]];
          vehicle_height =
            _selected_vehicle_model?.height[sizeUnit[0]?.["label"]];
          payload_capacity =
            _selected_vehicle_model?.capacity[sizeUnit[0]?.["label"]];
        }
        setState({
          vehicle_width,
          vehicle_height,
          payload_capacity,
        });
      }

      if (name === "vehicle_make") {
        setState({
          vehicleModel: cars
            .filter((j: any) => j?.make === value?.value)
            .map((i) => {
              return { ...i, label: i?.fullName, value: i?.fullName };
            }),
        });
      }

      if (name === "vehicle_type") {
        setState({
          vehicle_make: [],
          vehicle_model: [],
          payload_capacity: "",
          vehicle_width: "",
          vehicle_height: "",
          vehicle_weight: "",
        });
      }

      setState({
        [name]: value.value,
        [`selected_${name}`]: value,
        confirmBox: name === "is_active" ? true : false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const validateData = () => {
    try {
      const validate_data = {
        vehicle_type: state.selected_vehicle_type,
        vehicle_reg_no: state.vehicle_reg_no,
        vehicle_make: state.selected_vehicle_make,
        vehicle_model: state?.vehicle_model,
        vehicle_reg_country_id: state?.vehicle_reg_country_id,
        vehicle_reg_city_id: state?.vehicle_reg_city_id,
        vehicle_reg_owner: state?.vehicle_reg_owner,
        vehicle_color: state?.vehicle_color,
        is_green_type: state?.selected_is_green_type,
        vehicle_document1: state?.show_vehicle_document1,
        vehicle_document2: state?.show_vehicle_document2,
        vehicle_image1: state?.show_vehicle_image1,
        vehicle_image2: state?.show_vehicle_image2,
      } as any;

      let is_error = false as boolean;

      for (let index = 0; index < 13; index++) {
        const _key = Object.keys(validate_data)[index] as any;
        if (!validate_data[_key]) {
          SnackBar.Error(
            `${label_to_value[_key as any]} ${Strings.IS_REQUIRED}`
          );
          is_error = true;
          break;
        }
      }
      setTimeout(() => {
        setState({ isBtnDisable: false });
      }, 4000);
      return is_error;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleAddVahicle = async () => {
    try {
      if (!validateData()) {
        setState({ isBtnDisable: false });
        let formData = new FormData();
        formData.append("files", state.vehicle_document1_obj?.modifiedFile);
        formData.append("files", state.vehicle_document2_obj?.modifiedFile);
        formData.append("files", state.vehicle_image1_obj?.modifiedFile);
        formData.append("files", state.vehicle_image2_obj?.modifiedFile);

        const upload_resp = await axios({
          baseURL: BASE_URL,
          headers: {
            "Content-Type": "multipart/form-data", // this is important
          },
          method: "post",
          url: "upload/upload-multiple",
          data: formData,
        });

        if (upload_resp.data.status === 201) {
          let dataUrls = upload_resp.data.data;
          let obj = {
            vehicle_type: state?.selected_vehicle_type.value,
            vehicle_reg_no: state?.vehicle_reg_no,
            vehicle_reg_date: moment().format("YYYY-MM-DD"),
            vehicle_reg_city_id: state?.vehicle_reg_city_id,
            vehicle_reg_country_id: state?.vehicle_reg_country_id,
            vehicle_make: state?.vehicle_make,
            vehicle_model: state?.vehicle_model,
            payload_capacity: state?.payload_capacity
              ? convert(state?.payload_capacity)
                  .from(sizeUnit[0]?.label ?? "m")
                  .to("m")
              : null,
            vehicle_width: state?.vehicle_width
              ? convert(state?.vehicle_width)
                  .from(sizeUnit[0]?.label ?? "m")
                  .to("m")
              : null,
            vehicle_height: state?.vehicle_height
              ? convert(state?.vehicle_height)
                  .from(sizeUnit[0]?.label ?? "m")
                  .to("m")
              : null,
            vehicle_weight: state?.vehicle_weight
              ? convert(state?.vehicle_weight)
                  .from(weightUnit[0]?.unit ?? "kg")
                  .to("kg")
              : null,
            vehicle_reg_owner: state?.vehicle_reg_owner,
            vehicle_color: state?.vehicle_color,
            is_green_type: state?.selected_is_green_type?.value,
            added_date: moment().format("YYYY-MM-DD"),
            vehicle_document1: dataUrls[0].uri,
            vehicle_document2: dataUrls[1].uri,
            vehicle_image1: dataUrls[2].uri,
            vehicle_image2: dataUrls[3].uri,
          };
          let response = await apiServices.postFromApi(`vehicles`, obj, "");
          if (response?.status === 201) {
            SnackBar.showSuccess("Vehicle Added Succesfully");
            navigate("/vehicle");
          } else {
            SnackBar.Error(
              JSON.stringify(
                response?.data?.error ??
                  response?.error?.error ??
                  response?.data?.isEmail ??
                  response?.data?.isNotEmpty ??
                  "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
              )
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCarMakeName = () => {
    try {
      let data = _.uniqBy(cars, "make");
      setState({
        vehicleMake: data.map((i: any) => {
          return { ...i, label: i.make, value: i.make };
        }),
      });
    } catch (error) {}
  };

  const handleClose = () => {
    try {
      setState({
        is_assign_vehicle: false,
        confirmBox: false,
        viewbox: false,
      });
    } catch (error) {}
  };

  const handleOpenImage = (item: any) => {
    try {
      setState({
        viewBox: true,
        img: item,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLaunchCamera = async (e: any, stateName: any) => {
    try {
      setState({
        loading: true,
      });
      const imageFile = e.target.files[0];
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      let file = compressedFile || null;
      let uri = (await toBase64(file)) as any;

      if (!uri) {
        return;
      }

      const modifiedFile = new File(
        [file],
        `${stateName}-` + new Date().getTime(),
        { type: file.type }
      );

      setState({
        [`${[stateName]}_obj`]: { modifiedFile, uri },
        [`show_${stateName}`]: true,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setState({ loading: false });
    }
  };

  let convert = require("convert-units");

  if (state.loading) {
    return <LoadingComponent activity={true} />;
  }

  return (
    <Grid
      container
      spacing={2}
      style={{ padding: "20px 20px 50px 20px", background: "#F6F6F6" }}
    >
      <Grid
        xs={12}
        md={12}
        item
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Grid xs={6} item>
          <span>
            {vehicleSlicer.panel?.vehicleDoc &&
            vehicleSlicer.panel?.vehicleInfo &&
            vehicleSlicer.panel?.assignVehicle ? (
              <h3
                onClick={() => handleExpandCollapse(false)}
                style={{
                  margin: 0,
                  cursor: "pointer",
                  color: "#3344B5",
                  fontSize: "16px",
                  fontFamily: "Roboto-Regular",
                }}
              >
                Collapse All
              </h3>
            ) : (
              <h3
                onClick={() => handleExpandCollapse(true)}
                style={{
                  margin: 0,
                  cursor: "pointer",
                  color: "#3344B5",
                  fontSize: "16px",
                  fontFamily: "Roboto-Regular",
                }}
              >
                Expand All
              </h3>
            )}
          </span>
          {params?.id ? (
            <span>
              <FormControlLabel
                style={{
                  color: "#3026f1",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "Roboto-Regular",
                }}
                control={<Switch onChange={handleSwitchOnChange} />}
                label={"Editable "}
              />
            </span>
          ) : null}
        </Grid>

        <Grid xs={6} item style={{ display: "flex", justifyContent: "end" }}>
          {params?.id ? (
            <div
              className={"f-gray-scale"}
              style={{
                marginRight: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5px",
                border: "1px solid grey",
              }}
            >
              <PDFDownloadLink
                fileName={`vehicle-${params?.id}`}
                document={
                  <VehiclePDF selected_vehicle={state?.selected_vehicle} />
                }
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <PdfImage className={"f-gray-scale"} />
                  ) : (
                    <PdfImage className={"f-gray-scale"} />
                  )
                }
              </PDFDownloadLink>
            </div>
          ) : null}

          <div>
            <Button
              classesNames={classNames(
                classes.button_width,
                classes.outlined_button
              )}
              onClick={() => {
                navigate("/vehicle");
              }}
              style={{ marginRight: 15 }}
              value={"Back to All"}
            />

            {params?.id ? null : (
              <Button
                disable={state?.isBtnDisable}
                classesNames={classNames(
                  classes.button_width,
                  classes.contained_button
                )}
                onClick={() => {
                  handleAddVahicle();
                  setState({
                    isBtnDisable: true,
                  });
                }}
                style={{ color: state?.isBtnDisable ? "#F8F8F8" : null }}
                value={"Save"}
              />
            )}
          </div>
        </Grid>
      </Grid>

      <Grid xs={12} md={12} item>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item style={{ paddingLeft: "16px" }}>
            <Accordion
              className={classes?.accordion_panel}
              expanded={vehicleSlicer?.panel?.vehicleDoc}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes?.accordion_head}
                onClick={() => {
                  dispatch(
                    handleTogglePanel({
                      name: "vehicleDoc",
                      value: !vehicleSlicer?.panel?.vehicleDoc,
                    } as any)
                  );
                }}
              >
                <span style={{ flex: 1 }}>
                  <Typography className={classes?.accordion_title}>
                    Vehicle Doc/Status
                  </Typography>
                </span>
                <ContentCopyIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(
                      "Vehicle Doc",
                      "vehicle_doc",
                      props.handleSnackBar
                    );
                  }}
                  className={classes?.copy_pdf_icon}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={4}
                  style={{ padding: "10px" }}
                  id="vehicle_doc"
                >
                  <Grid xs={12} md={6} item>
                    <InputField
                      input_label={label_to_value.verfication_status}
                      required
                      onChange={(event) => onInputChange(event)}
                      value={state?.is_verified ? "Verified" : "Not Verified"}
                      disabled={true}
                      name={"is_verified"}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    {!params?.id ? (
                      <SelectField
                        input_label={label_to_value.Activation_status}
                        value={state?.selected_is_active}
                        handleSelect={onChangeSelect}
                        options={[
                          { label: "Active", value: true },
                          { label: "Inactive", value: false },
                        ]}
                        name={"is_active"}
                        readOnly={true}
                      />
                    ) : (
                      <SelectField
                        input_label={label_to_value.Activation_status}
                        value={state?.selected_is_active}
                        handleSelect={onChangeSelect}
                        options={[
                          { label: "Active", value: true },
                          { label: "Inactive", value: false },
                        ]}
                        name={"is_active"}
                        readOnly={state?.editable}
                      />
                    )}
                  </Grid>
                  {params?.id ? (
                    <>
                      {getUserData?.transporter_type === "Rider" ? (
                        <>
                          <Grid xs={12} md={6} item style={{ display: "flex" }}>
                            <Button
                              classesNames={classNames(
                                classes.button_width,
                                classes.outlined_button
                              )}
                              style={{ marginTop: "25px" }}
                              value={"Assign to Me"}
                              onClick={assignVehicletoRider}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={12} md={6} item>
                            <SelectField
                              value={state?.selected_sub_rider}
                              input_label={label_to_value.Select_sub_rider}
                              handleSelect={onChangeSelect}
                              name={"sub_rider"}
                              options={state?.all_subRiders}
                              readOnly={state?.editable}
                            />
                          </Grid>

                          <Grid xs={12} md={6} item style={{ display: "flex" }}>
                            <Button
                              classesNames={classNames(
                                classes.button_width,
                                classes.outlined_button
                              )}
                              style={{ marginTop: "25px" }}
                              value={
                                !state?.is_vehicle_assigned
                                  ? "Assign"
                                  : "Release"
                              }
                              onClick={() => {
                                if (!state?.selected_sub_rider) {
                                  SnackBar.Error("No Sub Rider Selected");
                                  return;
                                }
                                setState({
                                  is_assign_vehicle: true,
                                });
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className={classes?.accordion_panel}
              expanded={vehicleSlicer?.panel?.assignVehicle}
              style={{ marginTop: "10px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes?.accordion_head}
                onClick={() => {
                  dispatch(
                    handleTogglePanel({
                      name: "assignVehicle",
                      value: !vehicleSlicer?.panel?.assignVehicle,
                    } as any)
                  );
                }}
              >
                <span style={{ flex: 1 }}>
                  <Typography className={classes?.accordion_title}>
                    Vehicle Doc/Images
                  </Typography>
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} style={{ padding: "10px" }}>
                  <Grid xs={12} md={12} item>
                    <div style={{ display: "flex", marginTop: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            className={classNames(
                              "RobotoCondensed-Regular",
                              classes?.input_label_style
                            )}
                            style={{
                              paddingLeft: "15px",
                              fontSize: "16px",
                              flex: 1,
                            }}
                          >
                            Vehicle Reg. Doc Image 1
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              {" "}
                              *
                            </span>
                          </div>
                          <div>
                            <input
                              id={"vehicle_document1"}
                              type="file"
                              className={"inputfile"}
                              accept={""}
                              onChange={(e: any) => {
                                handleLaunchCamera(e, "vehicle_document1");
                              }}
                            />
                            {!state?.vehicle_document1_obj?.uri ? null : (
                              <label htmlFor={"vehicle_document1"}>
                                <Tooltip title="Change Image">
                                  <EditIcon
                                    onClick={(e: any) => {
                                      handleLaunchCamera(
                                        e,
                                        "vehicle_document1"
                                      );
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#3026F2",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: 5 }}>
                          <div
                            style={{
                              height: "120px",
                              width: "100%",
                              border: "1px dashed gray",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {state?.show_vehicle_document1 ||
                            state?.vehicle_document1 ? (
                              <img
                                onClick={() => {
                                  handleOpenImage(
                                    state.vehicle_document1_obj?.uri ??
                                      state?.vehicle_document1
                                  );
                                }}
                                className={classes?.subRiderDocs}
                                src={
                                  state?.vehicle_document1_obj?.uri ??
                                  state?.vehicle_document1 ??
                                  ""
                                }
                                alt=""
                              />
                            ) : (
                              <>
                                <input
                                  id={"vehicle_document1"}
                                  type="file"
                                  className={"inputfile"}
                                  accept={""}
                                  onChange={(e: any) => {
                                    handleLaunchCamera(e, "vehicle_document1");
                                  }}
                                />
                                <label htmlFor={"vehicle_document1"}>
                                  <AddCircleOutlineIcon
                                    style={{
                                      fontSize: 28,
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          className={classNames(
                            "RobotoCondensed-Regular",
                            classes?.input_label_style
                          )}
                          style={{
                            paddingLeft: "15px",
                            fontSize: "16px",
                            display: "flex",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            Vehicle Reg. Doc Image 2
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              {" "}
                              *
                            </span>
                          </div>
                          <div>
                            <input
                              id={"vehicle_document2"}
                              type="file"
                              className={"inputfile"}
                              accept={""}
                              onChange={(e: any) => {
                                handleLaunchCamera(e, "vehicle_document2");
                              }}
                            />
                            {!state?.vehicle_document2_obj?.uri ? null : (
                              <label htmlFor={"vehicle_document2"}>
                                <Tooltip title="Change Image">
                                  <EditIcon
                                    onClick={(e: any) => {
                                      handleLaunchCamera(
                                        e,
                                        "vehicle_document2"
                                      );
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#3026F2",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: 5 }}>
                          <div
                            style={{
                              height: "120px",
                              width: "100%",
                              border: "1px dashed gray",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {state?.show_vehicle_document2 ||
                            state?.vehicle_document2 ? (
                              <img
                                onClick={() => {
                                  handleOpenImage(
                                    state.vehicle_document2_obj?.uri ??
                                      state?.vehicle_document2
                                  );
                                }}
                                className={classes?.subRiderDocs}
                                src={
                                  state.vehicle_document2_obj?.uri ??
                                  state?.vehicle_document2 ??
                                  ""
                                }
                                alt=""
                              />
                            ) : (
                              <>
                                <input
                                  id={"vehicle_document2"}
                                  type="file"
                                  className={"inputfile"}
                                  accept={""}
                                  onChange={(e: any) => {
                                    handleLaunchCamera(e, "vehicle_document2");
                                  }}
                                />
                                <label htmlFor={"vehicle_document2"}>
                                  <AddCircleOutlineIcon
                                    style={{
                                      fontSize: 28,
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <div
                          className={classNames(
                            "RobotoCondensed-Regular",
                            classes?.input_label_style
                          )}
                          style={{
                            paddingLeft: "15px",
                            fontSize: "16px",
                            display: "flex",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            Vehicle Front Pic 1
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              {" "}
                              *
                            </span>
                          </div>
                          <div>
                            <input
                              id={"vehicle_image1"}
                              type="file"
                              className={"inputfile"}
                              accept={""}
                              onChange={(e: any) => {
                                handleLaunchCamera(e, "vehicle_image1");
                              }}
                            />
                            {!state?.vehicle_image1_obj?.uri ? null : (
                              <label htmlFor={"vehicle_image1"}>
                                <Tooltip title="Change Image">
                                  <EditIcon
                                    onClick={(e: any) => {
                                      handleLaunchCamera(e, "vehicle_image1");
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#3026F2",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: 5 }}>
                          <div
                            style={{
                              height: "120px",
                              width: "100%",
                              border: "1px dashed gray",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {state?.show_vehicle_image1 ||
                            state?.vehicle_image1 ? (
                              <img
                                onClick={() => {
                                  handleOpenImage(
                                    state.vehicle_image1_obj?.uri ??
                                      state?.vehicle_image1
                                  );
                                }}
                                className={classes?.subRiderDocs}
                                src={
                                  state.vehicle_image1_obj?.uri ??
                                  state?.vehicle_image1 ??
                                  ""
                                }
                                alt=""
                              />
                            ) : (
                              <>
                                <input
                                  id={"vehicle_image1"}
                                  type="file"
                                  className={"inputfile"}
                                  accept={""}
                                  onChange={(e: any) => {
                                    handleLaunchCamera(e, "vehicle_image1");
                                  }}
                                />
                                <label htmlFor={"vehicle_image1"}>
                                  <AddCircleOutlineIcon
                                    style={{
                                      fontSize: 28,
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          className={classNames(
                            "RobotoCondensed-Regular",
                            classes?.input_label_style
                          )}
                          style={{
                            paddingLeft: "15px",
                            fontSize: "16px",
                            display: "flex",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            Vehicle Front Pic 2
                            <span style={{ color: "red", marginLeft: "3px" }}>
                              {" "}
                              *
                            </span>
                          </div>
                          <div>
                            <input
                              id={"vehicle_image2"}
                              type="file"
                              className={"inputfile"}
                              accept={""}
                              onChange={(e: any) => {
                                handleLaunchCamera(e, "vehicle_image2");
                              }}
                            />
                            {!state?.vehicle_image2_obj?.uri ? null : (
                              <label htmlFor={"vehicle_image2"}>
                                <Tooltip title="Change Image">
                                  <EditIcon
                                    onClick={(e: any) => {
                                      handleLaunchCamera(e, "vehicle_image2");
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#3026F2",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: 5 }}>
                          <div
                            style={{
                              height: "120px",
                              width: "100%",
                              border: "1px dashed gray",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {state?.show_vehicle_image2 ||
                            state?.vehicle_image2 ? (
                              <img
                                onClick={() => {
                                  handleOpenImage(
                                    state.vehicle_image2_obj?.uri ??
                                      state?.vehicle_image2
                                  );
                                }}
                                className={classes?.subRiderDocs}
                                src={
                                  state.vehicle_image2_obj?.uri ??
                                  state?.vehicle_image2 ??
                                  ""
                                }
                                alt=""
                              />
                            ) : (
                              <>
                                <input
                                  id={"vehicle_image2"}
                                  type="file"
                                  className={"inputfile"}
                                  accept={""}
                                  onChange={(e: any) => {
                                    handleLaunchCamera(e, "vehicle_image2");
                                  }}
                                />
                                <label htmlFor={"vehicle_image2"}>
                                  <AddCircleOutlineIcon
                                    style={{
                                      fontSize: 28,
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid xs={12} md={6} item style={{ paddingLeft: "16px" }}>
            <Accordion
              className={classes?.accordion_panel}
              expanded={vehicleSlicer?.panel?.vehicleInfo}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes?.accordion_head}
                onClick={() => {
                  dispatch(
                    handleTogglePanel({
                      name: "vehicleInfo",
                      value: !vehicleSlicer?.panel?.vehicleInfo,
                    } as any)
                  );
                }}
              >
                <span style={{ flex: 1 }}>
                  <Typography className={classes?.accordion_title}>
                    Vehicle Info
                  </Typography>
                </span>
                <ContentCopyIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(
                      "Vehicle Info",
                      "vehicle_info",
                      props.handleSnackBar
                    );
                  }}
                  className={classes?.copy_pdf_icon}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={4}
                  style={{ padding: "10px" }}
                  id="vehicle_info"
                >
                  <Grid xs={12} md={6} item>
                    <SelectField
                      value={state?.selected_vehicle_type}
                      input_label={label_to_value.vehicle_type}
                      handleSelect={onChangeSelect}
                      name={"vehicle_type"}
                      options={vehicleTypes}
                      readOnly={params?.id ? true : false}
                      required
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <InputField
                      required
                      input_label={label_to_value.vehicle_reg_no}
                      name="vehicle_reg_no"
                      onChange={(event) => onInputChange(event)}
                      value={state?.vehicle_reg_no}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                          // "text_feild_small"
                        ),
                      }}
                    />
                  </Grid>

                  {state?.vehicle_type !== "Car" ? (
                    <>
                      <Grid xs={12} md={6} item>
                        <InputField
                          required
                          input_label={label_to_value.vehicle_make}
                          name="vehicle_make"
                          onChange={(event) => onInputChange(event)}
                          value={state?.vehicle_make}
                          InputProps={{
                            readOnly: params?.id ? true : false,
                          }}
                          inputProps={{
                            className: classNames(
                              classes?.input_feild,
                              "Roboto-Medium"
                              // "text_feild_small"
                            ),
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <InputField
                          required
                          input_label={label_to_value.vehicle_model}
                          name="vehicle_model"
                          onChange={(event) => onInputChange(event)}
                          value={state?.vehicle_model}
                          InputProps={{
                            readOnly: params?.id ? true : false,
                          }}
                          inputProps={{
                            className: classNames(
                              classes?.input_feild,
                              "Roboto-Medium"
                              // "text_feild_small"
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid xs={12} md={6} item>
                        <SelectField
                          required
                          input_label={label_to_value.vehicle_make}
                          handleSelect={onChangeSelect}
                          value={state?.selected_vehicle_make}
                          readOnly={params?.id ? true : false}
                          name={"vehicle_make"}
                          options={state?.vehicleMake}
                        />
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <SelectField
                          required
                          input_label={label_to_value.vehicle_model}
                          handleSelect={onChangeSelect}
                          name={"vehicle_model"}
                          readOnly={params?.id ? true : false}
                          value={state?.selected_vehicle_model}
                          options={state?.vehicleModel}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid xs={12} md={6} item>
                    <UnitTextfeild
                      input_label={label_to_value.payload_capacity}
                      value={state?.payload_capacity}
                      type={"number"}
                      name={"payload_capacity"}
                      unit={sizeUnit[0]?.label ?? "ft"}
                      onChange={(event) => onInputChange(event)}
                      readOnly={params?.id ? true : false}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <UnitTextfeild
                      name={"vehicle_weight"}
                      input_label={label_to_value.vehicle_weight}
                      type={"number"}
                      unit={weightUnit[0]?.label}
                      value={state?.vehicle_weight}
                      onChange={(event) => onInputChange(event)}
                      readOnly={params?.id ? true : false}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                          // "text_feild_small"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <UnitTextfeild
                      name={"vehicle_width"}
                      input_label={label_to_value.vehicle_width}
                      type={"number"}
                      unit={sizeUnit[0]?.label ?? "ft"}
                      value={state?.vehicle_width}
                      onChange={(event) => onInputChange(event)}
                      readOnly={params?.id ? true : false}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                          // "text_feild_small"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <UnitTextfeild
                      name={"vehicle_height"}
                      input_label={label_to_value.vehicle_height}
                      type={"number"}
                      value={state?.vehicle_height}
                      unit={sizeUnit[0]?.label ?? "ft"}
                      onChange={(event) => onInputChange(event)}
                      readOnly={params?.id ? true : false}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                          // "text_feild_small"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <SelectField
                      required
                      input_label={label_to_value.vehicle_reg_country_id}
                      value={state?.selected_vehicle_reg_country_id}
                      name={"vehicle_reg_country_id"}
                      readOnly={params?.id ? true : false}
                      options={parentSlicer.countries || []}
                      handleSelect={(name, event, value) =>
                        handleSelect(name, event, value)
                      }
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <SelectField
                      required
                      input_label={label_to_value.vehicle_reg_city_id}
                      value={state?.selected_vehicle_reg_city_id}
                      name={"vehicle_reg_city_id"}
                      readOnly={params?.id ? true : false}
                      options={state.user_cities || []}
                      handleSelect={(name, event, value) =>
                        handleSelect(name, event, value)
                      }
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <InputField
                      input_label={label_to_value.vehicle_reg_owner}
                      required
                      InputProps={{
                        readOnly: params?.id ? true : false,
                      }}
                      name="vehicle_reg_owner"
                      onChange={(event) => onInputChange(event)}
                      value={state?.vehicle_reg_owner}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                          // "text_feild_small"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <InputField
                      input_label={label_to_value.vehicle_color}
                      required
                      InputProps={{
                        readOnly: params?.id ? true : false,
                      }}
                      name="vehicle_color"
                      onChange={(event) => onInputChange(event)}
                      value={state?.vehicle_color}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <SelectField
                      required
                      input_label={Strings?.IS_GREEN_TYPE}
                      readOnly={params?.id ? true : false}
                      value={state?.selected_is_green_type}
                      handleSelect={onChangeSelect}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      name={"is_green_type"}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        md={12}
        item
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Button
          classesNames={classNames(
            classes.button_width,
            classes.outlined_button
          )}
          onClick={() => {
            navigate("/vehicle");
          }}
          style={{ marginRight: 15 }}
          value={"Back To All"}
        />

        {params?.id ? null : (
          <Button
            classesNames={classNames(
              classes.button_width,
              classes.contained_button
            )}
            value={"Save"}
            onClick={() => {
              handleAddVahicle();
              setState({
                isBtnDisable: true,
              });
            }}
            style={{ color: state?.isBtnDisable ? "#F8F8F8" : null }}
          />
        )}
      </Grid>

      <DraggableDialog
        maxWidth="sm"
        open={state?.is_assign_vehicle}
        aria-labelledby="responsive-dialog-title"
        handleClose={handleClose}
        classes={classes}
        dialog_title={`${
          state?.is_vehicle_assigned ? "Release" : "Assign"
        } Vehicle`}
        dialogBody={
          <DialogContent dividers>
            <div>
              <Grid container>
                <Grid xs={12} item>
                  <Grid xs={12} item>
                    <p className={classes?.info_line}>
                      Are you sure to perform this action?
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        }
        dialogFooter={
          <DialogActions style={{ height: 80 }}>
            <div style={{ flex: 1 }}></div>
            <div>
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                style={{ marginRight: 25 }}
                value={"Cancel"}
                onClick={handleClose}
              />
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.contained_button
                )}
                style={{ marginRight: 25 }}
                onClick={assignVehicle}
                value={"OK"}
              />
            </div>
          </DialogActions>
        }
      />

      <DraggableDialog
        maxWidth="sm"
        open={state?.confirmBox}
        aria-labelledby="responsive-dialog-title"
        handleClose={handleClose}
        classes={classes}
        dialog_title={"Change Activation Status"}
        dialogBody={
          <DialogContent dividers>
            <div>
              <Grid container>
                <Grid xs={12} item>
                  <Grid xs={12} item>
                    <p className={classes?.info_line}>
                      Are you sure to perform this action?
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        }
        dialogFooter={
          <DialogActions style={{ height: 80 }}>
            <div style={{ flex: 1 }}></div>
            <div>
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                style={{ marginRight: 25 }}
                value={"Cancel"}
                onClick={handleClose}
              />
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.contained_button
                )}
                style={{ marginRight: 25 }}
                onClick={handleHandleStatus}
                value={"OK"}
              />
            </div>
          </DialogActions>
        }
      />

      <DraggableDialog
        maxWidth="sm"
        open={state?.viewBox}
        aria-labelledby="responsive-dialog-title"
        handleClose={() => {
          setState({ viewBox: false });
        }}
        classes={classes}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            dividers
          >
            <img
              src={state?.img}
              alt="image"
              height={400}
              style={{ objectFit: "contain", width: "100%" }}
            />
          </DialogContent>
        }
      />
    </Grid>
  );
}

export default AddNewVehicleComponent;
