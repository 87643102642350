import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { earnings } from "dummy-data";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

let getSettingsData = getDataFromLocalStorage("settings");
export interface earningState {
  all_earnings: any;
  all_earnings_data: any;
  selected_earning: object;
  activity: boolean;
  paginate_data?: {
    current_count: any;
    // current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

interface APIParams {
  end_point: string;
}

const initialState: earningState = {
  all_earnings: [],
  all_earnings_data: [],
  selected_earning: {},
  activity: true,
};

export const earningRequests = createAsyncThunk(
  "transporters/transporter-earnings",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "earnings");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const earningSlice = createSlice({
  name: "earning",
  initialState,
  reducers: {
    setSelectedEarning: (state: earningState, action: any) => {
      state.selected_earning = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(earningRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(earningRequests.fulfilled, (state, { payload }) => {
        let earnings = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          earnings.push([
            item?.order_id || "- - -",
            "N/A",
            item?.delivery_date ? moment(item?.delivery_date).format(getSettingsData?.date_format ?? "DD MMM YYYY") : "- - -",
            item?.commission_type === "Fix Amount" ? item?.commission_type : "%",
            `% ${item?.commission_percent || "- - -"}`,
             `€ ${item?.bonus || "- - -"}`,
            `€ ${item?.deduction || "- - -"}`,
            `€ ${item?.total_earning || "0.00"}`,
            item?.is_paid || "Unpaid",
            item?.payment_due_date ? moment(item?.payment_due_date).format(getSettingsData?.date_format ?? "DD MMM YYYY") : "- - -",
          ]);
        }
        state.all_earnings_data = payload?.data;
        state.all_earnings = earnings;
        state.paginate_data = {
          current_count: payload?.current_count,
          // current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages ?? 1,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

export const { setSelectedEarning } = earningSlice.actions;

export default earningSlice.reducer;
