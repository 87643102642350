import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "config";
import { all_orders } from "dummy-data";
import apiServices from "services/RequestHandler";
import { countries } from "utils/countries";
import { cities } from "utils/cities";

export interface ParentState {
  countries: any;
  cities: any;
  panel: {
    profileInfoAcc: boolean;
    identityAcc: boolean;
    bankInfoAcc: boolean;
    licenseAcc: boolean;
    passportAcc: boolean;
    vehicleAcc: boolean;
  };
}

interface APIParams {
  end_point: string;
}

const initialState: ParentState = {
  countries: countries,
  cities: cities,
  panel: {
    profileInfoAcc: true,
    identityAcc: true,
    bankInfoAcc: true,
    licenseAcc: true,
    passportAcc: true,
    vehicleAcc: true,
  },
};

// export const CountryRequests = createAsyncThunk(
//   "parents/get-all-countries",
//   async (data: APIParams) => {
//     try {
//       let response = await apiServices.getFromApi(data.end_point, "");
//       return response;
//     } catch (err) {
//       console.log(err);
//     }
//   }
// );
// export const CityRequests = createAsyncThunk(
//   "parents/get-all-cities",
//   async (data: APIParams) => {
//     try {
//       let response = await apiServices.getFromApi(data.end_point, "");
//       return response;
//     } catch (err) {
//       console.log(err);
//     }
//   }
// );

export const parentSlicer = createSlice({
  name: "parent",
  initialState,
  reducers: {
    clearCountries: (state: ParentState, action: any) => {
      state.countries = [];
    },
    clearCities: (state: ParentState, action: any) => {
      state.cities = [];
    },
    handleToggleAllPanels: (state: ParentState, action: any) => {
      state.panel = {
        profileInfoAcc: action.payload,
        bankInfoAcc: action.payload,
        identityAcc: action.payload,
        licenseAcc: action.payload,
        passportAcc: action.payload,
        vehicleAcc: action.payload,
      };
    },
    handleTogglePanel: (state: any, action: any) => {
      state.panel[action.payload.name] = action.payload.value;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(CountryRequests.pending, (state, { payload }) => {})
  //     .addCase(CityRequests.pending, (state, { payload }) => {})

  //     .addCase(CountryRequests.fulfilled, (state, { payload }) => {
  //       state.countries = payload.result;
  //     })
  //     .addCase(CityRequests.fulfilled, (state, { payload }) => {
  //       state.cities = payload.result;
  //     });
  // },
});

export const {
  clearCountries,
  clearCities,
  handleToggleAllPanels,
  handleTogglePanel,
} = parentSlicer.actions;

export default parentSlicer.reducer;
