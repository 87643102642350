import React from "react";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import frankLogo from "../../assets/images/frank-logo.png";

Font.register({
  family: "Roboto-Regular",
  src: require("../../assets/fonts/Roboto/Roboto-Regular.ttf"),
});
Font.register({
  family: "Roboto-Condensed",
  src: require("../../assets/fonts/Roboto/RobotoCondensed-Regular.ttf"),
});
Font.register({
  family: "RobotoCondensed-Bold",
  src: require("../../assets/fonts/Roboto/RobotoCondensed-Bold.ttf"),
});

const styles = StyleSheet.create({
  body: {
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 18,
    fontFamily: "Roboto-Regular",
  },
  invoice_title: {
    fontSize: 30,
    fontFamily: "Roboto-Black",
  },
  address_line: {
    fontSize: 10,
    fontFamily: "Roboto-Regular",
    width: "55%",
  },
  section: {
    flexGrow: 1,
  },
  panel_head: {
    fontSize: 14,
    padding: 5,
    backgroundColor: "#f6f6f6",
    paddingLeft: 10,
    fontFamily: "Roboto-Regular",
  },
  detail_text: {
    margin: "2px 0px",
    fontSize: 12,
    fontFamily: "Roboto-Regular",

    color: "#0f1436",
    width: "75%",
  },
  table_head_cell: {
    fontSize: 11,
    flexGrow: 1,
    padding: 5,
    fontFamily: "Roboto-Regular",

    borderBottom: "1px solid #a4a4a4",
    pageBreakBefore: "always",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    fontFamily: "Roboto-Regular",

    textAlign: "center",
    color: "grey",
  },
  signature: {
    position: "absolute",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Roboto-Regular",

    bottom: 30,
    color: "grey",
    right: 20,
  },
});

export default function PdfComponent(props: any) {
  const { selected_vehicle } = props;

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      barcode: "",
      data: null,
      balance: null,
    }
  );

  React.useEffect(() => {
    if (selected_vehicle) {
    } else {
    }
   }, [props.selected_vehicle]);

  return (
    <Document>
      <Page size={"A4"}  style={{ padding: 10 }} >
        <View style={{ flexDirection: "row" }}>
          <View fixed style={{ padding: 10 }}>
            <Image src={frankLogo} style={{ width: 100, height: 50 }} />
          </View>
          <View style={{ ...styles.section, marginLeft: 10 }}>
            <Text style={styles.title}>FRANK</Text>
            <Text style={styles.address_line}>
              {
                "4th Floor Rimjhim Shopping Tower Block 6, Gulshan-e-Iqbal, Karachi, Pakistan"
              }
            </Text>
            <Text style={styles.address_line}>
              {"(+33)"} {"142 764040"}, {"support@frankme.com"}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: 20,
            flexDirection: "row",
          }}
        >
          <View
            style={{
              ...styles.panel_head,
              border: "1px solid #a4a4a4",
              width: "100%",
            }}
          >
            <Text>Vehicle Information </Text>
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              width: "50%",
              padding: "0px 0px 0px 10px",
              borderBottom: "1px solid #a4a4a4",
              borderLeft: "1px solid #a4a4a4",
            }}
          >
            <View style={{ flexDirection: "row", paddingTop: 5 }}>
              <Text style={styles.detail_text}>Vehicle ID : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.id ?? "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Reg No : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.vehicle_reg_no ?? "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Type : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.vehicle_type ?? "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Make : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.vehicle_make || "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Model : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.vehicle_model || "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Owner :</Text>
              <Text
                style={{
                  ...styles.detail_text,
                }}
              >
                {props.selected_vehicle?.vehicle_reg_owner ?? "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Color : </Text>
              <Text
                style={{
                  ...styles.detail_text,
                }}
              >
                {props.selected_vehicle?.vehicle_color ?? "- - -"}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: "50%",
              padding: "0px 0px 0px 10px",
              borderBottom: "1px solid #a4a4a4",
              borderLeft: "1px solid #a4a4a4",
            }}
          >
            <View style={{ flexDirection: "row", paddingTop: 5 }}>
              <Text style={styles.detail_text}>
                Vehicle Dimesions (LxWxW) :{" "}
              </Text>
              <Text style={styles.detail_text}>
                {`${props.selected_vehicle?.vehicle_height ?? "- "} X ${
                  props.selected_vehicle?.vehicle_width ?? "- "
                } X ${props.selected_vehicle?.vehicle_weight ?? "- "} `}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>
                Vehicle Payload Capacity : 
              </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.payload_capacity ?? "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>is Vehicle Assigned: </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.is_vehicle_assigned ? "yes" : "No"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Vehicle Type : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.vehicle_type ?? "- - -"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Verification Status : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.is_verified ? "Active" : "Inactive"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.detail_text}>Activition status : </Text>
              <Text style={styles.detail_text}>
                {props.selected_vehicle?.is_active ? "Active" : "Inactive"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
