import axios from "axios";
import { defaultLabelColors } from "./constant";
import SnackBar from "ui-components/SnackBar/SnackBar";
  
export const numberFormater = (number: number) =>
  Intl.NumberFormat("en-US",{
    maximumFractionDigits:2
  }).format(number);

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => resolve(null);
  });

export const googleMapsApiKey = "AIzaSyDck8jd3P-F9dSPmSwzhKkIbCtvL4vzExs";

export const getStatusLabel = (item: string): string => {
  switch (item) {
    case "cancelled_transporter":
      return "Cancelled By Transporter";
    case "picked":
      return "Picked";
    case "accepted":
      return "Accepted";
    case "waiting":
      return "Waiting";
    case "pending":
      return "Pending";
    case "delivered":
      return "Delivered";
    case "cancelled_back_office_admin":
      return "Cancelled by BO Admin";
    case "cancelled_store_admin":
      return "Cancelled by Store Admin";
    case "cancelled_customer":
      return "Cancelled by Customer";

    default:
      return item;
  }
};

export const getStatusLabelForTable = (item: string): string => {
  switch (item) {
    case "cancelled_transporter":
      return "Cancelled";
    case "picked":
      return "Picked";
    case "accepted":
      return "Accepted";
    case "waiting":
      return "Waiting";
    case "pending":
      return "Pending";
    case "delivered":
      return "Delivered";
    case "cancelled_back_office_admin":
      return "Cancelled";
    case "cancelled_store_admin":
      return "Cancelled";
    case "cancelled_customer":
      return "Cancelled";

    default:
      return item;
  }
};

export const GetButtonLabel = (item: string): string => {
  switch (item) {
    case "picked":
      return "Deliverd";
    case "accepted":
      return "Picked";
    case "waiting":
      return "Accept";
    case "pending":
      return "Accept";
    case "delivered":
      return "Delivered";
    default:
      return item;
  }
};

export const cancelButtonLabel = (item: string): string => {
  switch (item) {
    case "picked":
      return "Cancel";
    case "accepted":
      return "Cancel";
    case "waiting":
      return "Reject";
    case "pending":
      return "Reject";

    default:
      return item;
  }
};

export const getStatusLabelColor = (status: string): string => {
  switch (status) {
    case "waiting":
      return "Waiting";
    case "pending":
      return defaultLabelColors.orange_yellow;
    case "accepted":
      return defaultLabelColors.green;
    case "picked":
      return defaultLabelColors.green;
    case "delivered":
      return defaultLabelColors.green;
    case "cancelled_transporter":
      return defaultLabelColors.red;
    case "cancelled_back_office_admin":
      return defaultLabelColors.red;
    case "cancelled_store_admin":
      return defaultLabelColors.red;
    case "cancelled_customer":
      return defaultLabelColors.red;
    default:
      return "";
  }
};


export const getAmountColor = (status: string): string => {
  switch (status) {
    case "pending":
      return defaultLabelColors.dark_blue;
    case "accepted":
      return defaultLabelColors.dark_blue;
    case "picked":
      return defaultLabelColors.dark_blue;
    case "delivered":
      return defaultLabelColors.green;
    case "cancelled_transporter":
      return defaultLabelColors.red;
    case "cancelled_back_office_admin":
      return defaultLabelColors.red;
    case "cancelled_store_admin":
      return defaultLabelColors.red;
    case "cancelled_customer":
      return defaultLabelColors.red;
    default:
      return "";
  }
};

export const convertMilisecondsToDuration = (milliseconds: number): string => {
  try {
    let sec_num = milliseconds / 1000; //parseInt( / 1000);
    let secsUsed = 0;
    let years = Math.floor(sec_num / 31536000);
    if (years > 0) {
      secsUsed += years * 31536000;
    }
    let months = Math.floor((sec_num - secsUsed) / 2628288);
    if (months > 0) {
      secsUsed += months * 2628288;
    }
    let weeks = Math.floor((sec_num - secsUsed) / 604800);
    if (weeks > 0) {
      secsUsed += weeks * 604800;
    }
    let days = Math.floor((sec_num - secsUsed) / 86400);
    if (days > 0) {
      secsUsed += days * 86400;
    }
    let hours = Math.floor((sec_num - secsUsed) / 3600);
    if (hours > 0) {
      secsUsed += hours * 3600;
    }
    let minutes = Math.floor((sec_num - secsUsed) / 60);
    if (minutes > 0) {
      secsUsed += minutes * 60;
    }
    let seconds = sec_num - secsUsed;
    if (years > 1) {
      return "More than 1 year";
    } else if (years > 0) {
      return (
        years + " Years " + months + " Months "
        // weeks +
        // " Weeks " +
        // days +
        // " Days " +
        // hours +
        // " Hours " +
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (months > 0) {
      return (
        months + " Months " + weeks + " Weeks "
        //  +
        // days +
        // " Days " +
        // hours +
        // " Hours " +
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (weeks > 0) {
      return (
        weeks +
        ` ${weeks > 1 ? "Weeks" : "Week "}` +
        days +
        ` ${days > 1 ? "Days" : "Day"}` //+
        // hours +
        // " Hours " +
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (days > 0) {
      return (
        days + " Days " + hours + " Hours " //+
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (hours > 0) {
      return hours + " Hours " + minutes + " Minutes "; // + seconds + " Seconds";
    } else if (minutes > 0) {
      return minutes + " Minutes " + seconds + " Seconds";
    } else if (seconds > 0) {
      return seconds + " Seconds";
    } else if (seconds == 0) {
      return "Less than a minute";
    } else {
      return (
        days +
        " Days " +
        hours +
        " Hours " +
        minutes +
        " Minutes " +
        seconds +
        " Seconds"
      );
    }
  } catch (error) {
    return "N/A";
  }
};

export const numberFormatWithoutCurrency = (value: number) =>
  Intl.NumberFormat("en-US").format(value);

export interface ORIGIN_DESTINATION_INTERFACE {
  lat: number;
  lng: number;
}

let directionsService: google.maps.DirectionsService;

export const calculateDistance = async (
  origin: ORIGIN_DESTINATION_INTERFACE,
  destination: ORIGIN_DESTINATION_INTERFACE
) => {
  try {
    const apiKey = googleMapsApiKey;
    // const resp = await fetch(url);
    const resp = await axios({
      baseURL: `https://maps.googleapis.com/maps/api/directions/json?origin={origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&key=${apiKey}`,
      method: "get",
    });
    let distance = resp.data;
   } catch (error) {
    console.log(error);
    return null;
  }
  // return false;
  // try {
  //   const apiKey = googleMapsApiKey;
  //   const resp = await axios({
  //     baseURL: `https://maps.googleapis.com/maps/api/directions/json?origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&key=${apiKey}`,
  //     method: "get",
  //   });
  //   let distance = resp.data;
  //   return distance.routes[0]?.legs[0]?.distance?.value;
  // } catch (error) {
  //   console.log(error);
  //   return null;
  // }
};

export const copyToClipboard = async (
  section_name: any,
  data: any,
  handleShowSnack: any,
  has_label_feilds?: any
) => {
  try {
    if (!navigator.clipboard) {
      handleShowSnack(
        "Your browser dose not support Copy to Clipboard API",
        "error"
      );
      return;
    }
    let copied_data = `**${section_name}**\n`;
    if (has_label_feilds) {
      document
        .querySelectorAll(`#${data}`)[0]
        ?.childNodes?.forEach((item: any, index: any) => {
          if (item?.children[0]?.className?.includes("_seperator")) return;
          let code = "",
            mobile = "",
            unit_ = "",
            value_2 = "",
            value_3 = "";

          if (!item.children?.[1]?.innerText) {
            code =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 2
              ]?.innerText;

            mobile =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 1
              ]?.children?.[1]?.innerText;
          }
          copied_data += `${item.children?.[0]?.innerText || ""}: ${
            `${
              item.children?.[1]?.innerText
                ? item.children?.[1]?.innerText + " "
                : ""
            }${value_2}${value_3}${unit_ || ""}` ||
            `${code || ""} ${mobile || ""}` ||
            ""
          }\n`;
        });
    } else {
      document
        .querySelectorAll(`#${data}`)[0]
        ?.childNodes?.forEach((item: any, index: any) => {
          if (item?.children[0]?.className?.includes("_seperator")) return;
          let code = "",
            mobile = "",
            unit_ = "",
            value_2 = "",
            value_3 = "";

          if (!item.children?.[0]?.[0]?.value) {
            code =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 2
              ]?.innerText;

            mobile =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 1
              ]?.children?.[0]?.[0]?.value;
          }
          copied_data += `${item.children?.[0]?.firstChild?.innerText || ""}: ${
            `${
              item.children?.[0]?.[0]?.value
                ? item.children?.[0]?.[0]?.value + " "
                : ""
            }${value_2}${value_3}${unit_ || ""}` ||
            `${code || ""} ${mobile || ""}` ||
            ""
          }\n`;
        });
    }
    await navigator.clipboard.writeText(copied_data);
    SnackBar.showSuccess(`${section_name ?? ""} Copied to clipboard`);
  } catch (error) {
    console.log(error);
  }
};

 
 