import React from "react";
 import { Grid } from "@mui/material";
import lock from "../../assets/images/lock.png";


function UnauthorizePage(props: any) {
  return (
    <Grid container style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Grid xs={12} item style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
      <img src={lock}  style={{height:'300px',width:'300px'}} alt="lock" />
      </Grid>
      <Grid xs={12} item style={{display:'flex',justifyContent:'center',alignItems:"center",fontSize:'92px'}}>
        401
      </Grid>
      <Grid xs={12} item style={{display:'flex',justifyContent:'center',alignItems:"center",fontSize:'32px',marginTop:'20px'}}>
        You don't have permission to access the resources
      </Grid>
    </Grid>
  );
}

export default UnauthorizePage;