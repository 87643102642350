import { configureStore } from "@reduxjs/toolkit";
import BreadcrumbSlice from "./breadcrumbReducer";
import drawerReducer from "./drawerReducer";
import orderReducers from "./orderReducers";
import driverReducers from "./driverReducers";
import vehicleReducers from "./vehicleReducers";
import subRiderReducers from "./subRidersReducers";
import serviceAreaReducers from "./serviceAreaReducers";
import earningReducers from "./earningReducers";
import parentReducers from "./parentReducers";
import banksReducers from "./banksReducers";
import paymentReducers from "./paymentReducers";
// ==============================|| REDUX - MAIN STORE ||============================== //
export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    breadcrumb: BreadcrumbSlice,
    orders: orderReducers,
    driver: driverReducers,
    vehicle: vehicleReducers,
    subRider: subRiderReducers,
    serviceArea: serviceAreaReducers,
    earnings: earningReducers,
    parent: parentReducers,
    banks:banksReducers,
    payment:paymentReducers
  },
});

export interface PostApiParams {
  end_point: string;
  data: any;
}

export interface GetApiParams {
  end_point: string;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
