// material-ui
import { Typography } from "@mui/material";
import NavGroup from "../NavGroup";

// project imports
// import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import riderMenuItems from "menu-items/menu-items-rider";
import subRiderMenuItems from "menu-items/menu-items-subrider";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import airRiderMenu from "menu-items/menu-item-air-rider";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const user = getDataFromLocalStorage("user");
  let t_type = user?.transporter_type ?? "";
  let navItems = [] as any;

  switch (t_type) {

    case "Fleet Manager":
      navItems = menuItem?.items?.map((item: any) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;
      
    case "Rider":
      navItems = riderMenuItems?.items?.map((item: any) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;

    case "Sub Rider":
      navItems = subRiderMenuItems?.items?.map((item: any) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;

      
    case "Air Rider":
      navItems = airRiderMenu?.items?.map((item: any) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;

    default:
      break;
  }

  return <>{navItems}</>;
};

export default MenuList;
