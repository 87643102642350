/*
  Created By Muhammad Hassaan
  Date 15 April 2023
*/
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, OutlinedInput, TextField } from "@mui/material";

export default function UnitTextfeild(props: any) {
  const {
    input_label,
    required,
    value,
    classes,
    error,
    labelIcon,
    type,
    unit,
    readOnly
  } = props;
  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {input_label && (
        <label
          className={classNames(
            "RobotoCondensed-Regular",
            classes?.input_label_style
          )}
          style={{ paddingLeft: "15px", fontSize: "16px", display: "flex" }}
        >
          <span>{labelIcon}</span>

          {input_label}
          {required && !value ? (
            <span style={{ color: "red", marginLeft: "3px" }}> *</span>
          ) : (
            ""
          )}
        </label>
      )}
      <OutlinedInput
      readOnly={readOnly}
        id="outlined-adornment-weight"
        endAdornment={
          <InputAdornment position="end" >
            <span style={{fontFamily:'Roboto-Bold'}}>{unit ?? 'kg'}</span>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
        
        {...props}
      />
    </form>
  );
}
UnitTextfeild.defaultProps = {
  name: "",
  input_label: "",
  value: "",
  required: false,
  fullWidth: true,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  type: "text",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  onChange: null,
  onClick: null,
  disabled: false,
  unit: "kg",
  readOnly:false
};

UnitTextfeild.propTypes = {
  name: PropTypes.string,
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  labelIcon: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  unit: PropTypes.any,
  readOnly:PropTypes.bool
};
