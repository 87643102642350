import LocalizedStrings from "react-localization";
let Strings = new LocalizedStrings({
  en: {
    //is Required
    IS_REQUIRED: "is required",

    // Sub Rider
    SUB_RIDER_COUNTRY_CODE: "Please Select Country Code",
    SUB_RIDER_PHONE_NUMBER: "Please Enter Phone Number ",
    SUB_RIDER_NAME: "Sub-Rider Name ",

    // Vehicle
    VEHICLE_TYPE: "Vehicle Type ",
    VEHICLE_REG_NUMBER: "Vehicle Reg No",
    VEHICLE_MAKE: "Vehicle Make ",
    VEHICLE_MODEL: "Vehicle Model ",
    VEHICLE_LOAD_CAPACITY: "Vehicle Trunk/Boot Capacity ",
    VEHICLE_HEIGHT: "Vehicle Height ",
    VEHICLE_WEIGHT: "Vehicle Weight ",
    VEHICLE_WIDTH: "Vehicle Width ",
    VEHICLE_COUTRY: "Vehicle Country ",
    VEHICLE_CITY: "Vehicle City ",
    VEHICLE_OWNER: "Vehicle Owner ",
    VEHICLE_COLOR: "Vehicle Color ",
    VEHICLE_IMGAE_1: "Vehicle Image 1",
    VEHICLE_IMGAE_2: "Vehicle Image 2",
    VEHICLE_DOCUMENT_1: "Vehicle Document 1",
    VEHICLE_DOCUMENT_2: "Vehicle Document 2",
    IS_GREEN_TYPE: "is Green Type",

    // Service Area
    SERVICE_AREA_NAME: "Service Area Name ",
    SERVICE_AREA_FROM_TIME: "Please Select from Time",
    SERVICE_AREA_TO_TIME: "Please Select to Time",
    SERVICE_AREA_FROM_WEEKDAY: "Please Select from Week day",
    SERVICE_AREA_TO_WEEKDAY: "Please Select to Week day",
    SERVICE_AREA_FREQUENCY: "Please Select frequency",
    SERVICE_AREA_STATUS: "Please Select Service Area Active Status",
    SERVICE_AREA_MAP_TYPE: "Map Type not define",
    UPLOADING_DOCUMENT_ERROR: "There is an Error while uploading",
    OTP_MISSING: "OTP is Missing",

    VERIFICATION_STATUS: "Verification Status",
    ACTIVATION_STATUS: "Activation Status",
    PROFILE_NAME: "Name",
    EMAIL: "Email",
    PHONE_NUMBER: "Phone Number",
    RANKING: "Ranking",
    RATINGS: "Ratings",
    ATTACH: "Attach ",
    SELECT_SUB_RIDER: "Select Sub-Rider",

    // Prohibited Items Section Strings
    list_of_prohibited_items: "List of Prohibited Items",
    prohibited_item_descp:
      "In compliance of international, regional and national laws, rules, regulations and safety measures, Frank and its services will not accept and entertain following prohibited items for any sort of carriage. Frank faithfully follows the standards and regulations set by the concerned bodies of ‘European Union’ (EU); ‘Agreement on Dangerous Goods by Road’ (ADR), ‘International Air Transport Association’ (IATA); and ‘International Maritime Dangerous Goods’ (IMDG). It is the responsibility of the shipper to check the presence of prohibited item in the parcel before packaging and confirming the order.",
    prohibited_item_1:
      "Alcoholic beverages containing more than 24% alcohol by volume (ABV)",
    prohibited_item_2: "Animals live and pets",
    prohibited_item_3: `Animal parts including animal skin, furs, mount, taxidermy, preserved animal's body, hunting (animal) trophies, horns, Ivory`,
    prohibited_item_4: `Arms, ammunition and guns of any kind, whether in whole, in parts, or their replicas`,
    prohibited_item_5: `Batteries especially lithium batteries, power-bank, any kind of used or damaged batteries`,
    prohibited_item_6: `Bullion (of bulk gold, silver or any precious metal)`,
    prohibited_item_7: `Cash and currency (including coins, banknotes, legal tender bills, money)`,
    prohibited_item_8: `Chemicals (hazardous) restricted by REACH (Registration, Evaluation, Authorisation and Restriction of Chemicals) of EU, and CLP Regulation`,
    prohibited_item_9: `Cigarettes, cigars, tobacco products and electronic cigarette`,
    prohibited_item_10: `Counterfeit material in breach of Intellectual Property Rights (IPR) and EU Copyright Directives, or Pirated stuff (including pirated copies of music, movies, software, games; illegal copies of branded fashion items; illegal replicas of artwork, and photocopies of copy-righted books, documents, certificates)`,
    prohibited_item_11: `Dangerous goods including acids, asbestos, magnetized materials, dry ice`,
    prohibited_item_12: `Explosive items including inert-explosive, crackers, gelatin-sticks, fireworks, fire-snaps, fuses, dynamites, bomb detonators, DIME, HMX, RDX`,
    prohibited_item_13: `Drugs, Narcotics, Heroin, Diamorphine, Cocaine, Opium, Marijuana, Cannabis, LSD, and other stimulants, tranquilizers, and steroids banned by WADA (World Anti-Doping Agency)`,
    prohibited_item_14: `Fire extinguishers`,
    prohibited_item_15: `Flammable items (whether in solid, gas or liquid form) including aerosol sprays, acetone, deodorants, air-freshener, cigarette-lighters, cleaning compounds, gels, hair-dyes, matches, matchsticks, nail polish, shaving gels`,
    prohibited_item_16: `Gasoline, CNG (condensed natural gas) and LPG (liquid petroleum gas), compressed gas canisters`,
    prohibited_item_17: `Human or animal remains including ashes and funerary urns`,
    prohibited_item_18: `Human biological samples (organs, bodily fluids, blood sample, tissue sample)`,
    prohibited_item_19: `Literature (controversial) posters or pamphlets, whether in print or digital, banned by government or official authorities of origin/destination place`,
    prohibited_item_20: `Lottery tickets and gambling stuff of any kind`,
    prohibited_item_21: `Perfumes and scents (containing alcohol)`,
    prohibited_item_22: `Pornographic materials, whether in print, film or digital format`,
    prohibited_item_23: `Radioactive products or by-products (involving uranium, thorium, plutonium) against safety standards of IAEA`,
    prohibited_item_24: `Toxic and Infectious substances, including poisons, pesticides, insecticides, and mercury`,
    prohibited_item_25: `Unidentifiable materials, doubtful substances`,
    prohibited_item_26: `Vignette, road tax stickers`,

    // Restricted Items Section Strings
    list_of_restricted_items: `List of Restricted Items`,
    restricted_items_desp: `There is a subtle difference between prohibited and restricted items. Restricted goods may be allowed to be shipped only within certain circumstances or under certain conditions. Their transportation generally requires special licenses or permits. Some restricted items may be allowed in limited quantities for personal use only. Whereas some restricted items are only allowed when they are packed as per safety standards and do not pose any risks or ecological hazards`,
    restricted_item_1: `Any (non-prohibited) item in bulk quantity – requires license and/or official permit for its transportation`,
    restricted_item_2: `Construction material, concrete, coal tar, stones, big steel frames etc. – requires special vehicle in compliance of occupational safety rules set by local/national administration`,
    restricted_item_3: `Documents of high value, including but not limited to court papers, legal notices, diplomatic mails, property documents, will and testaments, divorce papers, cheques, money orders, bank demand drafts, original passport, airline tickets – consult head office for permission, waybill or/and insurance policy`,
    restricted_item_4: `Flowers – not allowed in parcel involving border crossing; certain flower species are banned by some countries; you can only send and receive bunch of locally available flowers within an area or same city as bouquet or gift`,
    restricted_item_5: `Food (anything perishable, including liquid form, meat, dairy products, meals ready to eat, fresh fruits, or vegetables) – food stuff not allowed in parcel involving border crossing; for personal use you can send and receive food/meal only within an area or same city, if properly packed`,
    restricted_item_6: `Food supplements, herbs, vitamins etc. – should not be products made of animal parts; you may send or receive permissible food supplements for personal use only, if registered physician’s prescription is provided`,
    restricted_item_7: `Jewellery (of diamond, gold, silver etc.) and previous watches and ornament – not allowed in parcel involving border crossing; you can only send and receive precious jewellery within an area or same city, if copy of invoice or waybill is provided`,
    restricted_item_8: `Laptop computer – only one per package allowed along with copy of invoice`,
    restricted_item_9: `Medicinal drugs, pharmaceutical products – not allowed in parcel involving border crossing; for personal use, you can only send and receive medicines within an area or same city with registered physician’s prescription`,
    restricted_item_10: `Mobile phones and small electronic items containing lithium ion batteries – only one phone per package along with copy of invoice`,
    restricted_item_11: `Plants – live planets not allowed in parcel involving border crossing; you may only send and receive plants with proper packaging within an area or same city`,
    restricted_item_12: `Precious and semi-precious stones (cut, or un-cut) – not allowed in parcel involving border crossing; requires certificate from licenced gemologist and waybill`,
    restricted_item_13: `Seeds – not allowed in parcel involving border crossing; you may only send and receive small quantity within an area or same city`,
    restricted_item_14: `Urea, fertilizer, soil or agricultural farming stuff – not allowed in parcel involving border crossing`,
    restricted_item_15: `Used car parts and engines – not allowed in parcel involving border crossing; you may only send and receive vehicle parts with proper packaging within an area or same city`,

    // Terms and Conditions Section Strings
    terms_and_conditions: `Terms and Conditions`,
    summary: "Summary",
    terms_and_cond_summary: `We reserve to right the refuse entry\n\nYou may transfer your ticket or otherwise update ticket details up to two days before the date of the event by logging in to Event brite and changing the details yourself. Ticket transfers are not possible for invite-only events.\n\nYou may cancel your ticket for a full refund up to one month before the date of the event by logging in to Event brite and requesting a refund. No refunds will be issued for tickets that are cancelled less than one month before the event.\n\nYou grant us the right to use your company name and any quotes, video or photography taken at our event in future marketing of our events.\n\nYou grant us the right to use your company name and any quotes, video or photography taken at our event in future marketing of our events, within the boundaries set by our Privacy Policy and your rights as set out in Clause 9.\n\nBy attending our events you agree to abide by our Code of Conduct at all times`,

    introduction: "TRANSPORTER",
    term_intro_1: `These Terms and Conditions constitute an Agreement between you as ‘Transporter’ and the ‘Frank’. Please read it carefully before accessing and using Frank solution, including its Service(s) and App(s). This implies your understanding, agreement and acceptance of the full Terms and Conditions, contained herein. These Terms and Conditions constitute an Agreement between you as ‘Transporter’ and the ‘Frank’. Please read it carefully before accessing and using Frank solution, including its Service(s) and App(s). This implies your understanding, agreement and acceptance of the full Terms and Conditions, contained herein. `,
    term_intro_2: `Nonetheless, your personal data protection and privacy are ensured, and your statutory consumer rights are unaffected.`,
    term_intro_3: `The following definitions shall have the meaning and interpretation, regardless of whether they appear in singular or in plural, or as noun or pronoun in these Terms and Conditions.`,

    definations: "1.	DEFINITIONS",
    term_defination_1: `1.1	“Account” or “User Account means a unique virtual account, created for or by Transporter, in order to access App(s) and Service(s) of Frank.`,
    term_defination_2: `1.2	“App Store” or “Application Store” means the digital distribution forum, from where user can download App(s). App Store included but not limited to Google’s Play Store, Apple’s App Store, and Huawei’s AppGallery.`,
    term_defination_3: `1.3	“App” or “Application” means the software program(s) provided by the Frank via any platform, that is downloaded, accessed and/or used by Transporter, or other entitled entity, on required electronic device, computer, laptop, smartphone, mobile phone or gadget.`,
    term_defination_4: `1.4	“Back Office” (also abbreviated “BO”) means a not-client-facing technical premises for system administration, database management and maintenance of the App(s) and Service(s) of Frank.`,
    term_defination_5: `1.5	“Company” here stands for the “Frank” (which is a registered and legal company). Words like "We", "Us" or "Our" in this document also refers to the Company.`,
    term_defination_6: `1.6	“Confidential Information” means non-public information of a competitive or commercially sensitive, proprietary, financial, trade information, technical data, app functionality, or any information that involves or implicates privacy interests.`,
    term_defination_7: `1.7	“Content” refers to any text, numbers, images, graphical elements, or other information that is submitted, received, viewed, linked or otherwise made available in the App(s) and Service(s) of Frank.`,
    term_defination_8: `1.8	“Credentials” means mandatory information including user-name, email id, phone number and password, that is required in the process of subscription, registration and signing-in.`,
    term_defination_9: `1.9	“Customer” means any individual, company, or other legal entity, that is duly registered and legally uses the App(s) and Service(s) of Frank on required and relevant device. If you are Customer of Frank, then words “You” and “your”, in this document, refer to Customer. `,
    term_defination_10: `1.10	“Device” means modern digital device and gadget that enable you to access and use the App(s) and Service(s) of Frank; such as a computer, laptop, smartphone, mobile phone, tablet etc.`,
    term_defination_11: `1.11	“Order” is a request and process of shipping parcel from one place to another. Order is placed by Customer, and fulfilled by Transporter. Another kind of Order involves parcel storage at Space. `,
    term_defination_12: `1.12	“Payment” is the performance of an obligation to pay money, such as payment of order delivery amount, store subscription, transporter commission etc. `,
    term_defination_13: `1.13	“Registration” is an act of online registration that initiates the process of becoming Transporter of Frank. It requires an agreement between you as Transporter and Frank, that legally entitles you to access and use its App(s) and Service(s) of Frank for agreed period of time.`,
    term_defination_14: `1.14	“Service” or “Service(s)” or “Services” includes different types of shipping deliveries, digital facilities, virtual functionalities and features, directly or indirectly provided by Frank to its Customers, Transporters and Stores.`,
    term_defination_15: `1.15	“Sign-in” (also known as Log-in) refers to act of legally accessing the App(s) and Service(s) with the help of valid Credential on required Device.`,
    term_defination_16: `1.16	“Space” (also termed Storage or Space Storage or Space Store) is a special entity that facilitates temporary storage of customers’ parcels and packages. It may also fall under the category of Store.`,
    term_defination_17: `1.17	“Store” by definition is any online store by means of which general goods or services are sold over the internet. It is available as Web-App or App, with e-commerce solution, plug-ins and payment system. Frank offers its Service(s) to subscribed stores. If you are a subscribed Store of Frank, then words “You” and “your”, in this document, refer to Store.`,
    term_defination_18: `1.18	“Support” means technical support and assistance (whether verbal, written, graphical, audio or visual), provided by Frank to you as Transporter.`,
    term_defination_19: `1.19	“Terms and Conditions” (also referred as "Terms" or “Terms of Use”) means these Terms and Conditions, that form the entire agreement between Transporter and the Frank, regarding the access and use of the its App(s) and Service(s).`,
    term_defination_20: `1.20	“Third-party” (also abbreviated “3P”) means virtual services, platforms, or software (including APIs, syntax, products, services, or programing snippet) that are created and provided by any third-party entity, and that is directly or indirectly incorporated in the front-end or backend of Frank App(s) and Service(s).`,
    term_defination_21: `1.21	“Tracking” refers to a Service of tracing the location and status of requested Order, from its origin to final destination, and providing this information to the relevant Customer and involved entity.`,
    term_defination_22: `1.22	“Transporter” refers to a registered and verified person that manages and delivers the requested Order of Customer. Under Frank umbrella, Rider, Fleet Manager, Sub-Rider and Air-Rider are different types of transporters. If you are Transporter of Frank, then words “You” and “your”, in this document, refer to Transporter.`,
    term_defination_23: `1.23	“Update” means minor change(s) in the existing front-end or back-end, in order to fix technical issue(s), or to improve and enhance the Frank App(s) and Service(s).`,
    term_defination_24: `1.24	“Web App” (i.e. web application) is an instance of Frank App(s) that is accessed and used by Transporter with the help of any recommended internet browser.`,

    acknowledgment: "Acknowledgment",
    term_ack_1: `2.1	These are the Terms and Conditions governing the use of App(s) and Service(s) and the agreement that operates between you as Transporter and the Company. These Terms and Conditions set out the rights and obligations of all users of the App(s) and Service(s).`,
    term_ack_2: `2.2	Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the App(s) and Service(s).`,
    term_ack_3: `2.3	By accessing or using the App(s) and Service(s), you agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then you may not access the App(s) and Service(s).`,
    term_ack_4: `2.4	You represent that you are adult of over the age of 18 years. The Company does not permit those under 18 to use the Service(s).`,
    term_ack_5: `2.5	You are in normal physical and mental condition, that do not hinder the essential expertise, required to operate the Device and access and utilize the App(s) and Service(s).`,
    term_ack_6: `2.6	Your access and use of the App(s) and Service(s) is also conditioned on your acceptance of and compliance with the Privacy Policy of the Company. `,

    Intellectual_property: "INTELLECTUAL PROPERTY",
    term_intel_property_1: `3.1	The architecture, programming codes, database, logical flows, interface, features and functionalities of the App(s) and Service(s) are, and, will remain the exclusive property of the Company.`,
    term_intel_property_2: `3.2	The Company and its App(s) are protected by copyright, trademark, and other laws of both the France and European Union.`,
    term_intel_property_3: `3.3	The brand name, trademark, and content may not be used in connection with any other product or service, without the prior written consent of the Company.`,

    privacy_confidentially: `PRIVACY AND CONFIDENTIALITY `,
    term_privacy_conf_1: `4.1	Company and Transporter (each party) acknowledges that certain information that will receive from the other party may be confidential information of the other party. Any party receiving confidential information shall exercise the same degree of reasonable standard of care and protection with respect to the other party. `,
    term_privacy_conf_2: `4.2	The recipient of the confidential information and its personnel may only use other party’s confidential information to the extent necessary to fulfill necessary obligations under the scope of the App(s) and Service(s) of Frank.`,
    term_privacy_conf_3: `4.3	The recipient of the confidential information shall not, directly or indirectly, disclose, copy, share, distribute, republish or allow any other individual or party to have access to any confidential information. `,
    term_privacy_conf_4: `4.4	The recipient of the confidential information may only disclose confidential information to its trusted personnel, if such personnel have a need to know the confidential information in order for the recipient to perform its obligations, and such personnel have agreed in writing to comply with the confidentiality obligations contained in these Terms and Conditions.`,

    data_protection: "DATA PROTECTION",
    term_data_protection_1: `5.1	Company will ensure that Transporter Personal Data will be processed in accordance with applicable privacy and data protection laws and regulations.`,
    term_data_protection_2: `5.2	Company will treat Transporter Personal Data confidential and implement appropriate technical and organizational measures to protect this data.`,
    term_data_protection_3: `5.3	Company will not share Transporter Personal Data to any irrelevant third-party, out of technical scope of App(s) and Service(s) of Frank. `,
    term_data_protection_4: `5.4	Company will process Transporter Personal Data solely for the purpose of providing the agreed Service(s) and Support. `,

    user_account: "USER ACCOUNT",
    terms_user_account_1:
      "6.1	While registering and signing-in the App(s), you must submit valid Credentials that is accurate, complete, updated, and not harmful to anyone or anything by any means. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your Frank Account.",
    terms_user_account_2:
      "6.2	While accessing and using the App(s) and Service(s), you must submit required data that is accurate, complete, updated, and not harmful to anyone or anything by any means. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your Frank Account.",
    terms_user_account_3:
      "6.3	You are responsible for protecting the Credentials, that you use to access the App(s) and Service(s) of Frank.",
    terms_user_account_4:
      "6.4	You agree not to disclose your Credentials (user-name, email, phone number, confirmation-code and password) to any irrelevant entity or third party. You must notify Frank immediately upon becoming aware of any breach of security or unauthorized use of your Account.",
    terms_user_account_5:
      "6.5	You will not use someone else’s user-name, email or phone number, that is not lawfully entitled to access and use the App(s) and Service(s) of Frank.",

    terms_support: "SUPPORT",
    terms_support_1:
      "7.1	Support is aimed at initial assistance for introducing Transporter to the proper utilization of the App(s) and Service(s), and for troubleshooting any unexpected issue, during limited time period. ",
    terms_support_2:
      "7.2	Company will offer technical support to you, if you are a verified Transporter and lawfully using the App(s) and Service(s) of Frank, that explicitly include providence of Support feature. ",
    terms_support_3:
      "7.3	Company will provide technical support to Transporter, according to the workhour schedule and time-zone of the support-providing personnel of the Company. ",
    terms_support_4:
      "7.4	Company is not bound to offer exhaustive technical support or training program to Transporter, all the time. ",
    terms_support_5:
      "7.5	Request for adding a new feature in the App(s) and Service(s) is not, and, will not consider Support.",

    payment: "PAYMENT",
    terms_payment_1:
      "8.1	Frank does not freely avail shipping and transportation services from its registered and verified Transporters, unless explicitly mention. ",
    terms_payment_2:
      "8.2	A certain amount of applicable commission is paid to you as Transporter, subject to Order amount and other applicable conditions. ",
    terms_payment_3:
      "8.3	You will keep your contact information, invoice billing information, bank account and credit card information up-to-date. ",
    terms_payment_4:
      "8.4	You authorize Frank to use your credit card and bank account for transactions of the receivables and payables",
    terms_payment_5:
      "8.5	You authorize legal third party to process payments, and consent to the disclosure of your payment information to such third party. ",
    terms_payment_6:
      "8.6	All invoiced amounts, to or from concerned party (whether You or Company), are due and payable within thirty (30) days from the date of the invoice, unless otherwise specified and mutually agreed.",
    terms_payment_7:
      "8.7	Company reserves the right to levy penalty charges upon you as Transporter, for cancelling the Customer Order after accepting, or delaying the shipping delivery, or rendering any intentional damage to ordered parcel.",

    limitation_liability: "LIMITATION OF LIABILITY",
    terms_limitation_liability_1:
      "9.1	In no event will Company be liable for any incidental, consequential, special, punitive, direct or indirect loss of business opportunity, loss of goodwill, loss of profits, loss of vehicle, loss of parcel package, or loss of personal data which may arise in connection with or pertaining to App(s) and Service(s) of Frank.",
    terms_limitation_liability_2:
      "9.2	In no event will Company be liable for any incidental, consequential, special, punitive, direct or indirect damage to any real estate property, damage to any vehicle, damage to any device, damage to any hardware, or damage to any parcel package, which may arise in connection with or pertaining to App(s) and Service(s) of Frank.",
    terms_limitation_liability_3:
      "9.3	In no event shall the Company or its personnel be liable for any: (a) death or physical injury; (b) fraud or fraudulent misrepresentation; (c) willful misconduct; (d) infringement of the other party’s intellectual property rights; (e) the breach of confidentiality and personal data; (f) company payment obligations; or which (g) cannot be limited under applicable law.",

    as_available: "AS IS and AS AVAILABLE Disclaimer",
    terms_is_available_1:
      "10.1	The App(s) and Service(s) of Frank are provided to You, AS IS and AS AVAILABLE and with all its limitations, technical drawbacks or human errors, without warranty of any kind.",
    terms_is_available_2:
      "10.2	To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates, and respective licensors and 3P service providers, expressly disclaims all warranties, whether expressed, implied, statutory or otherwise, with respect to the Service(s), including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. ",
    terms_is_available_3:
      "10.3	Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service(s) will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards, or be error free or that any errors or defects can or will be corrected.",
    terms_is_available_4:
      "10.4	Without limiting the foregoing, neither the Company, nor third-party, nor any relevant platform makes any representation or warranty of any kind, express or implied:  ",
    terms_is_available_4_a:
      "(a)	as to the operation or availability of the Service(s), or the information, content, and materials or products included thereon; ",
    terms_is_available_4_b:
      "(b)	that the Service will be uninterrupted or error-free; ",
    terms_is_available_4_c:
      "(c)	as to the accuracy, reliability, or currency of any information or content provided through the Service; or  ",
    terms_is_available_4_d:
      "(d)	that the App(s), Service(s), its interface, database, content, or e-mails sent from or on behalf of the Company are entirely free of bugs, software viruses, computer worms, malware, or other harmful components.",

    terms_is_available_5:
      "10.5	Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.",

    force_majeure: "FORCE MAJEURE",
    terms_force_majeure_1:
      "11.1	Company shall not be liable to you (as Transporter or any designated entity), for any loss or damages, as a direct or indirect result of the App(s) and Services(s), being prevented, hindered, delayed or rendered uneconomic by reason of circumstances or events beyond reasonable control of Company, including but not limited to acts of God, war, riot, strike, lock-down, fire, flood, storm, and natural calamity.",

    severaility_waiver: "SEVERABILITY AND WAIVER",
    terms_severaility_waiver_1:
      "12.1	If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect. ",
    terms_severaility_waiver_2:
      "12.2	Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms and Conditions shall not affect Transporter’s or Company’s ability to exercise such right or require such performance at any time thereafter, nor shall the waiver of a breach constitute a waiver of any subsequent breach.",

    changes_terms_condition: "CHANGES TO THESE TERMS AND CONDITIONS",
    terms_changes_terms_condition_1:
      "13.1	Company reserves the right, at its sole discretion, to modify, add or edit these Terms and Conditions at any time, with or without any prior notification. What constitutes a material change will be determined at sole discretion of Company.",
    terms_changes_terms_condition_2:
      "13.2	By continuing to access or use App(s) and Service(s), after those revisions become effective; you agree to be bound by the revised Terms. If You do not agree to the new or revised terms, in whole or in part, please stop using the App(s) and Service(s) of the Company.",

    general: "7. General",
    term_general_1: `7.1 These Terms and Conditions (together with any documents referred to herein or required to be entered into pursuant to these Terms and Conditions) contain the entire agreement and understanding between us and supersede all prior agreements, understandings or arrangements (both oral and written) relating to the subject matter of these Terms and Conditions and any such document.`,
    term_general_2: `7.2 These Terms and Conditions shall not create, nor shall they be construed as creating, any partnership or agency relationship between us.`,
    term_general_3: `7.3 You accept that communication with us will be electronic. We may contact you by e-mail or provide you with information by posting notices on our website. You agree to this electronic means of communication and you acknowledge that all such communications that we provide to you electronically comply with any legal or contractual requirement that such communication be made in writing.`,
    term_general_4: `7.4 Save as set out in Clause 4.2 you are not permitted to re-sell, transfer, assign or otherwise dispose of any of your rights or obligations arising under these Terms and Conditions.`,
    term_general_5: `7.5 These Terms and Conditions and the rights and obligations of both parties shall be governed by, and construed in accordance with, the laws of England and Wales and both parties irrevocably agree to submit to the exclusive jurisdiction of the courts of England and Wales in respect of any dispute which arises hereunder.`,
    term_general_6: `7.6 We reserve the right to refuse entry at our sole discretion.`,
    term_general_7: `7.7 If you have special access requirements (such as wheelchair access) please contact us when you register so we work with the venue to best accommodate you.`,

    code_of_conduct: `8.Code of Conduct`,
    code_of_conduct_descp: `Our Code of Conduct can be found at http://mindtheproduct.com/code-of-conduct – by attending the event you explicitly agree to be bound by the terms of the Code of Conduct.`,

    // Privacy Policy Section Strings
    privacy_policy: "Privacy Policy",
    policy_summary: `We reserve to right the refuse entry\n\nYou may transfer your ticket or otherwise update ticket details up to two days before the date of the event by logging in to Event brite and changing the details yourself. Ticket transfers are not possible for invite-only events.\n\nYou may cancel your ticket for a full refund up to one month before the date of the event by logging in to Event brite and requesting a refund. No refunds will be issued for tickets that are cancelled less than one month before the event.\n\nYou grant us the right to use your company name and any quotes, video or photography taken at our event in future marketing of our events.\n\nYou grant us the right to use your company name and any quotes, video or photography taken at our event in future marketing of our events, within the boundaries set by our Privacy Policy and your rights as set out in Clause 9.\n\nBy attending our events you agree to abide by our Code of Conduct at all times.`,

    policy_information_we_collect_and_how_we_use_it:
      "1. Information We Collect and How We Use it",
    policy_info_descp: `The event is organised by Mind the Product Ltd, a company registered in England and Wales with registration number 07721716 and registered office at Station Studios, 96 Ethel Street, Hove, East Sussex, BN3 3LL. Mailing list address is Mind the Product Ltd. Office 36 88-90, Hatton Garden, Holborn, London.\n\nReferences to “us” means Mind the Product Ltd and references to “we” and “our” shall be construed accordingly. Reference to “you” means the entity completing a booking request and references to “your” shall be construed accordingly.\n\nAll applications to register for the conference, and all orders to purchase the relevant documentation pack, are made subject to these Terms & Conditions (which shall apply to the exclusion of any terms imposed by you).`,

    policy_account_contact_info: `2. Account Creation and Contact Information`,
    policy_account_contact_info_descp: `You acknowledge and accept that we have the right to publicly announce our business relationship with you and your company which shall include but not be limited to announcements on social media. Such announcements shall not be disparaging or otherwise adverse to your business.\n\nYou grant us the right to use any photography or video of the day which may capture your image and any quotes on feedback forms for the future marketing of events, within the boundaries set by our privacy policy and your rights as set out in Clause 9. Such coverage shall not be disparaging or otherwise adverse to you or your business.`,

    policy_comments_media: `3. Comments & Media`,
    policy_comments_media_descp: `Prices will be clearly marked on the ticketing site.\n\nNo tickets are reserved prior to payment clearing, save for official sponsors.\n\nWe only offer payment by invoice for 5 tickets or more due to administrative overhead involved.\n\nIf are paying by invoice, payment is due immediately on receipt. Tickets will only be issued upon full payment, and are subject to availability.`,

    policy_embedded_media: "4. Embedded Media",
    policy_embedded_media_descp: `It may be necessary for reasons beyond our reasonable control to alter the advertised content, timing and/or location of the conference or the advertised speakers or cancel the event. We reserve the right to do this at any time. Where we alter the time and/or location of the conference, we will provide you with notice of the same and will offer you the choice of either a credit for a future event of your choice (up to the value of sums paid by you in respect of the conference) or the opportunity to attend the conference as varied.\n\nIf you are unable to attend the conference and cancel at least 30 days prior, we are happy to issue a full refund – please request this directly through the ticketing site. If you cancel within 30 days of the event, we welcome substitute delegates attending in your place at no extra cost provided that we have at least 10 days prior notice of the name of your proposed substitute and have received payment in full. All changes should be made directly in the ticketing system as the first port of call. Ticket transfers and substitutes are not possible for invite-only events. For subsequent issues, please contact us by email at: tickets@mindtheproduct.com.\n\nNo refunds will be given in respect of cancellations outside the reach of clause 4.2 or non-attendance.\n\nWe shall not be liable to you for fees, travel, accommodation or other costs and expenses incurred (included wasted costs and expenses) if we are required to cancel or relocate the conference as a result of an event outside our control (including, without limitation, to acts of God, floods, lightning, storm, fire, explosion, war, military operations, acts of terrorism or threats of any such acts, any strike action, lock-outs or other industrial action and a pandemic, epidemic or other widespread illness or force majeure).`,

    policy_biographical_social_media_information: `5. Biographical and Social Media Information`,
    policy_biographical_social_media_information_descp: `All rights in all presentations, documentation and materials published or otherwise made available as part of the conference (including but not limited to any documentation packs or audio or audio-visual recording of the conference) (“Content”) are owned by us or are included with the permission of the owner of the rights. You shall not distribute, reproduce, modify, store, transfer or in any other way use any of the Content for commercial reasons or in any way that might infringe third party rights or that may bring us or any of our affiliates into disrepute.\n\nWe actively encourage tweeting, blogging, sharing photos and videos of the day.\n\nThe Content does not necessarily reflect our views or opinions.\n\nWhilst we take reasonable care to ensure that the Content created by us is accurate and complete, some of it is supplied by third parties and we are unable to check its accuracy or completeness. You should verify the accuracy of any information (whether supplied by us or third parties) before relying on it. The Content is provided on an “AS IS” basis without any warranties of any kind (express or implied). We hereby exclude to the fullest extent permitted by law all liabilities, costs, claims, damages, losses and/or expenses arising from any inaccuracy or omission in the Content or arising from any infringing, defamatory or otherwise unlawful material in the Content.\n\nTo the extent that any Content is made available by us online we reserve the right to suspend or remove access to such Content at any time.\n\nWe reserve the right to change speakers at any time without recourse`,

    policy_billing_other_information: `6. Billing Other Information`,
    policy_billing_other_information_descp: `Our aggregate liability to you, whether such liability arises in contract, tort (including negligence) or otherwise, for any damages, loss, costs, claims or expenses of any kind howsoever arising, out of in connection with any booking (or requested booking) made by you or otherwise in relation to a conference, shall be limited to the price paid by you in respect of your booking to attend the conference.\n\nWe shall not be liable to you for (i) any loss of profit, loss of or damage to data, loss of anticipated savings or interest, loss of or damage to reputation or goodwill or; (ii) any indirect, special or consequential damages, loss, costs, claims or expenses of any kind.\n\nYou agree to indemnify us, our staff and our affiliates and to hold us harmless to the fullest extent permitted by law, against all loss, costs, claims or expenses of any kind arising from any act or omission by you (including your delegates) during or otherwise in relation to a conference\n\nNothing in this these Terms and Conditions shall limit or exclude either party’s liability for death or personal injury caused by that party’s negligence, or the negligence of that party’s employees, agents or subcontractors; fraud or fraudulent misrepresentation; or any other liability which cannot be limited or excluded by applicable law.`,

    policy_service_usage_information: `7. Service Usage Information`,
    policy_service_usage_information_descp: `These Terms and Conditions (together with any documents referred to herein or required to be entered into pursuant to these Terms and Conditions) contain the entire agreement and understanding between us and supersede all prior agreements, understandings or arrangements (both oral and written) relating to the subject matter of these Terms and Conditions and any such document.\n\nThese Terms and Conditions shall not create, nor shall they be construed as creating, any partnership or agency relationship between us.\n\nYou accept that communication with us will be electronic. We may contact you by e-mail or provide you with information by posting notices on our website. You agree to this electronic means of communication and you acknowledge that all such communications that we provide to you electronically comply with any legal or contractual requirement that such communication be made in writing.\n\nSave as set out in Clause 4.2 you are not permitted to re-sell, transfer, assign or otherwise dispose of any of your rights or obligations arising under these Terms and Conditions.\n\nThese Terms and Conditions and the rights and obligations of both parties shall be governed by, and construed in accordance with, the laws of England and Wales and both parties irrevocably agree to submit to the exclusive jurisdiction of the courts of England and Wales in respect of any dispute which arises hereunder.\n\nWe reserve the right to refuse entry at our sole discretion.\n\nIf you have special access requirements (such as wheelchair access) please contact us when you register so we work with the venue to best accommodate you.`,

    //FAQS//
    //General//
    what_is_frank: "What is Frank?",
    what_is_frank_description:
      "Frank is an affordable and trusted service provider company. It offers online logistics and transportation services to deliver wide range of parcels, packages, posts, and mails.",
    What_kind_of_items: "What kind of items, I can ship with Frank? ?",
    What_kind_of_items_description:
      "There is no barrier on weight or dimension of your parcel. However, as far as type of item inside parcel is concerned, Frank follows the standards and regulations of ‘Prohibited Items’, and ‘Restricted Items’.Find the detailed list here ↖.",
    What_is_the_advantages_of_choosing_Frank_services:
      "What is the advantages of choosing Frank services?",
    What_is_the_advantages_of_choosing_Frank_services_description:
      "Affordability’, ‘Security’ and ‘Timeliness’ are three primary advantages of opting Frank services. You get ‘affordability’ because of Frank’s highly competitive rates; ‘security’ because of access of real-time tracking system; and ‘timeliness’ on account of not relying on traditional queuing and quota delivery system.",
    What_delivery_types_offered_by_Frank:
      "What delivery types offered by Frank?",
    What_delivery_types_offered_by_Frank_description:
      "There are three distinct types of parcel delivery, offered by Frank services. They are (1) Urgent; (2) Standard; and (3) Economy. Needless to mention, ‘urgent’ option is the fastest delivery; ‘standard’ is commonly opted; while ‘economy’ is the cheapest available option",

    What_regions_and_countries_covered_by_Frank:
      "What regions and countries covered by Frank?",
    What_regions_and_countries_covered_by_Frank_description:
      "Frank products and services specially and exclusively covers EU (European Union) region. If EU is your origin or destination then you can send or receive parcel from anywhere in the world.",

    What_are_Frank_operating_hours_and_days:
      "What are Frank operating hours and days?",
    What_are_Frank_operating_hours_and_days_description:
      "24 hours, all 7 seven days of week. You can submit ‘Send Parcel’ or ‘Receive Parcel’ order anytime, from any corner of the globe.",

    How_competitive_are_your_courier_rates:
      "How competitive are your courier rates?",
    How_competitive_are_your_courier_rates_description:
      "With Frank services you really get an edge of competitive prices. In addition, Frank does not charge any hidden percentage or prices. This is not just a claim. Feel free to visit Frank website or download Frank App",

    Is_there_any_backend_office_or_support_team:
      "Is there any backend office or support team?",
    Is_there_any_backend_office_or_support_team_description:
      "Yes, Frank is a registered company on ground, and has its head office in Paris (France). Frank has state-of-the-art virtual platform, robust web and mobile applications, admin office, and professional customers’ support team to serve you round the clock. Registered users can contact Frank support team anytime.",

    How_can_I_use_Frank_services: "How can I use Frank services?",
    How_can_I_use_Frank_services_description:
      "You can send and/or receive parcel through two primary means: (1) via Frank mobile app; and (2) via Frank website. Just register and login, and fill the online form. It is highly user-friendly three step process.",

    Do_you_offer_insurance_or_claim_services:
      "Do you offer insurance or claim services?",
    Do_you_offer_insurance_or_claim_services_description:
      "Yes, Frank offer insurance scheme for special or high-value shipment, provided you additionally opt for it. The postage or shipping insurance cost is separately charged.",

    //Registration

    Is_there_any_dedicated_app_for_Fleet_Manager:
      "Is there any dedicated app for Fleet Manager? ",
    Is_there_any_dedicated_app_for_Fleet_Manager_description:
      "Since Fleet Manager falls under the category of Transporter, therefore Frank Transporter App caters the Fleet Manager. You may download Frank Transporter App from Google Play Store, Apple Store and Huawei App. Then register and sign-in as Fleet Manager.",

    What_are_my_stream_of_profit_as_Fleet_Manager:
      "What are my stream of profit as Fleet Manager?",
    What_are_my_stream_of_profit_as_Fleet_Manager_description:
      "The performance of your Sub-Riders (drivers) is source of your earning as Fleet Manager. You will get applicable commission on every successful order delivery, performed by your Sub-Riders.",

    What_are_payment_terms_between_Fleet_Manager_and_Sub_Riders:
      "What are payment terms between Fleet Manager and Sub-Riders?  ",
    What_are_payment_terms_between_Fleet_Manager_and_Sub_Riders_description:
      "Since Sub-Rider is a driver that is hired and managed by you as Fleet Manager. Therefore, you will decide how much they should be paid. Frank does not directly pay or receive any amount to Sub-Riders.",

    Can_I_attain_double_role_of_Rider_and_Fleet_Manager:
      "Can I attain double role of Rider and Fleet Manager? ",
    Can_I_attain_double_role_of_Rider_and_Fleet_Manager_description:
      "Yes, Frank allows Fleet Manger to simultaneously become Rider. In this case, you act dual role. Your earning as Fleet Manger comes from jobs of your Sub-Riders. While your earning as Rider depends on your job of fulfilling order yourself.  ",

    Although_I_ve_submitted_requested_documents_but_why_I_m_not_yet_verified:
      "Although I’ve submitted requested documents, but why I’m not yet verified?",
    Although_I_ve_submitted_requested_documents_but_why_I_m_not_yet_verified_description:
      "It takes a little time to verify the submitted documents. Frank consult relevant department to confirm you documents. Please be wait a while. ",

    Is_Vehicle_verification_different_from_Transporter_Fleet_Manager_verification:
      "Is Vehicle verification different from Transporter (Fleet Manager) verification",
    Is_Vehicle_verification_different_from_Transporter_Fleet_Manager_verification_description:
      "Yes, vehicle verification is different than transporter verification. Your verification asks for citizen (national) id card, while vehicle verification requires vehicle’s registration documents. ",

    Can_I_add_a_vehicle_in_my_earlier_defined_fleet:
      "Can I add a vehicle in my earlier-defined fleet",
    Can_I_add_a_vehicle_in_my_earlier_defined_fleet_description:
      "Yes, you may add new vehicle in your vehicle. However, it will also require verification",

    Can_I_de_active_a_vehicle_from_my_fleet_without_deleting_or_removing_it:
      "Can I de-active a vehicle from my fleet, without deleting or removing it?",
    Can_I_de_active_a_vehicle_from_my_fleet_without_deleting_or_removing_it_description:
      "Yes, you may de-activate (make dormant) a vehicle of your fleet for a certain time. So as you can re-activate your inactive vehicle again.",

    What_are_prerequisites_for_become_Frank_Rider:
      "What are prerequisites for become Frank Rider? ",
    What_are_prerequisites_for_become_Frank_Rider_description:
      "Frank is a great platform to join as Rider, as it offers convenience and flexibility. If you have a car, van, truck, bike or bicycle, you can apply for Rider. First install the Transporter app (or visit Frank website). Then register yourself and submit requested documents. ",

    How_do_Frank_verify_identity_of_candidate_and_vehicle:
      "How do Frank verify identity of candidate and vehicle?",
    How_do_Frank_verify_identity_of_candidate_and_vehicle_description:
      "Frank has a robust and efficient system of verification. When you submit your national/citizen card, driving license and vehicle registration, Frank confirms the authenticity of your documents from concerned issuing authority. ",

    Do_Frank_provides_any_training_to_its_riders:
      "Do Frank provides any training to its riders? ",
    Do_Frank_provides_any_training_to_its_riders_description:
      "Yes, this is necessary. Frank provides training to verified riders, before they land on ground to carry out delivery service. It enables candidate riders to be understand the process of delivery service and rights and responsibilities.",

    What_types_of_shipping_a_rider_needs_to_fulfill:
      "What types of shipping, a rider needs to fulfill? ",
    What_types_of_shipping_a_rider_needs_to_fulfill_description:
      "Frank Riders enjoy variety of shipping services, for instance C2C Shipping and Store Shipping (from E-merchant and D2C stores). Moreover, there are Classic, Flex and Green delivery types. ",

    Can_a_rider_join_Frank_as_part_time: "Can a rider join Frank as part-time?",
    Can_a_rider_join_Frank_as_part_time_description:
      "Yes, a rider can join Frank full-time as well as part-time. This facilitates students, household women and even retired persons serve as part-time rider.",

    Can_I_attain_double_role_of_Rider_and_Fleet_Manager_for_Rider:
      "Can I attain double role of Rider and Fleet Manager? ",
    Can_I_attain_double_role_of_Rider_and_Fleet_Manager_for_rider_description:
      "Yes, Frank allows Fleet Manger to simultaneously become Rider, and vice versa. In this case, you play a dual role. Your earning as Fleet Manger comes from jobs of your Sub-Riders. While your earning as Rider depends on your job of fulfilling order yourself.  ",

    Can_I_add_a_vehicle_in_my_earlier_defined_fleet_for_rider:
      "Can I add a vehicle in my earlier-defined fleet?",
    Can_I_add_a_vehicle_in_my_earlier_defined_fleet_for_rider_description:
      "Yes, you may add new vehicle in your vehicle. However, it will also require verification. ",

    Can_I_de_active_a_vehicle_from_my_fleet_without_deleting_or_removing_it_for_rider:
      "Can I de-active a vehicle from my fleet, without deleting or removing it?",
    Can_I_de_active_a_vehicle_from_my_fleet_without_deleting_or_removing_it_for_rider_description:
      "Yes, you may de-activate (make dormant) a vehicle of your fleet for a certain time. So as you can re-activate your inactive vehicle again.",

    What_if_I_forget_my_Frank_app_account_password:
      "What if I forget my Frank app account password?",
    What_if_I_forget_my_Frank_app_account_password_description:
      "The double verification system ensures retrieval of your lost or forgotten password. If problem persists then you can contact support team.",

    My_installed_Frank_app_is_not_functioning_What_should_I_do:
      "My installed Frank app is not functioning. What should I do?",
    My_installed_Frank_app_is_not_functioning_What_should_I_do_description:
      "Check your internet connection, and make sure signals are properly receiving at your mobile device. Most of the problems arise, because of poor or limited internet connection. If you are a first time user of Frank app, then please fill double verification codes, and sign-in again. Please also enable location preference from your phone ‘Settings’. Frank app services will not work, if location preference is setting off.",

    //Send Parcel
    How_do_I_book_a_courier_through_Frank:
      "How do I book a courier through Frank?",
    How_do_I_book_a_courier_through_Frank_description:
      "Booking ‘Send Parcel’ order is a simple process. The interface of app is highly user-friendly. Make sure to have receiver’s name, destination address, and details (weight, size and type) of parcel in hand. You have to submit these information for completing the online form, whether you are logged in from mobile app, or Frank website.",

    How_much_time_it_takes_to_send_my_parcel:
      "How much time it takes to send my parcel?",
    How_much_time_it_takes_to_send_my_parcel_description:
      "This depends on the distance between origin and destination location of parcel. Another factor is the chosen delivery type (urgent, standard or economy). If your order has confirmed, and transporter has picked your parcel, then you should expect same day delivery in most of the case.",

    What_kind_of_items_I_can_ship_with_Frank_Send_Parcel_service:
      "What kind of items, I can ship with Frank ‘Send Parcel’ service?",
    What_kind_of_items_I_can_ship_with_Frank_Send_Parcel_service_description:
      "There is no restriction on weight or dimension of sending parcel. However, as far as type of item inside parcel is concerned, Frank follows the standards and regulations of ‘Prohibited Items’, and ‘Restricted Items’. Find the detailed list here ↖.",

    Does_the_destination_location_affect_the_pricing_and_time_taken:
      "Does the destination location affect the pricing and time taken?",
    Does_the_destination_location_affect_the_pricing_and_time_taken_description:
      "Yes, of course. Nearer the destination, sooner the deliver. Same is also valid for the charged prices. Please note, if parcel destination is a difficult to approach location (like remote country side, thickly populated city centre, island or highland area) then it naturally asks additional time and cost.",

    The_drop_destination_is_not_shown_on_map_of_Frank_app:
      "The drop destination is not shown on map of Frank app?",
    The_drop_destination_is_not_shown_on_map_of_Frank_app_description:
      "If map screen/window is not available then please enable location preference at mobile default settings. If your destination is located at any remote or rushy area, and its exact address is not seen on the map then you can select nearest available neighbourhood. You may edit the location address by entering exact house/office number in input text-box (after selecting location from map, at step 2 screen/window). Frank transporter will contact receiver for confirmation of the address.",

    Can_I_cancel_my_Send_Parcel_order: "Can I cancel my ‘Send Parcel’ order?",
    Can_I_cancel_my_Send_Parcel_order_description:
      "Yes, you can cancel the order. And there are three possible situations. First, if you have filled the ‘Send Parcel’ form, but not ‘Submit’ the order. It will not cost you any deduction charges. Second situation, after submitting the parcel order, but before handing over the parcel package to the courier-man (transporter). It will cost you small percentage as charge. Third situation, after handing over the parcel package to the courier-man (transporter), if you cancel the order then charges will be higher.",

    What_is_the_pickup_time_slot_range: "What is the pickup time slot/range?",
    What_is_the_pickup_time_slot_range_description:
      "This option is provided for the ease of users. You may schedule your availability for handing over parcel. Frank carrier / transporter will approach you at given time slot/range.",

    What_if_no_transporter_carrier_is_around_to_pick_my_parcel:
      "What if no transporter/carrier is around to pick my parcel?",
    What_if_no_transporter_carrier_is_around_to_pick_my_parcel_description:
      "It is possible, especially in case of difficult to approach locations (like remote country side, thickly populated city centre, island, or highland area). Have some patience, or re-submit the order at a different time. If you have a status of loyal customer, then company may arrange alternate (third party service). Otherwise in case of urgency or emergency, you may cancel the order. It will not cost you any charges.",

    Is_it_necessary_to_mention_name_and_number_of_parcel_receiver_consignee:
      "Is it necessary to mention name and number of parcel receiver (consignee)?",
    Is_it_necessary_to_mention_name_and_number_of_parcel_receiver_consignee_description:
      "Yes. In order to deliver your parcel safely and timely, you have to submit full-name and active mobile number of parcel receiver (consignee).",

    What_if_consignee_or_receiver_of_parcel_is_not_available_at_address:
      "What if consignee or receiver of parcel is not available at address?",
    What_if_consignee_or_receiver_of_parcel_is_not_available_at_address_description:
      "If consignee or designated receiver is not available at mentioned address then courier-man (transporter) will contact you for asking alternate consignee/receiver. However, if your parcel contains any high value item, perishable good, or highly confidential document then courier-man (transporter) will contact you for asking alternate available consignee/receiver.",

    //Receive Parcel

    What_is_the_difference_between_Send_Parcel_and_Receive_Parcel:
      "What is the difference between ‘Send Parcel’ and ‘Receive Parcel’?",
    What_is_the_difference_between_Send_Parcel_and_Receive_Parcel_description:
      "‘Receive Parcel’ is a special service Frank. In this service, receiver (receiver) books the parcel order and pays the shipping price. No liability comes on the shoulder of sender (consignor). The parcel sender may be your kid, parent, friend, or employee, and you do not want to burden him/her shipment cost. You can also entertain ‘Receive Parcel’ service for shipping purchased stuff from online shopping stores (if both agree mutually).",

    How_much_time_it_takes_to_receive_my_parcel:
      "How much time it takes to receive my parcel?",
    How_much_time_it_takes_to_receive_my_parcel_description:
      "This depends on the distance between origin and destination location of parcel. Another factor is the chosen delivery type (urgent, standard or economy). If your order has confirmed, and transporter has picked your parcel, then you should expect same day delivery in most of the case.",

    What_kind_of_items_I_can_ship_with_Frank_Receive_Parcel_service:
      "What kind of items, I can ship with Frank ‘Receive Parcel’ service?",
    What_kind_of_items_I_can_ship_with_Frank_Receive_Parcel_service_description:
      "There is no restriction on weight or dimension of sending parcel. However, as far as type of item inside parcel is concerned, Frank follows the standards and regulations of ‘Prohibited Items’, and ‘Restricted Items’. Find the detailed list here ↖.",

    The_pick_origin_location_is_not_shown_on_map_of_Frank_app:
      "The pick/origin location is not shown on map of Frank app?",
    The_pick_origin_location_is_not_shown_on_map_of_Frank_app_description:
      "If map window is not available then please enable location preference at mobile default settings. If your destination is located at any remote or rushy area, and its exact location is not seen on the map then you can select nearest available neighbourhood. You may edit the location input text-box by adding exact house/office number. Frank transporter will contact receiver for confirmation of the address.",

    Can_I_cancel_my_Receive_Parcel_order:
      "Can I cancel my ‘Receive Parcel’ order?",
    Can_I_cancel_my_Receive_Parcel_order_description:
      "Yes, you can cancel the order. And there are three possible situations. First, if you have filled the ‘Receive Parcel’ form, but not ‘Submit’ the order. It will not cost you any deduction, fine or charges. Second situation, after submitting the order, but before handing over the parcel package to the courier-man (transporter). It will cost you small percentage as charge. Third situation, if courier-man (transporter) has already picked the parcel and rushed for delivery, and you cancel the order then cancellation charges will be higher.",

    What_is_the_pickup_time_slot_range_2: "What is the pickup time slot/range?",
    What_is_the_pickup_time_slot_rang_2_description:
      "This option is provided for the ease of users. You may schedule your availability for handing over or receiving parcel. Frank transporter (courier-man) will approach you at given time slot/range for picking or dropping the parcel.",

    Is_it_necessary_to_mention_name_and_number_of_parcel_sender_consignor:
      "Is it necessary to mention name and number of parcel sender (consignor)?",
    Is_it_necessary_to_mention_name_and_number_of_parcel_sender_consignor_description:
      "Yes. In order to ensure timely and safely delivery, you have to submit full-name and active mobile number of parcel sender (consignor).",

    What_if_consignor_or_sender_of_parcel_is_not_available_at_address:
      "What if consignor or sender of parcel is not available at address?",
    What_if_consignor_or_sender_of_parcel_is_not_available_at_address_description:
      "If consignor or designated sender is not available at mentioned address at time, then courier-man (transporter) will contact you for asking alternate sender. However, if sender is absent despite waiting, or sender refuses to handover parcel to courier-man (transporter) then you have pay the order cancellation charges.",

    //Track Parcel//
    Do_you_offer_any_kind_of_parcel_tracking_system:
      "Do you offer any kind of parcel tracking system?",
    Do_you_offer_any_kind_of_parcel_tracking_system_description:
      "Yes, all Frank services are linked to special and fully-featured tracking system. You can trace and track your parcel throughout its shipment process. Frank also notifies all steps for your reference.",

    What_if_designated_transporter_courier_man_does_not_reply_to_me:
      "What if designated transporter/courier-man does not reply to me?",
    What_if_designated_transporter_courier_man_does_not_reply_to_me_description:
      "Your transporter (courier-man) is morally bound to answer your valid inquiry (via phone or message). However, it is also possible that internet signals are not available to transporter while travelling. Or if transporter is on airplane, or going through rushy urban roads (where phone conversation or texting is not allowed while driving). You may also inquire where-about of your transporter from customer support office.",

    What_is_Parcel_Order_Number_How_to_use_it:
      "What is ‘Parcel Order Number’? How to use it?",
    What_is_Parcel_Order_Number_How_to_use_it_description:
      "Parcel Order Number is a 16-digits unique id for every sending and receiving order. You can track your parcel with the help of this number. Click ‘Track Parcel’, and enter order number of your parcel, the system will yield you real time status of parcel. Save your Parcel Order Number for any claim, complaint or inquiry.",

    Why_Track_Parcel_screen_is_not_updating:
      "Why ‘Track Parcel’ screen is not updating?",
    Why_Track_Parcel_screen_is_not_updating_description:
      "Check internet connection of your mobile device. Without internet, ‘Track Parcel’ screen cannot show live update. Second, in case of longer route or difficult to approach areas, the ‘Track Parcel’ status does not change frequently.",

    For_how_long_provided_Parcel_Order_Number_remains_valid:
      "For how long provided ‘Parcel Order Number’ remains valid?",
    For_how_long_provided_Parcel_Order_Number_remains_valid_description:
      "‘Parcel Order Number’ remains alive and active from the moment of the handing-over (pickup) of parcel up to the completion of the delivery.",

    Do_you_notify_reason_of_unexpected_delay:
      "Do you notify reason of unexpected delay?",
    Do_you_notify_reason_of_unexpected_delay_description:
      "Yes, Frank believes in fair dealings. We do not hide reason of unexpected delay and keep notifying you the parcel status. Delivery may be delayed in case of natural calamity, unforeseen incident, harsh weather, torrential rains, or road blockage.",

    Can_I_track_my_parcel_status_on_Frank_website_though_I_ordered_via_Frank_mobile_app:
      "Can I track my parcel status on Frank website, though I ordered via Frank mobile app?",
    Can_I_track_my_parcel_status_on_Frank_website_though_I_ordered_via_Frank_mobile_app_description:
      "Yes, the ‘Track Parcel’ feature is equally accessible from Frank website, even if you have submitted your order via mobile app. For confirmation, the system may ask verification code that is messaged or emailed to you on spot.",

    How_do_I_know_if_my_parcel_has_been_safely_delivered:
      "How do I know if my parcel has been safely delivered?",
    How_do_I_know_if_my_parcel_has_been_safely_delivered_description:
      "The notification system of all Frank services keep you update through all steps of shipment. The delivery notification is immediately sent to you. For your satisfaction, you can also call to your designated transporter (courier-man) at the time of delivery.",

    Can_I_contact_transporter_courier_man_of_my_parcel:
      "Can I contact transporter/courier-man of my parcel?",
    Can_I_contact_transporter_courier_man_of_my_parcel_description:
      "Yes, Frank tracking system facilitates its customers to contact their transporters on call or message, without costing any open or hidden charges.",

    Can_I_track_others_my_relatives_or_friends_parcel_status:
      "Can I track other’s (my relative’s or friend’s) parcel status?",
    Can_I_track_others_my_relatives_or_friends_parcel_status_description:
      "If your relative or friend is not accustomed to functioning of mobile/app or web browsing, then you may check his/her parcel status with mutual consent. For availing ‘Track Parcel’ feature, you must have valid 16-digits ‘Parcel Order Number’ in your hand. Frank system automatically generates and sends ‘Parcel Order Number’ only at mobile number and/or email id of concerned person, who has booked and paid the parcel order.",

    // Parcel Type //
    Do_I_need_to_pack_my_parcel: "Do I need to pack my parcel?",
    Do_I_need_to_pack_my_parcel_description:
      "Yes, it is necessary and highly recommended. Proper wrapping and packing of your parcel ensure safe and secure shipment. In addition, it also helps you measuring the size and weight of your parcel. Keep in mind, perishable goods, food items, plants, medicines, auto-parts, and heavy items should be packed according to transportation standards and safety regulations. Proper packaging of parcel is the responsibility of sender (consignor).",

    What_do_you_mean_by_Parcel_Name_Why_should_I_mention_it:
      "What do you mean by ‘Parcel Name’? Why should I mention it?",
    What_do_you_mean_by_Parcel_Name_Why_should_I_mention_it_description:
      "This information is necessary for the sake of safety, inquiry and claim. Frank system does not public this information to anyone, unless asked by police authorities on road, check-post at border, or custom officials at airport.",

    Why_there_is_mark_of_with_perishable_valuable_and_special_items:
      "Why there is mark of * with perishable, valuable and special items?",
    Why_there_is_mark_of_with_perishable_valuable_and_special_items_description:
      "The asterisk * mark beside perishable, valuable and special items prompts you to re-check type of your parcel with ‘List of Prohibited and Restricted Items’. This is highly recommended in case of sending parcel to another state or country. Frank will not be responsible, or entertain any claim for confiscation of restricted item by concerned authorities. Keep in mind, shipment of ‘Restricted items’ are subject to condition of quantity and/or written permission.",

    How_can_I_book_parcel_that_contains_two_three_or_more_different_types_of_items:
      "How can I book parcel that contains two, three or more different types of items?",
    How_can_I_book_parcel_that_contains_two_three_or_more_different_types_of_items_description:
      "This is simple. There is a button “Add more items (if any)” at step/screen number 3 of ‘Send Parcel’ and ‘Receive Parcel’ services. Click “Add more items (if any)”. Another window/screen will pop-up for including more parcel items in one-go. Frank app automatically compiles and sums the total weight and size and price",

    What_is_the_maximum_acceptable_weight_and_size_of_parcel:
      "What is the maximum acceptable weight and size of parcel?",
    What_is_the_maximum_acceptable_weight_and_size_of_parcel_description:
      "Apparently there is no maximum limit of weight and size. Apart from common mails, posts, and small gifts; most of the packaged parcels weigh few kilograms, while some are slightly big. In case of extremely odd sized, or too heavy-weight parcels, you may have to wait more for transporter of larger vehicle. For further inquire please contact customer support team.",

    How_should_I_weight_my_parcel_The_intended_parcel_item_is_too_small_or_too_big:
      "How should I weight my parcel? The intended parcel item is too small, or too big?",
    How_should_I_weight_my_parcel_The_intended_parcel_item_is_too_small_or_too_big_description:
      "The best approach is to wrap and/or pack your parcel items before handing over to courier-man (transporter). Generally, letter size small parcel does not alter overall shipment rate. However for big size parcel, you have to manage weight-scale, or make produce total weight of parcel.",

    What_units_of_weight_and_size_measurements_are_available_in_Frank_app:
      "What units of weight and size measurements are available in Frank app?",
    What_units_of_weight_and_size_measurements_are_available_in_Frank_app_description:
      "Frank app facilitates different unit systems for different customs of people. You may enter weight of your parcel in kilogram (kg) or pound. Similarly, you may enter size of your parcel in cm (centimeter), inches and meters. Even you can also choose mile or kilometer (km) for unit of distance. Frank app automatically converts all units. You may set your preferred weight, size and distance units from ‘Settings’ menu of Frank app.",

    Should_I_weight_my_parcel_along_with_wrapper_of_package_carton:
      "Should I weight my parcel along with wrapper of package/carton?",
    Should_I_weight_my_parcel_along_with_wrapper_of_package_carton_description:
      "Yes, parcels are usually measure in weight and size along with wrapper, package or carton.",

    Items_in_my_parcel_do_not_match_with_the_list_of_Parcel_Type_menu_at_step_3:
      "Items in my parcel do not match with the list of ‘Parcel Type’ menu at step-3?",
    Items_in_my_parcel_do_not_match_with_the_list_of_Parcel_Type_menu_at_step_3_description:
      "Although ‘Parcel Type’ menu provides a long list of items. But it is possible that you could not find exact nature of your parcel item in the menu. This is why ‘Other’ option is provided beneath every category. For example; Other common lightweight item, Other Attire, Décor item, Other Fragile, Digital item, Mix items: Other different items etc. Select closest match. And don’t forget to mention the exact name/nature of your item under ‘Parcel Name’ input text-box at step-3.",

    Why_should_I_take_a_picture_of_my_parcel_and_upload_it_with_order:
      "Why should I take a picture of my parcel, and upload it with order?",
    Why_should_I_take_a_picture_of_my_parcel_and_upload_it_with_order_description:
      "Parcel picture/snap before packaging is necessary, especially for international shipment. This feature is included in the Frank app for the sake of safety, and security. Photographic evidence may also help in inquiry, damage and/or claim. Frank respects privacy of customers, and does not public the parcel item pictures without your consent.",

    // Payment //
    What_are_the_modes_of_payment_for_booking_parcel_with_Frank:
      "What are the modes of payment for booking parcel with Frank?",
    What_are_the_modes_of_payment_for_booking_parcel_with_Frank_description:
      "Frank offers flexible payment and billing options for all shipping services. You can pay your parcel order via Master Card, Visa Card, or Paypal.",

    Do_your_offer_any_discount_or_coupon:
      "Do your offer any discount or coupon?",
    Do_your_offer_any_discount_or_coupon_description:
      "Yes, Frank offers various seasonal and special discounts. The coupon system is also available for the customers.",

    Why_Frank_mobile_app_is_not_accepting_my_credit_card:
      "Why Frank mobile app is not accepting my credit card?",
    Why_Frank_mobile_app_is_not_accepting_my_credit_card_description:
      "If your card payment fails, double check that you have correctly entered required details (name, card number, code, validity date). Do carefully check the card details before confirming the transaction for processing. Secondly, check the total available balance in your account. Is it sufficient enough for payment? If the account balance and entered details are correct but your order is refused or declined, then it's likely that the payment is rejected by the payment provider (or bank), not by the Frank system. Contact your payment provider (or bank) for more information about why the payment is rejected.",

    How_do_I_know_that_items_in_my_parcel_are_taxable:
      "How do I know that items in my parcel are taxable?",
    How_do_I_know_that_items_in_my_parcel_are_taxable_description:
      "There is no definite tax slab around the world. Tax rates and custom duties vary from region to region, and item to item. You should first inquire concerned authorities of origin/destination. Otherwise, contact customers support office before confirming order.",

    How_do_I_know_my_credit_card_information_is_secured:
      "How do I know my credit card information is secured?",
    How_do_I_know_my_credit_card_information_is_secured_description:
      "‘Security’ of customers’ data and shared information is the top priority of Frank. The payment system is specially designed and highly secured. Frank also encourages its customers to opt for 3D secured payment method with One-time Password (OTP) feature. Frank internal system immediately notifies successful payment.",

    Who_pays_the_customs_duties_taxes_on_my_shipment:
      "Who pays the customs/duties/taxes on my shipment?",
    Who_pays_the_customs_duties_taxes_on_my_shipment_description:
      "Naturally it is the responsibility of person who books the shipment or parcel order. If your parcel is liable to custom duties or tax, and you have neither mentioned it in the online order form, nor paid it before handing over to the courier-man (transporter). Then consignee (receiver of parcel) may pay in lieu of you. Otherwise, parcel may be seized until payment clearance.",

    Do_you_deduct_additional_hidden_charges_in_addition_to_quoted_rate:
      "Do you deduct additional hidden charges in addition to quoted rate?",
    Do_you_deduct_additional_hidden_charges_in_addition_to_quoted_rate_description:
      "No. Frank believes in fair dealings, and does not charge any hidden prices that are not listed. It should be borne in mind that parcel price is different from custom duties, taxes, and postage insurance. Finally, there may appear discrepancy between ‘Get Rate’ and actual ‘Send Parcel’ price. Because crude ‘Get Rate’ price does not include holiday factor, weekend, peak hour, custom duties, taxes, and other considerations.",

    Can_I_use_multiple_more_than_one_credit_cards_in_Frank_mobile_app:
      "Can I use multiple (more than one) credit cards in Frank mobile app?",
    Can_I_use_multiple_more_than_one_credit_cards_in_Frank_mobile_app_description:
      "Yes, you may add multiple credit cards in Frank app’s feature ‘Payment’. However it is recommended to use one card for one parcel order.",

    How_do_I_get_the_payment_receipt_or_invoice:
      "How do I get the payment receipt or invoice?",
    How_do_I_get_the_payment_receipt_or_invoice_description:
      "After successful completion of delivery, receipt (e-invoice) of parcel shipment is sent to you at your mobile and email id.",

    What_is_the_procedure_of_refunding_and_reimbursement:
      "What is the procedure of refunding and reimbursement?",
    What_is_the_procedure_of_refunding_and_reimbursement_description:
      "In case of valid and successful claim (for loss, theft, missing parcel, damage item etc.), Frank has a special mechanism. The due amount will be refunded in provided account via online transfer. A notification is also sent at your mobile and given email id.",

    //Frank Space //
    What_is_Frank_Space: "What is Frank Space?",
    What_is_Frank_Space_description:
      "Frank Space is a registered and secured location where parcels are temporarily stored. It is also known as ‘parcel-shop’ or ‘drop-shop’ location. It is a provisional spot between origin and destination of parcel. This storage facility is part of modern postal and courier services.",

    Who_can_utilize_and_entertain_Frank_Space:
      "Who can utilize and entertain Frank Space?",
    Who_can_utilize_and_entertain_Frank_Space_description:
      "Any registered ‘customer’ or ‘transporter’ of Frank App can utilize ‘Space’, against nominal charges. If you are a customer, then you can directly drop your parcel at nearby Space (instead of waiting for the transporter). The transporter will pick your parcel from ‘Space’, and deliver it at designated destination. You can also request a transporter to pick your parcel directly from your home/office, and drop it at nearby ‘Space’ against nominal charges. In addition, for split or connect route, transporters (courier-men) can also use ‘Space’ as interim location.",

    Who_can_offer_Space_and_become_Space_provider:
      "Who can offer Space and become Space-provider?",
    Who_can_offer_Space_and_become_Space_provider_description:
      "Any registered ‘customer’ or ‘transporter’ of Frank App can offer his/her location as ‘Space’ and start earning. The ‘Space’ can be a part of your home, apartment, shop, showroom, small warehouse or even mobile-van-house. In order to become a Space-provider, you need to fill an online form (available via mobile app, and web), and register your location as Frank Space.",

    What_is_the_charge_or_rate_of_using_Frank_Space:
      "What is the charge or rate of using Frank Space?",
    What_is_the_charge_or_rate_of_using_Frank_Space_description:
      "The locations (Space) are generally owned and administered by independent third parties that charge nominal rates for renting out their spaces. Rate mainly depends on time duration of storage, weight of parcel, and other facilities (like, packaging, handling, cooling, heating etc.).",

    What_is_the_advantage_of_entertaining_Frank_Space:
      "What is the advantage of entertaining Frank Space?",
    What_is_the_advantage_of_entertaining_Frank_Space_description:
      "The Frank Space offer great flexibility for those busy customers who don’t want to wait for transporter (courier-man) for picking or dropping their parcels. Moreover, Frank Space can also be used as ‘temporary warehouse’ for a short time.",

    //MY Profile
    First_Name: "First Name",
    Last_Name: "Last Name",
    Gender: "Gender",
    Date_of_birth: "Date of Birth",
    Country: "Country",
    City: "City",
    Address: "Address",
    Active: "Active",
    Inactive: "Inactive",
    Verified: "Verified",
    Not_verified: "Not verified",
    Rider: "Also Rider",
    Not_Rider: "Not Rider",
    Idenfy: "Idenfy",
    Not_idenfy: "Not idenfy",
    Identity_Details: "Identity Details",
    NIC_Number: " NIC Number",
    NIC_Issue_Date: "NIC Issue Date",
    NIC_Expiry_Date: "NIC Expiry Date",
    NIC_Images: "NIC Images",
    License_Details: "License Details",
    Driving_License_No: "Driving License No.",
    Driving_License_Type: "Driving License Type",
    License_Issue_Date: "License Issue Date",
    License_Expiry_Date: "License Expiry Date",
    License_Images: "License Images",
    Passport_Details: "Passport Details",
    Passport_Country: "Passport Country",
    Passport_No: "Passport No",
    Passport_Issue_Date: "Passport Issue Date",
    Passport_Expiry_Date: "Passport Expiry Date",
    Assigned_Vehicles: "Assigned Vehicle(s)",
    Bank_Info: "Bank Info",
    Bank_Name: "Bank Name",
    Account_Title: "Account Title",
    IBAN_Number: "IBAN Number",
    Reset_btn: "Reset",
    Save_btn: "Save",
    Ratings: "Ratings",

    //Service Area 
    Service_Area_Name:"Service Area Name",
    From_Time:"From Time",
    To_Time:"To Time",
    From_Weekday:"From Weekday",
    To_Weekday:"To Weekday",
    Frequency:"Frequency",
    From_Date:"From Date",
    To_Date:"To Date",
    Status:"Status",
    Change_Activation_Status:"Change Activation Status",
    Display_language:"Display language",
    Date_Format:"Date Format",
    Time_Format:"Time Format",
    Display:"Display",
    Dimensions_Unit:"Dimensions/Unit",
    Distance_Unit:"Distance Unit",
    Weight_Unit:"Weight Unit",
    Size_Unit:"Size Unit",
    Volume:"Volume",
    Polygon_Type_in_Map:"Polygon Type in Map",
    Valid_from:"Valid from",
    to:"to",

  },
});

export default Strings;
