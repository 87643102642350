
const menuItems = {
  items: [
    {
      id: "_dashboard",
      title: "Dashboard",
      type: "group",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "_shipping-orders",
      title: "Orders",
      type: "group",
      children: [
        {
          id: "shipping-orders",
          title: "Orders",
          type: "item",
          url: "/shipping-orders",
          // icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "profile_",
      title: "My Profile",
      type: "group",
      children: [
        {
          id: "my-profile",
          title: "My Profile",
          type: "item",
          url: "/my-profile",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "servicearea_",
      title: "Service Area",
      type: "group",
      children: [
        {
          id: "service-area",
          title: "Service Area",
          type: "item",
          url: "/service-area",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "vehicle_",
      title: "Vehicle",
      type: "group",
      children: [
        {
          id: "vehicle",
          title: "Vehicle",
          type: "item",
          url: "/vehicle",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "subRiders_",
      title: "Sub-Riders",
      type: "group",
      children: [
        {
          id: "sub-riders",
          title: "Sub-Riders",
          type: "item",
          url: "/sub-riders",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "myearning_",
      title: "Earning",
      type: "group",
      children: [
        {
          id: "my-earning",
          title: "Earning",
          type: "item",
          url: "/my-earning",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "payments_",
      title: "Payments",
      type: "group",
      children: [
        {
          id: "payments",
          title: "Payments",
          type: "item",
          url: "/payments",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "notification_",
      title: "Notifications",
      type: "group",
      children: [
        {
          id: "notification",
          title: "Notifications",
          type: "item",
          url: "/notification",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "settings_",
      title: "Settings",
      type: "group",
      children: [
        {
          id: "settings",
          title: "Settings",
          type: "item",
          url: "/settings",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "help",
      title: "Help",
      type: "group",
      children: [
        {
          id: "help_",
          title: "Help",
          type: "collapse",
          //   icon: icons.IconDashboard,
          children: [
            {
              id: "faqs",
              title: "FAQ",
              type: "item",
              url: "/help/faqs",
              //   icon: icons.IconDashboard,
            },
            {
              id: "prohobited-item",
              title: "Prohobited Items",
              type: "item",
              url: "/help/prohobited-item",
              //   icon: icons.IconDashboard,
            },
            {
              id: "terms-conditions",
              title: "Terms & Conditions",
              type: "item",
              url: "/help/terms-conditions",
              //   icon: icons.IconDashboard,
            },
            {
              id: "privacy-policy",
              title: "Privacy Policy",
              type: "item",
              url: "/help/privacy-policy",
              //   icon: icons.IconDashboard,
            },
          ],
        },
      ],
    },
    {
      id: "support_",
      title: "Support",
      type: "group",
      children: [
        {
          id: "support",
          title: "Support",
          type: "item",
          url: "/support",
          breadcrumbs: true,
        },
      ],
    },
  ],
};

export default menuItems;
