import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { earnings } from "dummy-data";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

let getSettingsData = getDataFromLocalStorage("settings");
export interface paymentState {
  all_payments: any;
  all_payments_data: any;
  selected_payment: object;
  activity: boolean;
  paginate_data?: {
    current_count: any;
    // current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

interface APIParams {
  end_point: string;
}

const initialState: paymentState = {
  all_payments: [],
  all_payments_data: [],
  selected_payment: {},
  activity: true,
};

export const paymentRequests = createAsyncThunk(
  "payments/payment-by-transporter",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "payments");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const paymentSlice = createSlice({
  name: "earning",
  initialState,
  reducers: {
    setSelectedPayment: (state: paymentState, action: any) => {
      state.selected_payment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(paymentRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(paymentRequests.fulfilled, (state, { payload }) => {
        let payments = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          payments.push([
            item?.id || "- - -",
             item?.payment_date ? moment(item?.payment_date).format(getSettingsData?.date_format ?? "DD MMM YYYY") : "- - -",
            item?.payment_mode || "- - -",
            item?.bank_id || "- - -",
             item?.transaction_id || "- - -",
            `€ ${item?.payment_amount || "- - -"}`,
            
          ]);
        }
        state.all_payments_data = payload?.data;
        state.all_payments = payments;
        state.paginate_data = {
          current_count: payload?.current_count,
          // current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages ?? 1,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

export const { setSelectedPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
