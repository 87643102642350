 import React, { Component } from "react";
import { toast } from "react-toastify";


class Snackbar extends Component {
  Error(message: any) {
    toast.error(message, {
       position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme:"colored"
    });
  }

  showSuccess(message: any) {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme:"colored"
    });
  }
}
export default new Snackbar({} as any);
