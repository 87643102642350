import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { ListItemButton, ListItemIcon, useMediaQuery } from "@mui/material";

// assets
import { RootState } from "store";
import { handleClose, handleMenuClick } from "store/drawerReducer";
import {
  DashboardIcon,
  EarningsIcon,
  MyProfileIcon,
  NotificationIcon,
  OrdersIcon,
  ServiceAreaIcon,
  SettingsIcon,
  SubRiderIconForSideBar,
  SupportIcon,
  VehicleIcon,
} from "uploads";
 
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state: RootState) => state.drawer);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref as any} {...props} to={item.url} target={itemTarget} />
    )),
    href: "",
    target: null,
  };

  if (item?.external) {
    listItemProps = {
      component: "a" as any,
      href: item.url as any,
      target: itemTarget as any,
    };
  }
  
  const itemHandler = (id: any) => {
    dispatch(handleMenuClick(id));

    if (matchesSM) dispatch(handleClose());
  };
  // active menu item on page load
  const url = window.location.pathname.split('/').pop();

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(handleMenuClick(item.id));
    }
    // eslint-disable-next-line
  }, [url]);

  const getIconByID = (_id: any) => {
    try {
      switch (_id) {
        case "dashboard":
          return (
            <ListItemIcon>
              <DashboardIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "sub-riders":
          return (
            <ListItemIcon>
              <SubRiderIconForSideBar
                height={25}
                width={25}
                stroke_width={"4px"}
              />
            </ListItemIcon>
          );
        case "shipping-orders":
          return (
            <ListItemIcon>
              <OrdersIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "service-area":
          return (
            <ListItemIcon>
              <ServiceAreaIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "vehicle":
          return (
            <ListItemIcon>
              <VehicleIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "my-earning":
          return (
            <ListItemIcon>
              <EarningsIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
          case "payments":
          return (
            <ListItemIcon>
              <EarningsIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "my-profile":
          return (
            <ListItemIcon>
              <MyProfileIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "notification":
          return (
            <ListItemIcon>
              <NotificationIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "settings":
          return (
            <ListItemIcon>
              <SettingsIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "support":
          return (
            <ListItemIcon>
              <SupportIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );

        default:
          break;
      }
    } catch (error) {}
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: "flex-start",
        py: 1,
        pl: level > 1 ? `${level * 20}px` : 1,
      }}
      selected={
        customization.isOpen.findIndex((id: any) => id === item.id) > -1
      }
      onClick={() => itemHandler(item.id)}
    >
      {getIconByID(item.id)}

      <p style={{ marginBottom: 0, flex: 1 }}>{item.title}</p>
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
