import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
// import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
// import MenuCard from "./MenuCard";
import { drawerWidth } from "store/constants";
import MenuList from "./MenuList";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }: any) => {
  const theme = useTheme() as any;
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const drawer = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 2,
          marginTop: matchUpMd ? 0 : 3,
        }}
      >
        <LogoSection />

        
      </Box>
      <BrowserView style={{ height: `calc(100% - 190px)` }}>
        <PerfectScrollbar
          component="div"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            height: `calc(100% - 10px)`,
            overflow: "hidden scroll",
          }}
          className="side-bar-scroll"
        >
          <MenuList />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box
          sx={{
            px: 2,
            height: "auto",
          }}
        >
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
        background: drawerOpen && theme.palette.frank.appSecondary,
      }}
      aria-label="side-nav"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.frank.appSecondary,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "20px",
            },
            [theme.breakpoints.down("sm")]: {
              top: "10px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
