import { lazy } from "react";
import Loadable from "ui-components/Loadable";
 
export const Login = Loadable(
lazy(() => import("views/Login/LoginComponent"))
);

export const UploadDocument = Loadable(
  lazy(() => import("ui-components/UploadDocument"))
);

export const Dashboard = Loadable(
lazy(() => import("views/Dashboard"))
);

export const AllOrders = Loadable(
lazy(() => import("views/Orders/AllOrders"))
);

export const MyRequests = Loadable(
  lazy(() => import("views/Requests/MyRequests/MyRequestComponent"))
  );

export const SchedulePickup = Loadable(
  lazy(() => import("views/Requests/SchedulePickup/SchedulePickupComponent"))
);

export const DriverRequest = Loadable(
  lazy(() => import("views/Requests/DriverRequests/DriverRequestsComponent"))
);

export const FindOrder = Loadable(
lazy(() => import("views/Orders/AllOrders"))
);

export const MyDriver = Loadable(
  lazy(() => import("views/MyDrivers/MyDriverComponent"))
);

export const MyVehicle = Loadable(
  lazy(() => import("views/MyVehicle/MyVehicleComponent"))
);

export const MyEarning = Loadable(
  lazy(() => import("views/MyEarning/MyEarningComponent"))
);
export const MyPayments = Loadable(
  lazy(() => import("views/Payments/PaymentsComponent"))
);

export const Notifications = Loadable(
  lazy(() => import("views/Notifications/NotificationComponent"))
);

export const Settings = Loadable(
  lazy(() => import("views/Settings/SettingsComponent"))
);

export const Faqs = Loadable(
  lazy(() => import("views/Help/FAQS/FaqsComponent"))
);

export const ProhobitedItem = Loadable(
  lazy(() => import("views/Help/ProhobitedItems/ProhibitedItemComponent"))
);

export const TermNConditions = Loadable(
  lazy(() => import("views/Help/TermsNCondition/TermsNConditionComponent"))
);

export const PrivacyNPolicy = Loadable(
  lazy(() => import("views/Help/PrivacyPolicy/PrivacyPolicyComponent"))
);

export const Support = Loadable(
  lazy(() => import("views/Support/SupportComponent"))
);

export const SubRider = Loadable(
  lazy(() => import("views/SubRider/SubRiderComponent"))
);

// export const ServiceArea = Loadable(
//   lazy(() => import("views/ServiceArea/ServiceAreaComponent"))
// );

export const ServiceAreas = Loadable(
  lazy(() => import("views/ServiceArea"))
);

export const ShippingOrderDetails = Loadable(
  lazy(() => import("views/Orders/OrderDetailsInnerPage/OrderDetailsInner"))
);

export const MyProfile = Loadable(
  lazy(() => import("views/MyProfile/MyProfileComponent"))
);

export const NewLogin = Loadable(
  lazy(() => import("views/Login/index"))
);

export const UnauthorizePage = Loadable(
  lazy(() => import("views/UnauthorizePage/index"))
);