export const cars = [
  {
    fullName: "Alfa Romeo 147 2000",
    make: "Alfa Romeo",
    model: "147 2000",
    length: {
       cm: 417,
       in: 164.17,
       ft: 13.68
    },
    width: {
       cm: 172.9,
       in: 68.07,
       ft: 5.67
    },
    height: {
       cm: 144.2,
       in: 56.77,
       ft: 4.73
    },
    capacity: {
       cm: 280,
       in: 17086.65,
       ft: 9.89
    }
 },
  {
    fullName: "Alfa Romeo 159 2005",
    make: "Alfa Romeo",
    model: "Romeo 159 2005",
    length: {
       cm: 466.1,
       in: 183.5,
       ft: 15.29
    },
    width: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    height: {
       cm: 145.2,
       in: 57.17,
       ft: 4.76
    },
    capacity: {
       cm: 405,
       in: 24714.62,
       ft: 14.3
    }
 },
  {
    fullName: "Alfa Romeo MiTo 1.4 2009",
    make: "Alfa",
    model: "MiTo 1.4 2009",
    length: {
       cm: 406.3,
       in: 159.96,
       ft: 13.33
    },
    width: {
       cm: 172.1,
       in: 67.76,
       ft: 5.65
    },
    height: {
       cm: 144.6,
       in: 56.93,
       ft: 4.74
    },
    capacity: {
       cm: 270,
       in: 16476.41,
       ft: 9.54
    }
 },
  {
    fullName: "Alfa-Romeo Giulia",
    make: "Alfa-Romeo",
    model: "Giulia",
    length: {
       cm: 464.3,
       in: 182.8,
       ft: 15.23
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 143.6,
       in: 56.54,
       ft: 4.71
    },
    capacity: {
       cm: 480,
       in: 29291.4,
       ft: 16.95
    }
 },
  {
    fullName: "Alfa-Romeo Giulietta",
    make: "Alfa-Romeo",
    model: "Giulietta",
    length: {
       cm: 435.1,
       in: 171.3,
       ft: 14.27
    },
    width: {
       cm: 179.8,
       in: 70.79,
       ft: 5.9
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Alfa-Romeo Stelvio",
    make: "Alfa-Romeo",
    model: "Stelvio",
    length: {
       cm: 468.7,
       in: 184.53,
       ft: 15.38
    },
    width: {
       cm: 190.3,
       in: 74.92,
       ft: 6.24
    },
    height: {
       cm: 167.1,
       in: 65.79,
       ft: 5.48
    },
    capacity: {
       cm: 525,
       in: 32037.47,
       ft: 18.54
    }
 },
  {
    fullName: "Aston-Martin DB11",
    make: "Aston-Martin",
    model: "DB11",
    length: {
       cm: 473.9,
       in: 186.57,
       ft: 15.55
    },
    width: {
       cm: 194,
       in: 76.38,
       ft: 6.36
    },
    height: {
       cm: 127.9,
       in: 50.35,
       ft: 4.2
    },
    capacity: {
       cm: 270,
       in: 16476.41,
       ft: 9.54
    }
 },
  {
    fullName: "Aston-Martin DBS Superleggera",
    make: "Aston-Martin",
    model: "DBS Superleggera",
    length: {
       cm: 471.2,
       in: 185.51,
       ft: 15.46
    },
    width: {
       cm: 196.9,
       in: 77.52,
       ft: 6.46
    },
    height: {
       cm: 128,
       in: 50.39,
       ft: 4.2
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Aston-Martin DBX",
    make: "Aston-Martin",
    model: "DBX",
    length: {
       cm: 503.9,
       in: 198.39,
       ft: 16.53
    },
    width: {
       cm: 199.8,
       in: 78.66,
       ft: 6.56
    },
    height: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    capacity: {
       cm: 632,
       in: 38567.02,
       ft: 22.32
    }
 },
  {
    fullName: "Aston-Martin Rapide AMR",
    make: "Aston-Martin",
    model: "Rapide AMR",
    length: {
       cm: 501.9,
       in: 197.6,
       ft: 16.47
    },
    width: {
       cm: 192.9,
       in: 75.94,
       ft: 6.33
    },
    height: {
       cm: 135,
       in: 53.15,
       ft: 4.43
    },
    capacity: {
       cm: 317,
       in: 19344.53,
       ft: 11.19
    }
 },
  {
    fullName: "Aston-Martin Vantage Coupe",
    make: "Aston-Martin",
    model: "Vantage Coupe",
    length: {
       cm: 446.5,
       in: 175.79,
       ft: 14.65
    },
    width: {
       cm: 194.2,
       in: 76.46,
       ft: 6.37
    },
    height: {
       cm: 127.3,
       in: 50.12,
       ft: 4.18
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Audi A1 citycarver",
    make: "Audi",
    model: "A1 citycarver",
    length: {
       cm: 404.6,
       in: 159.29,
       ft: 13.27
    },
    width: {
       cm: 175.6,
       in: 69.13,
       ft: 5.76
    },
    height: {
       cm: 148.3,
       in: 58.39,
       ft: 4.87
    },
    capacity: {
       cm: 335,
       in: 20442.96,
       ft: 11.83
    }
 },
  {
    fullName: "Audi A1 Sportback",
    make: "Audi",
    model: "A1 Sportback",
    length: {
       cm: 402.9,
       in: 158.62,
       ft: 13.22
    },
    width: {
       cm: 174,
       in: 68.5,
       ft: 5.71
    },
    height: {
       cm: 140.9,
       in: 55.47,
       ft: 4.62
    },
    capacity: {
       cm: 335,
       in: 20442.96,
       ft: 11.83
    }
 },
  {
    fullName: "Audi A2 (8Z) 2000",
    make: "Audi",
    model: "A2 (8Z) 2000",
    length: {
       cm: 383,
       in: 150.79,
       ft: 12.57
    },
    width: {
       cm: 167,
       in: 65.75,
       ft: 5.48
    },
    height: {
       cm: 155,
       in: 61.02,
       ft: 5.09
    },
    capacity: {
       cm: 390,
       in: 23799.27,
       ft: 13.77
    }
 },
  {
    fullName: "Audi A3 Cabrio",
    make: "Audi",
    model: "A3 Cabrio",
    length: {
       cm: 442.3,
       in: 174.13,
       ft: 14.51
    },
    width: {
       cm: 179.3,
       in: 70.59,
       ft: 5.88
    },
    height: {
       cm: 140.9,
       in: 55.47,
       ft: 4.62
    },
    capacity: {
       cm: 320,
       in: 19527.6,
       ft: 11.3
    }
 },
  {
    fullName: "Audi A3 Sedan",
    make: "Audi",
    model: "A3 Sedan",
    length: {
       cm: 445.8,
       in: 175.51,
       ft: 14.63
    },
    width: {
       cm: 179.6,
       in: 70.71,
       ft: 5.89
    },
    height: {
       cm: 141.6,
       in: 55.75,
       ft: 4.65
    },
    capacity: {
       cm: 425,
       in: 25935.1,
       ft: 15.01
    }
 },
  {
    fullName: "Audi A3 Sportback",
    make: "Audi",
    model: "A3 Sportback",
    length: {
       cm: 431.3,
       in: 169.8,
       ft: 14.15
    },
    width: {
       cm: 178.5,
       in: 70.28,
       ft: 5.86
    },
    height: {
       cm: 142.6,
       in: 56.14,
       ft: 4.68
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Audi A4",
    make: "Audi",
    model: "A4",
    length: {
       cm: 476.2,
       in: 187.48,
       ft: 15.62
    },
    width: {
       cm: 184.7,
       in: 72.72,
       ft: 6.06
    },
    height: {
       cm: 142.7,
       in: 56.18,
       ft: 4.68
    },
    capacity: {
       cm: 460,
       in: 28070.93,
       ft: 16.24
    }
 },
  {
    fullName: "Audi A4 (B8) Avant 2015",
    make: "Audi",
    model: "A4 (B8) Avant 2015",
    length: {
       cm: 471.9,
       in: 185.79,
       ft: 15.48
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 141.5,
       in: 55.71,
       ft: 4.64
    },
    capacity: {
       cm: 490,
       in: 29901.64,
       ft: 17.3
    }
 },
  {
    fullName: "Audi A4 allroad quattro",
    make: "Audi",
    model: "A4 allroad quattro",
    length: {
       cm: 476.2,
       in: 187.48,
       ft: 15.62
    },
    width: {
       cm: 184.7,
       in: 72.72,
       ft: 6.06
    },
    height: {
       cm: 149.3,
       in: 58.78,
       ft: 4.9
    },
    capacity: {
       cm: 495,
       in: 30206.76,
       ft: 17.48
    }
 },
  {
    fullName: "Audi A4 Avant",
    make: "Audi",
    model: "A4 Avant",
    length: {
       cm: 476.2,
       in: 187.48,
       ft: 15.62
    },
    width: {
       cm: 184.7,
       in: 72.72,
       ft: 6.06
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 495,
       in: 30206.76,
       ft: 17.48
    }
 },
  {
    fullName: "Audi A5 Coupe",
    make: "Audi",
    model: "A5 Coupe",
    length: {
       cm: 469.7,
       in: 184.92,
       ft: 15.41
    },
    width: {
       cm: 184.6,
       in: 72.68,
       ft: 6.06
    },
    height: {
       cm: 137.1,
       in: 53.98,
       ft: 4.5
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Audi A5 Sportback",
    make: "Audi",
    model: "A5 Sportback",
    length: {
       cm: 475.7,
       in: 187.28,
       ft: 15.61
    },
    width: {
       cm: 184.3,
       in: 72.56,
       ft: 6.05
    },
    height: {
       cm: 138.6,
       in: 54.57,
       ft: 4.55
    },
    capacity: {
       cm: 465,
       in: 28376.05,
       ft: 16.42
    }
 },
  {
    fullName: "Audi A6",
    make: "Audi",
    model: "A6",
    length: {
       cm: 493.9,
       in: 194.45,
       ft: 16.2
    },
    width: {
       cm: 188.6,
       in: 74.25,
       ft: 6.19
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Audi A6 Avant",
    make: "Audi",
    model: "A6 Avant",
    length: {
       cm: 493.9,
       in: 194.45,
       ft: 16.2
    },
    width: {
       cm: 188.6,
       in: 74.25,
       ft: 6.19
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 565,
       in: 34478.42,
       ft: 19.95
    }
 },
  {
    fullName: "Audi A7 Sportback",
    make: "Audi",
    model: "A7 Sportback",
    length: {
       cm: 496.9,
       in: 195.63,
       ft: 16.3
    },
    width: {
       cm: 190.8,
       in: 75.12,
       ft: 6.26
    },
    height: {
       cm: 142.2,
       in: 55.98,
       ft: 4.67
    },
    capacity: {
       cm: 535,
       in: 32647.71,
       ft: 18.89
    }
 },
  {
    fullName: "Audi A8",
    make: "Audi",
    model: "A8",
    length: {
       cm: 517.2,
       in: 203.62,
       ft: 16.97
    },
    width: {
       cm: 194.5,
       in: 76.57,
       ft: 6.38
    },
    height: {
       cm: 147.3,
       in: 57.99,
       ft: 4.83
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Audi A8 L",
    make: "Audi",
    model: "A8 L",
    length: {
       cm: 530.2,
       in: 208.74,
       ft: 17.4
    },
    width: {
       cm: 194.5,
       in: 76.57,
       ft: 6.38
    },
    height: {
       cm: 148.8,
       in: 58.58,
       ft: 4.88
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Audi e-tron",
    make: "Audi",
    model: "e-tron",
    length: {
       cm: 490.1,
       in: 192.95,
       ft: 16.08
    },
    width: {
       cm: 193.5,
       in: 76.18,
       ft: 6.35
    },
    height: {
       cm: 162.9,
       in: 64.13,
       ft: 5.34
    },
    capacity: {
       cm: 660,
       in: 40275.68,
       ft: 23.31
    }
 },
  {
    fullName: "Audi Q2",
    make: "Audi",
    model: "Q2",
    length: {
       cm: 419.1,
       in: 165,
       ft: 13.75
    },
    width: {
       cm: 179.4,
       in: 70.63,
       ft: 5.89
    },
    height: {
       cm: 150.8,
       in: 59.37,
       ft: 4.95
    },
    capacity: {
       cm: 405,
       in: 24714.62,
       ft: 14.3
    }
 },
  {
    fullName: "Audi Q3",
    make: "Audi",
    model: "Q3",
    length: {
       cm: 448.4,
       in: 176.54,
       ft: 14.71
    },
    width: {
       cm: 185.6,
       in: 73.07,
       ft: 6.09
    },
    height: {
       cm: 158.5,
       in: 62.4,
       ft: 5.2
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Audi Q3 Sportback",
    make: "Audi",
    model: "Q3 Sportback",
    length: {
       cm: 450,
       in: 177.17,
       ft: 14.76
    },
    width: {
       cm: 184.3,
       in: 72.56,
       ft: 6.05
    },
    height: {
       cm: 155.6,
       in: 61.26,
       ft: 5.1
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Audi Q3 Sportback",
    make: "Audi",
    model: "Q3 Sportback",
    length: {
       cm: 450,
       in: 177.17,
       ft: 14.76
    },
    width: {
       cm: 184.3,
       in: 72.56,
       ft: 6.05
    },
    height: {
       cm: 155.6,
       in: 61.26,
       ft: 5.1
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Audi Q5",
    make: "Audi",
    model: "Q5",
    length: {
       cm: 466.3,
       in: 183.58,
       ft: 15.3
    },
    width: {
       cm: 189.3,
       in: 74.53,
       ft: 6.21
    },
    height: {
       cm: 165.9,
       in: 65.31,
       ft: 5.44
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Audi Q7",
    make: "Audi",
    model: "Q7",
    length: {
       cm: 506.3,
       in: 199.33,
       ft: 16.61
    },
    width: {
       cm: 197,
       in: 77.56,
       ft: 6.46
    },
    height: {
       cm: 174.1,
       in: 68.54,
       ft: 5.71
    },
    capacity: {
       cm: 770,
       in: 46988.29,
       ft: 27.19
    }
 },
  {
    fullName: "Audi Q8",
    make: "Audi",
    model: "Q8",
    length: {
       cm: 498.6,
       in: 196.3,
       ft: 16.36
    },
    width: {
       cm: 199.5,
       in: 78.54,
       ft: 6.55
    },
    height: {
       cm: 170.5,
       in: 67.13,
       ft: 5.59
    },
    capacity: {
       cm: 605,
       in: 36919.37,
       ft: 21.37
    }
 },
  {
    fullName: "Audi R8",
    make: "Audi",
    model: "R8",
    length: {
       cm: 442.6,
       in: 174.25,
       ft: 14.52
    },
    width: {
       cm: 194,
       in: 76.38,
       ft: 6.36
    },
    height: {
       cm: 124,
       in: 48.82,
       ft: 4.07
    },
    capacity: {
       cm: 112,
       in: 6834.66,
       ft: 3.96
    }
 },
  {
    fullName: "Audi S3 Sportback",
    make: "Audi",
    model: "S3 Sportback",
    length: {
       cm: 432.2,
       in: 170.16,
       ft: 14.18
    },
    width: {
       cm: 178.5,
       in: 70.28,
       ft: 5.86
    },
    height: {
       cm: 140.4,
       in: 55.28,
       ft: 4.61
    },
    capacity: {
       cm: 340,
       in: 20748.08,
       ft: 12.01
    }
 },
  {
    fullName: "Audi S4",
    make: "Audi",
    model: "S4",
    length: {
       cm: 477,
       in: 187.8,
       ft: 15.65
    },
    width: {
       cm: 184.7,
       in: 72.72,
       ft: 6.06
    },
    height: {
       cm: 140.4,
       in: 55.28,
       ft: 4.61
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "Audi TT Coupe",
    make: "Audi",
    model: "TT Coupe",
    length: {
       cm: 419.1,
       in: 165,
       ft: 13.75
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 135.3,
       in: 53.27,
       ft: 4.44
    },
    capacity: {
       cm: 305,
       in: 18612.25,
       ft: 10.77
    }
 },
  {
    fullName: "Bentley Bentayga",
    make: "Bentley",
    model: "Bentayga",
    length: {
       cm: 514,
       in: 202.36,
       ft: 16.86
    },
    width: {
       cm: 199.8,
       in: 78.66,
       ft: 6.56
    },
    height: {
       cm: 174.2,
       in: 68.58,
       ft: 5.72
    },
    capacity: {
       cm: 484,
       in: 29535.5,
       ft: 17.09
    }
 },
  {
    fullName: "Bentley Continental GT",
    make: "Bentley",
    model: "Continental GT",
    length: {
       cm: 485,
       in: 190.94,
       ft: 15.91
    },
    width: {
       cm: 196.6,
       in: 77.4,
       ft: 6.45
    },
    height: {
       cm: 140.5,
       in: 55.31,
       ft: 4.61
    },
    capacity: {
       cm: 358,
       in: 21846.51,
       ft: 12.64
    }
 },
  {
    fullName: "Bentley Continental GT Convertible",
    make: "Bentley",
    model: "Continental GT Convertible",
    length: {
       cm: 485,
       in: 190.94,
       ft: 15.91
    },
    width: {
       cm: 196.4,
       in: 77.32,
       ft: 6.44
    },
    height: {
       cm: 139.9,
       in: 55.08,
       ft: 4.59
    },
    capacity: {
       cm: 235,
       in: 14340.58,
       ft: 8.3
    }
 },
  {
    fullName: "Bentley Flying Spur",
    make: "Bentley",
    model: "Flying Spur",
    length: {
       cm: 531.6,
       in: 209.29,
       ft: 17.44
    },
    width: {
       cm: 197.8,
       in: 77.87,
       ft: 6.49
    },
    height: {
       cm: 148.4,
       in: 58.43,
       ft: 4.87
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "Bentley Mulsanne",
    make: "Bentley",
    model: "Mulsanne",
    length: {
       cm: 557.5,
       in: 219.49,
       ft: 18.29
    },
    width: {
       cm: 192.6,
       in: 75.83,
       ft: 6.32
    },
    height: {
       cm: 152.1,
       in: 59.88,
       ft: 4.99
    },
    capacity: {
       cm: 443,
       in: 27033.53,
       ft: 15.64
    }
 },
  {
    fullName: "BMW 1",
    make: "BMW",
    model: "1",
    length: {
       cm: 431.9,
       in: 170.04,
       ft: 14.17
    },
    width: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    height: {
       cm: 143.4,
       in: 56.46,
       ft: 4.7
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "BMW 2 Active Tourer",
    make: "BMW",
    model: "2 Active Tourer",
    length: {
       cm: 435.4,
       in: 171.42,
       ft: 14.28
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 155.5,
       in: 61.22,
       ft: 5.1
    },
    capacity: {
       cm: 468,
       in: 28559.12,
       ft: 16.53
    }
 },
  {
    fullName: "BMW 2 Cabrio",
    make: "BMW",
    model: "2 Cabrio",
    length: {
       cm: 445.4,
       in: 175.35,
       ft: 14.61
    },
    width: {
       cm: 177.4,
       in: 69.84,
       ft: 5.82
    },
    height: {
       cm: 140.3,
       in: 55.24,
       ft: 4.6
    },
    capacity: {
       cm: 280,
       in: 17086.65,
       ft: 9.89
    }
 },
  {
    fullName: "BMW 2 Coupe",
    make: "BMW",
    model: "2 Coupe",
    length: {
       cm: 443.2,
       in: 174.49,
       ft: 14.54
    },
    width: {
       cm: 177.4,
       in: 69.84,
       ft: 5.82
    },
    height: {
       cm: 141.8,
       in: 55.83,
       ft: 4.65
    },
    capacity: {
       cm: 390,
       in: 23799.27,
       ft: 13.77
    }
 },
  {
    fullName: "BMW 2 Gran Tourer",
    make: "BMW",
    model: "2 Gran Tourer",
    length: {
       cm: 456.8,
       in: 179.84,
       ft: 14.99
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 161.2,
       in: 63.46,
       ft: 5.29
    },
    capacity: {
       cm: 560,
       in: 34173.31,
       ft: 19.78
    }
 },
  {
    fullName: "BMW 3",
    make: "BMW",
    model: "3",
    length: {
       cm: 470.9,
       in: 185.39,
       ft: 15.45
    },
    width: {
       cm: 182.7,
       in: 71.93,
       ft: 5.99
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 480,
       in: 29291.4,
       ft: 16.95
    }
 },
  {
    fullName: "BMW 3 Gran Turismo",
    make: "BMW",
    model: "3 Gran Turismo",
    length: {
       cm: 482.4,
       in: 189.92,
       ft: 15.83
    },
    width: {
       cm: 182.8,
       in: 71.97,
       ft: 6
    },
    height: {
       cm: 150.8,
       in: 59.37,
       ft: 4.95
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "BMW 3 Touring",
    make: "BMW",
    model: "3 Touring",
    length: {
       cm: 470.9,
       in: 185.39,
       ft: 15.45
    },
    width: {
       cm: 182.7,
       in: 71.93,
       ft: 5.99
    },
    height: {
       cm: 144,
       in: 56.69,
       ft: 4.72
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "BMW 4 Cabrio",
    make: "BMW",
    model: "4 Cabrio",
    length: {
       cm: 463.8,
       in: 182.6,
       ft: 15.22
    },
    width: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    height: {
       cm: 138.4,
       in: 54.49,
       ft: 4.54
    },
    capacity: {
       cm: 370,
       in: 22578.79,
       ft: 13.07
    }
 },
  {
    fullName: "BMW 4 Coupe",
    make: "BMW",
    model: "4 Coupe",
    length: {
       cm: 463.8,
       in: 182.6,
       ft: 15.22
    },
    width: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    height: {
       cm: 137.7,
       in: 54.21,
       ft: 4.52
    },
    capacity: {
       cm: 445,
       in: 27155.57,
       ft: 15.72
    }
 },
  {
    fullName: "BMW 4 Gran Coupe",
    make: "BMW",
    model: "4 Gran Coupe",
    length: {
       cm: 463.8,
       in: 182.6,
       ft: 15.22
    },
    width: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    height: {
       cm: 138.9,
       in: 54.69,
       ft: 4.56
    },
    capacity: {
       cm: 480,
       in: 29291.4,
       ft: 16.95
    }
 },
  {
    fullName: "BMW 5",
    make: "BMW",
    model: "5",
    length: {
       cm: 493.6,
       in: 194.33,
       ft: 16.19
    },
    width: {
       cm: 186.8,
       in: 73.54,
       ft: 6.13
    },
    height: {
       cm: 147.9,
       in: 58.23,
       ft: 4.85
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "BMW 5 Series E60 2003",
    make: "BMW",
    model: "5 Series E60 2003",
    length: {
       cm: 484,
       in: 190.55,
       ft: 15.88
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "BMW 5 Touring",
    make: "BMW",
    model: "5 Touring",
    length: {
       cm: 494.3,
       in: 194.61,
       ft: 16.22
    },
    width: {
       cm: 186.8,
       in: 73.54,
       ft: 6.13
    },
    height: {
       cm: 149.8,
       in: 58.98,
       ft: 4.91
    },
    capacity: {
       cm: 570,
       in: 34783.54,
       ft: 20.13
    }
 },
  {
    fullName: "BMW 6 Gran Turismo",
    make: "BMW",
    model: "6 Gran Turismo",
    length: {
       cm: 509.1,
       in: 200.43,
       ft: 16.7
    },
    width: {
       cm: 190.2,
       in: 74.88,
       ft: 6.24
    },
    height: {
       cm: 153.8,
       in: 60.55,
       ft: 5.05
    },
    capacity: {
       cm: 610,
       in: 37224.49,
       ft: 21.54
    }
 },
  {
    fullName: "BMW 7",
    make: "BMW",
    model: "7",
    length: {
       cm: 512,
       in: 201.57,
       ft: 16.8
    },
    width: {
       cm: 190.2,
       in: 74.88,
       ft: 6.24
    },
    height: {
       cm: 146.7,
       in: 57.76,
       ft: 4.81
    },
    capacity: {
       cm: 515,
       in: 31427.24,
       ft: 18.19
    }
 },
  {
    fullName: "BMW 7 L",
    make: "BMW",
    model: "7 L",
    length: {
       cm: 526,
       in: 207.09,
       ft: 17.26
    },
    width: {
       cm: 190.2,
       in: 74.88,
       ft: 6.24
    },
    height: {
       cm: 147.9,
       in: 58.23,
       ft: 4.85
    },
    capacity: {
       cm: 515,
       in: 31427.24,
       ft: 18.19
    }
 },
  {
    fullName: "BMW 8 Coupe",
    make: "BMW",
    model: "8 Coupe",
    length: {
       cm: 485.1,
       in: 190.98,
       ft: 15.92
    },
    width: {
       cm: 190.2,
       in: 74.88,
       ft: 6.24
    },
    height: {
       cm: 134.6,
       in: 52.99,
       ft: 4.42
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "BMW E87 1 Series 2004",
    make: "BMW",
    model: "E87 1 Series 2004",
    length: {
       cm: 423,
       in: 166.54,
       ft: 13.88
    },
    width: {
       cm: 175,
       in: 68.9,
       ft: 5.74
    },
    height: {
       cm: 143,
       in: 56.3,
       ft: 4.69
    },
    capacity: {
       cm: 330,
       in: 20137.84,
       ft: 11.65
    }
 },
  {
    fullName: "BMW E90 3 Series 2006",
    make: "BMW",
    model: "E90 3 Series 2006",
    length: {
       cm: 452,
       in: 177.95,
       ft: 14.83
    },
    width: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    height: {
       cm: 142,
       in: 55.91,
       ft: 4.66
    },
    capacity: {
       cm: 460,
       in: 28070.93,
       ft: 16.24
    }
 },
  {
    fullName: "BMW i3",
    make: "BMW",
    model: "i3",
    length: {
       cm: 401.1,
       in: 157.91,
       ft: 13.16
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 157.8,
       in: 62.13,
       ft: 5.18
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "BMW i3 2013",
    make: "BMW",
    model: "i3 2013",
    length: {
       cm: 399.9,
       in: 157.44,
       ft: 13.12
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 157.8,
       in: 62.13,
       ft: 5.18
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "BMW i8 Coupe",
    make: "BMW",
    model: "i8 Coupe",
    length: {
       cm: 468.9,
       in: 184.61,
       ft: 15.38
    },
    width: {
       cm: 194.2,
       in: 76.46,
       ft: 6.37
    },
    height: {
       cm: 129.3,
       in: 50.91,
       ft: 4.24
    },
    capacity: {
       cm: 154,
       in: 9397.66,
       ft: 5.44
    }
 },
  {
    fullName: "BMW i8 Roadster",
    make: "BMW",
    model: "i8 Roadster",
    length: {
       cm: 468.9,
       in: 184.61,
       ft: 15.38
    },
    width: {
       cm: 194.2,
       in: 76.46,
       ft: 6.37
    },
    height: {
       cm: 129.1,
       in: 50.83,
       ft: 4.24
    },
    capacity: {
       cm: 88,
       in: 5370.09,
       ft: 3.11
    }
 },
  {
    fullName: "BMW M2 Competition",
    make: "BMW",
    model: "M2 Competition",
    length: {
       cm: 446.1,
       in: 175.63,
       ft: 14.64
    },
    width: {
       cm: 185.4,
       in: 72.99,
       ft: 6.08
    },
    height: {
       cm: 141,
       in: 55.51,
       ft: 4.63
    },
    capacity: {
       cm: 390,
       in: 23799.27,
       ft: 13.77
    }
 },
  {
    fullName: "BMW M4 Coupe",
    make: "BMW",
    model: "M4 Coupe",
    length: {
       cm: 467.1,
       in: 183.9,
       ft: 15.32
    },
    width: {
       cm: 187,
       in: 73.62,
       ft: 6.14
    },
    height: {
       cm: 138.3,
       in: 54.45,
       ft: 4.54
    },
    capacity: {
       cm: 445,
       in: 27155.57,
       ft: 15.72
    }
 },
  {
    fullName: "BMW X1",
    make: "BMW",
    model: "X1",
    length: {
       cm: 444.7,
       in: 175.08,
       ft: 14.59
    },
    width: {
       cm: 182.1,
       in: 71.69,
       ft: 5.97
    },
    height: {
       cm: 161.2,
       in: 63.46,
       ft: 5.29
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "BMW X2",
    make: "BMW",
    model: "X2",
    length: {
       cm: 436,
       in: 171.65,
       ft: 14.3
    },
    width: {
       cm: 182.4,
       in: 71.81,
       ft: 5.98
    },
    height: {
       cm: 152.6,
       in: 60.08,
       ft: 5.01
    },
    capacity: {
       cm: 470,
       in: 28681.17,
       ft: 16.6
    }
 },
  {
    fullName: "BMW X3",
    make: "BMW",
    model: "X3",
    length: {
       cm: 470.8,
       in: 185.35,
       ft: 15.45
    },
    width: {
       cm: 189.1,
       in: 74.45,
       ft: 6.2
    },
    height: {
       cm: 167.6,
       in: 65.98,
       ft: 5.5
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "BMW X4",
    make: "BMW",
    model: "X4",
    length: {
       cm: 475.2,
       in: 187.09,
       ft: 15.59
    },
    width: {
       cm: 191.8,
       in: 75.51,
       ft: 6.29
    },
    height: {
       cm: 162.1,
       in: 63.82,
       ft: 5.32
    },
    capacity: {
       cm: 525,
       in: 32037.47,
       ft: 18.54
    }
 },
  {
    fullName: "BMW X5",
    make: "BMW",
    model: "X5",
    length: {
       cm: 492.2,
       in: 193.78,
       ft: 16.15
    },
    width: {
       cm: 200.4,
       in: 78.9,
       ft: 6.57
    },
    height: {
       cm: 174.5,
       in: 68.7,
       ft: 5.73
    },
    capacity: {
       cm: 575,
       in: 35088.66,
       ft: 20.31
    }
 },
  {
    fullName: "BMW X6",
    make: "BMW",
    model: "X6",
    length: {
       cm: 490.9,
       in: 193.27,
       ft: 16.11
    },
    width: {
       cm: 198.9,
       in: 78.31,
       ft: 6.53
    },
    height: {
       cm: 170.2,
       in: 67.01,
       ft: 5.58
    },
    capacity: {
       cm: 580,
       in: 35393.78,
       ft: 20.48
    }
 },
  {
    fullName: "BMW X7",
    make: "BMW",
    model: "X7",
    length: {
       cm: 515.1,
       in: 202.8,
       ft: 16.9
    },
    width: {
       cm: 200,
       in: 78.74,
       ft: 6.56
    },
    height: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    capacity: {
       cm: 750,
       in: 45767.82,
       ft: 26.49
    }
 },
  {
    fullName: "BMW Z4",
    make: "BMW",
    model: "Z4",
    length: {
       cm: 432.4,
       in: 170.24,
       ft: 14.19
    },
    width: {
       cm: 186.4,
       in: 73.39,
       ft: 6.12
    },
    height: {
       cm: 1300.4,
       in: 511.97,
       ft: 42.66
    },
    capacity: {
       cm: 281,
       in: 17147.68,
       ft: 9.92
    }
 },
  {
    fullName: "Chevrolet Camaro",
    make: "Chevrolet",
    model: "Camaro",
    length: {
       cm: 478.3,
       in: 188.31,
       ft: 15.69
    },
    width: {
       cm: 190,
       in: 74.8,
       ft: 6.23
    },
    height: {
       cm: 134,
       in: 52.76,
       ft: 4.4
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "Chevrolet Corvette",
    make: "Chevrolet",
    model: "Corvette",
    length: {
       cm: 449.2,
       in: 176.85,
       ft: 14.74
    },
    width: {
       cm: 187.2,
       in: 73.7,
       ft: 6.14
    },
    height: {
       cm: 123.9,
       in: 48.78,
       ft: 4.06
    },
    capacity: {
       cm: 287,
       in: 17513.82,
       ft: 10.14
    }
 },
  {
    fullName: "Chevrolet Niva 2002",
    make: "Chevrolet",
    model: "Niva 2002",
    length: {
       cm: 404.8,
       in: 159.37,
       ft: 13.2808399
    },
    width: {
       cm: 177,
       in: 69.69,
       ft: 5.807086614
    },
    height: {
       cm: 165.2,
       in: 65.04,
       ft: 5.419947507
    },
    capacity: {
       cm: 320,
       in: 19527.60288,
       ft: 11.3
    }
 },
  {
    fullName: "Citroen Berlingo M",
    make: "Citroen",
    model: "Berlingo M",
    length: {
       cm: 440.3,
       in: 173.35,
       ft: 14.45
    },
    width: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    height: {
       cm: 187.4,
       in: 73.78,
       ft: 6.15
    },
    capacity: {
       cm: 597,
       in: 36431.18,
       ft: 21.08
    }
 },
  {
    fullName: "Citroen Berlingo Multispace 2015",
    make: "Citroen",
    model: "Berlingo Multispace 2015",
    length: {
       cm: 438,
       in: 172.44,
       ft: 14.37
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 186.2,
       in: 73.31,
       ft: 6.11
    },
    capacity: {
       cm: 675,
       in: 41191.04,
       ft: 23.84
    }
 },
  {
    fullName: "Citroen Berlingo XL",
    make: "Citroen",
    model: "Berlingo XL",
    length: {
       cm: 475.3,
       in: 187.13,
       ft: 15.59
    },
    width: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    height: {
       cm: 187.9,
       in: 73.98,
       ft: 6.16
    },
    capacity: {
       cm: 859,
       in: 52419.41,
       ft: 30.34
    }
 },
  {
    fullName: "Citroen C1",
    make: "Citroen",
    model: "C1",
    length: {
       cm: 346.6,
       in: 136.46,
       ft: 11.37
    },
    width: {
       cm: 161.5,
       in: 63.58,
       ft: 5.3
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 196,
       in: 11960.66,
       ft: 6.92
    }
 },
  {
    fullName: "Citroen C1 2005",
    make: "Citroen",
    model: "C1 2005",
    length: {
       cm: 343,
       in: 135.04,
       ft: 11.25
    },
    width: {
       cm: 163,
       in: 64.17,
       ft: 5.35
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 139,
       in: 8482.3,
       ft: 4.91
    }
 },
  {
    fullName: "Citroen C3",
    make: "Citroen",
    model: "C3",
    length: {
       cm: 399.6,
       in: 157.32,
       ft: 13.11
    },
    width: {
       cm: 174.9,
       in: 68.86,
       ft: 5.74
    },
    height: {
       cm: 147.4,
       in: 58.03,
       ft: 4.84
    },
    capacity: {
       cm: 300,
       in: 18307.13,
       ft: 10.59
    }
 },
  {
    fullName: "Citroen C3 2002",
    make: "Citroen",
    model: "C3 1.6i 2002",
    length: {
       cm: 385,
       in: 151.57,
       ft: 12.63
    },
    width: {
       cm: 166.7,
       in: 65.63,
       ft: 5.47
    },
    height: {
       cm: 151.9,
       in: 59.8,
       ft: 4.98
    },
    capacity: {
       cm: 305,
       in: 18612.25,
       ft: 10.77
    }
 },
  {
    fullName: "Citroen C3 Aircross",
    make: "Citroen",
    model: "C3 Aircross",
    length: {
       cm: 415.4,
       in: 163.54,
       ft: 13.63
    },
    width: {
       cm: 175.6,
       in: 69.13,
       ft: 5.76
    },
    height: {
       cm: 163.7,
       in: 64.45,
       ft: 5.37
    },
    capacity: {
       cm: 410,
       in: 25019.74,
       ft: 14.48
    }
 },
  {
    fullName: "Citroen C3 Picasso 2010",
    make: "Citroen",
    model: "C3 Picasso 2010",
    length: {
       cm: 407.8,
       in: 160.55,
       ft: 13.38
    },
    width: {
       cm: 173,
       in: 68.11,
       ft: 5.68
    },
    height: {
       cm: 162.4,
       in: 63.94,
       ft: 5.33
    },
    capacity: {
       cm: 385,
       in: 23494.15,
       ft: 13.6
    }
 },
  {
    fullName: "Citroen C4  1.6i 2004",
    make: "Citroen",
    model: "C4 1.6i 2004",
    length: {
       cm: 426,
       in: 167.72,
       ft: 13.98
    },
    width: {
       cm: 177.3,
       in: 69.8,
       ft: 5.82
    },
    height: {
       cm: 145.8,
       in: 57.4,
       ft: 4.78
    },
    capacity: {
       cm: 320,
       in: 19527.6,
       ft: 11.3
    }
 },
  {
    fullName: "Citroen C4 Cactus",
    make: "Citroen",
    model: "C4 Cactus",
    length: {
       cm: 417,
       in: 164.17,
       ft: 13.68
    },
    width: {
       cm: 171,
       in: 67.32,
       ft: 5.61
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 358,
       in: 21846.51,
       ft: 12.64
    }
 },
  {
    fullName: "Citroen C4 Cactus 2014",
    make: "Citroen",
    model: "C4 Cactus 2014",
    length: {
       cm: 415.7,
       in: 163.66,
       ft: 13.64
    },
    width: {
       cm: 172.9,
       in: 68.07,
       ft: 5.67
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 348,
       in: 21236.27,
       ft: 12.29
    }
 },
  {
    fullName: "Citroen C4 Picasso 2007",
    make: "Citroen",
    model: "C4 Picasso 2007",
    length: {
       cm: 447,
       in: 175.98,
       ft: 14.67
    },
    width: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    height: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Citroen C4 Picasso 2011",
    make: "Citroen",
    model: "C4 Picasso 2011",
    length: {
       cm: 447,
       in: 175.98,
       ft: 14.67
    },
    width: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    height: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Citroen C4 Picasso 2013",
    make: "Citroen",
    model: "C4 Picasso 2013",
    length: {
       cm: 442.8,
       in: 174.33,
       ft: 14.53
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 162.5,
       in: 63.98,
       ft: 5.33
    },
    capacity: {
       cm: 537,
       in: 32769.76,
       ft: 18.96
    }
 },
  {
    fullName: "Citroen C4 Picasso 2013",
    make: "Citroen",
    model: "C4 Picasso 2013",
    length: {
       cm: 442.8,
       in: 174.33,
       ft: 14.53
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 161.3,
       in: 63.5,
       ft: 5.29
    },
    capacity: {
       cm: 537,
       in: 32769.76,
       ft: 18.96
    }
 },
  {
    fullName: "Citroen C4 Picasso 2016",
    make: "Citroen",
    model: "C4 Picasso 2016",
    length: {
       cm: 443.8,
       in: 174.72,
       ft: 14.56
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 162.5,
       in: 63.98,
       ft: 5.33
    },
    capacity: {
       cm: 537,
       in: 32769.76,
       ft: 18.96
    }
 },
  {
    fullName: "Citroen C4 SpaceTourer",
    make: "Citroen",
    model: "C4 SpaceTourer",
    length: {
       cm: 443.8,
       in: 174.72,
       ft: 14.56
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 162.5,
       in: 63.98,
       ft: 5.33
    },
    capacity: {
       cm: 537,
       in: 32769.76,
       ft: 18.96
    }
 },
  {
    fullName: "Citroen C5 1.8 2001",
    make: "Citroen",
    model: "C5 1.8 2001",
    length: {
       cm: 474.5,
       in: 186.81,
       ft: 15.57
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 147.6,
       in: 58.11,
       ft: 4.84
    },
    capacity: {
       cm: 471,
       in: 28742.19,
       ft: 16.63
    }
 },
  {
    fullName: "Citroen C5 Aircross",
    make: "Citroen",
    model: "C5 Aircross",
    length: {
       cm: 450,
       in: 177.17,
       ft: 14.76
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 168.9,
       in: 66.5,
       ft: 5.54
    },
    capacity: {
       cm: 580,
       in: 35393.78,
       ft: 20.48
    }
 },
  {
    fullName: "Citroen C5 Aircross",
    make: "Citroen",
    model: "C5 Aircross",
    length: {
       cm: 450,
       in: 177.17,
       ft: 14.76
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 168.9,
       in: 66.5,
       ft: 5.54
    },
    capacity: {
       cm: 580,
       in: 35393.78,
       ft: 20.48
    }
 },
  {
    fullName: "Citroen C5 II 2008",
    make: "Citroen",
    model: "C5 II 2008",
    length: {
       cm: 477.9,
       in: 188.15,
       ft: 15.68
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 145.1,
       in: 57.13,
       ft: 4.76
    },
    capacity: {
       cm: 439,
       in: 26789.43,
       ft: 15.5
    }
 },
  {
    fullName: "Citroen C5 Tourer 2010",
    make: "Citroen",
    model: "C5 Tourer 2010",
    length: {
       cm: 482.9,
       in: 190.12,
       ft: 15.84
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 149.5,
       in: 58.86,
       ft: 4.9
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Citroen C-Elysee",
    make: "Citroen",
    model: "C-Elysee",
    length: {
       cm: 442.7,
       in: 174.29,
       ft: 14.52
    },
    width: {
       cm: 174.8,
       in: 68.82,
       ft: 5.73
    },
    height: {
       cm: 146.6,
       in: 57.72,
       ft: 4.81
    },
    capacity: {
       cm: 506,
       in: 30878.02,
       ft: 17.87
    }
 },
  {
    fullName: "Citroen C-Elysee 2013",
    make: "Citroen",
    model: "C-Elysee 2013",
    length: {
       cm: 442.7,
       in: 174.29,
       ft: 14.52
    },
    width: {
       cm: 174.8,
       in: 68.82,
       ft: 5.73
    },
    height: {
       cm: 146.6,
       in: 57.72,
       ft: 4.81
    },
    capacity: {
       cm: 506,
       in: 30878.02,
       ft: 17.87
    }
 },
  {
    fullName: "Citroen C-Zero",
    make: "Citroen",
    model: "C-Zero",
    length: {
       cm: 347.5,
       in: 136.81,
       ft: 11.4
    },
    width: {
       cm: 147.5,
       in: 58.07,
       ft: 4.84
    },
    height: {
       cm: 160.8,
       in: 63.31,
       ft: 5.28
    },
    capacity: {
       cm: 166,
       in: 10129.94,
       ft: 5.86
    }
 },
  {
    fullName: "Citroen DS3 2010",
    make: "Citroen",
    model: "DS3 2010",
    length: {
       cm: 394.8,
       in: 155.43,
       ft: 12.95
    },
    width: {
       cm: 171.5,
       in: 67.52,
       ft: 5.63
    },
    height: {
       cm: 145.8,
       in: 57.4,
       ft: 4.78
    },
    capacity: {
       cm: 285,
       in: 17391.77,
       ft: 10.06
    }
 },
  {
    fullName: "Citroen DS5 2012",
    make: "Citroen",
    model: "DS5 2012",
    length: {
       cm: 453,
       in: 178.35,
       ft: 14.86
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 150.8,
       in: 59.37,
       ft: 4.95
    },
    capacity: {
       cm: 465,
       in: 28376.05,
       ft: 16.42
    }
 },
  {
    fullName: "Citroen E-Méhari",
    make: "Citroen",
    model: "E-Méhari",
    length: {
       cm: 380.9,
       in: 149.96,
       ft: 12.5
    },
    width: {
       cm: 172.8,
       in: 68.03,
       ft: 5.67
    },
    height: {
       cm: 165.3,
       in: 65.08,
       ft: 5.42
    },
    capacity: {
       cm: 200,
       in: 12204.75,
       ft: 7.06
    }
 },
  {
    fullName: "Citroen Grand C4 Picasso 2011",
    make: "Citroen",
    model: "Grand C4 Picasso 2011",
    length: {
       cm: 459,
       in: 180.71,
       ft: 15.06
    },
    width: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    height: {
       cm: 171,
       in: 67.32,
       ft: 5.61
    },
    capacity: {
       cm: 576,
       in: 35149.69,
       ft: 20.34
    }
 },
  {
    fullName: "Citroen Grand C4 Picasso 2016",
    make: "Citroen",
    model: "Grand C4 Picasso 2016",
    length: {
       cm: 460.2,
       in: 181.18,
       ft: 15.1
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 163.8,
       in: 64.49,
       ft: 5.37
    },
    capacity: {
       cm: 645,
       in: 39360.32,
       ft: 22.78
    }
 },
  {
    fullName: "Citroen Grand C4 SpaceTourer",
    make: "Citroen",
    model: "Grand C4 SpaceTourer",
    length: {
       cm: 460.2,
       in: 181.18,
       ft: 15.1
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 163.8,
       in: 64.49,
       ft: 5.37
    },
    capacity: {
       cm: 645,
       in: 39360.32,
       ft: 22.78
    }
 },
  {
    fullName: "Citroen Nemo Multispace 2012",
    make: "Citroen",
    model: "Nemo Multispace 2012",
    length: {
       cm: 395.9,
       in: 155.87,
       ft: 12.99
    },
    width: {
       cm: 171.6,
       in: 67.56,
       ft: 5.63
    },
    height: {
       cm: 178.2,
       in: 70.16,
       ft: 5.85
    },
    capacity: {
       cm: 356,
       in: 21724.46,
       ft: 12.57
    }
 },
  {
    fullName: "Citroen SpaceTourer M",
    make: "Citroen",
    model: "SpaceTourer M",
    length: {
       cm: 495.6,
       in: 195.12,
       ft: 16.26
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2381,
       in: 145297.57,
       ft: 84.09
    }
 },
  {
    fullName: "Citroen SpaceTourer XL",
    make: "Citroen",
    model: "SpaceTourer XL",
    length: {
       cm: 530.6,
       in: 208.9,
       ft: 17.41
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2932,
       in: 178921.66,
       ft: 103.54
    }
 },
  {
    fullName: "Citroen SpaceTourer XS",
    make: "Citroen",
    model: "SpaceTourer XS",
    length: {
       cm: 460.6,
       in: 181.34,
       ft: 15.11
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 190.5,
       in: 75,
       ft: 6.25
    },
    capacity: {
       cm: 1978,
       in: 120705,
       ft: 69.85
    }
 },
  {
    fullName: "Dacia Dokker",
    make: "Dacia",
    model: "Dokker",
    length: {
       cm: 436.3,
       in: 171.77,
       ft: 14.31
    },
    width: {
       cm: 175.1,
       in: 68.94,
       ft: 5.74
    },
    height: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    capacity: {
       cm: 800,
       in: 48819.01,
       ft: 28.25
    }
 },
  {
    fullName: "Dacia Dokker Stepway",
    make: "Dacia",
    model: "Dokker Stepway",
    length: {
       cm: 438.8,
       in: 172.76,
       ft: 14.4
    },
    width: {
       cm: 176.7,
       in: 69.57,
       ft: 5.8
    },
    height: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    capacity: {
       cm: 800,
       in: 48819.01,
       ft: 28.25
    }
 },
  {
    fullName: "Dacia Duster",
    make: "Dacia",
    model: "Duster",
    length: {
       cm: 434.1,
       in: 170.91,
       ft: 14.24
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 169.3,
       in: 66.65,
       ft: 5.55
    },
    capacity: {
       cm: 445,
       in: 27155.57,
       ft: 15.72
    }
 },
  {
    fullName: "Dacia Duster 2009",
    make: "Dacia",
    model: "Duster 2009",
    length: {
       cm: 431.5,
       in: 169.88,
       ft: 14.16
    },
    width: {
       cm: 182.2,
       in: 71.73,
       ft: 5.98
    },
    height: {
       cm: 162.5,
       in: 63.98,
       ft: 5.33
    },
    capacity: {
       cm: 475,
       in: 28986.3,
       ft: 16.77
    }
 },
  {
    fullName: "Dacia Lodgy",
    make: "Dacia",
    model: "Lodgy",
    length: {
       cm: 449.8,
       in: 177.09,
       ft: 14.76
    },
    width: {
       cm: 175.1,
       in: 68.94,
       ft: 5.74
    },
    height: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    capacity: {
       cm: 634,
       in: 38689.06,
       ft: 22.39
    }
 },
  {
    fullName: "Dacia Lodgy Stepway",
    make: "Dacia",
    model: "Lodgy Stepway",
    length: {
       cm: 452.1,
       in: 177.99,
       ft: 14.83
    },
    width: {
       cm: 176.7,
       in: 69.57,
       ft: 5.8
    },
    height: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    capacity: {
       cm: 634,
       in: 38689.06,
       ft: 22.39
    }
 },
  {
    fullName: "Dacia Logan",
    make: "Dacia",
    model: "Logan",
    length: {
       cm: 434.7,
       in: 171.14,
       ft: 14.26
    },
    width: {
       cm: 173.3,
       in: 68.23,
       ft: 5.69
    },
    height: {
       cm: 151.7,
       in: 59.72,
       ft: 4.98
    },
    capacity: {
       cm: 510,
       in: 31122.12,
       ft: 18.01
    }
 },
  {
    fullName: "Dacia Logan MCV",
    make: "Dacia",
    model: "Logan MCV",
    length: {
       cm: 449.2,
       in: 176.85,
       ft: 14.74
    },
    width: {
       cm: 173.3,
       in: 68.23,
       ft: 5.69
    },
    height: {
       cm: 155,
       in: 61.02,
       ft: 5.09
    },
    capacity: {
       cm: 573,
       in: 34966.61,
       ft: 20.24
    }
 },
  {
    fullName: "Dacia Logan MCV Stepway",
    make: "Dacia",
    model: "Logan MCV Stepway",
    length: {
       cm: 450.1,
       in: 177.2,
       ft: 14.77
    },
    width: {
       cm: 176.1,
       in: 69.33,
       ft: 5.78
    },
    height: {
       cm: 159,
       in: 62.6,
       ft: 5.22
    },
    capacity: {
       cm: 573,
       in: 34966.61,
       ft: 20.24
    }
 },
  {
    fullName: "Dacia Sandero",
    make: "Dacia",
    model: "Sandero",
    length: {
       cm: 405.8,
       in: 159.76,
       ft: 13.31
    },
    width: {
       cm: 173.3,
       in: 68.23,
       ft: 5.69
    },
    height: {
       cm: 151.8,
       in: 59.76,
       ft: 4.98
    },
    capacity: {
       cm: 320,
       in: 19527.6,
       ft: 11.3
    }
 },
  {
    fullName: "Dacia Sandero Stepway",
    make: "Dacia",
    model: "Sandero Stepway",
    length: {
       cm: 408.1,
       in: 160.67,
       ft: 13.39
    },
    width: {
       cm: 176.1,
       in: 69.33,
       ft: 5.78
    },
    height: {
       cm: 161.8,
       in: 63.7,
       ft: 5.31
    },
    capacity: {
       cm: 320,
       in: 19527.6,
       ft: 11.3
    }
 },
  {
    fullName: "DS DS3",
    make: "DS",
    model: "DS3",
    length: {
       cm: 394.8,
       in: 155.43,
       ft: 12.95
    },
    width: {
       cm: 171.5,
       in: 67.52,
       ft: 5.63
    },
    height: {
       cm: 145.8,
       in: 57.4,
       ft: 4.78
    },
    capacity: {
       cm: 285,
       in: 17391.77,
       ft: 10.06
    }
 },
  {
    fullName: "DS DS3 Crossback",
    make: "DS",
    model: "DS3 Crossback",
    length: {
       cm: 411.8,
       in: 162.13,
       ft: 13.51
    },
    width: {
       cm: 179.1,
       in: 70.51,
       ft: 5.88
    },
    height: {
       cm: 153.4,
       in: 60.39,
       ft: 5.03
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "DS DS7 Crossback",
    make: "DS",
    model: "DS7 Crossback",
    length: {
       cm: 457.3,
       in: 180.04,
       ft: 15
    },
    width: {
       cm: 190.6,
       in: 75.04,
       ft: 6.25
    },
    height: {
       cm: 162.5,
       in: 63.98,
       ft: 5.33
    },
    capacity: {
       cm: 555,
       in: 33868.19,
       ft: 19.6
    }
 },
  {
    fullName: "Ferrari 488 Pista",
    make: "Ferrari",
    model: "488 Pista",
    length: {
       cm: 460.5,
       in: 181.3,
       ft: 15.11
    },
    width: {
       cm: 197.5,
       in: 77.76,
       ft: 6.48
    },
    height: {
       cm: 120.6,
       in: 47.48,
       ft: 3.96
    },
    capacity: {
       cm: 170,
       in: 10374.04,
       ft: 6
    }
 },
  {
    fullName: "Ferrari 488 Spider",
    make: "Ferrari",
    model: "488 Spider",
    length: {
       cm: 456.8,
       in: 179.84,
       ft: 14.99
    },
    width: {
       cm: 195.2,
       in: 76.85,
       ft: 6.4
    },
    height: {
       cm: 121.1,
       in: 47.68,
       ft: 3.97
    },
    capacity: {
       cm: 230,
       in: 14035.46,
       ft: 8.12
    }
 },
  {
    fullName: "Ferrari 812 Superfast",
    make: "Ferrari",
    model: "812 Superfast",
    length: {
       cm: 465.7,
       in: 183.35,
       ft: 15.28
    },
    width: {
       cm: 197.1,
       in: 77.6,
       ft: 6.47
    },
    height: {
       cm: 127.6,
       in: 50.24,
       ft: 4.19
    },
    capacity: {
       cm: 320,
       in: 19527.6,
       ft: 11.3
    }
 },
  {
    fullName: "Ferrari F8 Tributo",
    make: "Ferrari",
    model: "F8 Tributo",
    length: {
       cm: 461.1,
       in: 181.54,
       ft: 15.13
    },
    width: {
       cm: 197.9,
       in: 77.91,
       ft: 6.49
    },
    height: {
       cm: 120.6,
       in: 47.48,
       ft: 3.96
    },
    capacity: {
       cm: 200,
       in: 12204.75,
       ft: 7.06
    }
 },
  {
    fullName: "Ferrari GTC4Lusso",
    make: "Ferrari",
    model: "GTC4Lusso",
    length: {
       cm: 492.2,
       in: 193.78,
       ft: 16.15
    },
    width: {
       cm: 198,
       in: 77.95,
       ft: 6.5
    },
    height: {
       cm: 138.3,
       in: 54.45,
       ft: 4.54
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Ferrari Portofino",
    make: "Ferrari",
    model: "Portofino",
    length: {
       cm: 458.6,
       in: 180.55,
       ft: 15.05
    },
    width: {
       cm: 193.8,
       in: 76.3,
       ft: 6.36
    },
    height: {
       cm: 131.8,
       in: 51.89,
       ft: 4.32
    },
    capacity: {
       cm: 292,
       in: 17818.94,
       ft: 10.31
    }
 },
  {
    fullName: "Ferrari Roma",
    make: "Ferrari",
    model: "Roma",
    length: {
       cm: 465.6,
       in: 183.31,
       ft: 15.28
    },
    width: {
       cm: 197.4,
       in: 77.72,
       ft: 6.48
    },
    height: {
       cm: 130.1,
       in: 51.22,
       ft: 4.27
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Ferrari SF90 Stradale",
    make: "Ferrari",
    model: "SF90 Stradale",
    length: {
       cm: 471,
       in: 185.43,
       ft: 15.45
    },
    width: {
       cm: 197.2,
       in: 77.64,
       ft: 6.47
    },
    height: {
       cm: 118.6,
       in: 46.69,
       ft: 3.89
    },
    capacity: {
       cm: 74,
       in: 4515.76,
       ft: 2.61
    }
 },
  {
    fullName: "Fiat 124 Spider",
    make: "Fiat",
    model: "124 Spider",
    length: {
       cm: 405.4,
       in: 159.61,
       ft: 13.3
    },
    width: {
       cm: 174,
       in: 68.5,
       ft: 5.71
    },
    height: {
       cm: 123.3,
       in: 48.54,
       ft: 4.05
    },
    capacity: {
       cm: 140,
       in: 8543.33,
       ft: 4.94
    }
 },
  {
    fullName: "Fiat 500",
    make: "Fiat",
    model: "500",
    length: {
       cm: 357.1,
       in: 140.59,
       ft: 11.72
    },
    width: {
       cm: 162.7,
       in: 64.06,
       ft: 5.34
    },
    height: {
       cm: 148.8,
       in: 58.58,
       ft: 4.88
    },
    capacity: {
       cm: 185,
       in: 11289.4,
       ft: 6.53
    }
 },
  {
    fullName: "Fiat 500 Pop 2007",
    make: "Fiat",
    model: "500 Pop 2007",
    length: {
       cm: 354.6,
       in: 139.61,
       ft: 11.63
    },
    width: {
       cm: 162.7,
       in: 64.06,
       ft: 5.34
    },
    height: {
       cm: 148.8,
       in: 58.58,
       ft: 4.88
    },
    capacity: {
       cm: 185,
       in: 11289.4,
       ft: 6.53
    }
 },
  {
    fullName: "Fiat 500L",
    make: "Fiat",
    model: "500L",
    length: {
       cm: 424.2,
       in: 167.01,
       ft: 13.92
    },
    width: {
       cm: 178.4,
       in: 70.24,
       ft: 5.85
    },
    height: {
       cm: 165.8,
       in: 65.28,
       ft: 5.44
    },
    capacity: {
       cm: 343,
       in: 20931.15,
       ft: 12.11
    }
 },
  {
    fullName: "Fiat 500L Cross",
    make: "Fiat",
    model: "500L Cross",
    length: {
       cm: 427.6,
       in: 168.35,
       ft: 14.03
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 167.9,
       in: 66.1,
       ft: 5.51
    },
    capacity: {
       cm: 343,
       in: 20931.15,
       ft: 12.11
    }
 },
  {
    fullName: "Fiat 500L Wagon",
    make: "Fiat",
    model: "500L Wagon",
    length: {
       cm: 437.5,
       in: 172.24,
       ft: 14.35
    },
    width: {
       cm: 178.4,
       in: 70.24,
       ft: 5.85
    },
    height: {
       cm: 166.7,
       in: 65.63,
       ft: 5.47
    },
    capacity: {
       cm: 416,
       in: 25385.88,
       ft: 14.69
    }
 },
  {
    fullName: "Fiat 500X",
    make: "Fiat",
    model: "500X",
    length: {
       cm: 426.4,
       in: 167.87,
       ft: 13.99
    },
    width: {
       cm: 179.6,
       in: 70.71,
       ft: 5.89
    },
    height: {
       cm: 159.5,
       in: 62.8,
       ft: 5.23
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Fiat Doblò",
    make: "Fiat",
    model: "Doblò",
    length: {
       cm: 440.6,
       in: 173.46,
       ft: 14.46
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    capacity: {
       cm: 790,
       in: 48208.77,
       ft: 27.9
    }
 },
  {
    fullName: "Fiat Doblò Maxi",
    make: "Fiat",
    model: "Doblò Maxi",
    length: {
       cm: 475.6,
       in: 187.24,
       ft: 15.6
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    capacity: {
       cm: 1050,
       in: 64074.95,
       ft: 37.08
    }
 },
  {
    fullName: "Fiat Doblò Maxi XL",
    make: "Fiat",
    model: "Doblò Maxi XL",
    length: {
       cm: 475.6,
       in: 187.24,
       ft: 15.6
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 211.5,
       in: 83.27,
       ft: 6.94
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Fiat Fullback",
    make: "Fiat",
    model: "Fullback",
    length: {
       cm: 528.5,
       in: 208.07,
       ft: 17.34
    },
    width: {
       cm: 178.5,
       in: 70.28,
       ft: 5.86
    },
    height: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Fiat Panda",
    make: "Fiat",
    model: "Panda",
    length: {
       cm: 365.3,
       in: 143.82,
       ft: 11.98
    },
    width: {
       cm: 164.3,
       in: 64.69,
       ft: 5.39
    },
    height: {
       cm: 155.1,
       in: 61.06,
       ft: 5.09
    },
    capacity: {
       cm: 225,
       in: 13730.35,
       ft: 7.95
    }
 },
  {
    fullName: "Fiat Panda 1.2 2003",
    make: "Fiat",
    model: "Panda 1.2 2003",
    length: {
       cm: 353.8,
       in: 139.29,
       ft: 11.61
    },
    width: {
       cm: 157.8,
       in: 62.13,
       ft: 5.18
    },
    height: {
       cm: 154,
       in: 60.63,
       ft: 5.05
    },
    capacity: {
       cm: 206,
       in: 12570.89,
       ft: 7.27
    }
 },
  {
    fullName: "Fiat Panda 2012",
    make: "Fiat",
    model: "Panda 2012 2012",
    length: {
       cm: 365.3,
       in: 143.82,
       ft: 11.98
    },
    width: {
       cm: 164.3,
       in: 64.69,
       ft: 5.39
    },
    height: {
       cm: 155.1,
       in: 61.06,
       ft: 5.09
    },
    capacity: {
       cm: 225,
       in: 13730.35,
       ft: 7.95
    }
 },
  {
    fullName: "Fiat Panda 4x4",
    make: "Fiat",
    model: "Panda 4x4",
    length: {
       cm: 368.6,
       in: 145.12,
       ft: 12.09
    },
    width: {
       cm: 167.2,
       in: 65.83,
       ft: 5.49
    },
    height: {
       cm: 160.5,
       in: 63.19,
       ft: 5.27
    },
    capacity: {
       cm: 225,
       in: 13730.35,
       ft: 7.95
    }
 },
  {
    fullName: "Fiat Qubo",
    make: "Fiat",
    model: "Qubo",
    length: {
       cm: 395.7,
       in: 155.79,
       ft: 12.98
    },
    width: {
       cm: 171.6,
       in: 67.56,
       ft: 5.63
    },
    height: {
       cm: 178.2,
       in: 70.16,
       ft: 5.85
    },
    capacity: {
       cm: 330,
       in: 20137.84,
       ft: 11.65
    }
 },
  {
    fullName: "Fiat Stilo 1.6 2001",
    make: "Fiat",
    model: "Stilo 1.6 2001",
    length: {
       cm: 425.3,
       in: 167.44,
       ft: 13.95
    },
    width: {
       cm: 175.6,
       in: 69.13,
       ft: 5.76
    },
    height: {
       cm: 152.5,
       in: 60.04,
       ft: 5
    },
    capacity: {
       cm: 335,
       in: 20442.96,
       ft: 11.83
    }
 },
  {
    fullName: "Fiat Talento Combi",
    make: "Fiat",
    model: "Talento Combi",
    length: {
       cm: 499.9,
       in: 196.81,
       ft: 16.4
    },
    width: {
       cm: 195.6,
       in: 77.01,
       ft: 6.42
    },
    height: {
       cm: 197.1,
       in: 77.6,
       ft: 6.47
    },
    capacity: {
       cm: 2500,
       in: 152559.4,
       ft: 88.29
    }
 },
  {
    fullName: "Fiat Talento Combi lg",
    make: "Fiat",
    model: "Talento Combi lg",
    length: {
       cm: 539.9,
       in: 212.56,
       ft: 17.71
    },
    width: {
       cm: 195.6,
       in: 77.01,
       ft: 6.42
    },
    height: {
       cm: 197.1,
       in: 77.6,
       ft: 6.47
    },
    capacity: {
       cm: 3400,
       in: 207480.78,
       ft: 120.07
    }
 },
  {
    fullName: "Fiat Tipo 4-door",
    make: "Fiat",
    model: "Tipo 4-door",
    length: {
       cm: 454,
       in: 178.74,
       ft: 14.9
    },
    width: {
       cm: 179.2,
       in: 70.55,
       ft: 5.88
    },
    height: {
       cm: 149,
       in: 58.66,
       ft: 4.89
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "Fiat Tipo 5-door",
    make: "Fiat",
    model: "Tipo 5-door",
    length: {
       cm: 435,
       in: 171.26,
       ft: 14.27
    },
    width: {
       cm: 179.2,
       in: 70.55,
       ft: 5.88
    },
    height: {
       cm: 149,
       in: 58.66,
       ft: 4.89
    },
    capacity: {
       cm: 440,
       in: 26850.45,
       ft: 15.54
    }
 },
  {
    fullName: "Fiat Tipo SW",
    make: "Fiat",
    model: "Tipo SW",
    length: {
       cm: 457,
       in: 179.92,
       ft: 14.99
    },
    width: {
       cm: 179.2,
       in: 70.55,
       ft: 5.88
    },
    height: {
       cm: 150,
       in: 59.06,
       ft: 4.92
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Ford B Max 2012",
    make: "Ford",
    model: "B Max 2012",
    length: {
       cm: 407.7,
       in: 160.51,
       ft: 13.38
    },
    width: {
       cm: 175.1,
       in: 68.94,
       ft: 5.74
    },
    height: {
       cm: 160.4,
       in: 63.15,
       ft: 5.26
    },
    capacity: {
       cm: 318,
       in: 19405.56,
       ft: 11.23
    }
 },
  {
    fullName: "Ford C Max Trend 2010",
    make: "Ford",
    model: "C MAX Trend 2010",
    length: {
       cm: 264,
       in: 103.94,
       ft: 8.66
    },
    width: {
       cm: 437.2,
       in: 172.13,
       ft: 14.34
    },
    height: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    capacity: {
       cm: 437,
       in: 26667.38,
       ft: 15.43
    }
 },
  {
    fullName: "Ford C-MAX",
    make: "Ford",
    model: "C-MAX",
    length: {
       cm: 437.9,
       in: 172.4,
       ft: 14.37
    },
    width: {
       cm: 182.8,
       in: 71.97,
       ft: 6
    },
    height: {
       cm: 161,
       in: 63.39,
       ft: 5.28
    },
    capacity: {
       cm: 471,
       in: 28742.19,
       ft: 16.63
    }
 },
  {
    fullName: "Ford EcoSport",
    make: "Ford",
    model: "EcoSport",
    length: {
       cm: 409.6,
       in: 161.26,
       ft: 13.44
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 165.3,
       in: 65.08,
       ft: 5.42
    },
    capacity: {
       cm: 356,
       in: 21724.46,
       ft: 12.57
    }
 },
  {
    fullName: "Ford Edge",
    make: "Ford",
    model: "Edge",
    length: {
       cm: 483.4,
       in: 190.31,
       ft: 15.86
    },
    width: {
       cm: 192.8,
       in: 75.91,
       ft: 6.33
    },
    height: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    capacity: {
       cm: 602,
       in: 36736.3,
       ft: 21.26
    }
 },
  {
    fullName: "Ford Explorer",
    make: "Ford",
    model: "Explorer",
    length: {
       cm: 504.9,
       in: 198.78,
       ft: 16.56
    },
    width: {
       cm: 200.4,
       in: 78.9,
       ft: 6.57
    },
    height: {
       cm: 177.8,
       in: 70,
       ft: 5.83
    },
    capacity: {
       cm: 635,
       in: 38750.09,
       ft: 22.43
    }
 },
  {
    fullName: "Ford Fiesta",
    make: "Ford",
    model: "Fiesta",
    length: {
       cm: 404,
       in: 159.06,
       ft: 13.25
    },
    width: {
       cm: 173.5,
       in: 68.31,
       ft: 5.69
    },
    height: {
       cm: 147.6,
       in: 58.11,
       ft: 4.84
    },
    capacity: {
       cm: 292,
       in: 17818.94,
       ft: 10.31
    }
 },
  {
    fullName: "Ford Fiesta 1.4 2008",
    make: "Ford",
    model: "Fiesta 1.4 2008",
    length: {
       cm: 395,
       in: 155.51,
       ft: 12.96
    },
    width: {
       cm: 172.2,
       in: 67.8,
       ft: 5.65
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 290,
       in: 17696.89,
       ft: 10.24
    }
 },
  {
    fullName: "Ford Fiesta 2010",
    make: "Ford",
    model: "Fiesta 2010",
    length: {
       cm: 395,
       in: 155.51,
       ft: 12.96
    },
    width: {
       cm: 172.2,
       in: 67.8,
       ft: 5.65
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 290,
       in: 17696.89,
       ft: 10.24
    }
 },
  {
    fullName: "Ford Fiesta 2013",
    make: "Ford",
    model: "Fiesta 2013",
    length: {
       cm: 396.9,
       in: 156.26,
       ft: 13.02
    },
    width: {
       cm: 172.2,
       in: 67.8,
       ft: 5.65
    },
    height: {
       cm: 149.5,
       in: 58.86,
       ft: 4.9
    },
    capacity: {
       cm: 290,
       in: 17696.89,
       ft: 10.24
    }
 },
  {
    fullName: "Ford Fiesta 6 2002",
    make: "Ford",
    model: "Fiesta 6 2002",
    length: {
       cm: 391.7,
       in: 154.21,
       ft: 12.85
    },
    width: {
       cm: 168.3,
       in: 66.26,
       ft: 5.52
    },
    height: {
       cm: 143.2,
       in: 56.38,
       ft: 4.7
    },
    capacity: {
       cm: 284,
       in: 17330.75,
       ft: 10.03
    }
 },
  {
    fullName: "Ford Fiesta Active",
    make: "Ford",
    model: "Fiesta Active",
    length: {
       cm: 406.8,
       in: 160.16,
       ft: 13.35
    },
    width: {
       cm: 175.6,
       in: 69.13,
       ft: 5.76
    },
    height: {
       cm: 149.8,
       in: 58.98,
       ft: 4.91
    },
    capacity: {
       cm: 311,
       in: 18978.39,
       ft: 10.98
    }
 },
  {
    fullName: "Ford Focus",
    make: "Ford",
    model: "Focus",
    length: {
       cm: 437.8,
       in: 172.36,
       ft: 14.36
    },
    width: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    height: {
       cm: 145.4,
       in: 57.24,
       ft: 4.77
    },
    capacity: {
       cm: 375,
       in: 22883.91,
       ft: 13.24
    }
 },
  {
    fullName: "Ford Focus 2 2004",
    make: "Ford",
    model: "Focus 2, 1.6 2004",
    length: {
       cm: 434.2,
       in: 170.94,
       ft: 14.25
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 149.7,
       in: 58.94,
       ft: 4.91
    },
    capacity: {
       cm: 385,
       in: 23494.15,
       ft: 13.6
    }
 },
  {
    fullName: "Ford Focus 3 2011",
    make: "Ford",
    model: "Focus 3 2011",
    length: {
       cm: 453.4,
       in: 178.5,
       ft: 14.88
    },
    width: {
       cm: 182.3,
       in: 71.77,
       ft: 5.98
    },
    height: {
       cm: 148.4,
       in: 58.43,
       ft: 4.87
    },
    capacity: {
       cm: 475,
       in: 28986.29,
       ft: 16.77
    }
 },
  {
    fullName: "Ford Focus Active",
    make: "Ford",
    model: "Focus Active",
    length: {
       cm: 439.7,
       in: 173.11,
       ft: 14.43
    },
    width: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    height: {
       cm: 150.2,
       in: 59.13,
       ft: 4.93
    },
    capacity: {
       cm: 375,
       in: 22883.91,
       ft: 13.24
    }
 },
  {
    fullName: "Ford Focus Sportbreak",
    make: "Ford",
    model: "Focus Sportbreak",
    length: {
       cm: 466.8,
       in: 183.78,
       ft: 15.31
    },
    width: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 608,
       in: 37102.45,
       ft: 21.47
    }
 },
  {
    fullName: "Ford Galaxy",
    make: "Ford",
    model: "Galaxy",
    length: {
       cm: 484.8,
       in: 190.87,
       ft: 15.91
    },
    width: {
       cm: 191.6,
       in: 75.43,
       ft: 6.29
    },
    height: {
       cm: 174.7,
       in: 68.78,
       ft: 5.73
    },
    capacity: {
       cm: 700,
       in: 42716.63,
       ft: 24.72
    }
 },
  {
    fullName: "Ford Grand C-MAX",
    make: "Ford",
    model: "Grand C-MAX",
    length: {
       cm: 451.9,
       in: 177.91,
       ft: 14.83
    },
    width: {
       cm: 182.8,
       in: 71.97,
       ft: 6
    },
    height: {
       cm: 164.2,
       in: 64.65,
       ft: 5.39
    },
    capacity: {
       cm: 475,
       in: 28986.29,
       ft: 16.77
    }
 },
  {
    fullName: "Ford Grand Tourneo Connect",
    make: "Ford",
    model: "Grand Tourneo Connect",
    length: {
       cm: 482.5,
       in: 189.96,
       ft: 15.83
    },
    width: {
       cm: 183.5,
       in: 72.24,
       ft: 6.02
    },
    height: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    capacity: {
       cm: 1287,
       in: 78537.58,
       ft: 45.45
    }
 },
  {
    fullName: "Ford Grand Tourneo Connect 2014",
    make: "Ford",
    model: "Grand Tourneo Connect 2014",
    length: {
       cm: 481.8,
       in: 189.69,
       ft: 15.81
    },
    width: {
       cm: 183.5,
       in: 72.24,
       ft: 6.02
    },
    height: {
       cm: 182.8,
       in: 71.97,
       ft: 6
    },
    capacity: {
       cm: 1287,
       in: 78537.58,
       ft: 45.45
    }
 },
  {
    fullName: "Ford Ka+",
    make: "Ford",
    model: "Ka+",
    length: {
       cm: 394.1,
       in: 155.16,
       ft: 12.93
    },
    width: {
       cm: 170.4,
       in: 67.09,
       ft: 5.59
    },
    height: {
       cm: 152.4,
       in: 60,
       ft: 5
    },
    capacity: {
       cm: 270,
       in: 16476.41,
       ft: 9.54
    }
 },
  {
    fullName: "Ford Ka+ Active",
    make: "Ford",
    model: "Ka+ Active",
    length: {
       cm: 395.5,
       in: 155.71,
       ft: 12.98
    },
    width: {
       cm: 174.3,
       in: 68.62,
       ft: 5.72
    },
    height: {
       cm: 155.1,
       in: 61.06,
       ft: 5.09
    },
    capacity: {
       cm: 270,
       in: 16476.41,
       ft: 9.54
    }
 },
  {
    fullName: "Ford Kuga",
    make: "Ford",
    model: "Kuga",
    length: {
       cm: 461.4,
       in: 181.65,
       ft: 15.14
    },
    width: {
       cm: 188.3,
       in: 74.13,
       ft: 6.18
    },
    height: {
       cm: 166.1,
       in: 65.39,
       ft: 5.45
    },
    capacity: {
       cm: 502,
       in: 30633.93,
       ft: 17.73
    }
 },
  {
    fullName: "Ford Kuga 2013",
    make: "Ford",
    model: "Kuga 2013",
    length: {
       cm: 452.4,
       in: 178.11,
       ft: 14.84
    },
    width: {
       cm: 183.8,
       in: 72.36,
       ft: 6.03
    },
    height: {
       cm: 168.9,
       in: 66.5,
       ft: 5.54
    },
    capacity: {
       cm: 456,
       in: 27826.83,
       ft: 16.1
    }
 },
  {
    fullName: "Ford Kuga 2017",
    make: "Ford",
    model: "Kuga 2017",
    length: {
       cm: 452.4,
       in: 178.11,
       ft: 14.84
    },
    width: {
       cm: 183.8,
       in: 72.36,
       ft: 6.03
    },
    height: {
       cm: 168.9,
       in: 66.5,
       ft: 5.54
    },
    capacity: {
       cm: 456,
       in: 27826.83,
       ft: 16.1
    }
 },
  {
    fullName: "Ford Mondeo",
    make: "Ford",
    model: "Mondeo",
    length: {
       cm: 487.1,
       in: 191.77,
       ft: 15.98
    },
    width: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    height: {
       cm: 148.2,
       in: 58.35,
       ft: 4.86
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Ford Mondeo 3 2000",
    make: "Ford",
    model: "Mondeo 3 2000",
    length: {
       cm: 473.1,
       in: 186.26,
       ft: 15.52
    },
    width: {
       cm: 181.2,
       in: 71.34,
       ft: 5.94
    },
    height: {
       cm: 142.9,
       in: 56.26,
       ft: 4.69
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Ford Mondeo 4 2007",
    make: "Ford",
    model: "Mondeo 4 2007",
    length: {
       cm: 478,
       in: 188.19,
       ft: 15.68
    },
    width: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    height: {
       cm: 150,
       in: 59.06,
       ft: 4.92
    },
    capacity: {
       cm: 540,
       in: 32952.83,
       ft: 19.07
    }
 },
  {
    fullName: "Ford Mondeo SportBreak",
    make: "Ford",
    model: "Mondeo SportBreak",
    length: {
       cm: 486.7,
       in: 191.61,
       ft: 15.97
    },
    width: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    height: {
       cm: 150.1,
       in: 59.09,
       ft: 4.92
    },
    capacity: {
       cm: 525,
       in: 32037.47,
       ft: 18.54
    }
 },
  {
    fullName: "Ford Mustang",
    make: "Ford",
    model: "Mustang",
    length: {
       cm: 478.4,
       in: 188.35,
       ft: 15.7
    },
    width: {
       cm: 191.6,
       in: 75.43,
       ft: 6.29
    },
    height: {
       cm: 138.1,
       in: 54.37,
       ft: 4.53
    },
    capacity: {
       cm: 408,
       in: 24897.69,
       ft: 14.41
    }
 },
  {
    fullName: "Ford Mustang Mach-E",
    make: "Ford",
    model: "Mustang Mach-E",
    length: {
       cm: 471.2,
       in: 185.51,
       ft: 15.46
    },
    width: {
       cm: 188.1,
       in: 74.06,
       ft: 6.17
    },
    height: {
       cm: 159.7,
       in: 62.87,
       ft: 5.24
    },
    capacity: {
       cm: 502,
       in: 30633.93,
       ft: 17.73
    }
 },
  {
    fullName: "Ford Puma",
    make: "Ford",
    model: "Puma",
    length: {
       cm: 420.7,
       in: 165.63,
       ft: 13.8
    },
    width: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    height: {
       cm: 153.7,
       in: 60.51,
       ft: 5.04
    },
    capacity: {
       cm: 456,
       in: 27826.83,
       ft: 16.1
    }
 },
  {
    fullName: "Ford Ranger",
    make: "Ford",
    model: "Ranger",
    length: {
       cm: 535.9,
       in: 210.98,
       ft: 17.58
    },
    width: {
       cm: 186.7,
       in: 73.5,
       ft: 6.13
    },
    height: {
       cm: 182.1,
       in: 71.69,
       ft: 5.97
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Ford Ranger 2012",
    make: "Ford",
    model: "Ranger 2012",
    length: {
       cm: 535.9,
       in: 210.98,
       ft: 17.58
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Ford Ranger 2016",
    make: "Ford",
    model: "Ranger 2016",
    length: {
       cm: 536.2,
       in: 211.1,
       ft: 17.59
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Ford S Max 2006",
    make: "Ford",
    model: "S Max 2006",
    length: {
       cm: 476.8,
       in: 187.72,
       ft: 15.64
    },
    width: {
       cm: 188.4,
       in: 74.17,
       ft: 6.18
    },
    height: {
       cm: 165.8,
       in: 65.28,
       ft: 5.44
    },
    capacity: {
       cm: 854,
       in: 52114.29,
       ft: 30.16
    }
 },
  {
    fullName: "Ford S-MAX",
    make: "Ford",
    model: "S-MAX",
    length: {
       cm: 479.6,
       in: 188.82,
       ft: 15.73
    },
    width: {
       cm: 191.6,
       in: 75.43,
       ft: 6.29
    },
    height: {
       cm: 165.5,
       in: 65.16,
       ft: 5.43
    },
    capacity: {
       cm: 630,
       in: 38444.97,
       ft: 22.25
    }
 },
  {
    fullName: "Ford Tourneo Connect",
    make: "Ford",
    model: "Tourneo Connect",
    length: {
       cm: 442.5,
       in: 174.21,
       ft: 14.52
    },
    width: {
       cm: 183.5,
       in: 72.24,
       ft: 6.02
    },
    height: {
       cm: 181.9,
       in: 71.61,
       ft: 5.97
    },
    capacity: {
       cm: 1029,
       in: 62793.45,
       ft: 36.34
    }
 },
  {
    fullName: "Ford Tourneo Connect 2014",
    make: "Ford",
    model: "Tourneo Connect 2014",
    length: {
       cm: 441.8,
       in: 173.94,
       ft: 14.49
    },
    width: {
       cm: 183.5,
       in: 72.24,
       ft: 6.02
    },
    height: {
       cm: 184.4,
       in: 72.6,
       ft: 6.05
    },
    capacity: {
       cm: 1029,
       in: 62793.45,
       ft: 36.34
    }
 },
  {
    fullName: "Ford Tourneo Courier",
    make: "Ford",
    model: "Tourneo Courier",
    length: {
       cm: 415.7,
       in: 163.66,
       ft: 13.64
    },
    width: {
       cm: 176.4,
       in: 69.45,
       ft: 5.79
    },
    height: {
       cm: 174.1,
       in: 68.54,
       ft: 5.71
    },
    capacity: {
       cm: 395,
       in: 24104.38,
       ft: 13.95
    }
 },
  {
    fullName: "Ford Tourneo Custom L1",
    make: "Ford",
    model: "Tourneo Custom L1",
    length: {
       cm: 497.2,
       in: 195.75,
       ft: 16.31
    },
    width: {
       cm: 198.6,
       in: 78.19,
       ft: 6.52
    },
    height: {
       cm: 198.4,
       in: 78.11,
       ft: 6.51
    },
    capacity: {
       cm: 2148,
       in: 131079.03,
       ft: 75.86
    }
 },
  {
    fullName: "Ford Tourneo Custom L2",
    make: "Ford",
    model: "Tourneo Custom L2",
    length: {
       cm: 533.9,
       in: 210.2,
       ft: 17.52
    },
    width: {
       cm: 198.6,
       in: 78.19,
       ft: 6.52
    },
    height: {
       cm: 197.7,
       in: 77.83,
       ft: 6.49
    },
    capacity: {
       cm: 2674,
       in: 163177.53,
       ft: 94.43
    }
 },
  {
    fullName: "Honda Civic",
    make: "Honda",
    model: "Civic",
    length: {
       cm: 451.8,
       in: 177.87,
       ft: 14.82
    },
    width: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    height: {
       cm: 143.4,
       in: 56.46,
       ft: 4.7
    },
    capacity: {
       cm: 478,
       in: 29169.36,
       ft: 16.88
    }
 },
  {
    fullName: "Honda Civic 2001",
    make: "Honda",
    model: "Civic VII 5d 2001",
    length: {
       cm: 428,
       in: 168.5,
       ft: 14.04
    },
    width: {
       cm: 170,
       in: 66.93,
       ft: 5.58
    },
    height: {
       cm: 150,
       in: 59.06,
       ft: 4.92
    },
    capacity: {
       cm: 370,
       in: 22578.79,
       ft: 13.07
    }
 },
  {
    fullName: "Honda Civic 4p",
    make: "Honda",
    model: "Civic 4p",
    length: {
       cm: 464.8,
       in: 182.99,
       ft: 15.25
    },
    width: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    height: {
       cm: 141.6,
       in: 55.75,
       ft: 4.65
    },
    capacity: {
       cm: 519,
       in: 31671.33,
       ft: 18.33
    }
 },
  {
    fullName: "Honda Civic VIII 2006",
    make: "Honda",
    model: "Civic VIII 2006",
    length: {
       cm: 424.8,
       in: 167.24,
       ft: 13.94
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 485,
       in: 29596.52,
       ft: 17.13
    }
 },
  {
    fullName: "Honda CR-V",
    make: "Honda",
    model: "CR-V",
    length: {
       cm: 460,
       in: 181.1,
       ft: 15.09
    },
    width: {
       cm: 185.5,
       in: 73.03,
       ft: 6.09
    },
    height: {
       cm: 168.9,
       in: 66.5,
       ft: 5.54
    },
    capacity: {
       cm: 472,
       in: 28803.21,
       ft: 16.67
    }
 },
  {
    fullName: "Honda CR-V",
    make: "Honda",
    model: "CR-V",
    length: {
       cm: 460,
       in: 181.1,
       ft: 15.09
    },
    width: {
       cm: 185.5,
       in: 73.03,
       ft: 6.09
    },
    height: {
       cm: 168.9,
       in: 66.5,
       ft: 5.54
    },
    capacity: {
       cm: 472,
       in: 28803.21,
       ft: 16.67
    }
 },
  {
    fullName: "Honda e",
    make: "Honda",
    model: "e",
    length: {
       cm: 389.4,
       in: 153.31,
       ft: 12.78
    },
    width: {
       cm: 175.2,
       in: 68.98,
       ft: 5.75
    },
    height: {
       cm: 151.2,
       in: 59.53,
       ft: 4.96
    },
    capacity: {
       cm: 171,
       in: 10435.06,
       ft: 6.04
    }
 },
  {
    fullName: "Honda HR-V",
    make: "Honda",
    model: "HR-V",
    length: {
       cm: 433.5,
       in: 170.67,
       ft: 14.22
    },
    width: {
       cm: 177.2,
       in: 69.76,
       ft: 5.81
    },
    height: {
       cm: 160.5,
       in: 63.19,
       ft: 5.27
    },
    capacity: {
       cm: 470,
       in: 28681.17,
       ft: 16.6
    }
 },
  {
    fullName: "Honda Jazz",
    make: "Honda",
    model: "Jazz",
    length: {
       cm: 402.8,
       in: 158.58,
       ft: 13.22
    },
    width: {
       cm: 169.4,
       in: 66.69,
       ft: 5.56
    },
    height: {
       cm: 152.5,
       in: 60.04,
       ft: 5
    },
    capacity: {
       cm: 354,
       in: 21602.41,
       ft: 12.5
    }
 },
  {
    fullName: "Honda Jazz 1.4i 2002",
    make: "Honda",
    model: "Jazz 1.4i 2002",
    length: {
       cm: 383,
       in: 150.79,
       ft: 12.57
    },
    width: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    height: {
       cm: 152,
       in: 59.84,
       ft: 4.99
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Honda NSX",
    make: "Honda",
    model: "NSX",
    length: {
       cm: 448.7,
       in: 176.65,
       ft: 14.72
    },
    width: {
       cm: 193.9,
       in: 76.34,
       ft: 6.36
    },
    height: {
       cm: 120.4,
       in: 47.4,
       ft: 3.95
    },
    capacity: {
       cm: 110,
       in: 6712.61,
       ft: 3.88
    }
 },
  {
    fullName: "Hyundai H-1 Travel",
    make: "Hyundai",
    model: "H-1 Travel",
    length: {
       cm: 515,
       in: 202.76,
       ft: 16.9
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 192.5,
       in: 75.79,
       ft: 6.32
    },
    capacity: {
       cm: 851,
       in: 51931.22,
       ft: 30.05
    }
 },
  {
    fullName: "Hyundai H-1 Travel",
    make: "Hyundai",
    model: "H-1 Travel",
    length: {
       cm: 515,
       in: 202.76,
       ft: 16.9
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 192.5,
       in: 75.79,
       ft: 6.32
    },
    capacity: {
       cm: 851,
       in: 51931.22,
       ft: 30.05
    }
 },
  {
    fullName: "Hyundai i10",
    make: "Hyundai",
    model: "i10",
    length: {
       cm: 367,
       in: 144.49,
       ft: 12.04
    },
    width: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 252,
       in: 15377.99,
       ft: 8.9
    }
 },
  {
    fullName: "Hyundai i20",
    make: "Hyundai",
    model: "i20",
    length: {
       cm: 403.5,
       in: 158.86,
       ft: 13.24
    },
    width: {
       cm: 173.4,
       in: 68.27,
       ft: 5.69
    },
    height: {
       cm: 147.4,
       in: 58.03,
       ft: 4.84
    },
    capacity: {
       cm: 326,
       in: 19893.75,
       ft: 11.51
    }
 },
  {
    fullName: "Hyundai i20 Active",
    make: "Hyundai",
    model: "i20 Active",
    length: {
       cm: 406.5,
       in: 160.04,
       ft: 13.34
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 152.9,
       in: 60.2,
       ft: 5.02
    },
    capacity: {
       cm: 326,
       in: 19893.75,
       ft: 11.51
    }
 },
  {
    fullName: "Hyundai i20 Coupe",
    make: "Hyundai",
    model: "i20 Coupe",
    length: {
       cm: 404.5,
       in: 159.25,
       ft: 13.27
    },
    width: {
       cm: 173,
       in: 68.11,
       ft: 5.68
    },
    height: {
       cm: 144.9,
       in: 57.05,
       ft: 4.75
    },
    capacity: {
       cm: 336,
       in: 20503.98,
       ft: 11.87
    }
 },
  {
    fullName: "Hyundai i30",
    make: "Hyundai",
    model: "i30",
    length: {
       cm: 434,
       in: 170.87,
       ft: 14.24
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 145.5,
       in: 57.28,
       ft: 4.77
    },
    capacity: {
       cm: 395,
       in: 24104.38,
       ft: 13.95
    }
 },
  {
    fullName: "Hyundai i30 Fastback",
    make: "Hyundai",
    model: "i30 Fastback",
    length: {
       cm: 445.5,
       in: 175.39,
       ft: 14.62
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 142.5,
       in: 56.1,
       ft: 4.68
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Hyundai i30 N",
    make: "Hyundai",
    model: "i30 N",
    length: {
       cm: 433.5,
       in: 170.67,
       ft: 14.22
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 145.1,
       in: 57.13,
       ft: 4.76
    },
    capacity: {
       cm: 381,
       in: 23250.05,
       ft: 13.46
    }
 },
  {
    fullName: "Hyundai i30 SW",
    make: "Hyundai",
    model: "i30 SW",
    length: {
       cm: 458.5,
       in: 180.51,
       ft: 15.04
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 602,
       in: 36736.3,
       ft: 21.26
    }
 },
  {
    fullName: "Hyundai IONIQ",
    make: "Hyundai",
    model: "IONIQ",
    length: {
       cm: 447,
       in: 175.98,
       ft: 14.67
    },
    width: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    height: {
       cm: 145,
       in: 57.09,
       ft: 4.76
    },
    capacity: {
       cm: 456,
       in: 27826.83,
       ft: 16.1
    }
 },
  {
    fullName: "Hyundai ix20",
    make: "Hyundai",
    model: "ix20",
    length: {
       cm: 412,
       in: 162.2,
       ft: 13.52
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 160,
       in: 62.99,
       ft: 5.25
    },
    capacity: {
       cm: 440,
       in: 26850.45,
       ft: 15.54
    }
 },
  {
    fullName: "Hyundai Kona",
    make: "Hyundai",
    model: "Kona",
    length: {
       cm: 416.5,
       in: 163.98,
       ft: 13.66
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 156.5,
       in: 61.61,
       ft: 5.13
    },
    capacity: {
       cm: 361,
       in: 22029.58,
       ft: 12.75
    }
 },
  {
    fullName: "Hyundai Nexo",
    make: "Hyundai",
    model: "Nexo",
    length: {
       cm: 467,
       in: 183.86,
       ft: 15.32
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 164,
       in: 64.57,
       ft: 5.38
    },
    capacity: {
       cm: 461,
       in: 28131.95,
       ft: 16.28
    }
 },
  {
    fullName: "Hyundai Santa Fe",
    make: "Hyundai",
    model: "Santa Fe",
    length: {
       cm: 477,
       in: 187.8,
       ft: 15.65
    },
    width: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    height: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    capacity: {
       cm: 547,
       in: 33380,
       ft: 19.32
    }
 },
  {
    fullName: "Hyundai Tucson",
    make: "Hyundai",
    model: "Tucson",
    length: {
       cm: 448,
       in: 176.38,
       ft: 14.7
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 165,
       in: 64.96,
       ft: 5.41
    },
    capacity: {
       cm: 513,
       in: 31305.19,
       ft: 18.12
    }
 },
  {
    fullName: "Infiniti Q30",
    make: "Infiniti",
    model: "Q30",
    length: {
       cm: 442.5,
       in: 174.21,
       ft: 14.52
    },
    width: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    height: {
       cm: 149.5,
       in: 58.86,
       ft: 4.9
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Infiniti Q50",
    make: "Infiniti",
    model: "Q50",
    length: {
       cm: 481,
       in: 189.37,
       ft: 15.78
    },
    width: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    height: {
       cm: 144.5,
       in: 56.89,
       ft: 4.74
    },
    capacity: {
       cm: 400,
       in: 24409.5,
       ft: 14.13
    }
 },
  {
    fullName: "Infiniti QX30",
    make: "Infiniti",
    model: "QX30",
    length: {
       cm: 442.5,
       in: 174.21,
       ft: 14.52
    },
    width: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    height: {
       cm: 151.5,
       in: 59.65,
       ft: 4.97
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Isuzu D-MAX",
    make: "Isuzu",
    model: "D-MAX",
    length: {
       cm: 529.5,
       in: 208.46,
       ft: 17.37
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 178.5,
       in: 70.28,
       ft: 5.86
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Jaguar E-PACE",
    make: "Jaguar",
    model: "E-PACE",
    length: {
       cm: 441.1,
       in: 173.66,
       ft: 14.47
    },
    width: {
       cm: 190,
       in: 74.8,
       ft: 6.23
    },
    height: {
       cm: 164.9,
       in: 64.92,
       ft: 5.41
    },
    capacity: {
       cm: 377,
       in: 23005.96,
       ft: 13.31
    }
 },
  {
    fullName: "Jaguar F-PACE",
    make: "Jaguar",
    model: "F-PACE",
    length: {
       cm: 474.6,
       in: 186.85,
       ft: 15.57
    },
    width: {
       cm: 193.6,
       in: 76.22,
       ft: 6.35
    },
    height: {
       cm: 165.2,
       in: 65.04,
       ft: 5.42
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Jaguar F-TYPE Convertible",
    make: "Jaguar",
    model: "F-TYPE Convertible",
    length: {
       cm: 448.2,
       in: 176.46,
       ft: 14.7
    },
    width: {
       cm: 192.3,
       in: 75.71,
       ft: 6.31
    },
    height: {
       cm: 130.7,
       in: 51.46,
       ft: 4.29
    },
    capacity: {
       cm: 207,
       in: 12631.92,
       ft: 7.31
    }
 },
  {
    fullName: "Jaguar F-TYPE Coupe",
    make: "Jaguar",
    model: "F-TYPE Coupe",
    length: {
       cm: 448.2,
       in: 176.46,
       ft: 14.7
    },
    width: {
       cm: 192.3,
       in: 75.71,
       ft: 6.31
    },
    height: {
       cm: 131,
       in: 51.57,
       ft: 4.3
    },
    capacity: {
       cm: 310,
       in: 18917.37,
       ft: 10.95
    }
 },
  {
    fullName: "Jaguar I-PACE",
    make: "Jaguar",
    model: "I-PACE",
    length: {
       cm: 468.2,
       in: 184.33,
       ft: 15.36
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 156.5,
       in: 61.61,
       ft: 5.13
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Jaguar X Type 2.5 2001",
    make: "Jaguar",
    model: "X Type 2.5 2001",
    length: {
       cm: 467.2,
       in: 183.94,
       ft: 15.33
    },
    width: {
       cm: 178.9,
       in: 70.43,
       ft: 5.87
    },
    height: {
       cm: 139.2,
       in: 54.8,
       ft: 4.57
    },
    capacity: {
       cm: 452,
       in: 27582.74,
       ft: 15.96
    }
 },
  {
    fullName: "Jaguar XE",
    make: "Jaguar",
    model: "XE",
    length: {
       cm: 467.8,
       in: 184.17,
       ft: 15.35
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 141.6,
       in: 55.75,
       ft: 4.65
    },
    capacity: {
       cm: 291,
       in: 17757.91,
       ft: 10.28
    }
 },
  {
    fullName: "Jaguar XF",
    make: "Jaguar",
    model: "XF",
    length: {
       cm: 495.4,
       in: 195.04,
       ft: 16.25
    },
    width: {
       cm: 188,
       in: 74.02,
       ft: 6.17
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 415,
       in: 25324.86,
       ft: 14.66
    }
 },
  {
    fullName: "Jaguar XF Sportbrake",
    make: "Jaguar",
    model: "XF Sportbrake",
    length: {
       cm: 495.5,
       in: 195.08,
       ft: 16.26
    },
    width: {
       cm: 188,
       in: 74.02,
       ft: 6.17
    },
    height: {
       cm: 149.6,
       in: 58.9,
       ft: 4.91
    },
    capacity: {
       cm: 490,
       in: 29901.64,
       ft: 17.3
    }
 },
  {
    fullName: "Jaguar XJ",
    make: "Jaguar",
    model: "XJ",
    length: {
       cm: 513,
       in: 201.97,
       ft: 16.83
    },
    width: {
       cm: 189.9,
       in: 74.76,
       ft: 6.23
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 478,
       in: 29169.36,
       ft: 16.88
    }
 },
  {
    fullName: "Jaguar XJ-LWB",
    make: "Jaguar",
    model: "XJ-LWB",
    length: {
       cm: 525.5,
       in: 206.89,
       ft: 17.24
    },
    width: {
       cm: 189.9,
       in: 74.76,
       ft: 6.23
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 478,
       in: 29169.36,
       ft: 16.88
    }
 },
  {
    fullName: "Jeep Cherokee",
    make: "Jeep",
    model: "Cherokee",
    length: {
       cm: 462.3,
       in: 182.01,
       ft: 15.17
    },
    width: {
       cm: 185.9,
       in: 73.19,
       ft: 6.1
    },
    height: {
       cm: 166.9,
       in: 65.71,
       ft: 5.48
    },
    capacity: {
       cm: 448,
       in: 27338.64,
       ft: 15.82
    }
 },
  {
    fullName: "Jeep Compass",
    make: "Jeep",
    model: "Compass",
    length: {
       cm: 439.5,
       in: 173.03,
       ft: 14.42
    },
    width: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    height: {
       cm: 164,
       in: 64.57,
       ft: 5.38
    },
    capacity: {
       cm: 438,
       in: 26728.41,
       ft: 15.47
    }
 },
  {
    fullName: "Jeep Grand Cherokee",
    make: "Jeep",
    model: "Grand Cherokee",
    length: {
       cm: 482.8,
       in: 190.08,
       ft: 15.84
    },
    width: {
       cm: 194.3,
       in: 76.5,
       ft: 6.37
    },
    height: {
       cm: 180.2,
       in: 70.94,
       ft: 5.91
    },
    capacity: {
       cm: 457,
       in: 27887.86,
       ft: 16.14
    }
 },
  {
    fullName: "Jeep Renegade",
    make: "Jeep",
    model: "Renegade",
    length: {
       cm: 423.6,
       in: 166.77,
       ft: 13.9
    },
    width: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    height: {
       cm: 169.7,
       in: 66.81,
       ft: 5.57
    },
    capacity: {
       cm: 351,
       in: 21419.34,
       ft: 12.4
    }
 },
  {
    fullName: "Jeep Wrangler",
    make: "Jeep",
    model: "Wrangler",
    length: {
       cm: 433.4,
       in: 170.63,
       ft: 14.22
    },
    width: {
       cm: 189.4,
       in: 74.57,
       ft: 6.21
    },
    height: {
       cm: 184.1,
       in: 72.48,
       ft: 6.04
    },
    capacity: {
       cm: 203,
       in: 12387.82,
       ft: 7.17
    }
 },
  {
    fullName: "Jeep Wrangler Unlimited",
    make: "Jeep",
    model: "Wrangler Unlimited",
    length: {
       cm: 488.2,
       in: 192.2,
       ft: 16.02
    },
    width: {
       cm: 189.4,
       in: 74.57,
       ft: 6.21
    },
    height: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    capacity: {
       cm: 548,
       in: 33441.02,
       ft: 19.35
    }
 },
  {
    fullName: "Kia Carens",
    make: "Kia",
    model: "Carens",
    length: {
       cm: 452.5,
       in: 178.15,
       ft: 14.85
    },
    width: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    height: {
       cm: 161,
       in: 63.39,
       ft: 5.28
    },
    capacity: {
       cm: 492,
       in: 30023.69,
       ft: 17.37
    }
 },
  {
    fullName: "Kia Ceed",
    make: "Kia",
    model: "Ceed",
    length: {
       cm: 431,
       in: 169.69,
       ft: 14.14
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 144.7,
       in: 56.97,
       ft: 4.75
    },
    capacity: {
       cm: 395,
       in: 24104.38,
       ft: 13.95
    }
 },
  {
    fullName: "Kia Ceed 2007",
    make: "Kia",
    model: "Ceed 2007",
    length: {
       cm: 424,
       in: 166.93,
       ft: 13.91
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 340,
       in: 20748.08,
       ft: 12.01
    }
 },
  {
    fullName: "Kia Ceed Sportswagon",
    make: "Kia",
    model: "Ceed Sportswagon",
    length: {
       cm: 460,
       in: 181.1,
       ft: 15.09
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 625,
       in: 38139.85,
       ft: 22.07
    }
 },
  {
    fullName: "Kia Ceed Sportswagon",
    make: "Kia",
    model: "Ceed Sportswagon",
    length: {
       cm: 460,
       in: 181.1,
       ft: 15.09
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 625,
       in: 38139.85,
       ft: 22.07
    }
 },
  {
    fullName: "Kia e-Soul",
    make: "Kia",
    model: "e-Soul",
    length: {
       cm: 419.5,
       in: 165.16,
       ft: 13.76
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 160.5,
       in: 63.19,
       ft: 5.27
    },
    capacity: {
       cm: 315,
       in: 19222.48,
       ft: 11.12
    }
 },
  {
    fullName: "Kia Niro",
    make: "Kia",
    model: "Niro",
    length: {
       cm: 435.5,
       in: 171.46,
       ft: 14.29
    },
    width: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    height: {
       cm: 154.5,
       in: 60.83,
       ft: 5.07
    },
    capacity: {
       cm: 436,
       in: 26606.36,
       ft: 15.4
    }
 },
  {
    fullName: "Kia Optima",
    make: "Kia",
    model: "Optima",
    length: {
       cm: 485.5,
       in: 191.14,
       ft: 15.93
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 510,
       in: 31122.12,
       ft: 18.01
    }
 },
  {
    fullName: "Kia Optima Sportswagon",
    make: "Kia",
    model: "Optima Sportswagon",
    length: {
       cm: 485.5,
       in: 191.14,
       ft: 15.93
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 552,
       in: 33685.11,
       ft: 19.49
    }
 },
  {
    fullName: "Kia Picanto",
    make: "Kia",
    model: "Picanto",
    length: {
       cm: 359.5,
       in: 141.54,
       ft: 11.79
    },
    width: {
       cm: 159.5,
       in: 62.8,
       ft: 5.23
    },
    height: {
       cm: 148.5,
       in: 58.46,
       ft: 4.87
    },
    capacity: {
       cm: 255,
       in: 15561.06,
       ft: 9.01
    }
 },
  {
    fullName: "Kia ProCeed",
    make: "Kia",
    model: "ProCeed",
    length: {
       cm: 460.5,
       in: 181.3,
       ft: 15.11
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 144.2,
       in: 56.77,
       ft: 4.73
    },
    capacity: {
       cm: 594,
       in: 36248.11,
       ft: 20.98
    }
 },
  {
    fullName: "Kia Rio",
    make: "Kia",
    model: "Rio",
    length: {
       cm: 406.5,
       in: 160.04,
       ft: 13.34
    },
    width: {
       cm: 172.5,
       in: 67.91,
       ft: 5.66
    },
    height: {
       cm: 145,
       in: 57.09,
       ft: 4.76
    },
    capacity: {
       cm: 325,
       in: 19832.72,
       ft: 11.48
    }
 },
  {
    fullName: "Kia Sorento",
    make: "Kia",
    model: "Sorento",
    length: {
       cm: 480,
       in: 188.98,
       ft: 15.75
    },
    width: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    height: {
       cm: 169,
       in: 66.54,
       ft: 5.54
    },
    capacity: {
       cm: 605,
       in: 36919.37,
       ft: 21.37
    }
 },
  {
    fullName: "Kia Sportage",
    make: "Kia",
    model: "Sportage",
    length: {
       cm: 448.5,
       in: 176.57,
       ft: 14.71
    },
    width: {
       cm: 185.5,
       in: 73.03,
       ft: 6.09
    },
    height: {
       cm: 163.5,
       in: 64.37,
       ft: 5.36
    },
    capacity: {
       cm: 503,
       in: 30694.95,
       ft: 17.76
    }
 },
  {
    fullName: "Kia Stinger",
    make: "Kia",
    model: "Stinger",
    length: {
       cm: 483,
       in: 190.16,
       ft: 15.85
    },
    width: {
       cm: 187,
       in: 73.62,
       ft: 6.14
    },
    height: {
       cm: 140,
       in: 55.12,
       ft: 4.59
    },
    capacity: {
       cm: 406,
       in: 24775.65,
       ft: 14.34
    }
 },
  {
    fullName: "Kia Stonic",
    make: "Kia",
    model: "Stonic",
    length: {
       cm: 414,
       in: 162.99,
       ft: 13.58
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 152,
       in: 59.84,
       ft: 4.99
    },
    capacity: {
       cm: 352,
       in: 21480.36,
       ft: 12.43
    }
 },
  {
    fullName: "Kia Venga",
    make: "Kia",
    model: "Venga",
    length: {
       cm: 407.5,
       in: 160.43,
       ft: 13.37
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 160,
       in: 62.99,
       ft: 5.25
    },
    capacity: {
       cm: 440,
       in: 26850.45,
       ft: 15.54
    }
 },
  {
    fullName: "Kia XCeed",
    make: "Kia",
    model: "XCeed",
    length: {
       cm: 439.5,
       in: 173.03,
       ft: 14.42
    },
    width: {
       cm: 182.6,
       in: 71.89,
       ft: 5.99
    },
    height: {
       cm: 149,
       in: 58.66,
       ft: 4.89
    },
    capacity: {
       cm: 426,
       in: 25996.12,
       ft: 15.04
    }
 },
  {
    fullName: "Lada Granta I Sedan",
    make: "Lada",
    model: "Granta I Sedan",
    length: {
       cm: 426,
       in: 167.72,
       ft: 13.97637795
    },
    width: {
       cm: 170,
       in: 66.93,
       ft: 5.577427822
    },
    height: {
       cm: 150,
       in: 59.06,
       ft: 4.921259843
    },
    capacity: {
       cm: 520,
       in: 31732.35468,
       ft: 18.36
    }
 },
  {
    fullName: "Lamborghini Aventador S Coupé",
    make: "Lamborghini",
    model: "Aventador S Coupé",
    length: {
       cm: 479.7,
       in: 188.86,
       ft: 15.74
    },
    width: {
       cm: 203,
       in: 79.92,
       ft: 6.66
    },
    height: {
       cm: 113.6,
       in: 44.72,
       ft: 3.73
    },
    capacity: {
       cm: 140,
       in: 8543.33,
       ft: 4.94
    }
 },
  {
    fullName: "Lamborghini Huracán EVO",
    make: "Lamborghini",
    model: "Huracán EVO",
    length: {
       cm: 452,
       in: 177.95,
       ft: 14.83
    },
    width: {
       cm: 193.3,
       in: 76.1,
       ft: 6.34
    },
    height: {
       cm: 116.5,
       in: 45.87,
       ft: 3.82
    },
    capacity: {
       cm: 100,
       in: 6102.38,
       ft: 3.53
    }
 },
  {
    fullName: "Lamborghini Urus",
    make: "Lamborghini",
    model: "Urus",
    length: {
       cm: 511.2,
       in: 201.26,
       ft: 16.77
    },
    width: {
       cm: 201.6,
       in: 79.37,
       ft: 6.61
    },
    height: {
       cm: 163.8,
       in: 64.49,
       ft: 5.37
    },
    capacity: {
       cm: 616,
       in: 37590.64,
       ft: 21.75
    }
 },
  {
    fullName: "Lancia Ypsilon",
    make: "Lancia",
    model: "Ypsilon",
    length: {
       cm: 383.7,
       in: 151.06,
       ft: 12.59
    },
    width: {
       cm: 167.6,
       in: 65.98,
       ft: 5.5
    },
    height: {
       cm: 151.8,
       in: 59.76,
       ft: 4.98
    },
    capacity: {
       cm: 245,
       in: 14950.82,
       ft: 8.65
    }
 },
  {
    fullName: "Land Rover 2011",
    make: "Land Rover",
    model: "Range Rover Evoque 2011",
    length: {
       cm: 435.5,
       in: 171.46,
       ft: 14.29
    },
    width: {
       cm: 196.5,
       in: 77.36,
       ft: 6.45
    },
    height: {
       cm: 160.5,
       in: 63.19,
       ft: 5.27
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Land-Rover Defender 110",
    make: "Land-Rover",
    model: "Defender 110",
    length: {
       cm: 475.8,
       in: 187.32,
       ft: 15.61
    },
    width: {
       cm: 199.6,
       in: 78.58,
       ft: 6.55
    },
    height: {
       cm: 196.7,
       in: 77.44,
       ft: 6.45
    },
    capacity: {
       cm: 646,
       in: 39421.35,
       ft: 22.81
    }
 },
  {
    fullName: "Land-Rover Defender 90",
    make: "Land-Rover",
    model: "Defender 90",
    length: {
       cm: 432.3,
       in: 170.2,
       ft: 14.18
    },
    width: {
       cm: 199.6,
       in: 78.58,
       ft: 6.55
    },
    height: {
       cm: 197.4,
       in: 77.72,
       ft: 6.48
    },
    capacity: {
       cm: 297,
       in: 18124.06,
       ft: 10.49
    }
 },
  {
    fullName: "Land-Rover Discovery",
    make: "Land-Rover",
    model: "Discovery",
    length: {
       cm: 497,
       in: 195.67,
       ft: 16.31
    },
    width: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    height: {
       cm: 188.8,
       in: 74.33,
       ft: 6.19
    },
    capacity: {
       cm: 723,
       in: 44120.18,
       ft: 25.53
    }
 },
  {
    fullName: "Land-Rover Discovery Sport",
    make: "Land-Rover",
    model: "Discovery Sport",
    length: {
       cm: 459.7,
       in: 180.98,
       ft: 15.08
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 172.7,
       in: 67.99,
       ft: 5.67
    },
    capacity: {
       cm: 541,
       in: 33013.85,
       ft: 19.11
    }
 },
  {
    fullName: "Land-Rover Range Rover",
    make: "Land-Rover",
    model: "Range Rover",
    length: {
       cm: 500,
       in: 196.85,
       ft: 16.4
    },
    width: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    height: {
       cm: 183.6,
       in: 72.28,
       ft: 6.02
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Land-Rover Range Rover Evoque",
    make: "Land-Rover",
    model: "Range Rover Evoque",
    length: {
       cm: 437.1,
       in: 172.09,
       ft: 14.34
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 164.9,
       in: 64.92,
       ft: 5.41
    },
    capacity: {
       cm: 472,
       in: 28803.21,
       ft: 16.67
    }
 },
  {
    fullName: "Land-Rover Range Rover LWB",
    make: "Land-Rover",
    model: "Range Rover LWB",
    length: {
       cm: 520,
       in: 204.72,
       ft: 17.06
    },
    width: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    height: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Land-Rover Range Rover Sport",
    make: "Land-Rover",
    model: "Range Rover Sport",
    length: {
       cm: 487.9,
       in: 192.09,
       ft: 16.01
    },
    width: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    height: {
       cm: 180.3,
       in: 70.98,
       ft: 5.92
    },
    capacity: {
       cm: 522,
       in: 31854.4,
       ft: 18.43
    }
 },
  {
    fullName: "Land-Rover Range Rover Velar",
    make: "Land-Rover",
    model: "Range Rover Velar",
    length: {
       cm: 479.7,
       in: 188.86,
       ft: 15.74
    },
    width: {
       cm: 195,
       in: 76.77,
       ft: 6.4
    },
    height: {
       cm: 168.5,
       in: 66.34,
       ft: 5.53
    },
    capacity: {
       cm: 513,
       in: 31305.19,
       ft: 18.12
    }
 },
  {
    fullName: "Lexus CT",
    make: "Lexus",
    model: "CT",
    length: {
       cm: 435.5,
       in: 171.46,
       ft: 14.29
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 145.5,
       in: 57.28,
       ft: 4.77
    },
    capacity: {
       cm: 375,
       in: 22883.91,
       ft: 13.24
    }
 },
  {
    fullName: "Lexus ES",
    make: "Lexus",
    model: "ES",
    length: {
       cm: 497.5,
       in: 195.87,
       ft: 16.32
    },
    width: {
       cm: 186.5,
       in: 73.43,
       ft: 6.12
    },
    height: {
       cm: 144.5,
       in: 56.89,
       ft: 4.74
    },
    capacity: {
       cm: 454,
       in: 27704.79,
       ft: 16.03
    }
 },
  {
    fullName: "Lexus IS",
    make: "Lexus",
    model: "IS",
    length: {
       cm: 468,
       in: 184.25,
       ft: 15.35
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 143,
       in: 56.3,
       ft: 4.69
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Lexus LC",
    make: "Lexus",
    model: "LC",
    length: {
       cm: 477,
       in: 187.8,
       ft: 15.65
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 134.5,
       in: 52.95,
       ft: 4.41
    },
    capacity: {
       cm: 197,
       in: 12021.68,
       ft: 6.96
    }
 },
  {
    fullName: "Lexus LS",
    make: "Lexus",
    model: "LS",
    length: {
       cm: 523.5,
       in: 206.1,
       ft: 17.18
    },
    width: {
       cm: 190,
       in: 74.8,
       ft: 6.23
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Lexus NX",
    make: "Lexus",
    model: "NX",
    length: {
       cm: 464,
       in: 182.68,
       ft: 15.22
    },
    width: {
       cm: 184.5,
       in: 72.64,
       ft: 6.05
    },
    height: {
       cm: 164.5,
       in: 64.76,
       ft: 5.4
    },
    capacity: {
       cm: 555,
       in: 33868.19,
       ft: 19.6
    }
 },
  {
    fullName: "Lexus RC",
    make: "Lexus",
    model: "RC",
    length: {
       cm: 470,
       in: 185.04,
       ft: 15.42
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 139.5,
       in: 54.92,
       ft: 4.58
    },
    capacity: {
       cm: 340,
       in: 20748.08,
       ft: 12.01
    }
 },
  {
    fullName: "Lexus RX",
    make: "Lexus",
    model: "RX",
    length: {
       cm: 489,
       in: 192.52,
       ft: 16.04
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 168.5,
       in: 66.34,
       ft: 5.53
    },
    capacity: {
       cm: 539,
       in: 32891.81,
       ft: 19.03
    }
 },
  {
    fullName: "Lexus RX L",
    make: "Lexus",
    model: "RX L",
    length: {
       cm: 500,
       in: 196.85,
       ft: 16.4
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 170,
       in: 66.93,
       ft: 5.58
    },
    capacity: {
       cm: 828,
       in: 50527.67,
       ft: 29.24
    }
 },
  {
    fullName: "Lexus UX",
    make: "Lexus",
    model: "UX",
    length: {
       cm: 449.5,
       in: 176.97,
       ft: 14.75
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 154,
       in: 60.63,
       ft: 5.05
    },
    capacity: {
       cm: 320,
       in: 19527.6,
       ft: 11.3
    }
 },
  {
    fullName: "Maserati Ghibli",
    make: "Maserati",
    model: "Ghibli",
    length: {
       cm: 497.1,
       in: 195.71,
       ft: 16.31
    },
    width: {
       cm: 194.5,
       in: 76.57,
       ft: 6.38
    },
    height: {
       cm: 146.1,
       in: 57.52,
       ft: 4.79
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Maserati GranCabrio",
    make: "Maserati",
    model: "GranCabrio",
    length: {
       cm: 492,
       in: 193.7,
       ft: 16.14
    },
    width: {
       cm: 191.5,
       in: 75.39,
       ft: 6.28
    },
    height: {
       cm: 138,
       in: 54.33,
       ft: 4.53
    },
    capacity: {
       cm: 173,
       in: 10557.11,
       ft: 6.11
    }
 },
  {
    fullName: "Maserati GranTurismo",
    make: "Maserati",
    model: "GranTurismo",
    length: {
       cm: 492,
       in: 193.7,
       ft: 16.14
    },
    width: {
       cm: 191.5,
       in: 75.39,
       ft: 6.28
    },
    height: {
       cm: 135.3,
       in: 53.27,
       ft: 4.44
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "Maserati Levante",
    make: "Maserati",
    model: "Levante",
    length: {
       cm: 500.5,
       in: 197.05,
       ft: 16.42
    },
    width: {
       cm: 198.1,
       in: 77.99,
       ft: 6.5
    },
    height: {
       cm: 169.3,
       in: 66.65,
       ft: 5.55
    },
    capacity: {
       cm: 580,
       in: 35393.78,
       ft: 20.48
    }
 },
  {
    fullName: "Maserati Quattroporte",
    make: "Maserati",
    model: "Quattroporte",
    length: {
       cm: 526.4,
       in: 207.24,
       ft: 17.27
    },
    width: {
       cm: 194.8,
       in: 76.69,
       ft: 6.39
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Mazda 2",
    make: "Mazda",
    model: "2",
    length: {
       cm: 407,
       in: 160.24,
       ft: 13.35
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 149.5,
       in: 58.86,
       ft: 4.9
    },
    capacity: {
       cm: 250,
       in: 15255.94,
       ft: 8.83
    }
 },
  {
    fullName: "Mazda 3",
    make: "Mazda",
    model: "3",
    length: {
       cm: 446,
       in: 175.59,
       ft: 14.63
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 358,
       in: 21846.51,
       ft: 12.64
    }
 },
  {
    fullName: "Mazda 3 2003",
    make: "Mazda",
    model: "3 2003",
    length: {
       cm: 441.5,
       in: 173.82,
       ft: 14.48
    },
    width: {
       cm: 175.5,
       in: 69.09,
       ft: 5.76
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 346,
       in: 21114.22,
       ft: 12.22
    }
 },
  {
    fullName: "Mazda 3 2013",
    make: "Mazda",
    model: "3 III 2013",
    length: {
       cm: 446.5,
       in: 175.79,
       ft: 14.65
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 145,
       in: 57.09,
       ft: 4.76
    },
    capacity: {
       cm: 364,
       in: 22212.65,
       ft: 12.85
    }
 },
  {
    fullName: "Mazda 3 Sedan",
    make: "Mazda",
    model: "3 Sedan",
    length: {
       cm: 466,
       in: 183.46,
       ft: 15.29
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 144,
       in: 56.69,
       ft: 4.72
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Mazda 5 2006",
    make: "Mazda",
    model: "5 2006",
    length: {
       cm: 450.5,
       in: 177.36,
       ft: 14.78
    },
    width: {
       cm: 175.5,
       in: 69.09,
       ft: 5.76
    },
    height: {
       cm: 166.5,
       in: 65.55,
       ft: 5.46
    },
    capacity: {
       cm: 426,
       in: 25996.12,
       ft: 15.04
    }
 },
  {
    fullName: "Mazda 5 2006",
    make: "Mazda",
    model: "5 2006",
    length: {
       cm: 450.5,
       in: 177.36,
       ft: 14.78
    },
    width: {
       cm: 175.5,
       in: 69.09,
       ft: 5.76
    },
    height: {
       cm: 166.5,
       in: 65.55,
       ft: 5.46
    },
    capacity: {
       cm: 426,
       in: 25996.12,
       ft: 15.04
    }
 },
  {
    fullName: "Mazda 6",
    make: "Mazda",
    model: "6",
    length: {
       cm: 487,
       in: 191.73,
       ft: 15.98
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 145,
       in: 57.09,
       ft: 4.76
    },
    capacity: {
       cm: 480,
       in: 29291.4,
       ft: 16.95
    }
 },
  {
    fullName: "Mazda 6 Sedan 2002",
    make: "Mazda",
    model: "6 Sedan 2002",
    length: {
       cm: 468,
       in: 184.25,
       ft: 15.35
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 501,
       in: 30572.9,
       ft: 17.69
    }
 },
  {
    fullName: "Mazda 6 Wagon",
    make: "Mazda",
    model: "6 Wagon",
    length: {
       cm: 480.5,
       in: 189.17,
       ft: 15.76
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 522,
       in: 31854.4,
       ft: 18.43
    }
 },
  {
    fullName: "Mazda CX-3",
    make: "Mazda",
    model: "CX-3",
    length: {
       cm: 427.5,
       in: 168.31,
       ft: 14.03
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 153.5,
       in: 60.43,
       ft: 5.04
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Mazda CX-30",
    make: "Mazda",
    model: "CX-30",
    length: {
       cm: 439.5,
       in: 173.03,
       ft: 14.42
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 154,
       in: 60.63,
       ft: 5.05
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Mazda CX-5",
    make: "Mazda",
    model: "CX-5",
    length: {
       cm: 455,
       in: 179.13,
       ft: 14.93
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 167.5,
       in: 65.94,
       ft: 5.5
    },
    capacity: {
       cm: 506,
       in: 30878.02,
       ft: 17.87
    }
 },
  {
    fullName: "Mazda CX-5",
    make: "Mazda",
    model: "CX-5",
    length: {
       cm: 455,
       in: 179.13,
       ft: 14.93
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 167.5,
       in: 65.94,
       ft: 5.5
    },
    capacity: {
       cm: 506,
       in: 30878.02,
       ft: 17.87
    }
 },
  {
    fullName: "Mazda MX-5",
    make: "Mazda",
    model: "MX-5",
    length: {
       cm: 391.5,
       in: 154.13,
       ft: 12.84
    },
    width: {
       cm: 173.5,
       in: 68.31,
       ft: 5.69
    },
    height: {
       cm: 123,
       in: 48.43,
       ft: 4.04
    },
    capacity: {
       cm: 130,
       in: 7933.09,
       ft: 4.59
    }
 },
  {
    fullName: "Mercedes Benz C Class 180 2000",
    make: "Mercedes",
    model: "Benz W203 C Class 180 2000",
    length: {
       cm: 453,
       in: 178.35,
       ft: 14.86
    },
    width: {
       cm: 173,
       in: 68.11,
       ft: 5.68
    },
    height: {
       cm: 143,
       in: 56.3,
       ft: 4.69
    },
    capacity: {
       cm: 455,
       in: 27765.81,
       ft: 16.07
    }
 },
  {
    fullName: "Mercedes Benz E Class (W211) 2002",
    make: "Mercedes",
    model: "Benz E Class (W211) 2002",
    length: {
       cm: 285,
       in: 112.2,
       ft: 9.35
    },
    width: {
       cm: 482,
       in: 189.76,
       ft: 15.81
    },
    height: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    capacity: {
       cm: 540,
       in: 32952.83,
       ft: 19.07
    }
 },
  {
    fullName: "Mercedes Benz W204 Class C 2007",
    make: "Mercedes",
    model: "Benz W204 Class C 2007",
    length: {
       cm: 458,
       in: 180.31,
       ft: 15.03
    },
    width: {
       cm: 177,
       in: 69.69,
       ft: 5.81
    },
    height: {
       cm: 145,
       in: 57.09,
       ft: 4.76
    },
    capacity: {
       cm: 475,
       in: 28986.29,
       ft: 16.77
    }
 },
  {
    fullName: "Mercedes-Benz A",
    make: "Mercedes-Benz",
    model: "A",
    length: {
       cm: 441.9,
       in: 173.98,
       ft: 14.5
    },
    width: {
       cm: 179.6,
       in: 70.71,
       ft: 5.89
    },
    height: {
       cm: 144,
       in: 56.69,
       ft: 4.72
    },
    capacity: {
       cm: 370,
       in: 22578.79,
       ft: 13.07
    }
 },
  {
    fullName: "Mercedes-Benz A Sedan",
    make: "Mercedes-Benz",
    model: "A Sedan",
    length: {
       cm: 454.9,
       in: 179.09,
       ft: 14.92
    },
    width: {
       cm: 179.6,
       in: 70.71,
       ft: 5.89
    },
    height: {
       cm: 144.6,
       in: 56.93,
       ft: 4.74
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "Mercedes-Benz AMG GT",
    make: "Mercedes-Benz",
    model: "AMG GT",
    length: {
       cm: 454.4,
       in: 178.9,
       ft: 14.91
    },
    width: {
       cm: 193.9,
       in: 76.34,
       ft: 6.36
    },
    height: {
       cm: 128.7,
       in: 50.67,
       ft: 4.22
    },
    capacity: {
       cm: 175,
       in: 10679.16,
       ft: 6.18
    }
 },
  {
    fullName: "Mercedes-Benz AMG GT 4-door Coupé",
    make: "Mercedes-Benz",
    model: "AMG GT 4-door Coupé",
    length: {
       cm: 505.4,
       in: 198.98,
       ft: 16.58
    },
    width: {
       cm: 187.1,
       in: 73.66,
       ft: 6.14
    },
    height: {
       cm: 144.2,
       in: 56.77,
       ft: 4.73
    },
    capacity: {
       cm: 456,
       in: 27826.83,
       ft: 16.1
    }
 },
  {
    fullName: "Mercedes-Benz B",
    make: "Mercedes-Benz",
    model: "B",
    length: {
       cm: 442.6,
       in: 174.25,
       ft: 14.52
    },
    width: {
       cm: 179.6,
       in: 70.71,
       ft: 5.89
    },
    height: {
       cm: 154.1,
       in: 60.67,
       ft: 5.06
    },
    capacity: {
       cm: 455,
       in: 27765.81,
       ft: 16.07
    }
 },
  {
    fullName: "Mercedes-Benz C",
    make: "Mercedes-Benz",
    model: "C",
    length: {
       cm: 468.6,
       in: 184.49,
       ft: 15.37
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 144.2,
       in: 56.77,
       ft: 4.73
    },
    capacity: {
       cm: 455,
       in: 27765.81,
       ft: 16.07
    }
 },
  {
    fullName: "Mercedes-Benz C Coupe",
    make: "Mercedes-Benz",
    model: "C Coupe",
    length: {
       cm: 468.6,
       in: 184.49,
       ft: 15.37
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 140.5,
       in: 55.31,
       ft: 4.61
    },
    capacity: {
       cm: 400,
       in: 24409.5,
       ft: 14.13
    }
 },
  {
    fullName: "Mercedes-Benz C Estate",
    make: "Mercedes-Benz",
    model: "C Estate",
    length: {
       cm: 470.2,
       in: 185.12,
       ft: 15.43
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 460,
       in: 28070.93,
       ft: 16.24
    }
 },
  {
    fullName: "Mercedes-Benz Citan Tourer",
    make: "Mercedes-Benz",
    model: "Citan Tourer",
    length: {
       cm: 432.1,
       in: 170.12,
       ft: 14.18
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 180.9,
       in: 71.22,
       ft: 5.94
    },
    capacity: {
       cm: 685,
       in: 41801.27,
       ft: 24.19
    }
 },
  {
    fullName: "Mercedes-Benz Citan Tourer xlg",
    make: "Mercedes-Benz",
    model: "Citan Tourer xlg",
    length: {
       cm: 470.5,
       in: 185.24,
       ft: 15.44
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 183.9,
       in: 72.4,
       ft: 6.03
    },
    capacity: {
       cm: 300,
       in: 18307.13,
       ft: 10.59
    }
 },
  {
    fullName: "Mercedes-Benz CLA Coupé",
    make: "Mercedes-Benz",
    model: "CLA Coupé",
    length: {
       cm: 468.8,
       in: 184.57,
       ft: 15.38
    },
    width: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    height: {
       cm: 143.9,
       in: 56.65,
       ft: 4.72
    },
    capacity: {
       cm: 460,
       in: 28070.93,
       ft: 16.24
    }
 },
  {
    fullName: "Mercedes-Benz CLA Shooting Brake",
    make: "Mercedes-Benz",
    model: "CLA Shooting Brake",
    length: {
       cm: 468.8,
       in: 184.57,
       ft: 15.38
    },
    width: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    height: {
       cm: 144.2,
       in: 56.77,
       ft: 4.73
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Mercedes-Benz CLS Coupé",
    make: "Mercedes-Benz",
    model: "CLS Coupé",
    length: {
       cm: 499.6,
       in: 196.69,
       ft: 16.39
    },
    width: {
       cm: 189.6,
       in: 74.65,
       ft: 6.22
    },
    height: {
       cm: 143.6,
       in: 56.54,
       ft: 4.71
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "Mercedes-Benz E",
    make: "Mercedes-Benz",
    model: "E",
    length: {
       cm: 492.3,
       in: 193.82,
       ft: 16.15
    },
    width: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    height: {
       cm: 146.8,
       in: 57.8,
       ft: 4.82
    },
    capacity: {
       cm: 540,
       in: 32952.83,
       ft: 19.07
    }
 },
  {
    fullName: "Mercedes-Benz E All-Terrain",
    make: "Mercedes-Benz",
    model: "E All-Terrain",
    length: {
       cm: 494.7,
       in: 194.76,
       ft: 16.23
    },
    width: {
       cm: 186.1,
       in: 73.27,
       ft: 6.11
    },
    height: {
       cm: 149.7,
       in: 58.94,
       ft: 4.91
    },
    capacity: {
       cm: 670,
       in: 40885.92,
       ft: 23.66
    }
 },
  {
    fullName: "Mercedes-Benz E Coupé",
    make: "Mercedes-Benz",
    model: "E Coupé",
    length: {
       cm: 482.6,
       in: 190,
       ft: 15.83
    },
    width: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    height: {
       cm: 143,
       in: 56.3,
       ft: 4.69
    },
    capacity: {
       cm: 425,
       in: 25935.1,
       ft: 15.01
    }
 },
  {
    fullName: "Mercedes-Benz E Estate",
    make: "Mercedes-Benz",
    model: "E Estate",
    length: {
       cm: 493.3,
       in: 194.21,
       ft: 16.18
    },
    width: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    height: {
       cm: 147.5,
       in: 58.07,
       ft: 4.84
    },
    capacity: {
       cm: 670,
       in: 40885.92,
       ft: 23.66
    }
 },
  {
    fullName: "Mercedes-Benz EQC",
    make: "Mercedes-Benz",
    model: "EQC",
    length: {
       cm: 476.2,
       in: 187.48,
       ft: 15.62
    },
    width: {
       cm: 188.4,
       in: 74.17,
       ft: 6.18
    },
    height: {
       cm: 162.4,
       in: 63.94,
       ft: 5.33
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Mercedes-Benz G",
    make: "Mercedes-Benz",
    model: "G",
    length: {
       cm: 481.7,
       in: 189.65,
       ft: 15.8
    },
    width: {
       cm: 193.1,
       in: 76.02,
       ft: 6.34
    },
    height: {
       cm: 196.9,
       in: 77.52,
       ft: 6.46
    },
    capacity: {
       cm: 487,
       in: 29718.57,
       ft: 17.2
    }
 },
  {
    fullName: "Mercedes-Benz GLA",
    make: "Mercedes-Benz",
    model: "GLA",
    length: {
       cm: 441,
       in: 173.62,
       ft: 14.47
    },
    width: {
       cm: 183.4,
       in: 72.2,
       ft: 6.02
    },
    height: {
       cm: 161.6,
       in: 63.62,
       ft: 5.3
    },
    capacity: {
       cm: 435,
       in: 26545.34,
       ft: 15.36
    }
 },
  {
    fullName: "Mercedes-Benz GLB",
    make: "Mercedes-Benz",
    model: "GLB",
    length: {
       cm: 463.4,
       in: 182.44,
       ft: 15.2
    },
    width: {
       cm: 183.4,
       in: 72.2,
       ft: 6.02
    },
    height: {
       cm: 165.8,
       in: 65.28,
       ft: 5.44
    },
    capacity: {
       cm: 570,
       in: 34783.54,
       ft: 20.13
    }
 },
  {
    fullName: "Mercedes-Benz GLC Coupé",
    make: "Mercedes-Benz",
    model: "GLC Coupé",
    length: {
       cm: 473.1,
       in: 186.26,
       ft: 15.52
    },
    width: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    height: {
       cm: 160,
       in: 62.99,
       ft: 5.25
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Mercedes-Benz GLC SUV",
    make: "Mercedes-Benz",
    model: "GLC SUV",
    length: {
       cm: 465.5,
       in: 183.27,
       ft: 15.27
    },
    width: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    height: {
       cm: 164.4,
       in: 64.72,
       ft: 5.39
    },
    capacity: {
       cm: 550,
       in: 33563.07,
       ft: 19.42
    }
 },
  {
    fullName: "Mercedes-Benz GLE Coupé",
    make: "Mercedes-Benz",
    model: "GLE Coupé",
    length: {
       cm: 490,
       in: 192.91,
       ft: 16.08
    },
    width: {
       cm: 200.3,
       in: 78.86,
       ft: 6.57
    },
    height: {
       cm: 173.1,
       in: 68.15,
       ft: 5.68
    },
    capacity: {
       cm: 650,
       in: 39665.44,
       ft: 22.95
    }
 },
  {
    fullName: "Mercedes-Benz GLE SUV",
    make: "Mercedes-Benz",
    model: "GLE SUV",
    length: {
       cm: 492.4,
       in: 193.86,
       ft: 16.15
    },
    width: {
       cm: 194.7,
       in: 76.65,
       ft: 6.39
    },
    height: {
       cm: 179.7,
       in: 70.75,
       ft: 5.9
    },
    capacity: {
       cm: 630,
       in: 38444.97,
       ft: 22.25
    }
 },
  {
    fullName: "Mercedes-Benz GLS",
    make: "Mercedes-Benz",
    model: "GLS",
    length: {
       cm: 513,
       in: 201.97,
       ft: 16.83
    },
    width: {
       cm: 193.4,
       in: 76.14,
       ft: 6.35
    },
    height: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    capacity: {
       cm: 680,
       in: 41496.16,
       ft: 24.01
    }
 },
  {
    fullName: "Mercedes-Benz S",
    make: "Mercedes-Benz",
    model: "S",
    length: {
       cm: 514.1,
       in: 202.4,
       ft: 16.87
    },
    width: {
       cm: 190.5,
       in: 75,
       ft: 6.25
    },
    height: {
       cm: 149.8,
       in: 58.98,
       ft: 4.91
    },
    capacity: {
       cm: 510,
       in: 31122.12,
       ft: 18.01
    }
 },
  {
    fullName: "Mercedes-Benz S Coupé",
    make: "Mercedes-Benz",
    model: "S Coupé",
    length: {
       cm: 503.4,
       in: 198.19,
       ft: 16.52
    },
    width: {
       cm: 189.9,
       in: 74.76,
       ft: 6.23
    },
    height: {
       cm: 141.4,
       in: 55.67,
       ft: 4.64
    },
    capacity: {
       cm: 400,
       in: 24409.5,
       ft: 14.13
    }
 },
  {
    fullName: "Mercedes-Benz S lg",
    make: "Mercedes-Benz",
    model: "S lg",
    length: {
       cm: 527.1,
       in: 207.52,
       ft: 17.29
    },
    width: {
       cm: 190.5,
       in: 75,
       ft: 6.25
    },
    height: {
       cm: 149.6,
       in: 58.9,
       ft: 4.91
    },
    capacity: {
       cm: 510,
       in: 31122.12,
       ft: 18.01
    }
 },
  {
    fullName: "Mercedes-Benz SL",
    make: "Mercedes-Benz",
    model: "SL",
    length: {
       cm: 463.1,
       in: 182.32,
       ft: 15.19
    },
    width: {
       cm: 187.7,
       in: 73.9,
       ft: 6.16
    },
    height: {
       cm: 131.5,
       in: 51.77,
       ft: 4.31
    },
    capacity: {
       cm: 345,
       in: 21053.2,
       ft: 12.18
    }
 },
  {
    fullName: "Mercedes-Benz SLC",
    make: "Mercedes-Benz",
    model: "SLC",
    length: {
       cm: 413.3,
       in: 162.72,
       ft: 13.56
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 130.1,
       in: 51.22,
       ft: 4.27
    },
    capacity: {
       cm: 225,
       in: 13730.35,
       ft: 7.95
    }
 },
  {
    fullName: "Mercedes-Benz V ct",
    make: "Mercedes-Benz",
    model: "V ct",
    length: {
       cm: 489.5,
       in: 192.72,
       ft: 16.06
    },
    width: {
       cm: 192.8,
       in: 75.91,
       ft: 6.33
    },
    height: {
       cm: 188,
       in: 74.02,
       ft: 6.17
    },
    capacity: {
       cm: 2400,
       in: 146457.02,
       ft: 84.76
    }
 },
  {
    fullName: "Mercedes-Benz V lg",
    make: "Mercedes-Benz",
    model: "V lg",
    length: {
       cm: 514,
       in: 202.36,
       ft: 16.86
    },
    width: {
       cm: 192.8,
       in: 75.91,
       ft: 6.33
    },
    height: {
       cm: 188,
       in: 74.02,
       ft: 6.17
    },
    capacity: {
       cm: 2820,
       in: 172087,
       ft: 99.59
    }
 },
  {
    fullName: "Mercedes-Benz V xlg",
    make: "Mercedes-Benz",
    model: "V xlg",
    length: {
       cm: 537,
       in: 211.42,
       ft: 17.62
    },
    width: {
       cm: 192.8,
       in: 75.91,
       ft: 6.33
    },
    height: {
       cm: 188,
       in: 74.02,
       ft: 6.17
    },
    capacity: {
       cm: 3200,
       in: 195276.03,
       ft: 113.01
    }
 },
  {
    fullName: "Mercedes-Benz X",
    make: "Mercedes-Benz",
    model: "X",
    length: {
       cm: 534,
       in: 210.24,
       ft: 17.52
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 181.9,
       in: 71.61,
       ft: 5.97
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "MINI 3-door",
    make: "MINI",
    model: "3-door",
    length: {
       cm: 382.1,
       in: 150.43,
       ft: 12.54
    },
    width: {
       cm: 172.7,
       in: 67.99,
       ft: 5.67
    },
    height: {
       cm: 141.4,
       in: 55.67,
       ft: 4.64
    },
    capacity: {
       cm: 211,
       in: 12876.01,
       ft: 7.45
    }
 },
  {
    fullName: "MINI 5-door",
    make: "MINI",
    model: "5-door",
    length: {
       cm: 398.2,
       in: 156.77,
       ft: 13.06
    },
    width: {
       cm: 172.7,
       in: 67.99,
       ft: 5.67
    },
    height: {
       cm: 142.5,
       in: 56.1,
       ft: 4.68
    },
    capacity: {
       cm: 278,
       in: 16964.61,
       ft: 9.82
    }
 },
  {
    fullName: "MINI Cabrio",
    make: "MINI",
    model: "Cabrio",
    length: {
       cm: 382.1,
       in: 150.43,
       ft: 12.54
    },
    width: {
       cm: 172.7,
       in: 67.99,
       ft: 5.67
    },
    height: {
       cm: 141.5,
       in: 55.71,
       ft: 4.64
    },
    capacity: {
       cm: 160,
       in: 9763.8,
       ft: 5.65
    }
 },
  {
    fullName: "MINI Clubman",
    make: "MINI",
    model: "Clubman",
    length: {
       cm: 425.3,
       in: 167.44,
       ft: 13.95
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 144.1,
       in: 56.73,
       ft: 4.73
    },
    capacity: {
       cm: 360,
       in: 21968.55,
       ft: 12.71
    }
 },
  {
    fullName: "MINI Countryman",
    make: "MINI",
    model: "Countryman",
    length: {
       cm: 429.9,
       in: 169.25,
       ft: 14.1
    },
    width: {
       cm: 182.2,
       in: 71.73,
       ft: 5.98
    },
    height: {
       cm: 155.7,
       in: 61.3,
       ft: 5.11
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Mini One 1.6 2001",
    make: "Mini",
    model: "One 1.6 2001",
    length: {
       cm: 362.6,
       in: 142.76,
       ft: 11.9
    },
    width: {
       cm: 168.8,
       in: 66.46,
       ft: 5.54
    },
    height: {
       cm: 140.8,
       in: 55.43,
       ft: 4.62
    },
    capacity: {
       cm: 150,
       in: 9153.56,
       ft: 5.3
    }
 },
  {
    fullName: "Mitsubishi ASX",
    make: "Mitsubishi",
    model: "ASX",
    length: {
       cm: 436.5,
       in: 171.85,
       ft: 14.32
    },
    width: {
       cm: 177,
       in: 69.69,
       ft: 5.81
    },
    height: {
       cm: 164,
       in: 64.57,
       ft: 5.38
    },
    capacity: {
       cm: 406,
       in: 24775.65,
       ft: 14.34
    }
 },
  {
    fullName: "Mitsubishi Eclipse Cross",
    make: "Mitsubishi",
    model: "Eclipse Cross",
    length: {
       cm: 440.5,
       in: 173.43,
       ft: 14.45
    },
    width: {
       cm: 180.5,
       in: 71.06,
       ft: 5.92
    },
    height: {
       cm: 168.5,
       in: 66.34,
       ft: 5.53
    },
    capacity: {
       cm: 378,
       in: 23066.98,
       ft: 13.35
    }
 },
  {
    fullName: "Mitsubishi L200",
    make: "Mitsubishi",
    model: "L200",
    length: {
       cm: 522.5,
       in: 205.71,
       ft: 17.14
    },
    width: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    height: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Mitsubishi Outlander",
    make: "Mitsubishi",
    model: "Outlander",
    length: {
       cm: 469.5,
       in: 184.84,
       ft: 15.4
    },
    width: {
       cm: 181,
       in: 71.26,
       ft: 5.94
    },
    height: {
       cm: 171,
       in: 67.32,
       ft: 5.61
    },
    capacity: {
       cm: 498,
       in: 30389.83,
       ft: 17.59
    }
 },
  {
    fullName: "Mitsubishi Space Star",
    make: "Mitsubishi",
    model: "Space Star",
    length: {
       cm: 379.5,
       in: 149.41,
       ft: 12.45
    },
    width: {
       cm: 166.5,
       in: 65.55,
       ft: 5.46
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 235,
       in: 14340.58,
       ft: 8.3
    }
 },
  {
    fullName: "Nissan 370Z",
    make: "Nissan",
    model: "370Z",
    length: {
       cm: 425,
       in: 167.32,
       ft: 13.94
    },
    width: {
       cm: 184.5,
       in: 72.64,
       ft: 6.05
    },
    height: {
       cm: 131.5,
       in: 51.77,
       ft: 4.31
    },
    capacity: {
       cm: 235,
       in: 14340.58,
       ft: 8.3
    }
 },
  {
    fullName: "Nissan e-NV200 Evalia",
    make: "Nissan",
    model: "e-NV200 Evalia",
    length: {
       cm: 456,
       in: 179.53,
       ft: 14.96
    },
    width: {
       cm: 175.5,
       in: 69.09,
       ft: 5.76
    },
    height: {
       cm: 185.8,
       in: 73.15,
       ft: 6.1
    },
    capacity: {
       cm: 1000,
       in: 61023.76,
       ft: 35.32
    }
 },
  {
    fullName: "Nissan GT-R",
    make: "Nissan",
    model: "GT-R",
    length: {
       cm: 471,
       in: 185.43,
       ft: 15.45
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 137,
       in: 53.94,
       ft: 4.49
    },
    capacity: {
       cm: 315,
       in: 19222.48,
       ft: 11.12
    }
 },
  {
    fullName: "Nissan Juke",
    make: "Nissan",
    model: "Juke",
    length: {
       cm: 421,
       in: 165.75,
       ft: 13.81
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 159.5,
       in: 62.8,
       ft: 5.23
    },
    capacity: {
       cm: 422,
       in: 25752.03,
       ft: 14.9
    }
 },
  {
    fullName: "Nissan Leaf",
    make: "Nissan",
    model: "Leaf",
    length: {
       cm: 448,
       in: 176.38,
       ft: 14.7
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 154,
       in: 60.63,
       ft: 5.05
    },
    capacity: {
       cm: 435,
       in: 26545.34,
       ft: 15.36
    }
 },
  {
    fullName: "Nissan Leaf 2010",
    make: "Nissan",
    model: "Leaf 2010",
    length: {
       cm: 445,
       in: 175.2,
       ft: 14.6
    },
    width: {
       cm: 177,
       in: 69.69,
       ft: 5.81
    },
    height: {
       cm: 155,
       in: 61.02,
       ft: 5.09
    },
    capacity: {
       cm: 435,
       in: 26545.34,
       ft: 15.36
    }
 },
  {
    fullName: "Nissan Micra",
    make: "Nissan",
    model: "Micra",
    length: {
       cm: 399.5,
       in: 157.28,
       ft: 13.11
    },
    width: {
       cm: 174.3,
       in: 68.62,
       ft: 5.72
    },
    height: {
       cm: 145.2,
       in: 57.17,
       ft: 4.76
    },
    capacity: {
       cm: 300,
       in: 18307.13,
       ft: 10.59
    }
 },
  {
    fullName: "Nissan Micra K12 2003",
    make: "Nissan",
    model: "Micra K12 2003",
    length: {
       cm: 371.9,
       in: 146.42,
       ft: 12.2
    },
    width: {
       cm: 166,
       in: 65.35,
       ft: 5.45
    },
    height: {
       cm: 154,
       in: 60.63,
       ft: 5.05
    },
    capacity: {
       cm: 251,
       in: 15316.96,
       ft: 8.86
    }
 },
  {
    fullName: "Nissan NP300 Navara",
    make: "Nissan",
    model: "NP300 Navara",
    length: {
       cm: 533,
       in: 209.84,
       ft: 17.49
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Nissan Qashqai",
    make: "Nissan",
    model: "Qashqai",
    length: {
       cm: 439.4,
       in: 172.99,
       ft: 14.42
    },
    width: {
       cm: 180.6,
       in: 71.1,
       ft: 5.93
    },
    height: {
       cm: 159,
       in: 62.6,
       ft: 5.22
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Nissan Qashqai II 2014",
    make: "Nissan",
    model: "Qashqai II  2014",
    length: {
       cm: 437.7,
       in: 172.32,
       ft: 14.36
    },
    width: {
       cm: 180.6,
       in: 71.1,
       ft: 5.93
    },
    height: {
       cm: 159,
       in: 62.6,
       ft: 5.22
    },
    capacity: {
       cm: 439,
       in: 26789.43,
       ft: 15.5
    }
 },
  {
    fullName: "Nissan Qashqai J10 2007",
    make: "Nissan",
    model: "Qashqai J10 2007",
    length: {
       cm: 432,
       in: 170.08,
       ft: 14.17
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 162,
       in: 63.78,
       ft: 5.31
    },
    capacity: {
       cm: 400,
       in: 24409.5,
       ft: 14.13
    }
 },
  {
    fullName: "Nissan X-Trail",
    make: "Nissan",
    model: "X-Trail",
    length: {
       cm: 469,
       in: 184.65,
       ft: 15.39
    },
    width: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    height: {
       cm: 171,
       in: 67.32,
       ft: 5.61
    },
    capacity: {
       cm: 565,
       in: 34478.42,
       ft: 19.95
    }
 },
  {
    fullName: "Opel ADAM",
    make: "Opel",
    model: "ADAM",
    length: {
       cm: 369.8,
       in: 145.59,
       ft: 12.13
    },
    width: {
       cm: 172,
       in: 67.72,
       ft: 5.64
    },
    height: {
       cm: 148.4,
       in: 58.43,
       ft: 4.87
    },
    capacity: {
       cm: 170,
       in: 10374.04,
       ft: 6
    }
 },
  {
    fullName: "Opel Ampera 2011",
    make: "Opel",
    model: "Ampera 2011",
    length: {
       cm: 449.8,
       in: 177.09,
       ft: 14.76
    },
    width: {
       cm: 178.7,
       in: 70.35,
       ft: 5.86
    },
    height: {
       cm: 160,
       in: 62.99,
       ft: 5.25
    },
    capacity: {
       cm: 310,
       in: 18917.37,
       ft: 10.95
    }
 },
  {
    fullName: "Opel Astra",
    make: "Opel",
    model: "Astra",
    length: {
       cm: 437,
       in: 172.05,
       ft: 14.34
    },
    width: {
       cm: 180.9,
       in: 71.22,
       ft: 5.94
    },
    height: {
       cm: 148.5,
       in: 58.46,
       ft: 4.87
    },
    capacity: {
       cm: 370,
       in: 22578.79,
       ft: 13.07
    }
 },
  {
    fullName: "Opel Astra H 2004",
    make: "Opel",
    model: "Astra H 2004",
    length: {
       cm: 424.9,
       in: 167.28,
       ft: 13.94
    },
    width: {
       cm: 175.3,
       in: 69.02,
       ft: 5.75
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Opel Astra J 2009",
    make: "Opel",
    model: "Astra J 2009",
    length: {
       cm: 441.9,
       in: 173.98,
       ft: 14.5
    },
    width: {
       cm: 181.4,
       in: 71.42,
       ft: 5.95
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 370,
       in: 22578.79,
       ft: 13.07
    }
 },
  {
    fullName: "Opel Astra K 2015",
    make: "Opel",
    model: "Astra K 2015",
    length: {
       cm: 437,
       in: 172.05,
       ft: 14.34
    },
    width: {
       cm: 187.1,
       in: 73.66,
       ft: 6.14
    },
    height: {
       cm: 148.5,
       in: 58.46,
       ft: 4.87
    },
    capacity: {
       cm: 370,
       in: 22578.79,
       ft: 13.07
    }
 },
  {
    fullName: "Opel Astra Sports Tourer",
    make: "Opel",
    model: "Astra Sports Tourer",
    length: {
       cm: 470.2,
       in: 185.12,
       ft: 15.43
    },
    width: {
       cm: 180.9,
       in: 71.22,
       ft: 5.94
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 540,
       in: 32952.83,
       ft: 19.07
    }
 },
  {
    fullName: "Opel Combo Life",
    make: "Opel",
    model: "Combo Life",
    length: {
       cm: 440.3,
       in: 173.35,
       ft: 14.45
    },
    width: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    height: {
       cm: 184.1,
       in: 72.48,
       ft: 6.04
    },
    capacity: {
       cm: 597,
       in: 36431.18,
       ft: 21.08
    }
 },
  {
    fullName: "Opel Combo Life L2",
    make: "Opel",
    model: "Combo Life L2",
    length: {
       cm: 475.3,
       in: 187.13,
       ft: 15.59
    },
    width: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    height: {
       cm: 188,
       in: 74.02,
       ft: 6.17
    },
    capacity: {
       cm: 850,
       in: 51870.2,
       ft: 30.02
    }
 },
  {
    fullName: "Opel Corsa",
    make: "Opel",
    model: "Corsa",
    length: {
       cm: 406,
       in: 159.84,
       ft: 13.32
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 309,
       in: 18856.34,
       ft: 10.91
    }
 },
  {
    fullName: "Opel Corsa C 1.0 2000",
    make: "Opel",
    model: "Corsa C 1.0 2000",
    length: {
       cm: 383.9,
       in: 151.14,
       ft: 12.6
    },
    width: {
       cm: 164.6,
       in: 64.8,
       ft: 5.4
    },
    height: {
       cm: 144,
       in: 56.69,
       ft: 4.72
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "Opel Corsa D 2006",
    make: "Opel",
    model: "Corsa D 2006",
    length: {
       cm: 399.9,
       in: 157.44,
       ft: 13.12
    },
    width: {
       cm: 173.9,
       in: 68.46,
       ft: 5.71
    },
    height: {
       cm: 148.8,
       in: 58.58,
       ft: 4.88
    },
    capacity: {
       cm: 285,
       in: 17391.77,
       ft: 10.06
    }
 },
  {
    fullName: "Opel Crossland X",
    make: "Opel",
    model: "Crossland X",
    length: {
       cm: 421.2,
       in: 165.83,
       ft: 13.82
    },
    width: {
       cm: 176.5,
       in: 69.49,
       ft: 5.79
    },
    height: {
       cm: 160.5,
       in: 63.19,
       ft: 5.27
    },
    capacity: {
       cm: 410,
       in: 25019.74,
       ft: 14.48
    }
 },
  {
    fullName: "Opel Grandland X",
    make: "Opel",
    model: "Grandland X",
    length: {
       cm: 447.7,
       in: 176.26,
       ft: 14.69
    },
    width: {
       cm: 185.6,
       in: 73.07,
       ft: 6.09
    },
    height: {
       cm: 160.9,
       in: 63.35,
       ft: 5.28
    },
    capacity: {
       cm: 514,
       in: 31366.21,
       ft: 18.15
    }
 },
  {
    fullName: "Opel Insignia Cosmo 2008",
    make: "Opel",
    model: "Insignia Cosmo 2008",
    length: {
       cm: 483,
       in: 190.16,
       ft: 15.85
    },
    width: {
       cm: 185.6,
       in: 73.07,
       ft: 6.09
    },
    height: {
       cm: 148.3,
       in: 58.39,
       ft: 4.87
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Opel Insignia Grand Sport",
    make: "Opel",
    model: "Insignia Grand Sport",
    length: {
       cm: 489.7,
       in: 192.8,
       ft: 16.07
    },
    width: {
       cm: 186.3,
       in: 73.35,
       ft: 6.11
    },
    height: {
       cm: 145.5,
       in: 57.28,
       ft: 4.77
    },
    capacity: {
       cm: 490,
       in: 29901.64,
       ft: 17.3
    }
 },
  {
    fullName: "Opel Insignia Sports Tourer",
    make: "Opel",
    model: "Insignia Sports Tourer",
    length: {
       cm: 498.6,
       in: 196.3,
       ft: 16.36
    },
    width: {
       cm: 186.3,
       in: 73.35,
       ft: 6.11
    },
    height: {
       cm: 150,
       in: 59.06,
       ft: 4.92
    },
    capacity: {
       cm: 560,
       in: 34173.31,
       ft: 19.78
    }
 },
  {
    fullName: "Opel Karl",
    make: "Opel",
    model: "Karl",
    length: {
       cm: 367.5,
       in: 144.69,
       ft: 12.06
    },
    width: {
       cm: 160.4,
       in: 63.15,
       ft: 5.26
    },
    height: {
       cm: 147.6,
       in: 58.11,
       ft: 4.84
    },
    capacity: {
       cm: 215,
       in: 13120.11,
       ft: 7.59
    }
 },
  {
    fullName: "Opel Meriva A 1.7 2003",
    make: "Opel",
    model: "Meriva A 1.7 2003",
    length: {
       cm: 405.2,
       in: 159.53,
       ft: 13.29
    },
    width: {
       cm: 169.4,
       in: 66.69,
       ft: 5.56
    },
    height: {
       cm: 162.4,
       in: 63.94,
       ft: 5.33
    },
    capacity: {
       cm: 360,
       in: 21968.55,
       ft: 12.71
    }
 },
  {
    fullName: "Opel Meriva B 2010",
    make: "Opel",
    model: "Meriva B 2010",
    length: {
       cm: 428.8,
       in: 168.82,
       ft: 14.07
    },
    width: {
       cm: 181.2,
       in: 71.34,
       ft: 5.94
    },
    height: {
       cm: 161.5,
       in: 63.58,
       ft: 5.3
    },
    capacity: {
       cm: 435,
       in: 26545.34,
       ft: 15.36
    }
 },
  {
    fullName: "Opel Mokka X",
    make: "Opel",
    model: "Mokka X",
    length: {
       cm: 427.5,
       in: 168.31,
       ft: 14.03
    },
    width: {
       cm: 178.1,
       in: 70.12,
       ft: 5.84
    },
    height: {
       cm: 165.8,
       in: 65.28,
       ft: 5.44
    },
    capacity: {
       cm: 356,
       in: 21724.46,
       ft: 12.57
    }
 },
  {
    fullName: "Opel Vectra C 2002",
    make: "Opel",
    model: "Vectra C 2002",
    length: {
       cm: 460,
       in: 181.1,
       ft: 15.09
    },
    width: {
       cm: 180.1,
       in: 70.91,
       ft: 5.91
    },
    height: {
       cm: 146.1,
       in: 57.52,
       ft: 4.79
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Opel Zafira",
    make: "Opel",
    model: "Zafira",
    length: {
       cm: 466.6,
       in: 183.7,
       ft: 15.31
    },
    width: {
       cm: 192.8,
       in: 75.91,
       ft: 6.33
    },
    height: {
       cm: 166,
       in: 65.35,
       ft: 5.45
    },
    capacity: {
       cm: 710,
       in: 43326.87,
       ft: 25.07
    }
 },
  {
    fullName: "Opel Zafira Life L",
    make: "Opel",
    model: "Zafira Life L",
    length: {
       cm: 530.6,
       in: 208.9,
       ft: 17.41
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2932,
       in: 178921.66,
       ft: 103.54
    }
 },
  {
    fullName: "Opel Zafira Life M",
    make: "Opel",
    model: "Zafira Life M",
    length: {
       cm: 495.6,
       in: 195.12,
       ft: 16.26
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2381,
       in: 145297.57,
       ft: 84.09
    }
 },
  {
    fullName: "Opel Zafira Life S",
    make: "Opel",
    model: "Zafira Life S",
    length: {
       cm: 460.6,
       in: 181.34,
       ft: 15.11
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 190.5,
       in: 75,
       ft: 6.25
    },
    capacity: {
       cm: 1978,
       in: 120705,
       ft: 69.85
    }
 },
  {
    fullName: "Peugeot 107 2009",
    make: "Peugeot",
    model: "107 2009",
    length: {
       cm: 343,
       in: 135.04,
       ft: 11.25
    },
    width: {
       cm: 163,
       in: 64.17,
       ft: 5.35
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 139,
       in: 8482.3,
       ft: 4.91
    }
 },
  {
    fullName: "Peugeot 107 2012",
    make: "Peugeot",
    model: "107 2012",
    length: {
       cm: 343,
       in: 135.04,
       ft: 11.25
    },
    width: {
       cm: 163,
       in: 64.17,
       ft: 5.35
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 139,
       in: 8482.3,
       ft: 4.91
    }
 },
  {
    fullName: "Peugeot 108",
    make: "Peugeot",
    model: "108",
    length: {
       cm: 347,
       in: 136.61,
       ft: 11.38
    },
    width: {
       cm: 162,
       in: 63.78,
       ft: 5.31
    },
    height: {
       cm: 145,
       in: 57.09,
       ft: 4.76
    },
    capacity: {
       cm: 196,
       in: 11960.66,
       ft: 6.92
    }
 },
  {
    fullName: "Peugeot 2008",
    make: "Peugeot",
    model: "2008",
    length: {
       cm: 430,
       in: 169.29,
       ft: 14.11
    },
    width: {
       cm: 177,
       in: 69.69,
       ft: 5.81
    },
    height: {
       cm: 155,
       in: 61.02,
       ft: 5.09
    },
    capacity: {
       cm: 434,
       in: 26484.31,
       ft: 15.33
    }
 },
  {
    fullName: "Peugeot 2008 2013",
    make: "Peugeot",
    model: "2008 2013",
    length: {
       cm: 415.9,
       in: 163.74,
       ft: 13.65
    },
    width: {
       cm: 173.9,
       in: 68.46,
       ft: 5.71
    },
    height: {
       cm: 155.6,
       in: 61.26,
       ft: 5.1
    },
    capacity: {
       cm: 360,
       in: 21968.55,
       ft: 12.71
    }
 },
  {
    fullName: "Peugeot 2008 2016",
    make: "Peugeot",
    model: "2008 2016",
    length: {
       cm: 415.9,
       in: 163.74,
       ft: 13.65
    },
    width: {
       cm: 173.9,
       in: 68.46,
       ft: 5.71
    },
    height: {
       cm: 155.6,
       in: 61.26,
       ft: 5.1
    },
    capacity: {
       cm: 360,
       in: 21968.55,
       ft: 12.71
    }
 },
  {
    fullName: "Peugeot 206 plus 2009",
    make: "Peugeot",
    model: "206 plus 2009",
    length: {
       cm: 387.2,
       in: 152.44,
       ft: 12.7
    },
    width: {
       cm: 166.9,
       in: 65.71,
       ft: 5.48
    },
    height: {
       cm: 144.6,
       in: 56.93,
       ft: 4.74
    },
    capacity: {
       cm: 245,
       in: 14950.82,
       ft: 8.65
    }
 },
  {
    fullName: "Peugeot 207 2006",
    make: "Peugeot",
    model: "207 2006",
    length: {
       cm: 403,
       in: 158.66,
       ft: 13.22
    },
    width: {
       cm: 172,
       in: 67.72,
       ft: 5.64
    },
    height: {
       cm: 147.2,
       in: 57.95,
       ft: 4.83
    },
    capacity: {
       cm: 270,
       in: 16476.41,
       ft: 9.54
    }
 },
  {
    fullName: "Peugeot 207 SW 2009",
    make: "Peugeot",
    model: "207 SW 2009",
    length: {
       cm: 416.4,
       in: 163.94,
       ft: 13.66
    },
    width: {
       cm: 174.8,
       in: 68.82,
       ft: 5.73
    },
    height: {
       cm: 152.7,
       in: 60.12,
       ft: 5.01
    },
    capacity: {
       cm: 337,
       in: 20565.01,
       ft: 11.9
    }
 },
  {
    fullName: "Peugeot 208",
    make: "Peugeot",
    model: "208",
    length: {
       cm: 405.5,
       in: 159.65,
       ft: 13.3
    },
    width: {
       cm: 174.5,
       in: 68.7,
       ft: 5.73
    },
    height: {
       cm: 143,
       in: 56.3,
       ft: 4.69
    },
    capacity: {
       cm: 265,
       in: 16171.3,
       ft: 9.36
    }
 },
  {
    fullName: "Peugeot 208 2012",
    make: "Peugeot",
    model: "208 2012",
    length: {
       cm: 396.2,
       in: 155.98,
       ft: 13
    },
    width: {
       cm: 173.9,
       in: 68.46,
       ft: 5.71
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 285,
       in: 17391.77,
       ft: 10.06
    }
 },
  {
    fullName: "Peugeot 208 2012",
    make: "Peugeot",
    model: "208 2012",
    length: {
       cm: 396.2,
       in: 155.98,
       ft: 13
    },
    width: {
       cm: 173.9,
       in: 68.46,
       ft: 5.71
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 285,
       in: 17391.77,
       ft: 10.06
    }
 },
  {
    fullName: "Peugeot 208 2015",
    make: "Peugeot",
    model: "208 2015",
    length: {
       cm: 396.2,
       in: 155.98,
       ft: 13
    },
    width: {
       cm: 173.9,
       in: 68.46,
       ft: 5.71
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 285,
       in: 17391.77,
       ft: 10.06
    }
 },
  {
    fullName: "Peugeot 3008",
    make: "Peugeot",
    model: "3008",
    length: {
       cm: 444.7,
       in: 175.08,
       ft: 14.59
    },
    width: {
       cm: 184.1,
       in: 72.48,
       ft: 6.04
    },
    height: {
       cm: 162.4,
       in: 63.94,
       ft: 5.33
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "Peugeot 3008 2009",
    make: "Peugeot",
    model: "3008 2009",
    length: {
       cm: 436.5,
       in: 171.85,
       ft: 14.32
    },
    width: {
       cm: 183.7,
       in: 72.32,
       ft: 6.03
    },
    height: {
       cm: 163.9,
       in: 64.53,
       ft: 5.38
    },
    capacity: {
       cm: 432,
       in: 26362.26,
       ft: 15.26
    }
 },
  {
    fullName: "Peugeot 3008 2013",
    make: "Peugeot",
    model: "3008 2013",
    length: {
       cm: 436.5,
       in: 171.85,
       ft: 14.32
    },
    width: {
       cm: 183.7,
       in: 72.32,
       ft: 6.03
    },
    height: {
       cm: 163.5,
       in: 64.37,
       ft: 5.36
    },
    capacity: {
       cm: 432,
       in: 26362.26,
       ft: 15.26
    }
 },
  {
    fullName: "Peugeot 307 2001",
    make: "Peugeot",
    model: "307 2001",
    length: {
       cm: 421.2,
       in: 165.83,
       ft: 13.82
    },
    width: {
       cm: 174.6,
       in: 68.74,
       ft: 5.73
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 341,
       in: 20809.1,
       ft: 12.04
    }
 },
  {
    fullName: "Peugeot 308",
    make: "Peugeot",
    model: "308",
    length: {
       cm: 425.3,
       in: 167.44,
       ft: 13.95
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "Peugeot 308 2007",
    make: "Peugeot",
    model: "308 2007",
    length: {
       cm: 425.3,
       in: 167.44,
       ft: 13.95
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 470,
       in: 28681.17,
       ft: 16.6
    }
 },
  {
    fullName: "Peugeot 308 2013",
    make: "Peugeot",
    model: "308 2013",
    length: {
       cm: 425.3,
       in: 167.44,
       ft: 13.95
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "Peugeot 308 2014",
    make: "Peugeot",
    model: "308 2014",
    length: {
       cm: 425.3,
       in: 167.44,
       ft: 13.95
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 420,
       in: 25629.98,
       ft: 14.83
    }
 },
  {
    fullName: "Peugeot 308 SW",
    make: "Peugeot",
    model: "308 SW",
    length: {
       cm: 458.5,
       in: 180.51,
       ft: 15.04
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 147.1,
       in: 57.91,
       ft: 4.83
    },
    capacity: {
       cm: 610,
       in: 37224.49,
       ft: 21.54
    }
 },
  {
    fullName: "Peugeot 308 SW 2014",
    make: "Peugeot",
    model: "308 SW 2014",
    length: {
       cm: 458.5,
       in: 180.51,
       ft: 15.04
    },
    width: {
       cm: 204.3,
       in: 80.43,
       ft: 6.7
    },
    height: {
       cm: 147.1,
       in: 57.91,
       ft: 4.83
    },
    capacity: {
       cm: 610,
       in: 37224.49,
       ft: 21.54
    }
 },
  {
    fullName: "Peugeot 407 2004",
    make: "Peugeot",
    model: "407 2004",
    length: {
       cm: 467.6,
       in: 184.09,
       ft: 15.34
    },
    width: {
       cm: 181.1,
       in: 71.3,
       ft: 5.94
    },
    height: {
       cm: 144.7,
       in: 56.97,
       ft: 4.75
    },
    capacity: {
       cm: 407,
       in: 24836.67,
       ft: 14.37
    }
 },
  {
    fullName: "Peugeot 5008",
    make: "Peugeot",
    model: "5008",
    length: {
       cm: 464.1,
       in: 182.72,
       ft: 15.23
    },
    width: {
       cm: 184.4,
       in: 72.6,
       ft: 6.05
    },
    height: {
       cm: 164.6,
       in: 64.8,
       ft: 5.4
    },
    capacity: {
       cm: 780,
       in: 47598.53,
       ft: 27.55
    }
 },
  {
    fullName: "Peugeot 508",
    make: "Peugeot",
    model: "508",
    length: {
       cm: 475,
       in: 187.01,
       ft: 15.58
    },
    width: {
       cm: 185.9,
       in: 73.19,
       ft: 6.1
    },
    height: {
       cm: 140.3,
       in: 55.24,
       ft: 4.6
    },
    capacity: {
       cm: 487,
       in: 29718.57,
       ft: 17.2
    }
 },
  {
    fullName: "Peugeot 508 2015",
    make: "Peugeot",
    model: "508 2015",
    length: {
       cm: 483,
       in: 190.16,
       ft: 15.85
    },
    width: {
       cm: 182.8,
       in: 71.97,
       ft: 6
    },
    height: {
       cm: 145.6,
       in: 57.32,
       ft: 4.78
    },
    capacity: {
       cm: 473,
       in: 28864.24,
       ft: 16.7
    }
 },
  {
    fullName: "Peugeot 508 SW",
    make: "Peugeot",
    model: "508 SW",
    length: {
       cm: 477.8,
       in: 188.11,
       ft: 15.68
    },
    width: {
       cm: 185.9,
       in: 73.19,
       ft: 6.1
    },
    height: {
       cm: 142,
       in: 55.91,
       ft: 4.66
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Peugeot Bipper Tepee 2012",
    make: "Peugeot",
    model: "Bipper Tepee 2012",
    length: {
       cm: 395.9,
       in: 155.87,
       ft: 12.99
    },
    width: {
       cm: 171.6,
       in: 67.56,
       ft: 5.63
    },
    height: {
       cm: 178.2,
       in: 70.16,
       ft: 5.85
    },
    capacity: {
       cm: 356,
       in: 21724.46,
       ft: 12.57
    }
 },
  {
    fullName: "Peugeot Expert Tepee ct 2012",
    make: "Peugeot",
    model: "Expert Tepee ct 2012",
    length: {
       cm: 480.5,
       in: 189.17,
       ft: 15.76
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 194.2,
       in: 76.46,
       ft: 6.37
    },
    capacity: {
       cm: 2163,
       in: 131994.39,
       ft: 76.39
    }
 },
  {
    fullName: "Peugeot Expert Tepee lg 2012",
    make: "Peugeot",
    model: "Expert Tepee lg 2012",
    length: {
       cm: 513.5,
       in: 202.17,
       ft: 16.85
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 194.2,
       in: 76.46,
       ft: 6.37
    },
    capacity: {
       cm: 2814,
       in: 171720.86,
       ft: 99.38
    }
 },
  {
    fullName: "Peugeot iOn",
    make: "Peugeot",
    model: "iOn",
    length: {
       cm: 347.5,
       in: 136.81,
       ft: 11.4
    },
    width: {
       cm: 147.5,
       in: 58.07,
       ft: 4.84
    },
    height: {
       cm: 160.8,
       in: 63.31,
       ft: 5.28
    },
    capacity: {
       cm: 166,
       in: 10129.94,
       ft: 5.86
    }
 },
  {
    fullName: "Peugeot Rifter",
    make: "Peugeot",
    model: "Rifter",
    length: {
       cm: 440.3,
       in: 173.35,
       ft: 14.45
    },
    width: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    height: {
       cm: 187.4,
       in: 73.78,
       ft: 6.15
    },
    capacity: {
       cm: 597,
       in: 36431.18,
       ft: 21.08
    }
 },
  {
    fullName: "Peugeot Rifter Long",
    make: "Peugeot",
    model: "Rifter Long",
    length: {
       cm: 475.3,
       in: 187.13,
       ft: 15.59
    },
    width: {
       cm: 184.8,
       in: 72.76,
       ft: 6.06
    },
    height: {
       cm: 187.9,
       in: 73.98,
       ft: 6.16
    },
    capacity: {
       cm: 850,
       in: 51870.2,
       ft: 30.02
    }
 },
  {
    fullName: "Peugeot Traveller Compact",
    make: "Peugeot",
    model: "Traveller Compact",
    length: {
       cm: 460.6,
       in: 181.34,
       ft: 15.11
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 190.5,
       in: 75,
       ft: 6.25
    },
    capacity: {
       cm: 1978,
       in: 120705,
       ft: 69.85
    }
 },
  {
    fullName: "Peugeot Traveller Long",
    make: "Peugeot",
    model: "Traveller Long",
    length: {
       cm: 530.8,
       in: 208.98,
       ft: 17.41
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2932,
       in: 178921.66,
       ft: 103.54
    }
 },
  {
    fullName: "Peugeot Traveller Standard",
    make: "Peugeot",
    model: "Traveller Standard",
    length: {
       cm: 495.6,
       in: 195.12,
       ft: 16.26
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2381,
       in: 145297.57,
       ft: 84.09
    }
 },
  {
    fullName: "Porsche 718 Boxster",
    make: "Porsche",
    model: "718 Boxster",
    length: {
       cm: 437.9,
       in: 172.4,
       ft: 14.37
    },
    width: {
       cm: 180.1,
       in: 70.91,
       ft: 5.91
    },
    height: {
       cm: 128.1,
       in: 50.43,
       ft: 4.2
    },
    capacity: {
       cm: 280,
       in: 17086.65,
       ft: 9.89
    }
 },
  {
    fullName: "Porsche 718 Cayman",
    make: "Porsche",
    model: "718 Cayman",
    length: {
       cm: 437.9,
       in: 172.4,
       ft: 14.37
    },
    width: {
       cm: 180.1,
       in: 70.91,
       ft: 5.91
    },
    height: {
       cm: 129.5,
       in: 50.98,
       ft: 4.25
    },
    capacity: {
       cm: 425,
       in: 25935.1,
       ft: 15.01
    }
 },
  {
    fullName: "Porsche 911 Carrera",
    make: "Porsche",
    model: "911 Carrera",
    length: {
       cm: 451.9,
       in: 177.91,
       ft: 14.83
    },
    width: {
       cm: 185.2,
       in: 72.91,
       ft: 6.08
    },
    height: {
       cm: 130,
       in: 51.18,
       ft: 4.27
    },
    capacity: {
       cm: 132,
       in: 8055.14,
       ft: 4.66
    }
 },
  {
    fullName: "Porsche Cayenne",
    make: "Porsche",
    model: "Cayenne",
    length: {
       cm: 491.8,
       in: 193.62,
       ft: 16.14
    },
    width: {
       cm: 198.3,
       in: 78.07,
       ft: 6.51
    },
    height: {
       cm: 169.6,
       in: 66.77,
       ft: 5.56
    },
    capacity: {
       cm: 770,
       in: 46988.29,
       ft: 27.19
    }
 },
  {
    fullName: "Porsche Cayenne Coupé",
    make: "Porsche",
    model: "Cayenne Coupé",
    length: {
       cm: 493.1,
       in: 194.13,
       ft: 16.18
    },
    width: {
       cm: 198.3,
       in: 78.07,
       ft: 6.51
    },
    height: {
       cm: 167.6,
       in: 65.98,
       ft: 5.5
    },
    capacity: {
       cm: 625,
       in: 38139.85,
       ft: 22.07
    }
 },
  {
    fullName: "Porsche Macan",
    make: "Porsche",
    model: "Macan",
    length: {
       cm: 469.6,
       in: 184.88,
       ft: 15.41
    },
    width: {
       cm: 192.3,
       in: 75.71,
       ft: 6.31
    },
    height: {
       cm: 162.4,
       in: 63.94,
       ft: 5.33
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Porsche Panamera",
    make: "Porsche",
    model: "Panamera",
    length: {
       cm: 504.9,
       in: 198.78,
       ft: 16.56
    },
    width: {
       cm: 193.7,
       in: 76.26,
       ft: 6.35
    },
    height: {
       cm: 142.3,
       in: 56.02,
       ft: 4.67
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Porsche Panamera Executive",
    make: "Porsche",
    model: "Panamera Executive",
    length: {
       cm: 519.9,
       in: 204.69,
       ft: 17.06
    },
    width: {
       cm: 193.7,
       in: 76.26,
       ft: 6.35
    },
    height: {
       cm: 142.8,
       in: 56.22,
       ft: 4.69
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Porsche Panamera Sport Turismo",
    make: "Porsche",
    model: "Panamera Sport Turismo",
    length: {
       cm: 504.9,
       in: 198.78,
       ft: 16.56
    },
    width: {
       cm: 193.7,
       in: 76.26,
       ft: 6.35
    },
    height: {
       cm: 142.8,
       in: 56.22,
       ft: 4.69
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "Porsche Taycan",
    make: "Porsche",
    model: "Taycan",
    length: {
       cm: 496.3,
       in: 195.39,
       ft: 16.28
    },
    width: {
       cm: 196.6,
       in: 77.4,
       ft: 6.45
    },
    height: {
       cm: 138.1,
       in: 54.37,
       ft: 4.53
    },
    capacity: {
       cm: 447,
       in: 27277.62,
       ft: 15.79
    }
 },
  {
    fullName: "Renault Alaskan",
    make: "Renault",
    model: "Alaskan",
    length: {
       cm: 539.9,
       in: 212.56,
       ft: 17.71
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 184.1,
       in: 72.48,
       ft: 6.04
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Renault Captur",
    make: "Renault",
    model: "Captur",
    length: {
       cm: 422.7,
       in: 166.42,
       ft: 13.87
    },
    width: {
       cm: 179.7,
       in: 70.75,
       ft: 5.9
    },
    height: {
       cm: 156.6,
       in: 61.65,
       ft: 5.14
    },
    capacity: {
       cm: 406,
       in: 24775.65,
       ft: 14.34
    }
 },
  {
    fullName: "Renault Clio",
    make: "Renault",
    model: "Clio",
    length: {
       cm: 405,
       in: 159.45,
       ft: 13.29
    },
    width: {
       cm: 179.8,
       in: 70.79,
       ft: 5.9
    },
    height: {
       cm: 144,
       in: 56.69,
       ft: 4.72
    },
    capacity: {
       cm: 366,
       in: 22334.7,
       ft: 12.93
    }
 },
  {
    fullName: "Renault Clio 2009",
    make: "Renault",
    model: "Clio 2009",
    length: {
       cm: 402.7,
       in: 158.54,
       ft: 13.21
    },
    width: {
       cm: 172,
       in: 67.72,
       ft: 5.64
    },
    height: {
       cm: 149.7,
       in: 58.94,
       ft: 4.91
    },
    capacity: {
       cm: 288,
       in: 17574.84,
       ft: 10.17
    }
 },
  {
    fullName: "Renault Clio 2012",
    make: "Renault",
    model: "Clio 3 2012",
    length: {
       cm: 422.8,
       in: 166.46,
       ft: 13.87
    },
    width: {
       cm: 170.7,
       in: 67.2,
       ft: 5.6
    },
    height: {
       cm: 151.3,
       in: 59.57,
       ft: 4.96
    },
    capacity: {
       cm: 439,
       in: 26789.43,
       ft: 15.5
    }
 },
  {
    fullName: "Renault Clio 2013",
    make: "Renault",
    model: "Clio 2013",
    length: {
       cm: 406.1,
       in: 159.88,
       ft: 13.32
    },
    width: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    height: {
       cm: 144.8,
       in: 57.01,
       ft: 4.75
    },
    capacity: {
       cm: 300,
       in: 18307.13,
       ft: 10.59
    }
 },
  {
    fullName: "Renault Clio 2016",
    make: "Renault",
    model: "Clio 2016",
    length: {
       cm: 406.2,
       in: 159.92,
       ft: 13.33
    },
    width: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    height: {
       cm: 144.8,
       in: 57.01,
       ft: 4.75
    },
    capacity: {
       cm: 300,
       in: 18307.13,
       ft: 10.59
    }
 },
  {
    fullName: "Renault Clio 3 2005",
    make: "Renault",
    model: "Clio 3 2005",
    length: {
       cm: 398.6,
       in: 156.93,
       ft: 13.08
    },
    width: {
       cm: 170.7,
       in: 67.2,
       ft: 5.6
    },
    height: {
       cm: 149.6,
       in: 58.9,
       ft: 4.91
    },
    capacity: {
       cm: 288,
       in: 17574.84,
       ft: 10.17
    }
 },
  {
    fullName: "Renault Clio Collection 2012",
    make: "Renault",
    model: "Clio Collection 2012",
    length: {
       cm: 402.7,
       in: 158.54,
       ft: 13.21
    },
    width: {
       cm: 172,
       in: 67.72,
       ft: 5.64
    },
    height: {
       cm: 149.7,
       in: 58.94,
       ft: 4.91
    },
    capacity: {
       cm: 288,
       in: 17574.84,
       ft: 10.17
    }
 },
  {
    fullName: "Renault Clio Grand Tour 2009",
    make: "Renault",
    model: "Clio Grand Tour 2009",
    length: {
       cm: 402.8,
       in: 158.58,
       ft: 13.22
    },
    width: {
       cm: 171.9,
       in: 67.68,
       ft: 5.64
    },
    height: {
       cm: 151.3,
       in: 59.57,
       ft: 4.96
    },
    capacity: {
       cm: 439,
       in: 26789.43,
       ft: 15.5
    }
 },
  {
    fullName: "Renault Clio Sport Tourer 2013",
    make: "Renault",
    model: "Clio Sport Tourer 2013",
    length: {
       cm: 426.7,
       in: 167.99,
       ft: 14
    },
    width: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 443,
       in: 27033.53,
       ft: 15.64
    }
 },
  {
    fullName: "Renault Clio Sport Tourer 2016",
    make: "Renault",
    model: "Clio Sport Tourer 2016",
    length: {
       cm: 426.7,
       in: 167.99,
       ft: 14
    },
    width: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    height: {
       cm: 147.5,
       in: 58.07,
       ft: 4.84
    },
    capacity: {
       cm: 443,
       in: 27033.53,
       ft: 15.64
    }
 },
  {
    fullName: "Renault Espace",
    make: "Renault",
    model: "Espace",
    length: {
       cm: 485.7,
       in: 191.22,
       ft: 15.94
    },
    width: {
       cm: 188.8,
       in: 74.33,
       ft: 6.19
    },
    height: {
       cm: 167.7,
       in: 66.02,
       ft: 5.5
    },
    capacity: {
       cm: 614,
       in: 37468.59,
       ft: 21.68
    }
 },
  {
    fullName: "Renault Grand Kangoo",
    make: "Renault",
    model: "Grand Kangoo",
    length: {
       cm: 466.6,
       in: 183.7,
       ft: 15.31
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 180.2,
       in: 70.94,
       ft: 5.91
    },
    capacity: {
       cm: 750,
       in: 45767.82,
       ft: 26.49
    }
 },
  {
    fullName: "Renault Grand Scénic",
    make: "Renault",
    model: "Grand Scénic",
    length: {
       cm: 463.4,
       in: 182.44,
       ft: 15.2
    },
    width: {
       cm: 186.6,
       in: 73.46,
       ft: 6.12
    },
    height: {
       cm: 165.5,
       in: 65.16,
       ft: 5.43
    },
    capacity: {
       cm: 533,
       in: 32525.66,
       ft: 18.82
    }
 },
  {
    fullName: "Renault Grand Trafic Combi",
    make: "Renault",
    model: "Grand Trafic Combi",
    length: {
       cm: 539.9,
       in: 212.56,
       ft: 17.71
    },
    width: {
       cm: 195.6,
       in: 77.01,
       ft: 6.42
    },
    height: {
       cm: 197.1,
       in: 77.6,
       ft: 6.47
    },
    capacity: {
       cm: 3400,
       in: 207480.78,
       ft: 120.07
    }
 },
  {
    fullName: "Renault Kadjar",
    make: "Renault",
    model: "Kadjar",
    length: {
       cm: 448.9,
       in: 176.73,
       ft: 14.73
    },
    width: {
       cm: 183.6,
       in: 72.28,
       ft: 6.02
    },
    height: {
       cm: 161.3,
       in: 63.5,
       ft: 5.29
    },
    capacity: {
       cm: 472,
       in: 28803.21,
       ft: 16.67
    }
 },
  {
    fullName: "Renault Kangoo",
    make: "Renault",
    model: "Kangoo",
    length: {
       cm: 428.2,
       in: 168.58,
       ft: 14.05
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 183.9,
       in: 72.4,
       ft: 6.03
    },
    capacity: {
       cm: 660,
       in: 40275.68,
       ft: 23.31
    }
 },
  {
    fullName: "Renault Koleos",
    make: "Renault",
    model: "Koleos",
    length: {
       cm: 467.3,
       in: 183.98,
       ft: 15.33
    },
    width: {
       cm: 184.3,
       in: 72.56,
       ft: 6.05
    },
    height: {
       cm: 167.8,
       in: 66.06,
       ft: 5.51
    },
    capacity: {
       cm: 565,
       in: 34478.42,
       ft: 19.95
    }
 },
  {
    fullName: "Renault Laguna 2 2001",
    make: "Renault",
    model: "Laguna 2 2001",
    length: {
       cm: 459.8,
       in: 181.02,
       ft: 15.09
    },
    width: {
       cm: 177.4,
       in: 69.84,
       ft: 5.82
    },
    height: {
       cm: 143.3,
       in: 56.42,
       ft: 4.7
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Renault Megane",
    make: "Renault",
    model: "Megane",
    length: {
       cm: 435.9,
       in: 171.61,
       ft: 14.3
    },
    width: {
       cm: 181.4,
       in: 71.42,
       ft: 5.95
    },
    height: {
       cm: 144.7,
       in: 56.97,
       ft: 4.75
    },
    capacity: {
       cm: 384,
       in: 23433.12,
       ft: 13.56
    }
 },
  {
    fullName: "Renault Megane 2 2002",
    make: "Renault",
    model: "Megane 2 2002",
    length: {
       cm: 420.9,
       in: 165.71,
       ft: 13.81
    },
    width: {
       cm: 177.7,
       in: 69.96,
       ft: 5.83
    },
    height: {
       cm: 145.7,
       in: 57.36,
       ft: 4.78
    },
    capacity: {
       cm: 330,
       in: 20137.84,
       ft: 11.65
    }
 },
  {
    fullName: "Renault Megane 2 2008",
    make: "Renault",
    model: "Megane 2 2008",
    length: {
       cm: 423,
       in: 166.54,
       ft: 13.88
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 144,
       in: 56.69,
       ft: 4.72
    },
    capacity: {
       cm: 330,
       in: 20137.84,
       ft: 11.65
    }
 },
  {
    fullName: "Renault Megane 2009",
    make: "Renault",
    model: "Megane 2009",
    length: {
       cm: 429.5,
       in: 169.09,
       ft: 14.09
    },
    width: {
       cm: 181.8,
       in: 71.57,
       ft: 5.96
    },
    height: {
       cm: 147.1,
       in: 57.91,
       ft: 4.83
    },
    capacity: {
       cm: 372,
       in: 22700.84,
       ft: 13.14
    }
 },
  {
    fullName: "Renault Megane 2012",
    make: "Renault",
    model: "Megane 2012",
    length: {
       cm: 429.5,
       in: 169.09,
       ft: 14.09
    },
    width: {
       cm: 180.8,
       in: 71.18,
       ft: 5.93
    },
    height: {
       cm: 147.1,
       in: 57.91,
       ft: 4.83
    },
    capacity: {
       cm: 372,
       in: 22700.84,
       ft: 13.14
    }
 },
  {
    fullName: "Renault Megane 2014",
    make: "Renault",
    model: "Megane 2014",
    length: {
       cm: 430.2,
       in: 169.37,
       ft: 14.11
    },
    width: {
       cm: 180.8,
       in: 71.18,
       ft: 5.93
    },
    height: {
       cm: 147.1,
       in: 57.91,
       ft: 4.83
    },
    capacity: {
       cm: 372,
       in: 22700.84,
       ft: 13.14
    }
 },
  {
    fullName: "Renault Megane Coupe 2009",
    make: "Renault",
    model: "Megane Coupe 2009",
    length: {
       cm: 429.9,
       in: 169.25,
       ft: 14.1
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 142.3,
       in: 56.02,
       ft: 4.67
    },
    capacity: {
       cm: 344,
       in: 20992.17,
       ft: 12.15
    }
 },
  {
    fullName: "Renault Megane Coupe 2012",
    make: "Renault",
    model: "Megane Coupe 2012",
    length: {
       cm: 429.9,
       in: 169.25,
       ft: 14.1
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 142.3,
       in: 56.02,
       ft: 4.67
    },
    capacity: {
       cm: 344,
       in: 20992.17,
       ft: 12.15
    }
 },
  {
    fullName: "Renault Megane Coupe 2014",
    make: "Renault",
    model: "Megane Coupe 2014",
    length: {
       cm: 431.2,
       in: 169.76,
       ft: 14.15
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 142.3,
       in: 56.02,
       ft: 4.67
    },
    capacity: {
       cm: 344,
       in: 20992.17,
       ft: 12.15
    }
 },
  {
    fullName: "Renault Megane Sport Tourer",
    make: "Renault",
    model: "Megane Sport Tourer",
    length: {
       cm: 462.6,
       in: 182.13,
       ft: 15.18
    },
    width: {
       cm: 181.4,
       in: 71.42,
       ft: 5.95
    },
    height: {
       cm: 144.1,
       in: 56.73,
       ft: 4.73
    },
    capacity: {
       cm: 580,
       in: 35393.78,
       ft: 20.48
    }
 },
  {
    fullName: "Renault Modus 1.5 2004",
    make: "Renault",
    model: "Modus 1.5 2004",
    length: {
       cm: 379,
       in: 149.21,
       ft: 12.43
    },
    width: {
       cm: 170,
       in: 66.93,
       ft: 5.58
    },
    height: {
       cm: 159,
       in: 62.6,
       ft: 5.22
    },
    capacity: {
       cm: 274,
       in: 16720.51,
       ft: 9.68
    }
 },
  {
    fullName: "Renault Scenic",
    make: "Renault",
    model: "Scenic",
    length: {
       cm: 440.6,
       in: 173.46,
       ft: 14.46
    },
    width: {
       cm: 186.6,
       in: 73.46,
       ft: 6.12
    },
    height: {
       cm: 165.3,
       in: 65.08,
       ft: 5.42
    },
    capacity: {
       cm: 506,
       in: 30878.02,
       ft: 17.87
    }
 },
  {
    fullName: "Renault Talisman",
    make: "Renault",
    model: "Talisman",
    length: {
       cm: 484.9,
       in: 190.91,
       ft: 15.91
    },
    width: {
       cm: 186.8,
       in: 73.54,
       ft: 6.13
    },
    height: {
       cm: 146.3,
       in: 57.6,
       ft: 4.8
    },
    capacity: {
       cm: 608,
       in: 37102.45,
       ft: 21.47
    }
 },
  {
    fullName: "Renault Talisman Sport Tourer",
    make: "Renault",
    model: "Talisman Sport Tourer",
    length: {
       cm: 486.6,
       in: 191.57,
       ft: 15.96
    },
    width: {
       cm: 187,
       in: 73.62,
       ft: 6.14
    },
    height: {
       cm: 146.5,
       in: 57.68,
       ft: 4.81
    },
    capacity: {
       cm: 572,
       in: 34905.59,
       ft: 20.2
    }
 },
  {
    fullName: "Renault Trafic Combi",
    make: "Renault",
    model: "Trafic Combi",
    length: {
       cm: 499.9,
       in: 196.81,
       ft: 16.4
    },
    width: {
       cm: 195.6,
       in: 77.01,
       ft: 6.42
    },
    height: {
       cm: 197.1,
       in: 77.6,
       ft: 6.47
    },
    capacity: {
       cm: 2500,
       in: 152559.4,
       ft: 88.29
    }
 },
  {
    fullName: "Renault Twingo",
    make: "Renault",
    model: "Twingo",
    length: {
       cm: 361.5,
       in: 142.32,
       ft: 11.86
    },
    width: {
       cm: 164.6,
       in: 64.8,
       ft: 5.4
    },
    height: {
       cm: 154.1,
       in: 60.67,
       ft: 5.06
    },
    capacity: {
       cm: 188,
       in: 11472.47,
       ft: 6.64
    }
 },
  {
    fullName: "Renault Twingo 2 2014",
    make: "Renault",
    model: "Twingo 2 2014",
    length: {
       cm: 368.7,
       in: 145.16,
       ft: 12.1
    },
    width: {
       cm: 165.4,
       in: 65.12,
       ft: 5.43
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 230,
       in: 14035.46,
       ft: 8.12
    }
 },
  {
    fullName: "Renault Twingo 2007",
    make: "Renault",
    model: "Twingo 2007",
    length: {
       cm: 360,
       in: 141.73,
       ft: 11.81
    },
    width: {
       cm: 165.4,
       in: 65.12,
       ft: 5.43
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 230,
       in: 14035.46,
       ft: 8.12
    }
 },
  {
    fullName: "Renault Twingo 2012",
    make: "Renault",
    model: "Twingo 2012",
    length: {
       cm: 368.7,
       in: 145.16,
       ft: 12.1
    },
    width: {
       cm: 165.4,
       in: 65.12,
       ft: 5.43
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 230,
       in: 14035.46,
       ft: 8.12
    }
 },
  {
    fullName: "Renault Twingo 2015",
    make: "Renault",
    model: "Twingo 2015",
    length: {
       cm: 359.5,
       in: 141.54,
       ft: 11.79
    },
    width: {
       cm: 164.6,
       in: 64.8,
       ft: 5.4
    },
    height: {
       cm: 155.4,
       in: 61.18,
       ft: 5.1
    },
    capacity: {
       cm: 188,
       in: 11472.47,
       ft: 6.64
    }
 },
  {
    fullName: "Renault Twizy",
    make: "Renault",
    model: "Twizy",
    length: {
       cm: 233.7,
       in: 92.01,
       ft: 7.67
    },
    width: {
       cm: 123.4,
       in: 48.58,
       ft: 4.05
    },
    height: {
       cm: 145.4,
       in: 57.24,
       ft: 4.77
    },
    capacity: {
       cm: 31,
       in: 1891.74,
       ft: 1.09
    }
 },
  {
    fullName: "Renault Zoe",
    make: "Renault",
    model: "Zoe",
    length: {
       cm: 408.7,
       in: 160.91,
       ft: 13.41
    },
    width: {
       cm: 173,
       in: 68.11,
       ft: 5.68
    },
    height: {
       cm: 156.2,
       in: 61.5,
       ft: 5.12
    },
    capacity: {
       cm: 338,
       in: 20626.03,
       ft: 11.94
    }
 },
  {
    fullName: "Seat Alhambra",
    make: "Seat",
    model: "Alhambra",
    length: {
       cm: 485.4,
       in: 191.1,
       ft: 15.93
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 174,
       in: 68.5,
       ft: 5.71
    },
    capacity: {
       cm: 658,
       in: 40153.63,
       ft: 23.24
    }
 },
  {
    fullName: "Seat Arona",
    make: "Seat",
    model: "Arona",
    length: {
       cm: 413.8,
       in: 162.91,
       ft: 13.58
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 155.2,
       in: 61.1,
       ft: 5.09
    },
    capacity: {
       cm: 400,
       in: 24409.5,
       ft: 14.13
    }
 },
  {
    fullName: "Seat Ateca",
    make: "Seat",
    model: "Ateca",
    length: {
       cm: 436.3,
       in: 171.77,
       ft: 14.31
    },
    width: {
       cm: 184.1,
       in: 72.48,
       ft: 6.04
    },
    height: {
       cm: 161.5,
       in: 63.58,
       ft: 5.3
    },
    capacity: {
       cm: 510,
       in: 31122.12,
       ft: 18.01
    }
 },
  {
    fullName: "Seat Ibiza",
    make: "Seat",
    model: "Ibiza",
    length: {
       cm: 405.9,
       in: 159.8,
       ft: 13.32
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 144.4,
       in: 56.85,
       ft: 4.74
    },
    capacity: {
       cm: 355,
       in: 21663.43,
       ft: 12.54
    }
 },
  {
    fullName: "Seat Leon",
    make: "Seat",
    model: "Leon",
    length: {
       cm: 436.8,
       in: 171.97,
       ft: 14.33
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 145.6,
       in: 57.32,
       ft: 4.78
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Seat Leon Sportstourer",
    make: "Seat",
    model: "Leon Sportstourer",
    length: {
       cm: 464.2,
       in: 182.76,
       ft: 15.23
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 145.1,
       in: 57.13,
       ft: 4.76
    },
    capacity: {
       cm: 617,
       in: 37651.66,
       ft: 21.79
    }
 },
  {
    fullName: "Seat Mii electric",
    make: "Seat",
    model: "Mii electric",
    length: {
       cm: 355.6,
       in: 140,
       ft: 11.67
    },
    width: {
       cm: 164.5,
       in: 64.76,
       ft: 5.4
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 251,
       in: 15316.96,
       ft: 8.86
    }
 },
  {
    fullName: "Seat Tarraco",
    make: "Seat",
    model: "Tarraco",
    length: {
       cm: 473.5,
       in: 186.42,
       ft: 15.53
    },
    width: {
       cm: 183.9,
       in: 72.4,
       ft: 6.03
    },
    height: {
       cm: 165.8,
       in: 65.28,
       ft: 5.44
    },
    capacity: {
       cm: 700,
       in: 42716.63,
       ft: 24.72
    }
 },
  {
    fullName: "Skoda Citigo iV",
    make: "Skoda",
    model: "Citigo iV",
    length: {
       cm: 359.7,
       in: 141.61,
       ft: 11.8
    },
    width: {
       cm: 164.5,
       in: 64.76,
       ft: 5.4
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 251,
       in: 15316.96,
       ft: 8.86
    }
 },
  {
    fullName: "Skoda Fabia",
    make: "Skoda",
    model: "Fabia",
    length: {
       cm: 399.7,
       in: 157.36,
       ft: 13.11
    },
    width: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    height: {
       cm: 146.7,
       in: 57.76,
       ft: 4.81
    },
    capacity: {
       cm: 330,
       in: 20137.84,
       ft: 11.65
    }
 },
  {
    fullName: "Skoda Fabia Combi",
    make: "Skoda",
    model: "Fabia Combi",
    length: {
       cm: 426.2,
       in: 167.8,
       ft: 13.98
    },
    width: {
       cm: 173.2,
       in: 68.19,
       ft: 5.68
    },
    height: {
       cm: 146.7,
       in: 57.76,
       ft: 4.81
    },
    capacity: {
       cm: 530,
       in: 32342.59,
       ft: 18.72
    }
 },
  {
    fullName: "Skoda Kamiq",
    make: "Skoda",
    model: "Kamiq",
    length: {
       cm: 424.1,
       in: 166.97,
       ft: 13.91
    },
    width: {
       cm: 179.3,
       in: 70.59,
       ft: 5.88
    },
    height: {
       cm: 153.1,
       in: 60.28,
       ft: 5.02
    },
    capacity: {
       cm: 400,
       in: 24409.5,
       ft: 14.13
    }
 },
  {
    fullName: "Skoda Karoq",
    make: "Skoda",
    model: "Karoq",
    length: {
       cm: 438.2,
       in: 172.52,
       ft: 14.38
    },
    width: {
       cm: 184.1,
       in: 72.48,
       ft: 6.04
    },
    height: {
       cm: 160.3,
       in: 63.11,
       ft: 5.26
    },
    capacity: {
       cm: 521,
       in: 31793.38,
       ft: 18.4
    }
 },
  {
    fullName: "Skoda Kodiaq",
    make: "Skoda",
    model: "Kodiaq",
    length: {
       cm: 469.7,
       in: 184.92,
       ft: 15.41
    },
    width: {
       cm: 188.2,
       in: 74.09,
       ft: 6.17
    },
    height: {
       cm: 167.6,
       in: 65.98,
       ft: 5.5
    },
    capacity: {
       cm: 630,
       in: 38444.97,
       ft: 22.25
    }
 },
  {
    fullName: "Skoda Octavia",
    make: "Skoda",
    model: "Octavia",
    length: {
       cm: 468.9,
       in: 184.61,
       ft: 15.38
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 600,
       in: 36614.26,
       ft: 21.19
    }
 },
  {
    fullName: "Skoda Octavia 2013",
    make: "Skoda",
    model: "Octavia 2013",
    length: {
       cm: 465.9,
       in: 183.43,
       ft: 15.29
    },
    width: {
       cm: 181.4,
       in: 71.42,
       ft: 5.95
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 590,
       in: 36004.02,
       ft: 20.84
    }
 },
  {
    fullName: "Skoda Octavia 2017",
    make: "Skoda",
    model: "Octavia 2017",
    length: {
       cm: 467,
       in: 183.86,
       ft: 15.32
    },
    width: {
       cm: 181.4,
       in: 71.42,
       ft: 5.95
    },
    height: {
       cm: 146.1,
       in: 57.52,
       ft: 4.79
    },
    capacity: {
       cm: 590,
       in: 36004.02,
       ft: 20.84
    }
 },
  {
    fullName: "Skoda Octavia 3 2013",
    make: "Skoda",
    model: "Octavia 3 2013",
    length: {
       cm: 465.9,
       in: 183.43,
       ft: 15.29
    },
    width: {
       cm: 181.4,
       in: 71.42,
       ft: 5.95
    },
    height: {
       cm: 146.1,
       in: 57.52,
       ft: 4.79
    },
    capacity: {
       cm: 590,
       in: 36004.02,
       ft: 20.84
    }
 },
  {
    fullName: "Skoda Octavia Combi",
    make: "Skoda",
    model: "Octavia Combi",
    length: {
       cm: 468.9,
       in: 184.61,
       ft: 15.38
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 640,
       in: 39055.21,
       ft: 22.6
    }
 },
  {
    fullName: "Skoda Roomster 2006",
    make: "Skoda",
    model: "Roomster 1.4 2006",
    length: {
       cm: 420.5,
       in: 165.55,
       ft: 13.8
    },
    width: {
       cm: 168.4,
       in: 66.3,
       ft: 5.52
    },
    height: {
       cm: 160.7,
       in: 63.27,
       ft: 5.27
    },
    capacity: {
       cm: 450,
       in: 27460.69,
       ft: 15.89
    }
 },
  {
    fullName: "Skoda Scala",
    make: "Skoda",
    model: "Scala",
    length: {
       cm: 436.2,
       in: 171.73,
       ft: 14.31
    },
    width: {
       cm: 179.3,
       in: 70.59,
       ft: 5.88
    },
    height: {
       cm: 147.1,
       in: 57.91,
       ft: 4.83
    },
    capacity: {
       cm: 467,
       in: 28498.1,
       ft: 16.49
    }
 },
  {
    fullName: "Skoda Superb",
    make: "Skoda",
    model: "Superb",
    length: {
       cm: 486.9,
       in: 191.69,
       ft: 15.97
    },
    width: {
       cm: 186.4,
       in: 73.39,
       ft: 6.12
    },
    height: {
       cm: 146.9,
       in: 57.83,
       ft: 4.82
    },
    capacity: {
       cm: 625,
       in: 38139.85,
       ft: 22.07
    }
 },
  {
    fullName: "Skoda Superb 2 2009",
    make: "Skoda",
    model: "Superb 2 2009",
    length: {
       cm: 483.8,
       in: 190.47,
       ft: 15.87
    },
    width: {
       cm: 181.7,
       in: 71.54,
       ft: 5.96
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 633,
       in: 38628.04,
       ft: 22.35
    }
 },
  {
    fullName: "Skoda Superb 3 2015",
    make: "Skoda",
    model: "Superb 3 2015",
    length: {
       cm: 486.1,
       in: 191.38,
       ft: 15.95
    },
    width: {
       cm: 186.4,
       in: 73.39,
       ft: 6.12
    },
    height: {
       cm: 146.8,
       in: 57.8,
       ft: 4.82
    },
    capacity: {
       cm: 625,
       in: 38139.85,
       ft: 22.07
    }
 },
  {
    fullName: "Skoda Superb Combi",
    make: "Skoda",
    model: "Superb Combi",
    length: {
       cm: 486.2,
       in: 191.42,
       ft: 15.95
    },
    width: {
       cm: 186.4,
       in: 73.39,
       ft: 6.12
    },
    height: {
       cm: 147.7,
       in: 58.15,
       ft: 4.85
    },
    capacity: {
       cm: 660,
       in: 40275.68,
       ft: 23.31
    }
 },
  {
    fullName: "Skoda Yeti Ambition 2010",
    make: "Skoda",
    model: "Yeti Ambition 2010",
    length: {
       cm: 257.8,
       in: 101.5,
       ft: 8.46
    },
    width: {
       cm: 422.3,
       in: 166.26,
       ft: 13.85
    },
    height: {
       cm: 179.3,
       in: 70.59,
       ft: 5.88
    },
    capacity: {
       cm: 405,
       in: 24714.62,
       ft: 14.3
    }
 },
  {
    fullName: "Smart EQ forfour",
    make: "Smart",
    model: "EQ forfour",
    length: {
       cm: 349.5,
       in: 137.6,
       ft: 11.47
    },
    width: {
       cm: 166.5,
       in: 65.55,
       ft: 5.46
    },
    height: {
       cm: 155.4,
       in: 61.18,
       ft: 5.1
    },
    capacity: {
       cm: 185,
       in: 11289.4,
       ft: 6.53
    }
 },
  {
    fullName: "Smart EQ fortwo",
    make: "Smart",
    model: "EQ fortwo",
    length: {
       cm: 269.5,
       in: 106.1,
       ft: 8.84
    },
    width: {
       cm: 166.3,
       in: 65.47,
       ft: 5.46
    },
    height: {
       cm: 155.5,
       in: 61.22,
       ft: 5.1
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "Smart EQ fortwo cabrio",
    make: "Smart",
    model: "EQ fortwo cabrio",
    length: {
       cm: 269.5,
       in: 106.1,
       ft: 8.84
    },
    width: {
       cm: 166.3,
       in: 65.47,
       ft: 5.46
    },
    height: {
       cm: 155.3,
       in: 61.14,
       ft: 5.1
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "SsangYong Korando",
    make: "SsangYong",
    model: "Korando",
    length: {
       cm: 445,
       in: 175.2,
       ft: 14.6
    },
    width: {
       cm: 187,
       in: 73.62,
       ft: 6.14
    },
    height: {
       cm: 162.9,
       in: 64.13,
       ft: 5.34
    },
    capacity: {
       cm: 551,
       in: 33624.09,
       ft: 19.46
    }
 },
  {
    fullName: "SsangYong Musso",
    make: "SsangYong",
    model: "Musso",
    length: {
       cm: 509.5,
       in: 200.59,
       ft: 16.72
    },
    width: {
       cm: 195,
       in: 76.77,
       ft: 6.4
    },
    height: {
       cm: 187,
       in: 73.62,
       ft: 6.14
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "SsangYong Rexton",
    make: "SsangYong",
    model: "Rexton",
    length: {
       cm: 485,
       in: 190.94,
       ft: 15.91
    },
    width: {
       cm: 196,
       in: 77.17,
       ft: 6.43
    },
    height: {
       cm: 182.5,
       in: 71.85,
       ft: 5.99
    },
    capacity: {
       cm: 641,
       in: 39116.23,
       ft: 22.64
    }
 },
  {
    fullName: "SsangYong Rodius",
    make: "SsangYong",
    model: "Rodius",
    length: {
       cm: 513,
       in: 201.97,
       ft: 16.83
    },
    width: {
       cm: 191.5,
       in: 75.39,
       ft: 6.28
    },
    height: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    capacity: {
       cm: 1975,
       in: 120521.92,
       ft: 69.75
    }
 },
  {
    fullName: "SsangYong Tivoli",
    make: "SsangYong",
    model: "Tivoli",
    length: {
       cm: 420.2,
       in: 165.43,
       ft: 13.79
    },
    width: {
       cm: 179.8,
       in: 70.79,
       ft: 5.9
    },
    height: {
       cm: 160,
       in: 62.99,
       ft: 5.25
    },
    capacity: {
       cm: 423,
       in: 25813.05,
       ft: 14.94
    }
 },
  {
    fullName: "SsangYong XLV",
    make: "SsangYong",
    model: "XLV",
    length: {
       cm: 444,
       in: 174.8,
       ft: 14.57
    },
    width: {
       cm: 179.8,
       in: 70.79,
       ft: 5.9
    },
    height: {
       cm: 163.5,
       in: 64.37,
       ft: 5.36
    },
    capacity: {
       cm: 720,
       in: 43937.11,
       ft: 25.43
    }
 },
  {
    fullName: "Subaru BRZ",
    make: "Subaru",
    model: "BRZ",
    length: {
       cm: 424,
       in: 166.93,
       ft: 13.91
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 132,
       in: 51.97,
       ft: 4.33
    },
    capacity: {
       cm: 243,
       in: 14828.77,
       ft: 8.58
    }
 },
  {
    fullName: "Subaru Forester",
    make: "Subaru",
    model: "Forester",
    length: {
       cm: 462.5,
       in: 182.09,
       ft: 15.17
    },
    width: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    height: {
       cm: 173,
       in: 68.11,
       ft: 5.68
    },
    capacity: {
       cm: 509,
       in: 31061.09,
       ft: 17.98
    }
 },
  {
    fullName: "Subaru Impreza",
    make: "Subaru",
    model: "Impreza",
    length: {
       cm: 446,
       in: 175.59,
       ft: 14.63
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 385,
       in: 23494.15,
       ft: 13.6
    }
 },
  {
    fullName: "Subaru Levorg",
    make: "Subaru",
    model: "Levorg",
    length: {
       cm: 469,
       in: 184.65,
       ft: 15.39
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 149,
       in: 58.66,
       ft: 4.89
    },
    capacity: {
       cm: 522,
       in: 31854.4,
       ft: 18.43
    }
 },
  {
    fullName: "Subaru Outback",
    make: "Subaru",
    model: "Outback",
    length: {
       cm: 481.5,
       in: 189.57,
       ft: 15.8
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 160.5,
       in: 63.19,
       ft: 5.27
    },
    capacity: {
       cm: 512,
       in: 31244.16,
       ft: 18.08
    }
 },
  {
    fullName: "Subaru XV",
    make: "Subaru",
    model: "XV",
    length: {
       cm: 446.5,
       in: 175.79,
       ft: 14.65
    },
    width: {
       cm: 180,
       in: 70.87,
       ft: 5.91
    },
    height: {
       cm: 161.5,
       in: 63.58,
       ft: 5.3
    },
    capacity: {
       cm: 385,
       in: 23494.15,
       ft: 13.6
    }
 },
  {
    fullName: "Suzuki Baleno 2016",
    make: "Suzuki",
    model: "Baleno 2016",
    length: {
       cm: 399.5,
       in: 157.28,
       ft: 13.11
    },
    width: {
       cm: 174.5,
       in: 68.7,
       ft: 5.73
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 355,
       in: 21663.43,
       ft: 12.54
    }
 },
  {
    fullName: "Suzuki Celerio 2015",
    make: "Suzuki",
    model: "Celerio 2015",
    length: {
       cm: 360,
       in: 141.73,
       ft: 11.81
    },
    width: {
       cm: 160,
       in: 62.99,
       ft: 5.25
    },
    height: {
       cm: 154,
       in: 60.63,
       ft: 5.05
    },
    capacity: {
       cm: 254,
       in: 15500.03,
       ft: 8.97
    }
 },
  {
    fullName: "Suzuki Ignis",
    make: "Suzuki",
    model: "Ignis",
    length: {
       cm: 370,
       in: 145.67,
       ft: 12.14
    },
    width: {
       cm: 166,
       in: 65.35,
       ft: 5.45
    },
    height: {
       cm: 159.5,
       in: 62.8,
       ft: 5.23
    },
    capacity: {
       cm: 260,
       in: 15866.18,
       ft: 9.18
    }
 },
  {
    fullName: "Suzuki Jimny",
    make: "Suzuki",
    model: "Jimny",
    length: {
       cm: 364.5,
       in: 143.5,
       ft: 11.96
    },
    width: {
       cm: 164.5,
       in: 64.76,
       ft: 5.4
    },
    height: {
       cm: 172,
       in: 67.72,
       ft: 5.64
    },
    capacity: {
       cm: 85,
       in: 5187.02,
       ft: 3
    }
 },
  {
    fullName: "Suzuki S-Cross",
    make: "Suzuki",
    model: "S-Cross",
    length: {
       cm: 430,
       in: 169.29,
       ft: 14.11
    },
    width: {
       cm: 178.5,
       in: 70.28,
       ft: 5.86
    },
    height: {
       cm: 158.5,
       in: 62.4,
       ft: 5.2
    },
    capacity: {
       cm: 430,
       in: 26240.22,
       ft: 15.19
    }
 },
  {
    fullName: "Suzuki Swift",
    make: "Suzuki",
    model: "Swift",
    length: {
       cm: 384,
       in: 151.18,
       ft: 12.6
    },
    width: {
       cm: 173.5,
       in: 68.31,
       ft: 5.69
    },
    height: {
       cm: 149.5,
       in: 58.86,
       ft: 4.9
    },
    capacity: {
       cm: 265,
       in: 16171.3,
       ft: 9.36
    }
 },
  {
    fullName: "Suzuki Swift 2013",
    make: "Suzuki",
    model: "Swift 2013",
    length: {
       cm: 385,
       in: 151.57,
       ft: 12.63
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 211,
       in: 12876.01,
       ft: 7.45
    }
 },
  {
    fullName: "Suzuki Vitara",
    make: "Suzuki",
    model: "Vitara",
    length: {
       cm: 417.5,
       in: 164.37,
       ft: 13.7
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 161,
       in: 63.39,
       ft: 5.28
    },
    capacity: {
       cm: 375,
       in: 22883.91,
       ft: 13.24
    }
 },
  {
    fullName: "Tesla Model 3",
    make: "Tesla",
    model: "Model 3",
    length: {
       cm: 469.4,
       in: 184.8,
       ft: 15.4
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 144.3,
       in: 56.81,
       ft: 4.73
    },
    capacity: {
       cm: 425,
       in: 25935.1,
       ft: 15.01
    }
 },
  {
    fullName: "Tesla Model S",
    make: "Tesla",
    model: "Model S",
    length: {
       cm: 497.9,
       in: 196.02,
       ft: 16.34
    },
    width: {
       cm: 196.4,
       in: 77.32,
       ft: 6.44
    },
    height: {
       cm: 144.5,
       in: 56.89,
       ft: 4.74
    },
    capacity: {
       cm: 804,
       in: 49063.1,
       ft: 28.39
    }
 },
  {
    fullName: "Tesla Model X",
    make: "Tesla",
    model: "Model X",
    length: {
       cm: 503.7,
       in: 198.31,
       ft: 16.53
    },
    width: {
       cm: 207,
       in: 81.5,
       ft: 6.79
    },
    height: {
       cm: 162.6,
       in: 64.02,
       ft: 5.33
    },
    capacity: {
       cm: 2180,
       in: 133031.79,
       ft: 76.99
    }
 },
  {
    fullName: "Toyota Auris 2010",
    make: "Toyota",
    model: "Auris 2010",
    length: {
       cm: 424.5,
       in: 167.13,
       ft: 13.93
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 151.5,
       in: 59.65,
       ft: 4.97
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Toyota Auris 2013",
    make: "Toyota",
    model: "Auris 2013",
    length: {
       cm: 427.5,
       in: 168.31,
       ft: 14.03
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 360,
       in: 21968.55,
       ft: 12.71
    }
 },
  {
    fullName: "Toyota Auris 2015",
    make: "Toyota",
    model: "Auris 2015",
    length: {
       cm: 433,
       in: 170.47,
       ft: 14.21
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 147.5,
       in: 58.07,
       ft: 4.84
    },
    capacity: {
       cm: 360,
       in: 21968.55,
       ft: 12.71
    }
 },
  {
    fullName: "Toyota Avensis II 2.0 2003",
    make: "Toyota",
    model: "Avensis II 2.0 2003",
    length: {
       cm: 464.5,
       in: 182.87,
       ft: 15.24
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 510,
       in: 31122.12,
       ft: 18.01
    }
 },
  {
    fullName: "Toyota AYGO",
    make: "Toyota",
    model: "AYGO",
    length: {
       cm: 346.5,
       in: 136.42,
       ft: 11.37
    },
    width: {
       cm: 161.5,
       in: 63.58,
       ft: 5.3
    },
    height: {
       cm: 146,
       in: 57.48,
       ft: 4.79
    },
    capacity: {
       cm: 168,
       in: 10251.99,
       ft: 5.93
    }
 },
  {
    fullName: "Toyota Camry",
    make: "Toyota",
    model: "Camry",
    length: {
       cm: 488.5,
       in: 192.32,
       ft: 16.03
    },
    width: {
       cm: 184,
       in: 72.44,
       ft: 6.04
    },
    height: {
       cm: 144.5,
       in: 56.89,
       ft: 4.74
    },
    capacity: {
       cm: 524,
       in: 31976.45,
       ft: 18.51
    }
 },
  {
    fullName: "Toyota C-HR",
    make: "Toyota",
    model: "C-HR",
    length: {
       cm: 436,
       in: 171.65,
       ft: 14.3
    },
    width: {
       cm: 179.5,
       in: 70.67,
       ft: 5.89
    },
    height: {
       cm: 155,
       in: 61.02,
       ft: 5.09
    },
    capacity: {
       cm: 377,
       in: 23005.96,
       ft: 13.31
    }
 },
  {
    fullName: "Toyota Corolla",
    make: "Toyota",
    model: "Corolla",
    length: {
       cm: 437,
       in: 172.05,
       ft: 14.34
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 361,
       in: 22029.58,
       ft: 12.75
    }
 },
  {
    fullName: "Toyota Corolla Sedan",
    make: "Toyota",
    model: "Corolla Sedan",
    length: {
       cm: 463,
       in: 182.28,
       ft: 15.19
    },
    width: {
       cm: 178,
       in: 70.08,
       ft: 5.84
    },
    height: {
       cm: 143.5,
       in: 56.5,
       ft: 4.71
    },
    capacity: {
       cm: 471,
       in: 28742.19,
       ft: 16.63
    }
 },
  {
    fullName: "Toyota Corolla Touring Sports",
    make: "Toyota",
    model: "Corolla Touring Sports",
    length: {
       cm: 465.3,
       in: 183.19,
       ft: 15.27
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 144.5,
       in: 56.89,
       ft: 4.74
    },
    capacity: {
       cm: 598,
       in: 36492.21,
       ft: 21.12
    }
 },
  {
    fullName: "Toyota GT 86 2012",
    make: "Toyota",
    model: "GT 86 2012",
    length: {
       cm: 257,
       in: 101.18,
       ft: 8.43
    },
    width: {
       cm: 424,
       in: 166.93,
       ft: 13.91
    },
    height: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    capacity: {
       cm: 243,
       in: 14828.77,
       ft: 8.58
    }
 },
  {
    fullName: "Toyota GT86",
    make: "Toyota",
    model: "GT86",
    length: {
       cm: 424,
       in: 166.93,
       ft: 13.91
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 128.5,
       in: 50.59,
       ft: 4.22
    },
    capacity: {
       cm: 243,
       in: 14828.77,
       ft: 8.58
    }
 },
  {
    fullName: "Toyota Hilux",
    make: "Toyota",
    model: "Hilux",
    length: {
       cm: 533,
       in: 209.84,
       ft: 17.49
    },
    width: {
       cm: 185.5,
       in: 73.03,
       ft: 6.09
    },
    height: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Toyota Hilux 2012",
    make: "Toyota",
    model: "Hilux 2012",
    length: {
       cm: 526,
       in: 207.09,
       ft: 17.26
    },
    width: {
       cm: 183.5,
       in: 72.24,
       ft: 6.02
    },
    height: {
       cm: 186,
       in: 73.23,
       ft: 6.1
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Toyota iQ 2009",
    make: "Toyota",
    model: "iQ 2009",
    length: {
       cm: 298.5,
       in: 117.52,
       ft: 9.79
    },
    width: {
       cm: 168,
       in: 66.14,
       ft: 5.51
    },
    height: {
       cm: 150,
       in: 59.06,
       ft: 4.92
    },
    capacity: {
       cm: 238,
       in: 14523.65,
       ft: 8.4
    }
 },
  {
    fullName: "Toyota Land Cruiser 3p",
    make: "Toyota",
    model: "Land Cruiser 3p",
    length: {
       cm: 456.5,
       in: 179.72,
       ft: 14.98
    },
    width: {
       cm: 188.5,
       in: 74.21,
       ft: 6.18
    },
    height: {
       cm: 183,
       in: 72.05,
       ft: 6
    },
    capacity: {
       cm: 390,
       in: 23799.27,
       ft: 13.77
    }
 },
  {
    fullName: "Toyota Land Cruiser 5p",
    make: "Toyota",
    model: "Land Cruiser 5p",
    length: {
       cm: 484,
       in: 190.55,
       ft: 15.88
    },
    width: {
       cm: 188.5,
       in: 74.21,
       ft: 6.18
    },
    height: {
       cm: 184.5,
       in: 72.64,
       ft: 6.05
    },
    capacity: {
       cm: 640,
       in: 39055.21,
       ft: 22.6
    }
 },
  {
    fullName: "Toyota Mirai",
    make: "Toyota",
    model: "Mirai",
    length: {
       cm: 489,
       in: 192.52,
       ft: 16.04
    },
    width: {
       cm: 181.5,
       in: 71.46,
       ft: 5.95
    },
    height: {
       cm: 153.5,
       in: 60.43,
       ft: 5.04
    },
    capacity: {
       cm: 361,
       in: 22029.58,
       ft: 12.75
    }
 },
  {
    fullName: "Toyota Prius",
    make: "Toyota",
    model: "Prius",
    length: {
       cm: 454,
       in: 178.74,
       ft: 14.9
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 502,
       in: 30633.93,
       ft: 17.73
    }
 },
  {
    fullName: "Toyota Prius I 2000",
    make: "Toyota",
    model: "Prius I 2000",
    length: {
       cm: 414,
       in: 162.99,
       ft: 13.58
    },
    width: {
       cm: 170,
       in: 66.93,
       ft: 5.58
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 390,
       in: 23799.27,
       ft: 13.77
    }
 },
  {
    fullName: "Toyota Prius II 2004",
    make: "Toyota",
    model: "Prius II 2004",
    length: {
       cm: 445,
       in: 175.2,
       ft: 14.6
    },
    width: {
       cm: 172.5,
       in: 67.91,
       ft: 5.66
    },
    height: {
       cm: 149,
       in: 58.66,
       ft: 4.89
    },
    capacity: {
       cm: 408,
       in: 24897.69,
       ft: 14.41
    }
 },
  {
    fullName: "Toyota Prius Plug-in Hybrid",
    make: "Toyota",
    model: "Prius Plug-in Hybrid",
    length: {
       cm: 464.5,
       in: 182.87,
       ft: 15.24
    },
    width: {
       cm: 176,
       in: 69.29,
       ft: 5.77
    },
    height: {
       cm: 147,
       in: 57.87,
       ft: 4.82
    },
    capacity: {
       cm: 359,
       in: 21907.53,
       ft: 12.68
    }
 },
  {
    fullName: "Toyota Prius+",
    make: "Toyota",
    model: "Prius+",
    length: {
       cm: 464.5,
       in: 182.87,
       ft: 15.24
    },
    width: {
       cm: 177.5,
       in: 69.88,
       ft: 5.82
    },
    height: {
       cm: 157.5,
       in: 62.01,
       ft: 5.17
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Toyota Proace ct 2013",
    make: "Toyota",
    model: "Proace ct 2013",
    length: {
       cm: 481.3,
       in: 189.49,
       ft: 15.79
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 198,
       in: 77.95,
       ft: 6.5
    },
    capacity: {
       cm: 2900,
       in: 176968.9,
       ft: 102.41
    }
 },
  {
    fullName: "Toyota Proace lg 2013",
    make: "Toyota",
    model: "Proace lg 2013",
    length: {
       cm: 514.3,
       in: 202.48,
       ft: 16.87
    },
    width: {
       cm: 189.5,
       in: 74.61,
       ft: 6.22
    },
    height: {
       cm: 198,
       in: 77.95,
       ft: 6.5
    },
    capacity: {
       cm: 3600,
       in: 219685.53,
       ft: 127.13
    }
 },
  {
    fullName: "Toyota Proace Verso Compact",
    make: "Toyota",
    model: "Proace Verso Compact",
    length: {
       cm: 460.9,
       in: 181.46,
       ft: 15.12
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 190.5,
       in: 75,
       ft: 6.25
    },
    capacity: {
       cm: 1978,
       in: 120705,
       ft: 69.85
    }
 },
  {
    fullName: "Toyota Proace Verso Long",
    make: "Toyota",
    model: "Proace Verso Long",
    length: {
       cm: 530.9,
       in: 209.02,
       ft: 17.42
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2932,
       in: 178921.66,
       ft: 103.54
    }
 },
  {
    fullName: "Toyota Proace Verso Medium",
    make: "Toyota",
    model: "Proace Verso Medium",
    length: {
       cm: 495.9,
       in: 195.24,
       ft: 16.27
    },
    width: {
       cm: 192,
       in: 75.59,
       ft: 6.3
    },
    height: {
       cm: 189,
       in: 74.41,
       ft: 6.2
    },
    capacity: {
       cm: 2381,
       in: 145297.57,
       ft: 84.09
    }
 },
  {
    fullName: "Toyota RAV4",
    make: "Toyota",
    model: "RAV4",
    length: {
       cm: 460,
       in: 181.1,
       ft: 15.09
    },
    width: {
       cm: 185.5,
       in: 73.03,
       ft: 6.09
    },
    height: {
       cm: 168.5,
       in: 66.34,
       ft: 5.53
    },
    capacity: {
       cm: 580,
       in: 35393.78,
       ft: 20.48
    }
 },
  {
    fullName: "Toyota RAV4 2013",
    make: "Toyota",
    model: "RAV4 2013",
    length: {
       cm: 457,
       in: 179.92,
       ft: 14.99
    },
    width: {
       cm: 184.5,
       in: 72.64,
       ft: 6.05
    },
    height: {
       cm: 166,
       in: 65.35,
       ft: 5.45
    },
    capacity: {
       cm: 547,
       in: 33380,
       ft: 19.32
    }
 },
  {
    fullName: "Toyota Yaris",
    make: "Toyota",
    model: "Yaris",
    length: {
       cm: 394.5,
       in: 155.31,
       ft: 12.94
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 286,
       in: 17452.8,
       ft: 10.1
    }
 },
  {
    fullName: "Toyota Yaris 2 2005",
    make: "Toyota",
    model: "Yaris 2 2005",
    length: {
       cm: 375,
       in: 147.64,
       ft: 12.3
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 153,
       in: 60.24,
       ft: 5.02
    },
    capacity: {
       cm: 272,
       in: 16598.46,
       ft: 9.61
    }
 },
  {
    fullName: "Toyota Yaris 2010",
    make: "Toyota",
    model: "Yaris 2010",
    length: {
       cm: 378.5,
       in: 149.02,
       ft: 12.42
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 153,
       in: 60.24,
       ft: 5.02
    },
    capacity: {
       cm: 272,
       in: 16598.46,
       ft: 9.61
    }
 },
  {
    fullName: "Toyota Yaris 2012",
    make: "Toyota",
    model: "Yaris 2012",
    length: {
       cm: 388.5,
       in: 152.95,
       ft: 12.75
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 286,
       in: 17452.8,
       ft: 10.1
    }
 },
  {
    fullName: "Toyota Yaris 2014",
    make: "Toyota",
    model: "Yaris 2014",
    length: {
       cm: 394.5,
       in: 155.31,
       ft: 12.94
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 286,
       in: 17452.8,
       ft: 10.1
    }
 },
  {
    fullName: "Toyota Yaris 3 2012",
    make: "Toyota",
    model: "Yaris 3 2012",
    length: {
       cm: 388.5,
       in: 152.95,
       ft: 12.75
    },
    width: {
       cm: 169.5,
       in: 66.73,
       ft: 5.56
    },
    height: {
       cm: 151,
       in: 59.45,
       ft: 4.95
    },
    capacity: {
       cm: 286,
       in: 17452.8,
       ft: 10.1
    }
 },
  {
    fullName: "Volkswagen Amarok",
    make: "Volkswagen",
    model: "Amarok",
    length: {
       cm: 525.4,
       in: 206.85,
       ft: 17.24
    },
    width: {
       cm: 195.4,
       in: 76.93,
       ft: 6.41
    },
    height: {
       cm: 183.4,
       in: 72.2,
       ft: 6.02
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Volkswagen Arteon",
    make: "Volkswagen",
    model: "Arteon",
    length: {
       cm: 486.2,
       in: 191.42,
       ft: 15.95
    },
    width: {
       cm: 187.1,
       in: 73.66,
       ft: 6.14
    },
    height: {
       cm: 142.7,
       in: 56.18,
       ft: 4.68
    },
    capacity: {
       cm: 563,
       in: 34356.38,
       ft: 19.88
    }
 },
  {
    fullName: "Volkswagen Caddy",
    make: "Volkswagen",
    model: "Caddy",
    length: {
       cm: 440.8,
       in: 173.54,
       ft: 14.46
    },
    width: {
       cm: 179.3,
       in: 70.59,
       ft: 5.88
    },
    height: {
       cm: 182.2,
       in: 71.73,
       ft: 5.98
    },
    capacity: {
       cm: 750,
       in: 45767.82,
       ft: 26.49
    }
 },
  {
    fullName: "Volkswagen Caddy Maxi",
    make: "Volkswagen",
    model: "Caddy Maxi",
    length: {
       cm: 487.8,
       in: 192.05,
       ft: 16
    },
    width: {
       cm: 179.3,
       in: 70.59,
       ft: 5.88
    },
    height: {
       cm: 183.1,
       in: 72.09,
       ft: 6.01
    },
    capacity: {
       cm: 1350,
       in: 82382.07,
       ft: 47.68
    }
 },
  {
    fullName: "Volkswagen eGolf 2017",
    make: "Volkswagen",
    model: "eGolf 2017",
    length: {
       cm: 425.8,
       in: 167.64,
       ft: 13.97
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 145.2,
       in: 57.17,
       ft: 4.76
    },
    capacity: {
       cm: 341,
       in: 20809.1,
       ft: 12.04
    }
 },
  {
    fullName: "Volkswagen Golf",
    make: "Volkswagen",
    model: "Golf",
    length: {
       cm: 428.4,
       in: 168.66,
       ft: 14.06
    },
    width: {
       cm: 178.9,
       in: 70.43,
       ft: 5.87
    },
    height: {
       cm: 145.6,
       in: 57.32,
       ft: 4.78
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Volkswagen Golf 2008",
    make: "Volkswagen",
    model: "Golf 2008",
    length: {
       cm: 419.9,
       in: 165.31,
       ft: 13.78
    },
    width: {
       cm: 178.6,
       in: 70.31,
       ft: 5.86
    },
    height: {
       cm: 148,
       in: 58.27,
       ft: 4.86
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Volkswagen Golf 2012",
    make: "Volkswagen",
    model: "Golf 2012",
    length: {
       cm: 425.5,
       in: 167.52,
       ft: 13.96
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 145.2,
       in: 57.17,
       ft: 4.76
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Volkswagen Golf 2017",
    make: "Volkswagen",
    model: "Golf 2017",
    length: {
       cm: 425.8,
       in: 167.64,
       ft: 13.97
    },
    width: {
       cm: 179,
       in: 70.47,
       ft: 5.87
    },
    height: {
       cm: 145.2,
       in: 57.17,
       ft: 4.76
    },
    capacity: {
       cm: 272,
       in: 16598.46,
       ft: 9.61
    }
 },
  {
    fullName: "Volkswagen Golf 5 2003",
    make: "Volkswagen",
    model: "Golf 5 2003",
    length: {
       cm: 420.4,
       in: 165.51,
       ft: 13.79
    },
    width: {
       cm: 175.9,
       in: 69.25,
       ft: 5.77
    },
    height: {
       cm: 148.5,
       in: 58.46,
       ft: 4.87
    },
    capacity: {
       cm: 350,
       in: 21358.32,
       ft: 12.36
    }
 },
  {
    fullName: "Volkswagen Golf 7 2012",
    make: "Volkswagen",
    model: "Golf 7 2012",
    length: {
       cm: 263.7,
       in: 103.82,
       ft: 8.65
    },
    width: {
       cm: 425.5,
       in: 167.52,
       ft: 13.96
    },
    height: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    capacity: {
       cm: 380,
       in: 23189.03,
       ft: 13.42
    }
 },
  {
    fullName: "Volkswagen Golf Alltrack",
    make: "Volkswagen",
    model: "Golf Alltrack",
    length: {
       cm: 457.8,
       in: 180.24,
       ft: 15.02
    },
    width: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    height: {
       cm: 151.5,
       in: 59.65,
       ft: 4.97
    },
    capacity: {
       cm: 605,
       in: 36919.37,
       ft: 21.37
    }
 },
  {
    fullName: "Volkswagen Golf Plus 2009",
    make: "Volkswagen",
    model: "Golf Plus 2009",
    length: {
       cm: 420.6,
       in: 165.59,
       ft: 13.8
    },
    width: {
       cm: 175.9,
       in: 69.25,
       ft: 5.77
    },
    height: {
       cm: 158,
       in: 62.2,
       ft: 5.18
    },
    capacity: {
       cm: 395,
       in: 24104.38,
       ft: 13.95
    }
 },
  {
    fullName: "Volkswagen Golf Plus 2009",
    make: "Volkswagen",
    model: "Golf Plus 2009",
    length: {
       cm: 420.4,
       in: 165.51,
       ft: 13.79
    },
    width: {
       cm: 175.9,
       in: 69.25,
       ft: 5.77
    },
    height: {
       cm: 159.2,
       in: 62.68,
       ft: 5.22
    },
    capacity: {
       cm: 395,
       in: 24104.38,
       ft: 13.95
    }
 },
  {
    fullName: "Volkswagen Golf Sportsvan",
    make: "Volkswagen",
    model: "Golf Sportsvan",
    length: {
       cm: 435.1,
       in: 171.3,
       ft: 14.27
    },
    width: {
       cm: 180.7,
       in: 71.14,
       ft: 5.93
    },
    height: {
       cm: 157.8,
       in: 62.13,
       ft: 5.18
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Volkswagen Golf Sportsvan 2014",
    make: "Volkswagen",
    model: "Golf Sportsvan 2014",
    length: {
       cm: 433.8,
       in: 170.79,
       ft: 14.23
    },
    width: {
       cm: 180.7,
       in: 71.14,
       ft: 5.93
    },
    height: {
       cm: 157.8,
       in: 62.13,
       ft: 5.18
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Volkswagen Golf Variant",
    make: "Volkswagen",
    model: "Golf Variant",
    length: {
       cm: 456.7,
       in: 179.8,
       ft: 14.98
    },
    width: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 605,
       in: 36919.37,
       ft: 21.37
    }
 },
  {
    fullName: "Volkswagen Golf Variant 2008",
    make: "Volkswagen",
    model: "Golf Variant 2008",
    length: {
       cm: 453.4,
       in: 178.5,
       ft: 14.88
    },
    width: {
       cm: 178.1,
       in: 70.12,
       ft: 5.84
    },
    height: {
       cm: 150.4,
       in: 59.21,
       ft: 4.93
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Volkswagen Golf Variant 2013",
    make: "Volkswagen",
    model: "Golf Variant 2013",
    length: {
       cm: 456.2,
       in: 179.61,
       ft: 14.97
    },
    width: {
       cm: 179.9,
       in: 70.83,
       ft: 5.9
    },
    height: {
       cm: 148.1,
       in: 58.31,
       ft: 4.86
    },
    capacity: {
       cm: 605,
       in: 36919.37,
       ft: 21.37
    }
 },
  {
    fullName: "Volkswagen ID.3",
    make: "Volkswagen",
    model: "ID.3",
    length: {
       cm: 426.1,
       in: 167.76,
       ft: 13.98
    },
    width: {
       cm: 180.9,
       in: 71.22,
       ft: 5.94
    },
    height: {
       cm: 155.2,
       in: 61.1,
       ft: 5.09
    },
    capacity: {
       cm: 385,
       in: 23494.15,
       ft: 13.6
    }
 },
  {
    fullName: "Volkswagen Passat",
    make: "Volkswagen",
    model: "Passat",
    length: {
       cm: 477.5,
       in: 187.99,
       ft: 15.67
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 148.3,
       in: 58.39,
       ft: 4.87
    },
    capacity: {
       cm: 586,
       in: 35759.92,
       ft: 20.69
    }
 },
  {
    fullName: "Volkswagen Passat 2014",
    make: "Volkswagen",
    model: "Passat B8 2014",
    length: {
       cm: 476.7,
       in: 187.68,
       ft: 15.64
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 147.7,
       in: 58.15,
       ft: 4.85
    },
    capacity: {
       cm: 650,
       in: 39665.44,
       ft: 22.95
    }
 },
  {
    fullName: "Volkswagen Passat Alltrack",
    make: "Volkswagen",
    model: "Passat Alltrack",
    length: {
       cm: 478,
       in: 188.19,
       ft: 15.68
    },
    width: {
       cm: 185.3,
       in: 72.95,
       ft: 6.08
    },
    height: {
       cm: 152.7,
       in: 60.12,
       ft: 5.01
    },
    capacity: {
       cm: 639,
       in: 38994.18,
       ft: 22.57
    }
 },
  {
    fullName: "Volkswagen Passat B6 2005",
    make: "Volkswagen",
    model: "Passat B6 2005",
    length: {
       cm: 476.5,
       in: 187.6,
       ft: 15.63
    },
    width: {
       cm: 182,
       in: 71.65,
       ft: 5.97
    },
    height: {
       cm: 147.2,
       in: 57.95,
       ft: 4.83
    },
    capacity: {
       cm: 485,
       in: 29596.52,
       ft: 17.13
    }
 },
  {
    fullName: "Volkswagen Passat Variant",
    make: "Volkswagen",
    model: "Passat Variant",
    length: {
       cm: 477.3,
       in: 187.91,
       ft: 15.66
    },
    width: {
       cm: 183.2,
       in: 72.13,
       ft: 6.01
    },
    height: {
       cm: 151.6,
       in: 59.69,
       ft: 4.97
    },
    capacity: {
       cm: 650,
       in: 39665.44,
       ft: 22.95
    }
 },
  {
    fullName: "Volkswagen Polo",
    make: "Volkswagen",
    model: "Polo",
    length: {
       cm: 405.3,
       in: 159.57,
       ft: 13.3
    },
    width: {
       cm: 175.1,
       in: 68.94,
       ft: 5.74
    },
    height: {
       cm: 144.6,
       in: 56.93,
       ft: 4.74
    },
    capacity: {
       cm: 351,
       in: 21419.34,
       ft: 12.4
    }
 },
  {
    fullName: "Volkswagen Polo 2011",
    make: "Volkswagen",
    model: "Polo 2011",
    length: {
       cm: 397,
       in: 156.3,
       ft: 13.02
    },
    width: {
       cm: 168.2,
       in: 66.22,
       ft: 5.52
    },
    height: {
       cm: 146.2,
       in: 57.56,
       ft: 4.8
    },
    capacity: {
       cm: 280,
       in: 17086.65,
       ft: 9.89
    }
 },
  {
    fullName: "Volkswagen Polo 2014",
    make: "Volkswagen",
    model: "Polo 2014",
    length: {
       cm: 397.2,
       in: 156.38,
       ft: 13.03
    },
    width: {
       cm: 168.2,
       in: 66.22,
       ft: 5.52
    },
    height: {
       cm: 146.2,
       in: 57.56,
       ft: 4.8
    },
    capacity: {
       cm: 280,
       in: 17086.65,
       ft: 9.89
    }
 },
  {
    fullName: "Volkswagen Polo 5 2009",
    make: "Volkswagen",
    model: "Polo 5 2009",
    length: {
       cm: 247,
       in: 97.24,
       ft: 8.1
    },
    width: {
       cm: 397,
       in: 156.3,
       ft: 13.02
    },
    height: {
       cm: 168.2,
       in: 66.22,
       ft: 5.52
    },
    capacity: {
       cm: 280,
       in: 17086.65,
       ft: 9.89
    }
 },
  {
    fullName: "Volkswagen Sharan",
    make: "Volkswagen",
    model: "Sharan",
    length: {
       cm: 485.4,
       in: 191.1,
       ft: 15.93
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 174,
       in: 68.5,
       ft: 5.71
    },
    capacity: {
       cm: 711,
       in: 43387.89,
       ft: 25.11
    }
 },
  {
    fullName: "Volkswagen T6 California",
    make: "Volkswagen",
    model: "T6 California",
    length: {
       cm: 490.4,
       in: 193.07,
       ft: 16.09
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Volkswagen T6 Caravelle ct",
    make: "Volkswagen",
    model: "T6 Caravelle ct",
    length: {
       cm: 490.4,
       in: 193.07,
       ft: 16.09
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Volkswagen T6 Caravelle lg",
    make: "Volkswagen",
    model: "T6 Caravelle lg",
    length: {
       cm: 530.4,
       in: 208.82,
       ft: 17.4
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 199,
       in: 78.35,
       ft: 6.53
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Volkswagen T6 Multivan",
    make: "Volkswagen",
    model: "T6 Multivan",
    length: {
       cm: 490.4,
       in: 193.07,
       ft: 16.09
    },
    width: {
       cm: 190.4,
       in: 74.96,
       ft: 6.25
    },
    height: {
       cm: 197,
       in: 77.56,
       ft: 6.46
    },
    capacity: {
       cm: null,
       in: null,
       ft: null
    }
 },
  {
    fullName: "Volkswagen T-Cross",
    make: "Volkswagen",
    model: "T-Cross",
    length: {
       cm: 411,
       in: 161.81,
       ft: 13.48
    },
    width: {
       cm: 175.6,
       in: 69.13,
       ft: 5.76
    },
    height: {
       cm: 155.9,
       in: 61.38,
       ft: 5.11
    },
    capacity: {
       cm: 385,
       in: 23494.15,
       ft: 13.6
    }
 },
  {
    fullName: "Volkswagen Tiguan",
    make: "Volkswagen",
    model: "Tiguan",
    length: {
       cm: 448.6,
       in: 176.61,
       ft: 14.72
    },
    width: {
       cm: 183.9,
       in: 72.4,
       ft: 6.03
    },
    height: {
       cm: 165.7,
       in: 65.24,
       ft: 5.44
    },
    capacity: {
       cm: 520,
       in: 31732.35,
       ft: 18.36
    }
 },
  {
    fullName: "Volkswagen Tiguan 2008",
    make: "Volkswagen",
    model: "Tiguan 2008",
    length: {
       cm: 442.7,
       in: 174.29,
       ft: 14.52
    },
    width: {
       cm: 180.9,
       in: 71.22,
       ft: 5.94
    },
    height: {
       cm: 168.6,
       in: 66.38,
       ft: 5.53
    },
    capacity: {
       cm: 470,
       in: 28681.17,
       ft: 16.6
    }
 },
  {
    fullName: "Volkswagen Tiguan 2011",
    make: "Volkswagen",
    model: "Tiguan 2011",
    length: {
       cm: 442.6,
       in: 174.25,
       ft: 14.52
    },
    width: {
       cm: 180.9,
       in: 71.22,
       ft: 5.94
    },
    height: {
       cm: 170.3,
       in: 67.05,
       ft: 5.59
    },
    capacity: {
       cm: 470,
       in: 28681.17,
       ft: 16.6
    }
 },
  {
    fullName: "Volkswagen Tiguan Allspace",
    make: "Volkswagen",
    model: "Tiguan Allspace",
    length: {
       cm: 470.1,
       in: 185.08,
       ft: 15.42
    },
    width: {
       cm: 183.9,
       in: 72.4,
       ft: 6.03
    },
    height: {
       cm: 167.4,
       in: 65.91,
       ft: 5.49
    },
    capacity: {
       cm: 700,
       in: 42716.63,
       ft: 24.72
    }
 },
  {
    fullName: "Volkswagen Touareg",
    make: "Volkswagen",
    model: "Touareg",
    length: {
       cm: 487.8,
       in: 192.05,
       ft: 16
    },
    width: {
       cm: 198.4,
       in: 78.11,
       ft: 6.51
    },
    height: {
       cm: 170.2,
       in: 67.01,
       ft: 5.58
    },
    capacity: {
       cm: 810,
       in: 49429.24,
       ft: 28.61
    }
 },
  {
    fullName: "Volkswagen Touran",
    make: "Volkswagen",
    model: "Touran",
    length: {
       cm: 452.7,
       in: 178.23,
       ft: 14.85
    },
    width: {
       cm: 182.9,
       in: 72.01,
       ft: 6
    },
    height: {
       cm: 165.9,
       in: 65.31,
       ft: 5.44
    },
    capacity: {
       cm: 633,
       in: 38628.04,
       ft: 22.35
    }
 },
  {
    fullName: "Volkswagen T-Roc",
    make: "Volkswagen",
    model: "T-Roc",
    length: {
       cm: 423.4,
       in: 166.69,
       ft: 13.89
    },
    width: {
       cm: 181.9,
       in: 71.61,
       ft: 5.97
    },
    height: {
       cm: 157.3,
       in: 61.93,
       ft: 5.16
    },
    capacity: {
       cm: 445,
       in: 27155.57,
       ft: 15.72
    }
 },
  {
    fullName: "Volkswagen up!",
    make: "Volkswagen",
    model: "up!",
    length: {
       cm: 360,
       in: 141.73,
       ft: 11.81
    },
    width: {
       cm: 164.5,
       in: 64.76,
       ft: 5.4
    },
    height: {
       cm: 150.4,
       in: 59.21,
       ft: 4.93
    },
    capacity: {
       cm: 251,
       in: 15316.96,
       ft: 8.86
    }
 },
  {
    fullName: "Volkswagen up! 2012",
    make: "Volkswagen",
    model: "Up! 2012",
    length: {
       cm: 242,
       in: 95.28,
       ft: 7.94
    },
    width: {
       cm: 354,
       in: 139.37,
       ft: 11.61
    },
    height: {
       cm: 164.1,
       in: 64.61,
       ft: 5.38
    },
    capacity: {
       cm: 251,
       in: 15316.96,
       ft: 8.86
    }
 },
  {
    fullName: "Volvo C30 2006",
    make: "Volvo",
    model: "C30 2006",
    length: {
       cm: 425.2,
       in: 167.4,
       ft: 13.95
    },
    width: {
       cm: 178.2,
       in: 70.16,
       ft: 5.85
    },
    height: {
       cm: 144.7,
       in: 56.97,
       ft: 4.75
    },
    capacity: {
       cm: 251,
       in: 15316.96,
       ft: 8.86
    }
 },
  {
    fullName: "Volvo S60",
    make: "Volvo",
    model: "S60",
    length: {
       cm: 476.1,
       in: 187.44,
       ft: 15.62
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 143.7,
       in: 56.57,
       ft: 4.71
    },
    capacity: {
       cm: 442,
       in: 26972.5,
       ft: 15.61
    }
 },
  {
    fullName: "Volvo S60 2.4 2000",
    make: "Volvo",
    model: "S60 2.4 2000",
    length: {
       cm: 457.6,
       in: 180.16,
       ft: 15.01
    },
    width: {
       cm: 180.4,
       in: 71.02,
       ft: 5.92
    },
    height: {
       cm: 142.8,
       in: 56.22,
       ft: 4.69
    },
    capacity: {
       cm: 424,
       in: 25874.07,
       ft: 14.97
    }
 },
  {
    fullName: "Volvo S90",
    make: "Volvo",
    model: "S90",
    length: {
       cm: 496.3,
       in: 195.39,
       ft: 16.28
    },
    width: {
       cm: 187.9,
       in: 73.98,
       ft: 6.16
    },
    height: {
       cm: 144.3,
       in: 56.81,
       ft: 4.73
    },
    capacity: {
       cm: 500,
       in: 30511.88,
       ft: 17.66
    }
 },
  {
    fullName: "Volvo V40",
    make: "Volvo",
    model: "V40",
    length: {
       cm: 436.9,
       in: 172.01,
       ft: 14.33
    },
    width: {
       cm: 180.2,
       in: 70.94,
       ft: 5.91
    },
    height: {
       cm: 142,
       in: 55.91,
       ft: 4.66
    },
    capacity: {
       cm: 335,
       in: 20442.96,
       ft: 11.83
    }
 },
  {
    fullName: "Volvo V40 Cross Country",
    make: "Volvo",
    model: "V40 Cross Country",
    length: {
       cm: 437,
       in: 172.05,
       ft: 14.34
    },
    width: {
       cm: 180.2,
       in: 70.94,
       ft: 5.91
    },
    height: {
       cm: 145.8,
       in: 57.4,
       ft: 4.78
    },
    capacity: {
       cm: 335,
       in: 20442.96,
       ft: 11.83
    }
 },
  {
    fullName: "Volvo V40 D2 2012",
    make: "Volvo",
    model: "Volvo V40 D2 2012",
    length: {
       cm: 436.9,
       in: 172.01,
       ft: 14.33
    },
    width: {
       cm: 180.2,
       in: 70.94,
       ft: 5.91
    },
    height: {
       cm: 142,
       in: 55.91,
       ft: 4.66
    },
    capacity: {
       cm: 335,
       in: 20442.96,
       ft: 11.83
    }
 },
  {
    fullName: "Volvo V60",
    make: "Volvo",
    model: "V60",
    length: {
       cm: 476.1,
       in: 187.44,
       ft: 15.62
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 143.3,
       in: 56.42,
       ft: 4.7
    },
    capacity: {
       cm: 529,
       in: 32281.57,
       ft: 18.68
    }
 },
  {
    fullName: "Volvo V60 Cross Country",
    make: "Volvo",
    model: "V60 Cross Country",
    length: {
       cm: 478.4,
       in: 188.35,
       ft: 15.7
    },
    width: {
       cm: 185,
       in: 72.83,
       ft: 6.07
    },
    height: {
       cm: 150.4,
       in: 59.21,
       ft: 4.93
    },
    capacity: {
       cm: 529,
       in: 32281.57,
       ft: 18.68
    }
 },
  {
    fullName: "Volvo V90",
    make: "Volvo",
    model: "V90",
    length: {
       cm: 493.6,
       in: 194.33,
       ft: 16.19
    },
    width: {
       cm: 187.9,
       in: 73.98,
       ft: 6.16
    },
    height: {
       cm: 147.5,
       in: 58.07,
       ft: 4.84
    },
    capacity: {
       cm: 560,
       in: 34173.31,
       ft: 19.78
    }
 },
  {
    fullName: "Volvo V90 Cross Country",
    make: "Volvo",
    model: "V90 Cross Country",
    length: {
       cm: 493.9,
       in: 194.45,
       ft: 16.2
    },
    width: {
       cm: 187.9,
       in: 73.98,
       ft: 6.16
    },
    height: {
       cm: 154.3,
       in: 60.75,
       ft: 5.06
    },
    capacity: {
       cm: 560,
       in: 34173.31,
       ft: 19.78
    }
 },
  {
    fullName: "Volvo XC40",
    make: "Volvo",
    model: "XC40",
    length: {
       cm: 442.5,
       in: 174.21,
       ft: 14.52
    },
    width: {
       cm: 185.1,
       in: 72.87,
       ft: 6.07
    },
    height: {
       cm: 165.2,
       in: 65.04,
       ft: 5.42
    },
    capacity: {
       cm: 460,
       in: 28070.93,
       ft: 16.24
    }
 },
  {
    fullName: "Volvo XC60",
    make: "Volvo",
    model: "XC60",
    length: {
       cm: 468.8,
       in: 184.57,
       ft: 15.38
    },
    width: {
       cm: 190.2,
       in: 74.88,
       ft: 6.24
    },
    height: {
       cm: 165.8,
       in: 65.28,
       ft: 5.44
    },
    capacity: {
       cm: 505,
       in: 30817,
       ft: 17.83
    }
 },
  {
    fullName: "Volvo XC90",
    make: "Volvo",
    model: "XC90",
    length: {
       cm: 495.3,
       in: 195,
       ft: 16.25
    },
    width: {
       cm: 192.3,
       in: 75.71,
       ft: 6.31
    },
    height: {
       cm: 177.6,
       in: 69.92,
       ft: 5.83
    },
    capacity: {
       cm: 692,
       in: 42228.44,
       ft: 24.44
    }
 },
  {
    fullName: "Volvo XC90 2015",
    make: "Volvo",
    model: "XC90 2015",
    length: {
       cm: 495,
       in: 194.88,
       ft: 16.24
    },
    width: {
       cm: 192.3,
       in: 75.71,
       ft: 6.31
    },
    height: {
       cm: 177.6,
       in: 69.92,
       ft: 5.83
    },
    capacity: {
       cm: 314,
       in: 19161.46,
       ft: 11.09
    }
 }
 ]