import "./App.css";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import AppRoute from "routes";
import NavigationScroll from "layout/NavigationScroll";

// defaultTheme
import themes from "themes";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServices from "services/RequestHandler";
import { getDeviceToken, requestPermission } from "utils/notificationListner";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
// project imports

function App() {
let user = getDataFromLocalStorage("user")
  
  useEffect(() => {
    if(user){
      checkForPermission();
    }

  }, []);

  const customization = useSelector((state: any) => state.customization);

  const checkForPermission = async () => {
    try {
      if (await requestPermission()) {
        const _token = await getDeviceToken();
        // eslint-disable-next-line
        await apiServices.updateToken("transporters/update-device-token", {
          device_token: _token,
        });
      } else {
        alert(
          "Notification Permission Denied, please allow notification permision from browser settings"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <NavigationScroll>
          <CssBaseline />
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
      <ToastContainer />
    </StyledEngineProvider>
  );
}

export default App;
