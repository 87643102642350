export const all_orders = {
  status: 200,
  data: [
    {
      order_id: "123412342855",
      order_date: "11 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Henry Patrick",
      amount: "€ 10.00",
      order_status: "Waiting",
      rider: "- - -",
      origin: "Paris, FRA",
      destination: "Paris, FRA",
    },
    {
      order_id: "123412342854",
      order_date: "11 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Flex",
      isStorage: true,
      customer: "Arthur Louis",
      amount: "€ 20.00",
      order_status: "Delivered",
      rider: "Abge Brett",
      origin: "Paris, FRA",
      destination: "Lyon, FRA",
    },
    {
      order_id: "123412342853",
      order_date: "11 Nov 2022",
      order_type: "C2C Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Paul Baker",
      amount: "€ 12.50",
      order_status: "Waiting",
      rider: "- - -",
      origin: "Bordeauz, FRA",
      destination: "Bordeauz, FRA",
    },
    {
      order_id: "123412342852",
      order_date: "10 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Harris Noel",
      amount: "€ 10.00",
      order_status: "In-Progress",
      rider: "Eric Strappe",
      origin: "Paris, FRA",
      destination: "Frankfurt, DEU",
    },
    {
      order_id: "123412342851",
      order_date: "10 Nov 2022",
      order_type: "C2C Shipping",
      delivery_type: "Green",
      isStorage: true,
      customer: "Nadia Loren",
      amount: "€ 16.00",
      order_status: "In-Progress",
      rider: "Steve Evra",
      origin: "Marseilles, FRA",
      destination: "Marseilles, FRA",
    },
    {
      order_id: "123412342850",
      order_date: "09 Nov 2022",
      order_type: "Storage Only",
      delivery_type: null,
      isStorage: null,
      customer: "Quinton Cedric",
      amount: "€ 12.00",
      order_status: "Delivered",
      rider: "Roger Gallas",
      origin: "Lyon, FRA",
      destination: "Lyon, FRA",
    },
    {
      order_id: "123412342849",
      order_date: "09 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "George Alice",
      amount: "€ 10.00",
      order_status: "Waiting",
      rider: "- - -",
      origin: "Milan, ITA",
      destination: "Milan, ITA",
    },
    {
      order_id: "123412342848",
      order_date: "09 Nov 2022",
      order_type: "C2C Air Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Henri Noel",
      amount: "€ 25.00",
      order_status: "Delivered",
      rider: "Roger Gallas",
      origin: "Paris, FRA",
      destination: "Paris, FRA",
    },
    {
      order_id: "123412342847",
      order_date: "08 Nov 2022",
      order_type: "C2C Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Lina Michelle",
      amount: "€ 18.50",
      order_status: "Cancelled",
      rider: "- - -",
      origin: "Barcelona, ESP",
      destination: "Barcelona, ESP",
    },
    {
      order_id: "123412342846",
      order_date: "08 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: true,
      customer: "Will G. Alice",
      amount: "€ 12.00",
      order_status: "Delivered",
      rider: "Allan Fynn",
      origin: "London, UK",
      destination: "London, UK",
    },
  ],
};
export const my_driver = {
  status: 200,
  data: [
    {
      driver_id: "123412342855",
      driver_name: "John Wick",
      status: "Acitve",
      total_jobs: "12",
      assigned: "5",
      pending: "5",
      totalPaid : '$1256.00'
    },
  ],
};
export const earnings={
  status: 200,
  data: [
    {
      order_id: "ODR-2323-33221-3",
      order_type:'C2C Courier',
      subRider: "John Wick",
      delivery_date: "02-04-2023",
      route_type: "single",
      orderAmount:'33.00',
      commission: "15.00 %",
      comisionAmount:'23.00',
      bonus_if_any: "5.00",
      deduction_if_any : '$12.00',
      earning : '$56.00',
      payment_status : 'Due',
      payment_date : '01.04.2023',

    },
  ],
}
export const service_area = {
  status: 200,
  data: [
    {
      service_area_id: "123412342855",
      service_area: "James Bond",
      timeSlot: "9 am to 9 pm",
      Repeatition: "12",
      status: "Active",
    },
  ],
};
export const my_subRider = {
  status: 200,
  data: [
    {
      subRider_id: "123412342855",
      subRider_name: "John Wickk",
      status: "Acitve",
      total_jobs: "12",
      assigned: "5",
      pending: "5",
      totalPaid : '$1256.00'
    },
  ],
};
export const my_vehicle = {
  status: 200,
  data: [
    {
      vahicle_id: "123412342855",
      model_name: "Toyota Subaro",
      vehicle_type: "sedan",
      reg_no: "BL-2323",
      color: "red",
      total_jobs_done: "5",
    },
    {
      vahicle_id: "123412342856",
      model_name: "Changan Alsvin",
      vehicle_type: "sedan",
      reg_no: "CT-1983",
      color: "black",
      total_jobs_done: "10",
    },
    {
      vahicle_id: "123412342857",
      model_name: "Santaro",
      vehicle_type: "hatchback",
      reg_no: "YLD-6712",
      color: "yellow",
      total_jobs_done: "18",
    },
  ],
};