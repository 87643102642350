import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { getStatusLabelColor, getStatusLabelForTable } from "utils/helper";

export interface OrderState {
  all_orders: any;
  all_orders_data: any;
  selected_order: object;
  activity: boolean;
  paginate_data?: {
    current_count: any;
    // current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

interface APIParams {
  end_point: string;
}

const initialState: OrderState = {
  all_orders: [],
  all_orders_data: [],
  selected_order: {},
  activity: true,
};

let getSettingsData = getDataFromLocalStorage("settings");

export const orderRequests = createAsyncThunk(
  "orders/get-order-histories",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setSelectedOrder: (state: OrderState, action: any) => {
      state.selected_order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderRequests.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(orderRequests.fulfilled, (state, { payload }) => {
        try {
          let orders = [] as any;
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            // eslint-disable-next-line
            {item?.order_status !== "waiting"
                ? orders.push([
                    item?.id || "- - -",
                    moment(item?.order_placed_at).format(
                      getSettingsData?.date_format ?? "DD MMM YYYY"
                    ) || "- - -",
                    item?.order_type || "- - -",
                    item?.delivery_type || "- - -",
                    item?.isStorage ? "Yes" : "No",
                    item?.full_name || "- - -",
                    item?.expected_earnings?.expected_earning ? `$ ${item?.expected_earnings?.expected_earning.toFixed(2)}` : "- - -",
                    {
                      name: getStatusLabelForTable(item?.order_status) || "- - -",
                      is_bool: true,
                      color: getStatusLabelColor(item?.order_status),
                      style: { color: getStatusLabelColor(item?.order_status) },
                    },
                    item?.transporter_name || "- - -",
                    item?.origin ?? "- - -",
                    item?.destination ?? "- - -",
                  ])
                : null;
            }
          }
          state.all_orders_data = payload?.data;
          state.all_orders = orders;
          state.paginate_data = {
            current_count: payload?.current_count,
            // current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages ?? 1,
            total_count: payload?.total_count,
          };
          state.activity = false;
        } catch (error) {
          state.activity = false;
          console.log(error);
        }
      });
  },
});

export const { setSelectedOrder } = orderSlice.actions;

export default orderSlice.reducer;
